export default {
    "en": {
        "colorlab": {
            "add-to-cart": "Finish design",
            "save-configuration": "Finish design"
        },
        "message": {
            "within_14_days": "Expected to be delivered within 2 weeks",
            "delivery_date_field_must_be_greater_than_earliest_delivery_date": "Delivery date field must be greater than {date}."
        },
        "employees": {
            "external_employee_id_info": "Enter the unique reference number of this employee here. This will automatically update your employee's information when changes are made.",
            "excel_upload": "Import via Excel",
            "manual_upload": "Add employee",
            "export": "Export all employees",
            "delete_selected": "Delete selection",
            "source": "Source",
            "source_manual_input": "Manual Input",
            "hris_sync": "API sync",
            "source_excel_upload": "Excel Upload",
            "source_hris_sync": "HRIS Sync",
            "employee": "Employee",
            "intro_notes": "'Employees' stores your employees details, allowing you to order with addresses pre-filled, set up Kudos campaigns and automate sending gifts to them on their birthday or work anniversary.",
            "sync": "Automatic synchronization",
            "delete_confirmation_title": "Delete employee?",
            "delete_confirmation": "Are you sure you want to delete this employee?<br>This action can not be undone.",
            "delete_multiple_confirmation_title": "Delete employees?",
            "delete_multiple_confirmation": "Are you sure you want to delete {count} employees?<br>This action can not be undone.",
            "api_connection_title": "Employees API connection",
            "api_connection_description": "Connect your HR system, and we'll automatically synchronize your employees and their details every week.",
            "manual_sync": "Manual sync",
            "auto_sync": "Auto sync",
            "manual_sync_started": "Manual sync started.",
            "auto_sync_started": "Auto sync started.",
            "manual_sync_completed": "Manual sync completed.",
            "auto_sync_completed": "Auto sync completed.",
            "load_error_refresh_page": "Something went wrong. Please try again by refreshing the page.",
            "connection_success": "{connector} has connected.",
            "setup_connection": "Setup connection",
            "loading": "Loading...",
            "setting_up_connection": "Setting up connection...",
            "connect_api_message": "Maximize your efficiency by linking your HR tool to Kadonation Select. This allows us to automatically import your employee data into Kadonation Select so you can more easily place orders for your employees.",
            "connect_api_title": "Sync your employees from your HR system into Kadonation Select",
            "not_synced_yet": "Not synced yet.",
            "last_synced_at": "Last sync",
            "syncing_employees": "Syncing employees",
            "in_progress": "In progress",
            "success": "Success",
            "synchronization": "Synchronization",
            "type": "Type",
            "date": "Date",
            "status": "Status",
            "sync_message": "{count} employees added or updated",
            "employee_sync": "Employee Sync {syncDate}",
            "employee_sync_summary": "Employees that were added or updated during the synchronization with {serviceName} on {syncDate} at {syncTime}",
            "api_connection": "API Connection",
            "missing_address_info": "The employee has no address set, contact an administrator to complete the address through employee management.",
            "failed": "Failed",
            "partial_success": "Partial success",
            "address_info_hidden": "Only users with the permission \"manage employees\" can see the employee's address.",
            "manager": "Manager/teamleader",
            "link_companies": "Link companies",
            "link_team_leader_companies": "Please link your Teamleader companies to your Kadonation companies",
            "kadonation_company": "Kadonation Company",
            "team_leader_company": "Teamleader Company",
            "save_companies": "Save companies",
            "rerun_employee_sync": "Rerun Employee Sync",
            "employees_added_successfully": "Employees added successfully",
            "employees_not_added": "Employees who could not be added",
            "employee_id": "Employee ID",
            "reason_of_failure": "Reason of failure",
            "copy_employee_id": "Copy Employee ID",
            "automatically_sync_your_employees": "Automatically sync your employees",
            "sync_process_error_title": "There was an issue with the employee sync",
            "no_sync": "Add employees automatically",
            "employees_title": "Employee management",
            "modify_connection": "Modify connection",
            "synchronise_now": "Synchronize now",
            "synchronising": "Synchronizing...",
            "no_employees_set_title": "No employees set",
            "no_employees_set_description": "The ‘Employees’ feature stores your employees’ details for easy access throughout Select. Adding employees is easy, just connect to your HR system, or upload an Excel.",
            "after_setting_employees": "When you’ve set Employees, you can:",
            "sync_with_hr_tool": "Automatically synchronise with your HR tool",
            "employees_possiblities_list_items": "Order gifts with addresses and details already filled out |\nCreate birthday and work anniversary gift automations, as well as Kudos campaigns to add team messages for the receiver of a gift |\nReceive reminders of birthdays and work anniversaries (coming soon)",
            "add_manually": "Add manually",
            "what_are_employees": "What is the Employees feature?",
            "company_entity": "Entity",
            "cant_edit_employee": "This employee cannot be edited.",
            "cant_edit_employee_description": "This employee was added via the HRIS synchronisation. Make your changes in the HRIS software and we will apply these on the next synchronisation.",
            "no_email_for_track_and_trace": "This employee doesn't have an email address associated with it. Therefore, we have added a default email for track and trace information.",
            "add_new_team": "Add new team",
            "select_from_saved_teams": "Select from existing team",
            "type_employee": "Employee",
            "type_contractor": "Freelancer",
            "type_temp": "Temporary",
            "type_other": "Other",
            "dispatched": "Pending",
            "sync_throttled": "Please try again later in {time_left} hours",
            "sync_already_in_progress": "There is already an active synchronization job in progress. Please wait for it to complete before trying again.",
            "contributor_qr_code_scan_string": "Hi {name} someone left you a message! Hurry up and scan it here.",
            "contributor_qr_code_shortlink_string": "{url}",
            "contributor_qr_code_thankyou_string": "Say thank you via the QR-code"
        },
        "cart": {
            "confirm-moq-warning": "Confirm and proceed to checkout",
            "cart-cleared": "Your cart has been cleared.",
            "clear": "Clear cart",
            "shipping_fee_calculated_at_checkout": "Shipping fee calculated at checkout",
            "added_to_cart": "Added to cart",
            "empty": "No items in cart",
            "handling": "Extras and handling",
            "remove_from_cart": "Remove from cart",
            "items_in_cart": "{count} item | {count} items"
        },
        "checkout": {
            "shipping_details": "Shipping details",
            "billing_details": "Billing details",
            "delivery_info_btn": "How to fill in the delivery date field?",
            "delivery_info_format": "Please fill in the delivery date with following format: <b>\"year-month-day\"</b>.",
            "delivery_info_format_example": "Example for the 1st of may 2021:",
            "delivery_info_after_date": "Delivery can't be before:",
            "delivery_info_no_weekend": "No delivery on Saturday and Sunday",
            "delivery_info_urgent_dates": "Urgent delivery available on",
            "delivery_info_no_delivery_dates": "No delivery on the following dates:",
            "items_total": "Items total",
            "delivery_details": "Delivery details",
            "order_summary": "Order summary",
            "please_add_address": "Please add an address",
            "total_including_vat": "total (inc. VAT)",
            "company_field_help_text": "Leave empty when shipping to a personal address.",
            "grouped_invoice_description": "Once a month you receive an invoice that includes all orders added to the grouped monthly invoice.",
            "separate_invoice_description": "A separate invoice will be created for this order. It will not be added to the grouped monthly invoice.",
            "back_to_cart": "Cart",
            "back_to_billing": "Back",
            "continue_to_shipping": "Continue to Shipping",
            "place_order": "Place Order",
            "breadcrumb_cart": "Cart",
            "breadcrumb_checkout": "Checkout",
            "breadcrumb_billing": "Billing",
            "breadcrumb_shipping": "Shipping",
            "terms_agreement_validation_error": "Please read the terms of agreement. You should agree the terms to be able to continue.",
            "address_numbered": "Address #{number}",
            "home_address_notification": "You don't need to provide shipping information for the items that will be delivered to home addresses.",
            "shipping_fee_calculated_at_next_step": "Calculated at next step",
            "there_are_errors": "There are problems with the information you’ve filled in.",
            "delivery_for_future_notification": "Expected delivery date was already set per recipient.",
            "remaining-credits": "Remaining Credits",
            "insufficient-credits-for-order": "You have insufficient Credits to cover the total of this Order.",
            "bus_help_text_checkout": "The word 'Bus' is added later by us (to the 'bus' field).",
            "calculating_shipping_fee": "Calculating...",
            "continue_to_billing": "Continue to billing",
            "back_to_shipping": "Back to shipping",
            "billing_details_shown_on_invoice": "These details will be shown on the invoice.",
            "pay_immediately": "Pay immediately",
            "pay_immediately_description": "Pay your order now via credit card or debit card.",
            "pay_via_credits": "Pay via Credits",
            "pay_via_credit_description": "Pay your order via prepaid credits.",
            "pay_after_invoice": "Pay after invoice",
            "pay_after_invoice_description": "Pay your order when receiving your invoice.",
            "payment_method": "Payment Method",
            "direct_payment_disabled_order_approval_required": "(Unavailable when order approval is required)"
        },
        "credits": {
            "pay-with-credits": "Pay with credits",
            "what-are-credits": "How do I get credits?",
            "i-want-to-pay-with-credits": "I want to pay with credits",
            "current-balance": "Current balance",
            "credits": "Credits",
            "need-more-credits": "Need more credits?",
            "need-more-credits-explanation": "To receive more credits please contact your account manager. They will add credits to your balance and create an invoice for it.",
            "index": {
                "credits-will-expire": "{credit} credits will expire on {date}.",
                "description": "Credits are a digital currency that can be spent in Kadonation Select. You can purchase these at any time and allow you to budget your spending better. Contact us for more information on why Credits can be useful for your organisation. 1 Credit equals 1 euro.",
                "buy-more-credits": "Buy more credits"
            },
            "expires-on": "Expires on {date}",
            "ordered-by": "Ordered by {name}",
            "no-credits-link-to-top-up": "You currently do not have any credits. To pay with credits, <a target=\"_blank\" href=\"{url}\" class=\"text-pink-300 underline\">top up your balance</a>.",
            "credits-with-value": "{count} credits",
            "remaining-via-invoice": "Remaining amount to be paid via invoice",
            "coming-soon": "What are credits?",
            "coming-soon-explanation": "Credits are a digital currency in Kadonation Select. They are purchased beforehand, via invoice, and are then added to your Select account. Once you have Credits, you can place orders using them.\n\nVisit the 'Credits' page in Select to request Credits, or contact support. (You can use the 'Support' button in the bottom right corner)",
            "no-items-found": "Nothing found",
            "spend-history": "Credits used",
            "buy-history": "Credits bought",
            "no_items_found": "Nothing found",
            "expired_on": "Expired on {expired_on}",
            "use_credits_by_default": "Use available credits by default when placing orders",
            "no-credits": "No credits available",
            "paying-with-credits": "Will be purchased with Credits",
            "expiring-soon": "{amount} credits expiring on {date}",
            "balance-title": "Balances of all {hierarchy}",
            "add-more-credits": "Add credits",
            "view-credit-history": "View credit history",
            "view-transactions": "View history",
            "history": {
                "title": "Credit history of",
                "spent-from": "Spent from {from}",
                "added-to": "Added to {to}",
                "deducted-from": "Deducted from {from}",
                "short-title": "Credit History"
            },
            "plural": {
                "user": "Users",
                "entity": "Company Entities",
                "department": "Departments"
            },
            "view-credit-balances": "View balances",
            "search-for-hierarchy": "Search for {hierarchy}",
            "by": "By",
            "added": "Added",
            "spent": "Spent",
            "deducted": "Deducted",
            "amount-of-credits": "Amount to add",
            "hierarchy-to-add-credits-to": "Add credits to",
            "request-credits": "Request credits",
            "select-a-hierarchy": "Select an option",
            "credit-request-placed-successfully": "Credits were successfully requested. We'll add these soon.",
            "add-modal-subtext-non-link": "Credit request will be verified by Kadonation, after which you'll receive an invoice. Credits become useable after payment of the invoice. When buying credits you agree to our",
            "add-modal-subtext-link": "terms of service",
            "use-from": "Use credits from",
            "partially-paid": "Partially paid with Credits, rest via invoice",
            "refund": "Refund",
            "refunded_for": "Refunded for"
        },
        "errors": {
            "mobile": "This platform is not supported on mobile devices. Please use your computer to access your account.",
            "error-request": "Error on your request.",
            "saml-auth-403": "Contact your company administrator if you think you should have access to this application.",
            "general-error": "Something went wrong. Please try again later.",
            "saml-auth-bad-configured": "The SAML authentication has been incorrectly configured. Please contact fabrice@kadonation.com to check the configuration.",
            "error_on_row": "There is an error on row {row}.",
            "no_result_found": "No result found",
            "row_has_errors": "This row contains errors.",
            "there_are_errors_on": "There are errors on",
            "error_form": "There are errors in the fields you've filled in.",
            "error_page_401_title": "Unauthorized",
            "error_page_403_title": "Access Denied",
            "error_page_404_title": "Not Found",
            "error_page_419_title": "Page Expired",
            "error_page_429_title": "Too Many Requests",
            "error_page_500_title": "Oops. Something went wrong.",
            "error_page_503_title": "We're Under Maintenance",
            "error_page_401_generic_description": "You should log in to access this page.",
            "error_page_403_generic_description": "You are not allowed to access this page.",
            "error_page_404_generic_description": "The page you're looking for could not be found.",
            "error_page_419_generic_description": "Please return to the form you are trying to submit, reload the page and try submitting again.",
            "error_page_429_generic_description": "We're sorry, but we are currently experiencing a high volume of requests and are unable to process them all at once. Please try again later and ensure that you are not making too many requests too quickly. Thank you for your patience.",
            "error_page_500_generic_description": "Sorry, something went wrong on our end and we're having trouble fulfilling your request at the moment. Please try again later or contact our support team for assistance. Thank you for your understanding.",
            "error_page_503_generic_description": "We're sorry, but the service you're trying to access is currently unavailable due to maintenance or other technical issues. Our team is working to get things up and running as soon as possible, so please check back later. Thank you for your patience and understanding."
        },
        "permissions": {
            "select_all": "Select all permissions",
            "admin_permission_explainer": "Able to see and edit settings (including API keys and SAML SSO). Able to edit available occasions and gifts. Able to create and edit automations (if order permissions are granted too).",
            "user_management_explainer": "Able to see, create and edit users.",
            "order_explainer": "Able to place orders for the department and entity this user belongs to.",
            "order_for_all_explainer": "Able to place orders for all departments and entities of the company.",
            "view_my_invoices_explainer": "Able to view invoices based on orders this user placed.",
            "view_all_invoices_explainer": "Able to view all invoices.",
            "reporting_explainer": "Able to see reports on spending.",
            "manage_all_orders_explainer": "Able to see and edit all orders and order details (including addresses).",
            "budget_constraint_management_explainer": "Able to edit budget constraints.",
            "manage_kudos_campaigns_explainer": "Able to see, create and edit Kudos campaigns.",
            "manage_employees_explainer": "Able to see, create and edit Employees. Able to see Employee info throughout Select. When disabled, this user cannot see any addresses or personal details of the Employee (but can order for them using their name).",
            "approve_orders_explainer": "Able to approve orders for linked users. Link ordering users to this approving user using the ‘Profile’ tab above.",
            "manage_credits_explainer": "See purchased and spent credits",
            "order_for_entity_explainer": "Able to place orders for all departments and the entity this user belongs to."
        },
        "budget": {
            "title": "Budgets",
            "text": "On this page you can set the voucher amount thresholds for every occasion. With the warning threshold they can still order, with the blocking threshold they can't. You can specify the company policy notification for each occasion by filling out the fields. This is visualised in the add to cart page to serve as a directive.",
            "warning-threshold": "Warning threshold",
            "blocking-threshold": "Blocking threshold",
            "notifications-title": "Edit company policy notifications",
            "default-threshold-message": "The {attribute} may not be greater than € {max}.",
            "proceed-warning-threshold": "Are you sure?",
            "updated-threshold-amounts": "Updated threshold amounts.",
            "updated-threshold-messages-occasion": "Updated threshold messages for {occasion}",
            "warning-threshold-message": "Amount is above € {max}. Are you sure?",
            "default-warning-info-message": "Check company policy above € {max} per gift.",
            "default-blocking-info-message": "Company policy set the max amount per gift to € {max}.",
            "validation-threshold-min": "The {attribute} must be at least € {min}.",
            "validation-threshold-max": "The {attribute} may not be greater than € {max}",
            "validation-messages-required_with": "Please enter all languages.",
            "message_rows": "{message} Row(s) {rows}.",
            "save_budget": "Save budget"
        },
        "shared-order-approval": {
            "step-1": {
                "title": "View and sign quotation(s)",
                "text": "We require the following actions from your side to process your order.",
                "download_title": "1. Download available quotations",
                "download_text": "You can only sign one quotation at a time.\nIf you wish to sign multiple quotations a new order per quotation will have to be made.",
                "upload_title": "2. Upload signed quotation",
                "upload_text": "After downloading your quotation you will have to sign it and upload it here.\nOnce it has been uploaded you can remove it and upload a different one until you submit your quotation.",
                "select_quotation_label": "Please select which quotation you’ll be using",
                "upload_quotation_label": "Upload your signed quotation",
                "submit": "Send signed quotation",
                "processing_your_quotation": "We’re processing your quotation",
                "processing_your_quotation_explanation": "We'll let you know via email once the order is ready to be approved by you. In the meantime, feel free to take a look at the quotations that were available and which one you have uploaded.",
                "available_quotations": "Available Quotations",
                "available_quotations_explanation": "These were the quotations that we offered to you. You can only place an order for one quotation at a time. If you wish to place an order for a different quotation feel free to contact us.",
                "your_signed_quotation": "Your signed quotation",
                "your_signed_quotation_explanation": "This is the signed quotation you uploaded. This is the quotation we are currently processing.",
                "deadline-missed": "{deadline} deadline missed!",
                "deadline-missed-explanation": "You have missed the deadline to upload your signed quotation, we cannot guarantee on time delivery!",
                "deadline-approaching": "{deadline} deadline approaching!",
                "deadline-approaching-explanation": "Please upload your signed quotation before this date, otherwise we cannot guarantee on time delivery!",
                "whoops": "Whoops...",
                "no-quotations-to-sign": "There are no quotations available to sign.",
                "contact-support": "Contact Support"
            },
            "step-2": {
                "customize_and_order": "Personalize and place your order",
                "customize_and_order_description": "Here you can take a look at all the items of your order as well as customize them if you wish.",
                "order_id": "Order number",
                "view_quotation": "View quotation",
                "personalize_order": "Do you want to personalize your order?",
                "personalize_order_description": "Add names, personal messages ... to the articles.",
                "invoiced_to": "Invoice address",
                "edit_po_cost_center": "Edit your PO number and/or cost center",
                "edit_po_title": "Add a PO number and/or cost center to your order.",
                "place_order": "Place order for € {cost}",
                "no_order_items_found": "No order items found.",
                "deadline-missed": "{deadline} deadline missed!",
                "deadline-missed-explanation": "You have missed the deadline to upload your signed quotation, we cannot guarantee on time delivery!",
                "deadline-approaching": "{deadline} deadline approaching!",
                "deadline-approaching-explanation": "Please upload your signed quotation before this date, otherwise we cannot guarantee on time delivery!",
                "add_delivery_address": "Add delivery address",
                "update_delivery_address": "Update Delivery Address",
                "update_delivery_address_explanation": "Update the delivery address for the order",
                "add_delivery_address_explanation": "Add a delivery address to your order",
                "delivery-address-updated": "Delivery address was successfully updated.",
                "errors-in-the-form": "There are errors in the form. Please review them.",
                "failed-to-update-delivery-address": "We couldn't update the delivery address for the order at this time. Please try again or contact support.",
                "can_not_place_order_error": "Cannot place order at this time because some order items contain errors.",
                "order_has_errors": "Order contains errors.",
                "review_item": "Please review the order item '{item}'.",
                "review_items": "Please review the order items."
            },
            "step-3": {
                "title": "We are processing your order",
                "subtitle": "We are working hard to process your order. Below is an overview of your order."
            },
            "back_to_order_overview": "Back to order overview",
            "display_logo": "Add logo?",
            "display_logo_description_one": "If you add a logo, it will be printed along with your gifts.",
            "display_logo_description_two": "If a logo is already set up for your company, you will see it here.",
            "logo_will_be_included": "Logo is added.",
            "logo_will_not_be_included": "Logo is not added.",
            "preview_voucher_and_email": "Send a sample email",
            "preview_voucher_and_email_description": "Wondering how recipients will receive their digital Kadonation gift card, send a sample email.",
            "send_example": "Send an example",
            "preview_landing_page": "Preview landing page",
            "preview_landing_page_description": "Recipients will be able to activate their Kadonation gift card through the landing page.",
            "view_landing_page": "Preview landing page",
            "recipient_list": "Add the list of beneficiaries.",
            "recipient_list_description": "You can upload the beneficiaries via an excel file or use the employee selector.",
            "upload_through_file": "Upload through file",
            "upload_description": "Download the excel template file and fill in the requested fields.",
            "list_of_recipients": "Overview of beneficiaries",
            "modified_order_message": "You have added more recipients or you have adjusted the amount so that the amount is no longer the same as the quote. Please note that the price of the order has been updated and the quote may no longer match the actual order.",
            "sending": "Sending an example ...",
            "something_went_wrong": "Oops. Something went wrong.",
            "preview_email_sent": "A preview email has been sent.",
            "provide_valid_email": "Please provide a valid email.",
            "minimum_one_recipient": "Add at least one beneficiary.",
            "direct_to_recipient": "Send directly to recipient",
            "physical_direct_to_recipient_explanation": "Your order will be shipped to the recipients' home addresses, therefore you cannot provide a general shipping address.",
            "digital_direct_to_recipient_explanation": "Your order will be sent to the recipients' email address directly.",
            "view_examples": "Preview Files",
            "personalize": "Personalize",
            "view_data": "View data",
            "preview_voucher": "View print files",
            "preview_voucher_description": "View which print files will be used to print the gift cards. Use this to get a preview of what the final gift will look like.",
            "preview_the_vouchers": "Preview the kadonation voucher in PDF",
            "step": "Step {step}",
            "no_delivery_information": "No delivery information was provided.",
            "delivery": "Delivery",
            "discount_has_been_applied": "A {discount}% discount has been applied.",
            "step-4": {
                "title": "Your order has been shipped.",
                "description": "We have shipped your order and you should receive your order soon. We've send you an email with the track and trace link."
            },
            "step-5": {
                "description": "You have received your invoice by e-mail, if you have any questions about this please contact customer service. We hope everything is to your satisfaction and look forward to seeing you again!",
                "title": "Your invoice for your order.",
                "subtitle": "The invoice for this order was delivered to you by mail."
            },
            "select-via-employees-explanation": "Select all the employees or teams you wish to give a gift to. You can add employees one by one, per team or all at once. Afterwards you can personalize the gift per employee or for several at once.",
            "show-note": "Show note",
            "hide-note": "Hide note",
            "generating_for_the_recipient": "We’re generating the demo voucher for {to}",
            "excel_limitation_note": "Note: Personal message is limited to 300 characters. Each enter counts for 40 characters. Language and country should be picked from the available options in the template file. Emojis are not supported.",
            "place_order_confirmation_description": "You’re about to place your order for € {cost}. You will not be able to make any changes and we will start processing your order immediately. Are you sure you wish to continue placing your order?",
            "preview_greeting_card": "Preview the greeting card in PDF format"
        },
        "invoice": {
            "KADONATION-GIFT-CARD": "Kadonation gift voucher(s)",
            "GIFTCARD": "Kadonation gift card(s)",
            "GIFTCARD-ENVELOPPE": "Kadonation gift card(s) in envelope",
            "GIFTBOX": "Kadonation gift box(es)",
            "GIFTBOX-SLEEVE": "Kadonation gift box(es) with sleeve",
            "VAT": "VAT",
            "INVOICE-ADDRESS": "Invoice address",
            "DELIVERY-ADDRESS": "Delivery address",
            "KVK-NUMBER": "Chamber of Commerce number",
            "CREDIT_NOTE": "Credit note",
            "INVOICE": "Invoice",
            "CREDIT-NOTE-LINKS-TO-INVOICE": "Credit note of invoice {invoiceNumber}",
            "DATE": "Date",
            "EXPIRATION-DATE": "Expiration date",
            "CLIENT-REFERENCE": "Client reference",
            "ORDER-NUMBER": "Order number",
            "PO-NUMBER": "PO-number",
            "DESCRIPTION": "Description",
            "AMOUNT-EXCL-VAT": "Amount (excl. VAT)",
            "QUANTITY": "Quantity",
            "TOTAL": "Total",
            "NET": "Net",
            "SHIPPING-COSTS": "Shipping costs",
            "PAYMENT-REFERENCE": "Message",
            "BANK": "Bank",
            "TERMSMESSAGE": "Our general terms and conditions can be found on the next page",
            "ORDER": "Order",
            "PO": "PO-number",
            "handling_fee": "Handling fee",
            "urgent_fee": "Urgent Fee",
            "total_exvat": "Total (excl. VAT)",
            "OCCASION": "Occasion",
            "GIFT": "Gift",
            "PRICE": "Price",
            "DETAILED_REFERENCE": "Detailed Reference",
            "FROM": "From",
            "TO": "To",
            "MESSAGE": "Message",
            "invoice_detail": "Detail",
            "INVOICE_DETAIL": "Detail",
            "credit_note_regarding_invoice": "Regarding invoice",
            "CREDIT-NOTE": "Credit note",
            "shipping_fee": "Shipping fee",
            "PRO_FORMA": "Proforma Invoice",
            "total_invat": "Total (incl. VAT)",
            "home_delivery_fee": "Home delivery fee",
            "INTRA-COMMUNITY-EXPLANATION": "intra-Community supply, exempt from VAT art 39bis 1° W. VAT",
            "INVOICE-DETAILS-REFERENCE": "You can view additional details of your invoice through the invoice view or through this link: <a href=\"{invoiceDetails}\">{invoiceDetails}</a>.",
            "no_invoices_found": "You don't have any invoices yet.",
            "paid_via_credits": "Paid via credits",
            "to_be_paid_invat": "To be paid (incl. VAT)",
            "credit_description": "Credits",
            "auto_approve_tenant_default": "Default from Tenant",
            "auto_approve_yes": "Yes",
            "auto_approve_no": "No",
            "PAID-BY-CREDITS": "Invoice already (partly) paid with credits.",
            "BALANCE-TO-BE-PAID": "Balance to be paid",
            "order_list_for_proforma_auto_finalization": "order|orders",
            "validation_max_date_range_for_export": "Please select a date range that is at most 1 year.",
            "export-mail-title": "Your exported invoices",
            "export-mail-text": "You've requested to export invoices from Kadonation. You'll find these in the attached Excel.",
            "export-mail-subject": "Your exported invoices",
            "export": "Export Invoices",
            "export_successful": "Invoices are being exported. This may take a moment. We'll send you an email containing the export soon.",
            "select_date_range_first": "Please select date range first to export invoices",
            "clear_filters": "Clear filters",
            "max_date_range_warning": "Only invoices from the last 365 days can be shown.",
            "last_month": "Last 30 days",
            "last_3_months": "Last 90 days",
            "last_6_months": "Last 180 days",
            "last_12_months": "Last 365 days",
            "invoice_date_range": "Filter invoices",
            "credit_for_receiver_description": "Credits for {receiver}",
            "INVOICE-ALREADY-PAID": "Invoice already paid",
            "INVOICE-TERMS-AND-CONDITIONS": "Terms and Conditions – The present invoice is governed by the B2B terms and conditions and thus forms an integral part of this invoice. In the event of any conflict or inconsistency between this invoice and the B2B terms and conditions, the provisions of the B2B terms and conditions shall prevail, unless this invoice expressly contains specific provisions, in which case such provisions of the invoice shall prevail. The B2B terms and conditions are available on the Kadonation website via the following hyperlink: <a target='_blank' href='https://kadonationselect.com/terms-and-conditions'>General Terms and Conditions</a>.\n\nPrivacy – The processing of personal data in the context of the execution of this Agreement is carried out and governed exclusively in accordance with the Data Processing Policy of Kadonation, as available on the Kadonation website via the following hyperlink: <a target='_blank' href='https://kadonationselect.com/data-processing-agreement'>Data Processing Policy</a>. The Client expressly acknowledges that by using the Platform, ordering the Products, and/or entering into an Agreement with Kadonation, he/she has read and accepted the Data Processing Policy in its entirety.",
            "default-subscription-invoice-section-name": "subscription"
        },
        "general": {
            "my_dashboard": "My dashboard",
            "gift_occasions": "Gift occasions",
            "check_all_occasions": "Check all occasions",
            "reports": "Reports",
            "coming_soon": "Coming soon",
            "create_user": "Create user",
            "user_management": "Users",
            "name": "Name",
            "department": "Department",
            "email": "Email",
            "cancel": "Cancel",
            "add_person": "Add user",
            "english": "English",
            "dutch": "Dutch",
            "french": "French",
            "german": "German",
            "permissions": "Permissions",
            "assign": "Assign",
            "assign_permission": "Assign permission",
            "reset_password": "Reset password",
            "cart": "Cart",
            "go_to_checkout": "Next",
            "occasion": "Occasion",
            "product": "Product",
            "quantity": "Quantity",
            "price": "Price",
            "discount": "Discount",
            "total_ex_vat": "Total (ex. VAT)",
            "total_inc_vat": "Total (inc. VAT)",
            "handling_fee": "Extras and handling",
            "grand_total": "Grand total",
            "checkout": "Checkout",
            "delivery_address": "Delivery address",
            "overview": "Overview",
            "download": "Download",
            "back_to_dashboard": "Back to dashboard",
            "items": "Number of items",
            "urgency_fee": "Urgency Fee",
            "item": "item",
            "company_settings": "Company settings",
            "company_departments": "Company departments",
            "all_departments": "All departments",
            "company_entities": "Company entities",
            "add_department": "Add department",
            "close": "Close",
            "add_entity": "Add entity",
            "vat_number": "VAT number",
            "address": "Address",
            "add_company_entity": "Add entity",
            "edit_delivery_address": "Edit address",
            "back": "Back",
            "edit_company_entity": "Edit entity",
            "invoice_address": "Invoice address",
            "default": "default",
            "add_delivery_address": "Add address",
            "delivery_addresses": "Delivery addresses",
            "orders_overview": "Orders overview",
            "company_entity": "Company entity",
            "invoices_type": "Invoice type",
            "expected_delivery": "Expected delivery",
            "messages": "Messages",
            "message": "Message",
            "order_item": "Order item",
            "from": "From",
            "to": "To",
            "locale": "Language",
            "gift": "Gift",
            "delivery_date": "Expected delivery date",
            "subtotal": "Subtotal",
            "cost": "Cost",
            "total": "Total",
            "order_details": "Order details",
            "vat": "VAT",
            "order_items": "Ordered items",
            "select_occasion": "Select an occasion",
            "edit": "edit",
            "edit_your_occasions": "Modify occasions",
            "update": "Update",
            "invoices_overview": "Invoices overview",
            "invoices_date": "Invoice date",
            "total_amount": "Total amount",
            "select_gift_for_occasion": "Select gift for the occasion: {occasion}",
            "select_gift": "Select gift",
            "enter_the_details": "Enter the details",
            "settings": "Settings",
            "logout": "Log out",
            "home": "Home",
            "orders": "Orders",
            "invoices": "Invoices",
            "login": "Login",
            "select_options": "Select an option",
            "select_price": "Select price",
            "personal_message": "Personal message",
            "for_individuals": "For individuals",
            "for_group": "For group",
            "order": "Order",
            "download_file": "Download file",
            "choose_saved_address": "Choose a saved address",
            "choose_new_address": "Or use a new address",
            "be": "Belgium",
            "nl": "Netherlands",
            "urgent_delivery": "urgent delivery",
            "cart_empty": "Your cart is empty, please add a product",
            "generating_download": "Generating download...",
            "invoice_type": "Invoice type",
            "separate_invoice": "Separate",
            "grouped_monthly_invoice": "Grouped monthly",
            "make_first_order": "Make your 1st order",
            "empty_orders_text": "You have no orders yet.",
            "add_to_cart": "Add to cart",
            "empty_invoices_text": "You have no invoices yet.",
            "add_address": "Add address",
            "change_address": "Change address",
            "no_address_selected": "No address selected",
            "edit_occasions_info": "Control which occasions are shown in your platform. By enabling an occasion, it will be visible, allowing you to view and purchase gifts for it. When an occasion is disabled, it will be hidden.",
            "view_order": "View order",
            "view_invoice": "View invoice",
            "view_invoices_overview": "View invoices overview",
            "invoices_id": "ID",
            "occasions": "Gift occasions",
            "order_id": "ID",
            "user": "User",
            "status": "Status",
            "active": "Active",
            "inactive": "Inactive",
            "activate": "Activate",
            "deactivate": "Deactivate",
            "total_value": "Total value",
            "exc": {
                "_VAT": "Excl. VAT"
            },
            "save": "Save",
            "total_handling_fee": "Total extras and handling",
            "VAT": "VAT",
            "set_password": "set a password",
            "order_confirmation_CTA": "order details",
            "invoice_mail_CTA": "view the invoice overview",
            "language": "Recipient's language",
            "create_password": "Create a password",
            "save_and_login": "Save and login",
            "urgent_fee": "Urgency fee",
            "terms_and_framework_agreement": "The terms of agreement are defined in a framework agreement.",
            "read_and_accept": "I have read and accept the",
            "terms_and_agreement": "Terms of agreement",
            "for_this_order": "For this order",
            "department_name": "Name of the department",
            "order_terms": "Order terms",
            "select_amount": "Amount",
            "enter_amount": "Enter an amount",
            "order_terms_and_agreement": "I have read and accept the terms of agreement for this order.",
            "for": "for",
            "link": "Click here to consult the terms of agreement.",
            "value": "Value",
            "privacy_policy": "Accept Policy",
            "use_policy": "Use policy",
            "data_policy": "Data policy",
            "read_and_accept_use_policy": "I have read and accept the use policy",
            "read_and_accept_data_policy": "I have read and accept the data policy",
            "invoice": "Invoice",
            "creditnote": "Credit note",
            "invoice_section": "Section",
            "invoice_sections": "Sections",
            "invoice_item": "Item",
            "invoice_items": "Items",
            "description": "Description",
            "save_and_submit": "Save and submit",
            "save_as_draft": "Save as draft",
            "change_status": "Change status",
            "status_changed": "Status changed!",
            "status_not_changed": "Status not changed for the following invoices (you can only approve invoices with status 'draft', 'pending' or 'proforma'):",
            "linked_invoice": "Linked invoice",
            "create_creditnote": "Create credit note",
            "credit_mail_CTA": "view the invoice overview",
            "via_form": "Via form",
            "via_excel": "Via excel",
            "detailed_reference": "Detailed reference",
            "invoice_or_credit_note": "Type",
            "total_shipping_fee": "Total shipping fee",
            "current_logo": "Logo",
            "contact_support": "Please contact our support to replace your logo",
            "no_logo": "No logo configured yet",
            "shipping_fee": "Shipping fee",
            "and_department": "and department",
            "kayako_faq": "FAQ",
            "new_invoice": "New invoice",
            "update_invoice": "Update invoice",
            "budgets": "Budgets",
            "finalize": "Finalize",
            "generate_label": "Generate label",
            "delivery_email": "Email",
            "delivery_direct": "Send gift to recipient directly",
            "proforma": "Proforma",
            "po_number": "PO Number",
            "proforma_id": "Proforma ID",
            "group": "Group",
            "team": "Team",
            "import": "Import",
            "delivery_direct_tag": "Direct to recipient",
            "create": "create",
            "delivery_direct_quantity-limit": "Please use 'Via excel' or 'from employees' if you want to send more than one gift to recipients directly.",
            "truncate_employees": "Remove all employees",
            "clear": "Clear",
            "senders": "Senders",
            "receivers": "Receivers",
            "empty_orders_for_approval_text": "You have no orders to be approved yet.",
            "orders_for_approval": "Order approval",
            "view": "view",
            "orders_for_approval_overview": "Order approval",
            "orders-for-approval": "Order approval",
            "approve": "Approve",
            "reject": "Reject",
            "go": "Save",
            "approval_manager": "Approval manager",
            "cancel_order": "Cancel order",
            "last_updated": "Last updated",
            "impersonating_welcome": "User impersonation active",
            "impersonating_description": "You are now impersonating a different user. Keep in mind, actions you perform will be on behalf of this person.",
            "order_creator": "Order created by",
            "impersonating_stop": "Stop Impersonation",
            "cost_center": "Cost Center",
            "reason": "Comment",
            "copy": "Copy",
            "lu": "Luxembourg",
            "api_keys": "API keys",
            "add_key": "Add API key",
            "next": "Next",
            "process_order": "Process order",
            "verify_order": "Verify order",
            "delivery_time": "Delivery time",
            "inbound": "Inbound",
            "outbound": "Outbound",
            "reseller": "Reseller",
            "tenders": "Tenders",
            "part": "Part",
            "download_link_disclaimer": "Download links are only valid for 1 week.",
            "option": "Option",
            "via_employee_list": "From employees",
            "edit_employee": "Edit employee",
            "gift_expires_on": "Expires On",
            "default_confirm_message": "Are you sure you want to perform this action?",
            "recipient_type": "Add recipients",
            "add_all_teams": "Add all teams",
            "personalize": "Personalize",
            "edit_occasion_by_drag_and_drop": "You can change the order by dragging the items",
            "home_delivery_fee": "Home delivery fee",
            "home_delivery_address": "Home delivery address",
            "total_home_delivery_fee": "Total cost for delivery to home addresses",
            "no_shipping_address_when_home_delivery_is_enabled": "Your order will be shipped to the recipients' home addresses, therefore you cannot provide a general shipping address.",
            "home_delivery_tag": "Delivery to home addresses",
            "no_shipping_address_when_order_is_digital_only": "Your order contains only digital gift cards, therefore you cannot provide a general shipping address.",
            "view_all": "View all",
            "no_home_delivery_for_card_must_choose_card_envelope": "If you wish to send kadonation gift cards to home addresses, please choose the option card with envelope",
            "selected_count": "+{count} selected",
            "apply": "Apply",
            "save_and_send": "Save and send",
            "close_panel": "Close panel",
            "add_all_employees": "Add all employees",
            "sendcloud_parcel_id": "Sendcloud Parcel ID",
            "tracking_number": "Track & trace number",
            "tracking_url": "Track & trace URL",
            "are-you-sure": "Are you sure?",
            "via_kudos": "Via Kudos campaign",
            "kudos_campaign": "Kudos campaign",
            "kudos_campaign_explanation": "To set the voucher amount and other details of the Kudos receivers, use the 'Edit Recipients' button below.",
            "kudos_tag": "Kudos",
            "sign-quotation": "Sign quotation",
            "place-order": "Confirm order",
            "processing-order": "Order being processed",
            "shipping-order": "Order shipped",
            "approve-invoice": "Consult invoice",
            "contact_us": "Contact us",
            "having_trouble": "Having trouble?",
            "another_vendor_in_cart": "Please first complete your order with gifts from {name} before ordering gifts from another vendor.",
            "open_cart": "Open cart",
            "vendor_about": "About",
            "vendor_discover": "Discover {name}",
            "send_message": "Send message",
            "help": "Help",
            "order_info": "Order info",
            "price_vat": "Price VAT",
            "total_items": "Total items",
            "total_vat": "Total VAT",
            "handling_fee_vat": "Handling Fee VAT",
            "invoice_details": "Invoice details",
            "digital_vouchers": "Digital vouchers",
            "excel": "Excel",
            "download_excel": "Download excel file with gift vouchers",
            "track_and_trace": "Track & trace",
            "select_all": "Select all",
            "select_all_matching": "Select all matching",
            "vendor_greeting_cards": "Greeting cards with personalization",
            "short_address": "Address (shortened)",
            "option_price": "Price:",
            "confirm_deletion": "Are you sure you want to delete {item}?",
            "gift_occasions_for": "{occasion}",
            "amount-of-choice": "Amount of choice",
            "load_more_gifts": "Load more gifts",
            "tbd": "Calculated at next step",
            "select_address_to_calculate_shipping": "Please select an address to calculate the shipping fee.",
            "delete": "Delete",
            "default_confirm_title": "Are you sure?",
            "checkout-tbd": "Calculated after setting the shipping address",
            "please_wait": "Please wait",
            "breadcrumb": "Breadcrumb",
            "another_directly_to_recipient_in_cart": "You cannot change this option since you have other gifts in your cart.",
            "view_gifts": "View Gifts",
            "continue_shopping": "Continue shopping",
            "view_cart": "View cart",
            "please_enter_shipping_address": "Please select or enter an address first.",
            "discounted_handling_fee": "Extras and handling discount",
            "continue": "Continue",
            "landing-page": {
                "discover-kadonation": "Discover Kadonation",
                "sign-in-to-kadonation": "Sign in to Kadonation Select"
            },
            "LOAD-ORDER-ITEMS": "Load older items",
            "credits_used": "Credits used",
            "vendor_order_items_summary": "Overview of order items",
            "vendor_kadonation_vouchers": "Kadonation vouchers",
            "vendor_merged_cards_front_back_kadonation_vouchers": "Print files for kadonation vouchers",
            "vendor_merged_cards_front_kadonation_vouchers": "Print files for pre-printed kadonation vouchers",
            "open": "Open",
            "of": "of",
            "note": "Note",
            "vendor_merged_cards_greeting_cards": "Print files for pre-printed kadonation greeting cards",
            "vendor_merged_digital_cards_greeting_cards": "Print files for kadonation greeting cards",
            "saving": "Saving...",
            "delete_delivery_address": "Delete address",
            "add": "Add",
            "read_more": "Read more",
            "read_less": "Read less",
            "address_from_vat_message": "When a correct VAT number is given, we will automatically retrieve the address and fill it out for you.",
            "edit_personalization": "Edit Personalization",
            "created": "Created",
            "mass_update_message_description": "Fill in the message you want to add to your selected recipients and click “update” to save it.",
            "page": "page",
            "download_personalization_file": "Download file",
            "show_details": "Show details",
            "hide_details": "Hide details",
            "personalization_file": "Customization file of gift",
            "cannot_reset_because_saml_enabled": "Password resetting options are unavailable since you are authenticating via an external service (such as Google, Microsoft, etc.)",
            "no-invoices": "No invoices found.",
            "top_info_bar_text": "false",
            "and": "and",
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "order_can_not_be_shipped_no_delivery_day": "No available delivery dates were found, please contact our customer service department so they can resolve this.",
            "generating_download_personalization_file": "Generating file...",
            "resend_welcome_email": "Resend Welcome Email",
            "actions_unavailable_because_tenancy_disabled_for_tenant": "The actions are not available because the tenant is not a KDN Select Customer.",
            "resend_welcome_email_confirmation": "Are you sure you want to send the welcome email to the user?",
            "reset_password_confirmation": "Are you sure you want to send a password reset email to the user?",
            "date_set_on_recipient": "Date set on recipient",
            "set_on_recipients": "Set on recipients",
            "address_to": "Addressed to",
            "order_can_not_be_shipped_no_countries": "There is no country available to ship to for your gifts in cart. Please check each gift to see if it ships to your country of choice.",
            "expected_delivery_date": "Expected delivery date",
            "multiple_delivery_set_per_recipient_in_excel": "multiple, set per recipient in Excel",
            "saturday": "Saturday",
            "sunday": "Sunday",
            "delivery_date_parse_failed": "Error parsing delivery-date!",
            "quote": "Quote",
            "vendor_merged_digital_cards_greeting_cards_custom": "Print files for custom greeting cards",
            "vendor_greeting_cards_custom_design": "CUSTOM DESIGN",
            "vendor_greeting_cards_custom_link": "Custom greeting cards with personalization",
            "copied": "Copied",
            "full_name": "Name",
            "full-name": "Name",
            "delete-user": "Delete User",
            "subscription": "Subscription",
            "no_vendor_greeting_cards": "NO GREETING CARDS",
            "or": "Or",
            "keep-account-h2": "Keep Your Account Active",
            "keep-account": "Keep My Account",
            "recipient": "Recipient",
            "delivered_on": "Delivered on",
            "you": "You"
        },
        "calendar": {
            "previous_month": "Previous month",
            "today": "Today",
            "next_month": "Next month",
            "upcoming_events": "Upcoming events",
            "events": {
                "place_order": "Find a gift",
                "nothing_planned": "Nothing planned",
                "no_employees_title": "Never forget a special day",
                "no_employees_description": "Add employees to Select and see their birthdays and work anniversaries here",
                "add_employees_button": "Add Employees"
            }
        },
        "automation": {
            "automations": "Automated gifts",
            "no_automation_created_yet": "No automated gifts created yet, select a template to set-up an automated gift.",
            "automation_name": "Automated gift",
            "expires_on": "Expires on",
            "never": "never",
            "delete_confirmation_title": "Delete automated gift",
            "delete_confirmation_message": "Are you sure you want to delete this automation? This action cannot be undone.",
            "employee_birthday_condition_text": "Wish every employee a happy birthday, without forgetting anyone!",
            "employee_work_anniversary_condition_text": "Never let another work anniversary slip by, you decide whether to celebrate every work anniversary or certain set work anniversaries.",
            "description_text": "Make gifting easier! Thanks to the automated gifting feature of Kadonation Select it's a breeze to appreciate your employees. Add your employees and start sending gifts automatically on their birthday or work anniversary, making sure everyone feels appreciated and rewarded.",
            "birthday_template_title": "Automatic birthday gifts",
            "work_anniversary_template_title": "Automatic work anniversary gifts",
            "birthday_template_cta": "Start set-up",
            "work_anniversary_template_cta": "Start set-up",
            "no_admin_found": "(No admin user found!)",
            "non_admin_modal_title": "Want to set up an automation?",
            "non_admin_modal_description": "Only “Admin” users can create and edit automations. Below is the “Admin user” for your company. Contact this person with your request to create an automation and they’ll take care of it.",
            "automations_features_title": "Besides the Automations feature, you’ll also to be able to:",
            "no_employees_set_description": "In order to use the automations feature, you’ll need to add employees first. The ‘Employees’ feature stores your employees’ details for easy access throughout Kadonation Select. Adding employees is easy, just connect to your HR system, or upload an Excel file.",
            "automations_feature_items": "Order gifts with addresses and details already filled out |\nCreate Kudos campaigns to add team messages for the receiver of a gift |\nReceive reminders of birthdays and work anniversaries (coming soon)",
            "no_automations_set_title": "No automations created yet",
            "no_automations_set_description": "Use one of our pre-designed templates.",
            "what_are_automations": "What are Automations?",
            "employees_required": "Please select at least one employee to move to next step",
            "blocked_modal_for_payment": {
                "title": "Automate gift sending",
                "description": "Sending gifts via automations is currently blocked for your account. This feature is unlocked when the financial status of your company is verified by us. Contact us to expedite the process."
            }
        },
        "export": {
            "from": "From",
            "to": "To",
            "message": "Message",
            "language": "Recipient's language",
            "detailed_reference": "Detailed reference",
            "amount": "Amount",
            "order_template": "Order template",
            "email": "Email",
            "delivery_date": "Delivery date",
            "first_name": "First name",
            "last_name": "Last name",
            "locale": "Language",
            "team": "Team",
            "employees_template": "employees_import",
            "delivery_time": "Delivery time",
            "kudos_user_uuid": "Kudos User ID",
            "kudos_campaign": "Kudos Campaign",
            "kudos_campaign_employees": "Kudos Campaign Employees",
            "date_of_birth": "Date of birth",
            "company_entity": "Company Entity",
            "department": "Department",
            "date_of_employment": "Date of employment",
            "street": "Street",
            "street_number": "Number",
            "bus_2": "Box",
            "zip_code": "Zip Code",
            "city_2": "City",
            "country": "Country",
            "row": "Row {row}",
            "external_employee_id": "External Employee ID",
            "bus": "Box",
            "city": "City",
            "select_all_matching": "Select all matching",
            "select_all": "Select All",
            "manager_id": "Manager/teamleader email address",
            "addressed_to": "Addressed to",
            "recipient_first_name": "Recipient first name",
            "recipient_last_name": "Recipient last name",
            "explainer": {
                "red-columns-are-mandatory": "- Red columns are mandatory.",
                "dropdown-options-only": "- For dropdown columns, only the options in the dropdown are allowed.",
                "column": "Column",
                "instructions": "Instructions",
                "amount": "Value of the voucher. Decimals are not possible.",
                "message": "Personal message to your recipient. Printed on the card. Maximum 600 characters. Each ‘enter’ counts as 40 characters. Emojis are not supported.",
                "language-beneficiary": "Determines the language of the text Kadonation adds on the voucher/card. Select an option from the dropdown menu.",
                "email-delivery": "Recipient’s email address. We’ll send the voucher to this address.",
                "email-track-and-trace": "Recipient’s email address. We’ll send the track and trace notifications to this email address.",
                "recipient-name": "Printed on the shipping label. Max 30 characters total.",
                "street": "Does not have a character limit.",
                "street_number": "Limit of 8 characters.",
                "bus": "Max 4 characters. The word ‘Bus’ is automatically added afterward.",
                "city": "Limit of 30 characters.",
                "country": "Select from the dropdown menu. If a country is missing, it means we cannot ship the gift to this country.",
                "delivery_date": "Date on which we should deliver the gift. This should be in format: day/month/year (for example: 25/03/2022)",
                "delivery_time": "Time on which we should deliver the voucher. This should be in format: hours{minutes} (for example: 09{30})",
                "delivery_date_voucher": "Date on which we should deliver the voucher. This should be in format: day/month/year (for example: 25/03/2022)",
                "detailed_reference": "Used for administrative details. This depends on your company's gifting policy.",
                "from": "Shows the recipient who the gift is from. Printed on the voucher. Maximum 60 characters.",
                "addressed_to": "Name of the recipient. Printed on the voucher/card. Maximum 60 characters.",
                "message-voucher": "Personal message to your recipient. Printed on the voucher. Maximum 300 characters. Each ‘enter’ counts as 40 characters. Emojis are not supported."
            },
            "recipient-name": "Recipient’s name",
            "alias": {
                "order": {
                    "addressed_to": "To, To Whom, Adressed to",
                    "language": "Recipient's language, language",
                    "email": "Contact",
                    "bus": "Box",
                    "city": "City",
                    "first_name": "First Name, Werknemer Naam"
                }
            }
        },
        "statuses": {
            "order": {
                "received": "Received",
                "generated": "Generated",
                "shipped": "Shipped",
                "cancelled": "Cancelled",
                "generating": "Generating",
                "generation-failed": "Generation Failed",
                "pending-approval": "Pending Approval",
                "rejected": "Rejected",
                "draft": "Draft",
                "invoiced": "Invoiced",
                "quotation-sent": "Quotation Sent",
                "quotation-signed": "Quotation Signed",
                "quotation-verified": "Quotation Verified",
                "ready-to-review": "Ready To Review",
                "scheduled-for-fulfillment": "Scheduled For Fulfillment",
                "in-fulfillment": "In Fulfillment",
                "sending-to-fulfillment": "Sending To Fulfillment",
                "in-cart": "In Cart",
                "pending-sales-approval": "Pending Kadonation Approval",
                "pending-payment": "Payment Pending"
            },
            "invoice": {
                "draft": "Draft",
                "proforma": "Proforma",
                "pending": "Pending",
                "final": "Final",
                "rejected": "Rejected",
                "cancelled": "Cancelled"
            },
            "order-quotation": {
                "awaiting-vendor-approval": "Awaiting vendor approval",
                "vendor-accepted-request": "Vendor accepted request",
                "vendor-rejected-request": "Vendor rejected request"
            },
            "order-item": {
                "generated": "Generated"
            },
            "order-item-message": {
                "shipped": "Shipped"
            },
            "quotation-section": {
                "awaiting-vendor-approval": "Awaiting Approval",
                "vendor-accepted-request": "Accepted",
                "vendor-rejected-request": "Rejected"
            },
            "quotation": {
                "draft": "Draft",
                "quotation-sent": "Sent to customer",
                "quotation-signed": "Signed",
                "quotation-verified": "Approved",
                "quotation-rejected": "Rejected",
                "awaiting-vendor-approval": "Awaiting approval from vendors",
                "vendor-rejected-request": "Partial vendor rejection",
                "all-vendors-accepted": "Complete vendor approval",
                "canceled": "quotation canceled"
            }
        },
        "mails": {
            "vendor_order_mail_part_of_the_order": "(part of Order #{originalOrderId})",
            "vendor_order_mail_part_of_the_order_section": "<strong>Order Information</strong>\n</br>\n<ul style=\"list-style-type: none; padding-right: 0; padding-left: 0;\">\n<li>Order ID: {orderId}</li>\n<li>Main Order ID: {originalOrderId}</li>\n</ul>\n</br>",
            "add_contribution": {
                "title": "You've been invited to add <span style=\"word-break: break-word; color: #ff70a3;\">personal message</span> to a gift.",
                "invitor_and_invitees": "{invitor} invites you to add a personal message to <span style=\"word-break: break-word; font-weight: 500;\">{occasion}</span> gift for <span style=\"word-break: break-word; font-weight: 500;\">{invitees}</span>.",
                "write_before_date": "If you wish to add a personal message to this gift, please write your message before <span style=\"word-break: break-word; font-weight: 500;\">{date}</span>.",
                "write_message_button": "Write message"
            },
            "say_thank_you": {
                "title": "{name} said <span style=\"word-break: break-word; color: #ff70a3;\">thank you</span> for the {gift} you sent for their {occasion}.",
                "card": {
                    "title": "Discover more thoughtful gifts for your team or employees.",
                    "action_button": "View Gifts"
                }
            }
        },
        "gifts_v2": {
            "placeholder": "Placeholder",
            "excluding_vat": "(excl. VAT)",
            "amount_of_choice": "Amount of choice",
            "available_until": "Available until",
            "order_now_to_receive_by": "Order now to receive by",
            "physical_delivery_to_someone": "Physical delivery to your office, or recipients",
            "more_about_shipping": "More info on shipping",
            "made_locally": "Made locally",
            "sustainable": "Sustainable",
            "price_free": "Free",
            "order_before_date_delivered_on_date": "Order before {orderDate}, delivered on {deliveryDate}",
            "free_download_or_email_recipients": "Free instant download, or emailed to recipients",
            "option": "Option",
            "voucher_type": "Voucher type",
            "request_quote": "Request a quote",
            "quantity_min_error_message": "You cannot order less than {quantity} pieces",
            "quantity_max_error_message": "You cannot order more than {quantity} pieces",
            "budget_default_blocking_message": "The unit price can not be greater than {limit} for this occasion.",
            "back_to_top": "Back to top",
            "ordering_for_campaign": "You are ordering for Kudos campaign {campaign}",
            "choose_recipients_and_personalize": "Choose recipients and personalize",
            "add_to_favorite": "Add to preferred selection",
            "remove_from_favorite": "Add/remove gift from your preferred selection",
            "gift_is_customizable": "This gift is customizable",
            "edit_colorlab_personalization": "Edit Customization",
            "start_colorlab_personalization": "Customize Gift",
            "gift_without_customization_confirmation_title": "No customization added",
            "gift_without_customization_confirmation_description": "You haven’t customized this gift, are you sure you wish to continue without a customization?",
            "add_customization": "Add customization",
            "services": {
                "your_message_on_card": "Add your message and we’ll print it on the card",
                "customize_card": "Customize the card to your branding (<a href=\"{mailTo}\" class=\"underline\">contact us</a>)!",
                "less_response_time": "Contact us for quick help with placing your order",
                "free_personalization_for_voucher": "Free personalization & logo added to the voucher",
                "voucher_can_be_used_at": "Voucher can be used at more than <a href=\"https://kadonation.com/en/gift-partners\" class=\"underline\" target=\"_blank\">80 partners</a>",
                "customize_voucher": "Customize the voucher to your branding (<a href=\"{mailTo}\" class=\"underline\">contact us</a>)!",
                "free_card_with_gift": "A card can be added to the gift, includes your message",
                "ship_to_office_or_directly": "Ship to office, or directly to recipient"
            },
            "description": "Description",
            "contents": "Contents",
            "product_specification": "Gift Specifications",
            "shipping_info": "Shipping Info",
            "ordering_info": "Ordering Info",
            "jump_to": "Go to:",
            "sustainability": "Sustainability",
            "article_number": "Article number",
            "country_of_origin": "Country of origin",
            "shelf_life": "Shelf life",
            "sizing": "Sizing",
            "length": "Length",
            "width": "Width",
            "height": "Height",
            "weight": "Weight",
            "allergens": "Allergens",
            "gift_is_shipped_from": "This gift is being shipped from",
            "delivery_time": "Delivery time",
            "delivery_cost": "Delivery cost",
            "minimum_maximum_order_quantities": "Minimum/Maximum order quantities",
            "minimum_order_quantity": "Minimum",
            "price_matrix_info": "Volume discounts",
            "maximum_order_quantity": "Maximum",
            "selected_option": "Selected option",
            "change_option": "Change option",
            "customization": "Customization",
            "change_customization": "Change customization",
            "customized": "Customized",
            "not_customized": "Not customized yet",
            "costs": "Price",
            "voucher_value": "Voucher value",
            "price_per_item": "Price / item",
            "handling_fee": "Extras and handling",
            "view_gift_details": "View gift details",
            "go_back_to_gift": "Back to gift",
            "add_to_cart": "Add to cart",
            "add_recipients": "Recipients and personalization",
            "add_recipients_description": "The order can be delivered to one address, or directly to the recipients.",
            "enter_address_first": "Please enter an address first",
            "delivery_time_title": "{range} items",
            "delivery_time_condition": "{days} working day (if ordered before {cutoff})|{days} working days (if ordered before {cutoff})",
            "price_will_be_charged_for_each_gift_by_vendor": "Delivery cost will be charged for each gift added, as gifts are packaged and shipped separately.",
            "request_quote_for_unavailable_gift": "This gift is currently not available for ordering via Select. If you wish to order this gift you can request a quote.",
            "go_to_cart_finish_order": "View cart",
            "multi_vendor_warning_title": "You can’t combine gifts from different vendors in one order.",
            "multi_vendor_warning_description": "<p>You already have a gift in your cart from a different vendor than the vendor of this gift. One order can contain only gifts from one specific vendor. Continue by selecting one of the options below:</p>\n<ul style=\"margin-top: 12px\">\n<li>Go to cart and finish current order</li>\n<li>Clear cart and add this gift</li>\n<li>Close this and do nothing</li>\n</ul>",
            "close_modal_do_nothing": "Close this warning and do nothing",
            "clear_cart_and_add_gift": "Clear cart and add this gift",
            "set_voucher_value_to_add_gift": "Please set a voucher value to continue.",
            "additional_delivery_time_for_country": "(add {days} working days to delivery time)",
            "can_be_combined_with_voucher": "This gift can be combined with a voucher,",
            "can_be_combined_with_voucher_contact_us": "contact us to order",
            "need_something_custom_get_quote": "Large order? Need something custom? Get a quote here",
            "which_occasion_to_place_order": "For which occasion is this gift?",
            "select_occasion": "Select occasion",
            "country_delivery_condition": "{cost} (add {days} working days to delivery time)",
            "cost_text": "(Depending on quantity bought)",
            "bus_help_text": "The word ‘Bus’ is added later by us.",
            "gifts_found": "No gifts found | 1 gift found | {count} gifts found",
            "no_gifts_found": "No gifts found",
            "no_gifts_found_description": "There were no gifts found. Please clear the filters to continue browsing.",
            "favorited_gifts_for_occasion": "Preferred gifts for this occasion",
            "favorited_gifts_for_occasion_description": "Discover the favorite gifts, thoughtfully selected by your company to add something special to this occasion.",
            "view_all_gifts": "View all gifts",
            "no_favorited_gifts_found": "No gifts found",
            "no_favorited_gifts_found_description": "No preferred gifts were found for this occasion. Please contact your admin to set these up, so you can continue ordering.",
            "all_gifts_for_occasion": "All gifts for this occasion",
            "packaging_is_customizable": "The packaging of this gift can be customized",
            "product_is_customizable": "The design of this gift can be customized",
            "start_colorlab_personalization_packaging": "Customize package",
            "start_colorlab_personalization_product": "Customize gift",
            "modals": {
                "direct_future_delivery_item_in_cart": {
                    "title": "Cannot add gift to cart",
                    "buttons": {
                        "close": "Close this warning",
                        "go_to_cart": "View cart"
                    },
                    "description": "You’ve added a gift to your cart where you’ve set a delivery date per recipient. This cannot be combined with other gifts. Please place the order for the gift in your cart first."
                },
                "cannot_add_future_delivery_gift": {
                    "title": "Cannot add gift to cart",
                    "description": "You’re trying to add a gift to your cart where the delivery date is set per recipient. This cannot be combined with other gifts. Please place the order for the gift in your cart first.",
                    "buttons": {
                        "close": "Close this warning",
                        "go_to_cart": "View cart"
                    }
                },
                "set_occasion": {
                    "title": "Set an occasion",
                    "explainer": "Let us know for which occasion you are buying this gift.",
                    "buttons": {
                        "save": "Save and add to cart"
                    }
                },
                "personalization_not_set": {
                    "title": "Suggested setup personalisation",
                    "buttons": {
                        "add_to_cart": "Add to cart"
                    },
                    "no_personalization_message": {
                        "explainer": "To provide your recipients with the best experience, we recommend setting a personal message."
                    },
                    "no_contributors_added": {
                        "explainer": "You’ve enabled the digitize option, but there are no contributors added."
                    }
                }
            },
            "regional_logic_title": "Ships to these regions",
            "gift_can_only_be_shipped_to_this_regions": "Check if the region you want to send to is included in this list, as this gift may not ship to all regions of a specific country.",
            "all_regions": "All regions",
            "more_about_shipping_per_region": "Ships to specific regions only",
            "add-vat-number-and-invoice-address-modal": {
                "vat-number-help-text": "<a href=\"{mailTo}\">Contact us</a> if you don’t have a VAT Number."
            },
            "add_vat_number_and_invoice_address": {
                "title": "Set VAT number and address",
                "description": "To place orders on Select, you'll need to add your VAT number and invoice address first. These are required to be able to create your invoice."
            },
            "preferred_gifts": {
                "add_modal_title": "Add to preferred gifts",
                "add_modal_description": "Preferred gifts will show at the top of the page when browsing gifts for an occasion. You can set each gift as preferred for multiple occasions. Only occasions you've activated are shown in the list below."
            },
            "preferred-gifts-updated": "Preferred gifts updated.",
            "packaging_and_product_are_customizable": "The packaging and the gift itself can be customized",
            "start_colorlab_personalization_packaging_and_product": "Customize packaging and gift",
            "voucher": {
                "delivery_radio": {
                    "direct_to_recipient": {
                        "label": "Send the voucher(s) directly to the recipient(s)",
                        "explainer": "You’ll set the email addresses for each recipient here."
                    },
                    "office": {
                        "label": "Send the voucher(s) to my email inbox",
                        "explainer": "We’ll send it to the email address of your account."
                    }
                },
                "digitize_message_toggle": {
                    "title": "Invite people to add personal messages",
                    "explainer": "When redeeming the voucher, the recipient will see a page where your message and contributor's message will be shown."
                }
            },
            "physical": {
                "delivery_radio": {
                    "direct_to_recipient": {
                        "label": "Send the gift(s) directly to the recipient(s)",
                        "explainer": "You’ll set the addresses for each recipient here."
                    },
                    "office": {
                        "label": "Send the gift(s) to a single location (usually the office)",
                        "explainer": "You’ll set the address during checkout."
                    }
                },
                "digitize_message_toggle": {
                    "title": "Invite other colleague(s) to add a personal message",
                    "explainer": "The messages will be available behind a QR-code on the greeting card"
                }
            },
            "greeting_card_title": "Greeting card",
            "greeting_card_description": "With Kadonation, you create impactful moments! We believe that a personal message is just as important as the gift. Share your message via a beautiful greeting card and invite others to write a message as well.",
            "greeting_card_toggle_title": "Add greeting card with your personal message (+ {price})",
            "greeting_card_toggle_description": "You can add your personal message for the card below.",
            "personalizaiton_disabled_title": "Personalization disabled",
            "personalizaiton_disabled_description": "Personalization per recipient is only possible when a greeting card is added.",
            "delivery_direct_subtext": "Where should this gift be delivered?",
            "delivery": "Delivery",
            "gift_quantity_and_value": "Quantity and amount",
            "gift_details": "Gift details",
            "pricing": "Pricing",
            "unit_price": "Unit price",
            "volume_discount": "Volume discount",
            "gift_quantity": "Quantity",
            "recipients_selected": "No recipient selected | 1 recipient selected | {count} recipients selected",
            "recipients_uploaded_via_excel": "No recipient uploaded via excel | 1 recipient uploaded via excel | {count} uploaded via excel",
            "delivery_type": {
                "directly_to_recipient": "Direct to recipient",
                "inbox": "E-mail inbox",
                "single_address": "Single address",
                "title": "Delivery",
                "explainer": "Where should this gift be delivered?"
            },
            "occasion_not_set": "Occasion not set",
            "select_employees_as_recipients": {
                "title": "Select employees as recipients",
                "explanation": "Select employees you’ve added to Select. You can also use this to manually add multiple recipients."
            },
            "save_recipients_before_adding_to_cart": "Please save recipients first to add the gift to cart",
            "pricing_empty_no_recipient_selected": "No recipient(s) selected yet",
            "recipient_country_missing": "Country missing",
            "shipping_fee_calculated_at_checkout": "Calculated at checkout",
            "recipient_details": "Recipient details",
            "recipient_address": "Recipient address",
            "upload_excel_file": "Upload excel file",
            "invoice_info": "Invoice info",
            "contributors_added": "1 contributor added | {count} contributors added",
            "message_contributors": {
                "title": "Personal messages",
                "explainer": "Determine here who can write a personal message. We will place a QR code on the greeting card. By scanning this code, the recipient will see all the personal messages."
            },
            "add_contributors_button": "Add contributors",
            "edit_contributors_button": "Edit invitees",
            "add_voucher_personal_message_disclaimer": {
                "title": "Invite contributors",
                "explainer": "When redeeming the voucher, the recipient will see a page where your message and contributor's messages will be shown."
            },
            "digitize_enabled": {
                "personalize_voucher_subtitle": "Personalize the content of your voucher.",
                "excel_disclaimer": {
                    "title": "Make sure that you have not invited any recipients to write a personal message, as they will be able to also add a message for themselves.",
                    "explainer": "Manually check recipients and invitees for personal messages"
                },
                "personalize_greeting_card_subtitle": "Customize the greeting card with a message. You can also add your own personal message by inviting yourself in 'Personal messages'. These messages are available via the QR-code that is printed on the greeting card."
            },
            "add_contributor_manually": "Add contributor manually",
            "add_myself_as_contributor": "Add myself as contributor",
            "message_contributor_explainer_modal": {
                "title": "How does 'Personal messages' work?",
                "explainer": "With' Personal messages' you can invite other people to write a personal message to the gift recipient."
            },
            "save_contributors": "Save contributors",
            "personal_message_input_length_limit_error": "Your message exceeds the number of allowed characters. Please make it shorter to ensure readability",
            "vendor_cannot_print_qr_title": "The greeting card with this gift does not have a QR code",
            "shipping_fee": "Shipping"
        },
        "days": {
            "sunday": "Sunday",
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday"
        },
        "api": {
            "key": "Key",
            "secret": "Secret",
            "secret_warning": "Please store this Secret somewhere safely, this is the only time you can see it.",
            "unused_token": "Token has never been used so far.",
            "token_last_used": "Last used at {at}",
            "token_expires_at": "Tokens don't expire automatically. We advise you to rotate your tokens frequently. You can create or revoke tokens at any time.",
            "new_token_placeholder": "New API token name",
            "too_many_tokens_warning": "It looks like you have more than one API token at the moment. This is not recommended for security reasons. Remove tokens you don't use."
        },
        "labels": {
            "beta": "Beta",
            "beta-description": "This gift is currently in Beta. This means some issues may still occur when trying to place an order for this gift.\n\nThis product is currently still in a beta phase. Are there things we can improve or are you experiencing problems placing your order? Please contact us at contact@kadonation.com or call +32 9 396 32 00."
        },
        "kudos": {
            "input-groups": "Input groups",
            "create-input-group": "Create input group",
            "activate": "Activate",
            "deactivate": "Deactivate",
            "click_desired_locale_url": "Copy URL to clipboard",
            "order_campaign": "Order gifts",
            "senders_required": "Please select who can write Kudos in step 2",
            "receivers_required": "Please select who receives Kudos messages in step 3",
            "order_step_1": "Step 1: download your employees",
            "order_step_2": "Step 2: order gifts",
            "order_step_1_description": "Download the excel file below and fill in the requested fields.",
            "order_step_2_description": "Once you have completed the excel file, you can upload it in the tab 'via excel' when placing an order.",
            "order_already_placed": "Warning: an order has already been placed",
            "order_already_placed_description": "An order has already been placed for this campaign.",
            "place_order": "Place order",
            "edit_campaign": "Modify campaign",
            "edit_order": "Edit order",
            "give_feedback": "Give kudos",
            "search_employee": "Search employee",
            "search_colleague": "To",
            "select_teams": "Select teams",
            "no_employees_selected": "No employees selected",
            "description_only_two_translation": "Warning: campaign description has only two translations",
            "name_only_two_translation": "Warning: campaign name has only two translation",
            "prefix_only_two_translation": "Warning: prefix has only two translation",
            "email_not_found": "Email not found",
            "submit": "Submit",
            "campaign_text": "Kudos are positive messages you can receive from your colleagues when you redeem your gift vouchers. They make the gift more personal and create a lasting moment! Configure and follow up Kudos campaigns here. A campaign allows you to define who writes Kudos messages and who are the recipients. Configure, follow-up and order vouchers for a campaign from this page.",
            "campaign_title": "Kudos campaigns",
            "description_only_one_translation": "Warning: campaign description has only one translation",
            "invite_text": "No need to sign on! Enter you email address in the box below and click submit. You will then receive a link to write Kudos to your colleagues for this campaign.",
            "prefix_only_one_translation": "Warning: prefix has only one translation",
            "welcome": "Welcome to Kadonation Kudos",
            "name_only_one_translation": "Warning: campaign name has only one translation",
            "selected_teams": "Selected teams",
            "selected_input_receivers": "Who receives",
            "selected_input_givers": "Who writes",
            "use_givers_as_receivers": "Add same employees that are writing Kudos",
            "create_campaign": "Create new campaign",
            "campaign_details": "Campaign info",
            "input_givers": "Who writes",
            "input_receivers": "Who is/are the recipient(s) of the Kudos campaign?",
            "campaign_form_description": "Configure a Kudos campaign in three simple steps. In step 1, define the campaign name and description in at least one language. Optionally, specify a prefix such as \"Thank you for...\" or \"Thank you for this memory...\" that is prefixed before every Kudos message.",
            "name": "Name of the campaign",
            "description": "Description",
            "prefix": "Kudos prefix, e.g. \"Thank you for...\"",
            "campaign_form_receivers_description": "Step 3: Select teams and/or individual employees for whom Kudos are written",
            "campaign_form_senders_description": "Step 2: Select teams and/or individual employees who can write Kudos",
            "campaigns": "Campaigns",
            "create_employee": "Create employee",
            "delete_employee": "Delete employees",
            "employee": "Employee",
            "employees": "Employees",
            "employees_text": "Employees can be used in Kudos. They do not have access to Kadonation Select.",
            "empty_employees_text": "You have not added any employees yet",
            "empty_kudos_text": "Employees can be added in a Kudos campaign. They do not have access to Kadonation Select itself.",
            "input_received": "# received",
            "input_given": "# written",
            "get_url": "Get campaign links",
            "no_employees_added": "No employees added",
            "kudos": "Kudos",
            "add_employee": "Add employee",
            "import_employees": "Import employees",
            "you_will_receive_an_email_if_you_have_access_to_this_campaign": "You will receive an email if you have access to this campaign",
            "add_colleague": "Write Kudos message",
            "input": {
                "added_colleagues": "Your written Kudo messages",
                "pending": "Pending kudo invites",
                "deadline": "Deadline for giving Kudos",
                "character-limit": "of 300 characters",
                "none-written": "You haven't written any kudos messages yet!",
                "no-one-left": "You have no one else to write kudos messages for!",
                "all_input_given_title": "Awesome!",
                "all_input_given_text": "You've written kudos messages for everyone! If you want, you can still edit your messages as long as the deadline hasn't passed."
            },
            "employee_message": "Message",
            "senders": "Senders",
            "receivers": "Receivers",
            "email_template": "Dear employee\n\nWelcome to Kadonation Kudos. \nA new campaign has started that you can be a part of!\nSend Kudos to your colleague(s) via the link below.",
            "employees_title": "Employees",
            "email_title": "Email Text",
            "go_campaign_title": "Go back to Campaign Overview",
            "campaign_links_title": "Campaign Links",
            "employees_description": "Copy email template text & modify as needed",
            "email_description": "Select the employees emails in bulk",
            "campaign_links_description": "Copy campaign links for other channels e.g. slack",
            "all_employees": "All employees",
            "en_employees": "English Employees",
            "whats_next": "What's next?",
            "campaign_overview": "Campaign Overview",
            "fr_employees": "French employees",
            "nl_employees": "Dutch Employees",
            "success": "Success",
            "or": "or",
            "emailing": "Emailing",
            "campaign_create_success": "You have successfully created the campaign",
            "go_to_emailing": "Go to emailing page",
            "empty_employees_message": "You cannot create a campaign without employees. Start adding or import your employees.",
            "sign_in_kudos_inactive_message": "This campaign is not active!\nThe campaign might have been finished or might still need to be activated.\nPlease check with the campaign creator if you have questions about this.",
            "receiver": "Receiver",
            "sender": "Sender",
            "email": {
                "magic_link_expired": "The link has expired.",
                "email_start": "<div id=\"email-start\">\n      <p>Hello <strong>[First Name] [Last Name]</strong></p>\n  </div>",
                "email_end": "<div id=\"email-end\" class=\"my-4\">\n    <p>Best regards,</p>\n    <p><strong>[Company entity]</strong></p>\n  </div>",
                "email_body": "<div class=\"py-2 opacity-50\" contenteditable=\"false\">\n    <div class=\"flex items-center space-x-2\">\n      <div class=\"w-full h-px border\"></div>\n      <div class=\"text-sm min-w-max uppercase font-bold\">Edit Above the Line</div>\n      <div class=\"w-full h-px border\"></div>\n    </div>\n    <br />\n    <!-- start content -->\n    <div>\n      <p>You are ready to hand out Kadonation Kudos.</p>\n\t<p>Click on the personal link below to contribute to the campaign.</p><p>Good luck!</p>\n\t<div class=\"my-8 flex justify-center\">\n\t\t<span class=\"uppercase text-xs font-semibold text-white bg-pink-300 py-3 px-6 rounded\">\n\t          Give kudos\n        \t\t</span>\n\t</div>\n\t<p><strong>Important: To ensure that others do not gain access, it is important that you do not forward this email.</strong></p>      \n    </div>\n    <!-- end content -->\n    <div class=\"flex items-center space-x-2\">\n      <div class=\"w-full h-px border\"></div>\n      <div class=\"text-sm min-w-max uppercase font-bold\">Edit Below the Line</div>\n      <div class=\"w-full h-px border\"></div>\n    </div>\n  </div>",
                "locale_emails": "When you select this option you can write a different email message for each language of your employees.",
                "locale_emails_label": "Use different emails per language",
                "general_language": "Language",
                "subject": "Subject",
                "subject-value": "Invitation for {campaignName}",
                "resend_kudo_invite_to_new_people": "Only send the kudo invites to people who haven’t received the kudo invites",
                "send_to_new_people_label": "Resend only to new people"
            },
            "ordering_message": "You are placing an order for the campaign \"{campaignName}\". Not ready yet?",
            "exit_button": "Exit Kudos",
            "stopped_ordering": "You are no longer ordering for the Kudos campaign",
            "download_explanation": "You can now fill in the excel further and use it to order any gift/option. Upon redeem, the messages will be shown to the end user",
            "employee_search_placeholder": "Search by name or email",
            "search": "Search a colleague",
            "campaign_email_description": "Step 4: Send an email with the Kudos link to your employees. You can skip this step if you'd like.\nThe following words [First Name] , [Last Name], [Campaign Name], [Company Entity] will be automatically populated with the correct data per employee.",
            "expired": {
                "btn": "Request new link",
                "title": "Oops!",
                "text": "Your link to give kudos has expired, please request a new link!"
            },
            "delete_title": "Are you sure you want to delete this campaign?",
            "delete_description": "Doing so means you'll lose all kudos messages. This action can't be undone!",
            "delete_cta": "Delete campaign",
            "activation_description": "You can activate and deactivate your campaign. When your kudos campaign is deactivated, kudos can't be given.",
            "send_campaign": "Send invites",
            "tooltip": {
                "deadline": "When this date has passed, your employees can no longer write messages",
                "prefix": "Use this to push your employees in the right direction with their message",
                "campaign_name": "Give this campaign a name",
                "description": "Here you can write additional info that will be shown to the people who may write a kudos message, use this to inform the invitees about the kudo opportunity."
            },
            "not_send_yet": "This campaign has not been sent out yet to the kudos givers",
            "kudos_given": "You've already written Kudos messages for:",
            "hide_message": "Hide message",
            "message_added": "Your message has been saved.",
            "message_edited": "Your message has been edited",
            "email_found": "Please check your inbox",
            "kudos_to_give": "You still have to write Kudos messages for:",
            "deadline_has_passed_sending_out_is_disabled": "The deadline for the kudo campaign has expired. Therefor you can't send the kudo campaign, please modify the deadline if you want to send the campaign.",
            "campaign_status_updated": "Campaign successfully updated",
            "campaign-expired": {
                "title": "This Kudo campaign has expired.",
                "text": "Please contact the organizer if you think this is a mistake. They can modify the deadline and send a new invite."
            },
            "cart_not_empty": "You have gifts in your cart. Please complete your ongoing order before ordering for the Kudos campaign.",
            "some_employees_without_email_are_omitted": "There are employees that do not have an email-address set and cannot receive your invitation to share a personal message. Please check your employees list and make sure all employees have an email-address.",
            "what_are_kudos": "What are Kudos?",
            "no_kudos_set_title": "No Kudos campaigns created yet",
            "no_kudos_set_description": "Start a Kudos campaign, invite your colleagues and send each other positive messages.",
            "kudos_feature_items": "Order gifts with addresses and details already filled out |\nCreate birthday and work anniversary gift automations |\nReceive reminders of birthdays and work anniversaries (coming soon)",
            "no_employees_set_description": "In order to use the Kudos feature, you’ll need to add employees first. The ‘Employees’ feature stores your employees’ details for easy access throughout Kadonation Select. Adding employees is easy, just connect to your HR system, or upload an Excel file.",
            "kudos_features_title": "Besides the Kudos feature, you’ll also to be able to:",
            "create_one_now": "Create one now",
            "kudo_deactivated_sending_out_is_disabled": "This campaign is not active. Please activate this campaign to send invitations.",
            "kudos_will_expire_on": "Deadline for giving Kudos:",
            "edit_kudos_message": "Edit kudos message",
            "search_recipient": "Search recipient",
            "exit_confirmation_title": "You’ve got gifts in your cart for this Kudos campaign. These will be removed from your cart if you continue.",
            "exit_confirmation_message": "Cart will be cleared",
            "deadline_description": "When the deadline is reached it is no longer possible to enter Kudos messages. This deadline date is generally when the gift will be delivered.",
            "feature_removed_modal": {
                "title": "This feature has been moved",
                "description": "When ordering a gift, you can now invite others to add personal messages. This applies to both digital vouchers and physical gifts. The recipient will be able to read these personal messages upon receiving the gift."
            },
            "contributions": {
                "page_title": "Messages",
                "all_messages_delivered": {
                    "title": "All your messages have been delivered",
                    "explainer": "Please come back when you receive a new invite to add a message to a gift."
                },
                "link_expired": {
                    "title": "The link you used has expired",
                    "explainer": "Please request another link by clicking the button below.",
                    "request_new_link_button": "Request a new link"
                },
                "influencing_text_to_add_message": "A heartfelt message is as much a part of the gift as the present itself, and transforms it into a lasting memory.",
                "add_message_title": "Write a message",
                "written_messages_title": "Written messages",
                "written_messages_explainer": "These disappear when the recipient has received the gift.",
                "go_back_button": "Go back",
                "write_message_for": "Write a message for",
                "message_input_label": "Message",
                "message_input_placeholder": "Write a message",
                "from_input_label": "From",
                "save_message_button": "Save message",
                "edit_message_button": "Edit message",
                "message_updated_notification": "Message has been saved successfully.",
                "view_message_button": "View",
                "write_message_button": "Write",
                "write_message_for_before_date_text": "by",
                "received_messages": {
                    "tap_instructions": {
                        "title": "Tap the screen to move through your messages",
                        "previous": "Previous message",
                        "next": "Next message",
                        "use_bottom_icons": "Or use these buttons"
                    },
                    "skip_to_end_button": "Skip to end",
                    "page_title": "Congratulations!",
                    "say_thank_you_button": "Say thank you",
                    "landing": {
                        "you_have_messages": {
                            "title": "Congratulations!",
                            "explainer": "You’ve received some special messages"
                        },
                        "say_thank_you": {
                            "title": "Say thank you to",
                            "explainer": "Let the giver of this gift know that you appreciate the gift."
                        },
                        "view_messages": "View messages",
                        "say_thank_you_button": "Say thank you"
                    }
                },
                "thank_you_modal": {
                    "thank_you_sent_title": "Your thank you message has been sent",
                    "title": "Say thank you",
                    "thank_you_sent_explainer": "The sender of the gift will receive this message via e-mail. Have a great day!",
                    "explainer": "The person who ordered will receive an email showing your thank you message.",
                    "message_input_label": "Thank you message",
                    "close": "Close",
                    "say_thanks_button": "Send thank you"
                },
                "thank_you_page": {
                    "title": "Say thank you to",
                    "explainer": "Let them know that you appreciate the gift by saying thank you, in whichever way you like.\n\nSome colleagues may still add a message as the deadline for contributing is not yet reached. Check back later today or tomorrow!"
                },
                "rewatch_messages_button": "Rewatch",
                "clear_all_contributors_button": "Clear list"
            }
        },
        "dashboard": {
            "news": "News",
            "spend_report": "Spending report",
            "no_news": "No news",
            "no_spend_data": "No orders have been placed yet, so there is no data for a report.",
            "trees_planted": "Trees Planted",
            "trees_planted_great_job": "Great job to you and your team!<br/>That’s enough to compensate",
            "learn_more": "Learn more",
            "no_trees_planted_message": "Start placing orders and see how this impact the environment via our WeForest program.",
            "select_occasion_before_redirect": "Please select an occasion before we redirect you to the selected gift.",
            "dashboard": "Dashboard",
            "change_language": "Change language"
        },
        "quotation": {
            "quotation": "Quotation",
            "date": "Quotation date",
            "expiration-date": "Expiration date",
            "description": "Description",
            "amount-excl-vat": "Amount (excl. VAT)",
            "quantity": "Quantity",
            "vat": "VAT",
            "total-excl-vat": "Total (excl. VAT)",
            "net": "Net",
            "total": "Total",
            "created-at": "Created at",
            "name": "Name",
            "signature": "Signature",
            "delivery-date": "Delivery date",
            "invoice-address": "Invoice address",
            "our-terms-can-be-found-on-the-following-page": "The performance of this offer is governed exclusively by Kadonation's <a target='_blank' href='https://kadonationselect.com/terms-and-conditions'>General Terms and Conditions</a> and <a target='_blank' href='https://kadonationselect.com/data-processing-agreement'>Data Processing Policy</a>, as enclosed. By approving/signing this offer (or confirming this offer by means of a purchase order, for instance, with reference to the quotation), the Customer declares that he/she is aware of the aforementioned documents and agrees to the provisions contained therein.",
            "for-agreement": "By agreement",
            "wanted-delivery-date": "Desired delivery date",
            "created-in-text": "Drawn up in Ghent on {createdAt} in as many copies as there are parties",
            "ordered-quantity-may-differ": "The final number of gift certificates ordered may vary 10% from the numbers listed in this quotation.",
            "provide_valid_email": "Please provide a valid email.",
            "minimum_one_recipient": "Add at least one beneficiary.",
            "missing-delivery-info-title": "No delivery address added to the order",
            "missing-delivery-info-description": "In order for the shipping costs to be calculated correctly, a shipping address must be added to the order. You can do this by going to the order page and clicking on the action \"Add delivery address to order\".",
            "vendor_confirm_quote": {
                "description": "The quote is ready to be presented to our client. Please confirm if the items can be offered to our client, or let us know what needs to change using the field below. If there are multiple quotations, please note which one cannot be offered.",
                "approve_or_reject_request": "Approve/reject request",
                "order_id": "Order ID",
                "email_sent_on": "Request email sent on",
                "remarks": "Remarks",
                "accept": "Accept",
                "reject": "Reject",
                "request_accepted": "Request accepted",
                "request_accepted_description": "This request is now marked as ‘accepted’. We will send the quotation to the client.",
                "request_rejected": "Request rejected",
                "request_rejected_description": "This request is now marked as ‘rejected’. We will review your notes, make the required changes and contact you for approval again.",
                "quotation_info": "Quotation info",
                "response_on": "Request response on",
                "quote_id": "Quote ID",
                "title": "Quote Request"
            },
            "requested-by": "Requested by",
            "missing-expected-delivery-at-info-text": "This order does not have an expected delivery date set. Beware that this is needed to calculate the expiry date of vouchers!",
            "missing-expected-delivery-at-info-title": "No expected delivery date is set",
            "shipping-price-page": {
                "explanation": "The shipping cost specified above is an indication and may vary. The following page contains our shipping prices in a table, allowing you to calculate the cost per office delivery.",
                "heading": "Shippingprice table",
                "table": {
                    "country": "Country",
                    "min_gifts": "Min Gifts",
                    "max_gifts": "Max Gifts",
                    "price": "Price (excl. VAT)"
                }
            },
            "delivery_to": "(delivery to: {deliveryType})",
            "office_delivery": "office",
            "home_delivery": "recipient",
            "will-be-delivered-to": "To be delivered in",
            "delivery-address": "Delivery address"
        },
        "filter": {
            "price_range": "Price range",
            "min": "Min",
            "max": "Max",
            "category": "Category",
            "countries": "Ships to",
            "view_more": "View {count} more",
            "view_less": "View less",
            "sort": {
                "popular": "Popular",
                "new": "New",
                "recommended": "Recommended",
                "price_asc": "Price low-high",
                "price_desc": "Price high-low"
            },
            "no_filter_found": "No filters found",
            "find_filter": "Search",
            "customizable": "Customizable",
            "next_day_delivery": "Next day delivery",
            "sustainable": "Sustainable",
            "made_locally": "Locally made",
            "clear_filters": "Clear filters",
            "boolan_filters": "Type",
            "loading_gifts": "Loading gifts",
            "price": "Price",
            "all_price": "Any price",
            "delivery_country": "Delivery to",
            "sort_label": "Sort",
            "delivery_region": "Delivery to specific region"
        },
        "modals": {
            "departments": {
                "delete_error": {
                    "has_users": {
                        "title": "Cannot delete department",
                        "message": "This department cannot be deleted, as there are users associated with it. Please assign these users to a different department before deleting it."
                    },
                    "has_credits": {
                        "title": "Cannot delete department",
                        "message": "This department cannot be deleted, as there are unused credits available to it."
                    }
                }
            },
            "users": {
                "deactivate_error": {
                    "has_applicants": {
                        "title": "Cannot deactivate user",
                        "message": "This user cannot be deleted, as it is set as approval manager for other users. Please assign a different approval manager for these  before deactivating it."
                    }
                }
            },
            "general": {
                "list": {
                    "has_more": "...and {number} more"
                }
            },
            "add_to_cart": {
                "no_address": {
                    "title": "No address set",
                    "message": "No address has been set on the company entity. Please add this first, as it is required to place orders.",
                    "buttons": {
                        "confirm": "Set Address",
                        "cancel": "Close"
                    }
                }
            },
            "invoices": {
                "insufficient_credits_to_finalize_proforma": {
                    "title": "Insufficient credits",
                    "message": "There are insufficient credits to cover the cost of some orders. If you continue finalizing the proforma, the remaining amount will be charged via invoice.",
                    "buttons": {
                        "cancel": "Cancel",
                        "confirm": "Finalize"
                    }
                }
            },
            "delete_department_modal_title": "Delete department?",
            "delete_address_modal_title": "Delete address?",
            "clear-cart-modal-title": "Clear cart?",
            "clear-cart-modal-message": "Are you sure you wish to remove all gift from your cart?",
            "confirm_delete_user": {
                "title": "Delete user?",
                "message": "Are you sure you wish to delete this user?"
            },
            "upload_logo_title": "Upload logo",
            "upload_logo_body": "Your logo will be printed on gift vouchers and greeting cards."
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The field '{attribute}' must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The field '{attribute}' has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The field '{attribute}' must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The field '{attribute}' does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": {
                "mixed": "The {attribute} must contain at least one uppercase and one lowercase letter.",
                "letters": "The {attribute} must contain at least one letter.",
                "symbols": "The {attribute} must contain at least one symbol. For example: @, &, /, etc",
                "numbers": "The {attribute} must contain at least one number.",
                "uncompromised": "The given {attribute} has appeared in a data leak and is not safe to use. Please choose a different {attribute}."
            },
            "present": "The field '{attribute}' must be present.",
            "regex": "The {attribute} format is invalid. The password must be more than 8 characters long and should at least have: 1 uppercase letter, 1 lower case letter, 1 numeric value and 1 special character.",
            "required": "The field '{attribute}' is required.",
            "required_if": "The field '{attribute}' is required when {other} is {value}.",
            "required_unless": "The field '{attribute}' is required unless {other} is in {values}.",
            "required_with": "The field '{attribute}' is required when {values} is present.",
            "required_with_all": "The field '{attribute}' is required when {values} are present.",
            "required_without": "The field '{attribute}' is required when {values} is not present.",
            "required_without_all": "The field '{attribute}' is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                },
                "message": {
                    "max": "The {attribute} may not be greater than {max} characters.",
                    "required_with": "The field '{attribute}' is required."
                },
                "from": {
                    "required_with": "The field '{attribute}' is required"
                },
                "to": {
                    "required_with": "The field '{attribute}' is required."
                },
                "option": {
                    "required_without": "The field '{attribute}' is required when {values} is not present.",
                    "required": "Please select an option"
                },
                "price": {
                    "required": "The field '{attribute}' is required.",
                    "numeric": "The field '{attribute}' must be numeric.",
                    "required_without": "Please choose or fill in an amount"
                },
                "excel": {
                    "required_with": "Please upload the excelfile",
                    "row_error": "There was an error on row {row}. {message}",
                    "message_row": "{message}. Row(s) {rows}.",
                    "date_format": "The provided date format for {attribute} is invalid, please format dates as such: dd/mm/yyyy",
                    "date_in_future": "The provided date for {attribute} can not be in the future",
                    "company_entity_not_found": "Company entity not found ({attribute})",
                    "department_not_found": "Department not found ({attribute})",
                    "country_not_found_with_code": "Country not found with countrycode {attribute}",
                    "external_employee_id": "Duplicated external employee ID!",
                    "attribute_missing": "The {attribute} value is missing!",
                    "manager_not_found": "Teamlead/manager could not be found."
                },
                "personal_message": {
                    "max": "Personal messages can be a maximum of {max} characters. Your message is {excess} characters too long. Keep in mind that an ‘enter’ counts as {newline_size} characters.",
                    "emoji": "Your personal message should not contain emojis or characters that form emojis. These cannot be printed."
                }
            },
            "tenant_subdomain_in_use": "Tenant subdomain is in use",
            "attributes": {
                "first_name": "first name",
                "option": "choose an option",
                "price": "amount",
                "is_group": "groupsfile",
                "department_id": "department",
                "last_name": "last name",
                "message": "message",
                "from": "The field 'from'",
                "to": "The field 'to'",
                "password": "password",
                "excel_file": "excel file",
                "name": "Name"
            },
            "message": "Message",
            "custom_dimension": "Image is invalid, Check the file for correct type and dimensions (Less than 150x30px or less than 5{1} ratio).",
            "duplicate_email_addresses": "The file contains duplicate email addresses ({address})",
            "excel": {
                "amount_required_with_from_to_message": "The amount is required"
            },
            "name": {
                "en": "Name",
                "fr": "Name",
                "nl": "Name"
            },
            "file_must_be_excel": "The file must be a valid excelfile",
            "must_have_only_one_option": "This gift only accepts one option.",
            "no_duplicate_data_is_allowed": "No duplicate data in amount or year count is allowed.",
            "employee_already_in_team": "Employee is already a member of the team previously chosen.",
            "year_count_should_be_divisible_by_point_five": "The {attribute} must be an integer or divisible by 0.5.",
            "employee_date_field_must_be_set": "employee_date_field data is not set for this occasion.",
            "invalid_tenant_subdomain": "Invalid subdomain. Avoid using spaces, dots and other special characters.",
            "invalid_zipcode": "Invalid zip code, please enter a valid zip code.",
            "shipping_country_not_supported": "{vendor} currently does not support shipping to {value}. Please choose a different address.",
            "default_entity_unset": "Cannot unset default company. To change set another one as default, and this will be unchecked automatically!",
            "unknown_shipping_country": "We do not support shipping to the country of this receiver. ({country})",
            "recipient_name_character_limit_exceeded": "The name of the recipient (first+last name) can be a maximum of 30 characters.",
            "tenant_vat_number_does_not_exist": "The VAT Number was checked and does not exist. Please check with the Tenant for their correct VAT Number. You may proceed creating this Tenant by indicating they are not ‘VAT Liable’ (meaning we will charge them VAT always)",
            "phone": "The {attribute} field must be a valid number.",
            "company_email": "Please use your work email to create an account. Email domains such as gmail, outlook and yahoo are not allowed.",
            "unique_email_domain": "It seems this company already has an account.",
            "gift_cannot_be_send_to_that_zipcode": "Gift cannot be send to this region ({zipCode}).",
            "gift_with_name_cannot_be_send_to_that_zipcode": "'{giftName}' cannot be sent to this region ({zipCode}).",
            "credits_payer_required": "Select where to get Credits from.",
            "expiration_period": "The max expiration period is 3 years."
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "2fa_verification": "2FA Verification",
            "2fa_continue": "Continue",
            "2fa_setup": "Setup 2FA Verification",
            "2fa_scan_or_code": "Scan the bar code below or use the code",
            "2fa_must_setup": "You must set up your Google Authenticator app before continuing. You will be unable to login otherwise.",
            "2fa_otp": "One Time Password",
            "2fa_wrong_otp": "The 'One Time Password' typed was wrong.",
            "2fa_cannot_be_empty": "One Time Password cannot be empty.",
            "2fa_unknown": "An unknown error has occurred. Please try again."
        },
        "anniversaries": {
            "step-1-title": "{typeOfService} details",
            "step-1-description": "Set up a {typeOfService} by defining a name and determining the amount the employees will receive.",
            "step-2-title": "Select employees",
            "step-2-description": "Select which employees or which teams should receive this gift.\nYou can also determine whether new employees are automatically added to this {typeOfService}.",
            "step-3-title": "Order details",
            "step-3-description": "Fully control how these orders are placed and what information should appear on the invoice.",
            "step-4-title": "Personalization details",
            "step-4-description": "Personalize the gift for the employee, determine the message on the gift, what email the employee will receive and at what time.",
            "different-amount-for-work": "For work anniversaries, you can set up different amounts for different years of service",
            "year": "Years Of Service",
            "add-another-option": "Add another option",
            "enrollment-title": "Automatic enrollment",
            "enrollment-description": "Allows for automatic enrollment of employees from the list when all employees are selected or from the selected teams",
            "no-results": "No employees were found with this search term",
            "team": "Team | Teams",
            "selected": "Selected",
            "select-all-employees": "Select all employees",
            "selected-teams": "Selected teams",
            "selected-employees": "Selected employees",
            "search": "Search employees",
            "template": "Template",
            "birthday-service": "happy birthday service",
            "work-anniversary-service": "work anniversary service",
            "service-name-placeholder": "E.g. {typeOfService} for all employees",
            "select-employees-or-enable-auto-enrollment": "You must select employees/teams or enable automatic enrollment",
            "having-trouble": "Having Trouble?",
            "contact-us": "Contact Us",
            "automate-service": "Automate {typeOfService}",
            "lets-automate": "Let’s automate the gifts",
            "automate-gifts": "Automate Gifts",
            "created-successfully": "{typeOfService} created successfully.",
            "birthday-automated-service-description": "Your birthday service is active, you can edit or delete it at any time.",
            "work-anniversary-automated-service-description": "Your work anniversary service is active, you can edit or delete it at any time.",
            "select-all-teams": "Select All Teams",
            "template-preview": "Template Preview",
            "expiration-date-description": "Set an expiration date for this automatic service. After this expiration date, we will no longer place orders for this service.",
            "deleted-successfully": "{typeOfService} is successfully deleted.",
            "are-you-sure": "Are you sure?",
            "all-employees": "All employees",
            "updated-successfully": "{typeOfService} updated successfully.",
            "lets-automate-work-anniversary": "Wish every employee a happy work anniversary!\nIf, as a company, you do not let this valuable moment pass unnoticed, then you definitely have an edge. No one forgotten? Our free Happy Anniversary Service takes care of it!\n\nCreate a link to your personnel database, set an amount, and Kadonation Select will automatically give your employee your gift with a personal message!",
            "lets-automate-birthday": "Wish every employee a happy birthday!\nIf, as a company, you do not let this valuable moment pass unnoticed, then you definitely have an edge. No one forgotten? Our free Happy Birthday Service takes care of it!\n\nCreate a link to your personnel database, set an amount, and Kadonation Select will automatically give your employee your gift with a personal message!",
            "is-loading": "Loading employees...",
            "finish": "Save",
            "error_message_here": "Error message here"
        },
        "subscription": {
            "upgrade_modal": {
                "general": {
                    "enterprise": {
                        "title": "Upgrade to Enterprise",
                        "body": "Elevate your corporate gifting to new heights with Kadonation Select's Enterprise plan, designed for large-scale operations and businesses that demand the best. This plan provides the ultimate in customization and control, ensuring that every gift reflects your brand's excellence."
                    },
                    "professional": {
                        "title": "Upgrade to Professional",
                        "body": "Unlock the full potential of your corporate gifting strategy with Kadonation Select's Professional plan. Designed for businesses that value exceptional employee and client relationships, this plan enhances your gifting experience by offering advanced tools and services."
                    }
                },
                "enterprise": {
                    "subscription_content": {
                        "title": "Upgrade to Enterprise to unlock these features:",
                        "list": "Add custom occasions ^\nDaily synchronisation of employees ^\nWhite label platform ^\nSingle Sign-on ^\nDedicated Success Manager ^\nFeature prioritisation ^\nPriority support ^\nAnd more..."
                    }
                },
                "professional": {
                    "subscription_content": {
                        "title": "Upgrade to Professional to unlock these features:",
                        "list": "Advanced invoicing settings ^\nManage gifts, occasions and budget ^\nAutomated synchronisation of employees ^\nGifting automations ^\nKudos campaigns ^\nOrder approval flow ^\nPermissions per user ^\nAnd more..."
                    }
                }
            },
            "view_plans": "View all plans",
            "upgrade_button": "Upgrade",
            "disclosure": {
                "title": "Upgrade to Professional to unlock this feature"
            },
            "cancel_subscription_button": "Cancel subscription",
            "canceled_disclosure": {
                "title": "Your subscription will end on {date}",
                "body": "You’ve canceled your subscription. You can use the subscription’s features until it ends. After that, data related to those features will be deleted. You’ll automatically return to the ‘Basic’ plan.",
                "button": "Reactivate Subscription"
            },
            "subscription_details": "Subscription Details",
            "subscription_plan": "Subscription plan",
            "trial": "trial",
            "subscription_billing_cycle": "Subscription billing",
            "subscription_billed_company_entity": "Billed Company Entity",
            "subscription_starts_at": "Subscription start",
            "subscription_canceled_at": "Subscription canceled on",
            "subscription_ends_at": "Subscription end",
            "next_invoice_at": "Next invoice",
            "cancel_modal": {
                "title": "Cancel Subscription",
                "cancel_subscription_button": "Yes, cancel Subscription",
                "keep_subscription_button": "No, keep subscription",
                "intro_text": "Your subscription will be canceled immediately and you will no longer be billed.",
                "lose_access_on": "You will lose access to the features of your subscription on {date}.",
                "data_removal_warning": {
                    "title": "All data will be removed",
                    "body": "We will remove ALL DATA related to your subscription features on {date}. This includes:",
                    "list": "All automations and Kudos campaigns ^\nPreferred gifts ^\nBudget and occasions settings ^\nUser permissions & order approval settings ^\nAdvanced invoice settings"
                },
                "employee_sync_deactivation_warning": "If you have an automatic employee synchronisation, this will be deactivated. Your added employees will be retained.",
                "sure_to_cancel_title": "Are you sure you wish to cancel your subscription?"
            },
            "upgrade_disclosure": {
                "basic": {
                    "title": "You’re currently on the ‘Basic’ plan",
                    "body": "Unlock the full potential of your corporate gifting strategy with Kadonation Select's Professional plan.",
                    "button": "Learn more"
                },
                "professional": {
                    "title": "You’re currently on the ‘Professional’ plan",
                    "body": "Elevate your corporate gifting to new heights with Kadonation Select's Enterprise plan, designed for large-scale operations and businesses that demand the best.",
                    "button": "Learn more"
                },
                "trial": {
                    "title": "You’re currently on the ‘Professional’ trial",
                    "body": "Unlock the full potential of your corporate gifting strategy with Kadonation Select's Professional plan. You'll automatically return to the 'Basic' plan after the trial ends.",
                    "button": "Learn more"
                }
            },
            "canceled": "Canceled"
        },
        "support": {
            "account_manager": "Customer success manager",
            "chat": "Chat with us",
            "FAQ": "F.A.Q.",
            "chat-btn": "Open chat",
            "no-articles": "No articles have been found. Need help? we're available via chat, email and phone",
            "title": "Support",
            "message_sent_successfully": "Your message was sent to our team successfully.",
            "support_message_received": "New support request",
            "support_message_description": "&nbsp;",
            "view_order": "View order",
            "footer_thank_you": "&nbsp;",
            "call": "Call  us",
            "call-be": "<b>Support BE:</b> <a href=\"tel:093963200\" class=\"hover{text}-pink-300 transition ease-in-out duration-300 pt-1\">+32 (0) 9 396 32 00</a>",
            "call-nl": "<b>Support NL:</b> <a href=\"tel:0858884933\" class=\"hover{text}-pink-300 transition ease-in-out duration-300 pt-1\">+31 (0) 85 888 49 33</a>"
        },
        "categories": {
            "categories": "Gift categories",
            "gift_categories_for": "{category}"
        },
        "forms": {
            "first_name": "First name",
            "last_name": "Last name",
            "email": "Email address",
            "password": "Password",
            "password_confirmation": "Password confirmation",
            "department": "Department",
            "company_entity": "Company Entity",
            "locale": "Language",
            "save": "Save",
            "entity": "Entity",
            "po_number": "PO number",
            "seperate_invoice": "Separate invoice",
            "monthly_invoice": "Add to monthly invoice",
            "confirm": "Confirm",
            "company_logo": "Logo of your company",
            "company_name": "Company name",
            "company_vat_number": "Company VAT number",
            "invoice_notify_email": "Send invoice by email to",
            "invoice_configuration": "Default invoice type",
            "add": "Add",
            "department_name": "Department name",
            "company_entity_name": "Company Entity name",
            "VAT_number": "VAT number",
            "set_default": "Set as default",
            "yes": "Yes",
            "no": "No",
            "address": "Address",
            "location_title": "Location title",
            "to_name": "Addressed to",
            "street": "Street",
            "number": "Number",
            "bus": "Bus",
            "zipcode": "Zip code",
            "city": "City",
            "country": "Country",
            "entity_name": "Entity name",
            "choose_option": "Please choose an option",
            "remember_me": "Remember me",
            "confirm_password": "Confirm password",
            "reset_password": "Reset password",
            "choose_country": "Choose a country",
            "language": "Language",
            "is_active": "Active",
            "permissions": "Permissions",
            "invoice": "Invoice type",
            "to_name_explanation": "You can freely change this for saved addresses",
            "address_title_explanation": "We use this to save the address",
            "choose_language": "Choose language",
            "general": "General information",
            "file_cannot_be_processed": "File is incomplete, so it can't be processed.",
            "detailed_invoice": "Detailed invoice",
            "invoice_per_department": "Monthly invoice per department",
            "cost_center": "Cost center",
            "proforma_first_for_separate_invoice": "Proforma first for separate invoice",
            "finalize_proforma_invoice": "Finalize Proforma Invoice",
            "group_name": "Group name",
            "proforma_first_for_monthly_invoice": "Proforma first for monthly invoice",
            "default_proforma_first_for_separate_invoice": "Default proforma first for separate invoice",
            "set_po_for_all_orders": "Set PO for all orders of this invoice",
            "finalize": "Finalize invoice",
            "orders": "Orders",
            "proforma_first": "Proforma invoice?",
            "is_inactive": "Inactive",
            "processing": "Processing",
            "set_approval_manager": "Orders should be approved by",
            "your_email": "Email address",
            "submit": "Submit",
            "policy_updated_both": "The acceptable use policy and data policy have both been updated. Please re-accept them.",
            "updated": "Updated",
            "data_policy_updated": "The data policy has been updated. Please re-accept.",
            "use_policy_updated": "The acceptable use policy has been updated. Please re-accept.",
            "no_employees_added": "No employees added",
            "are_you_sure": "Are you sure?",
            "please_select_items_to_update": "Please select items to update",
            "reason_approve": "Reason",
            "reject": "Reject",
            "approve": "Approve",
            "reason_reject": "Reason",
            "update_delivery_title": "Update kadonation voucher delivery",
            "update_delivery_title_delivered": "Update kadonation voucher delivery",
            "update_delivery_description": "Update delivery data",
            "delete": "Delete",
            "update_delivery": "Update Delivery",
            "select_all_permissions": "Check/uncheck all permissions",
            "date_of_birth": "Date of birth",
            "date_of_employment": "Date of employment",
            "update_delivery_description_delivered": "This voucher was already sent out. Update the date and/or e-mail address to resend.",
            "unknown": "Unknown",
            "prepared": "Prepared",
            "delivered": "Delivered",
            "saml_sso_url": "SSO URL",
            "saml_entity_id": "SSO Entity ID",
            "saml_sso_certificate": "Certificate (X.509)",
            "saml": "Enable SAML SSO",
            "file_upload": {
                "btn": "Upload",
                "btn-remove": "Remove",
                "drop": "Or drop a file here"
            },
            "from": "From",
            "to": "Addressed to",
            "amount": "Amount",
            "message": "Message",
            "delivery_date": "Delivery date",
            "delivery_time": "Delivery time",
            "detailed_reference": "Detail reference",
            "enter_message_here": "Enter your message here",
            "show": "Show",
            "hide": "Hide",
            "errors": "Errors",
            "deferred": "Deferred",
            "dropped": "Dropped",
            "bounce": "Bounces",
            "blocks": "Blocks",
            "include_department_name_on_invoices": "Include department name on invoice",
            "enter_street_here": "Enter the street here",
            "enter_street_number_here": "Enter the street number here",
            "enter_bus_here": "Enter the bus number here",
            "enter_zipcode_here": "Enter the zip code here",
            "enter_city_here": "Enter the city here",
            "enter_country_here": "Select country",
            "location_to_name": "Name of addressee",
            "default_proforma_first_for_monthly_invoice": "Default proforma first for monthly invoice",
            "vat_liable": "VAT liable",
            "address_from_vat_message": "We try to fill in your address details based on your VAT number.",
            "edit": "Edit",
            "cancel": "Cancel",
            "occasion": "Occasion",
            "ordered_by": "Ordered by",
            "optional": "Optional",
            "date_from": "Date from",
            "date_to": "Date to",
            "order-id": "Order ID",
            "full_name": "Full name",
            "external_employee_id": "Employee ID",
            "employees_override": "Overwrite all employees with new data",
            "employees_override_info": "This will update the information of existing employees with the data from the Excel. CAREFUL: it will also remove any employees that do not occur in the Excel file.",
            "company": "Company",
            "saved_addresses": "Saved addresses",
            "use_new_address": "Use new address",
            "search_employee": "Search employee",
            "select_occasion": "Select Occasion",
            "reset_password_sent": "Your password reset link has been send to your email!",
            "back_to_login": "Go back to login page",
            "reset_password_resend": "Resend password reset email",
            "subdomain": "Subdomain",
            "processed": "Processed",
            "add_more_options": "Add more options",
            "remove_option": "Remove Option",
            "edit_message": "Edit message",
            "apideck_company_id": "Company ID (in your HRIS software)",
            "open_in_editor": "Open in editor",
            "force_invoice_configuration": "Lock default invoice type for all orders",
            "fixed_cost_center": "Fixed cost center",
            "use_entity_cost_center": "Use Employee's Entity cost center",
            "recipient_email_address": "Recipient’s Email Address",
            "why_email_track_and_trace": "The track and trace notifications will be sent to this email address.",
            "po_number_required": "PO number required",
            "recipient_first_name": "Recipient's First Name",
            "recipient_last_name": "Recipient's Last Name",
            "enter_recipient_first_name": "Fill in recipient's first name",
            "enter_recipient_last_name": "Fill in recipient's last name",
            "street_number": "Street number",
            "expected_delivery_date": "Expected delivery date",
            "require_proforma_invoice": "Proforma invoice required",
            "cost_center_required": "Cost center required",
            "proforma_notify_email": "Send proforma invoice by email to",
            "datepicker": {
                "select": "Select",
                "cancel": "Cancel"
            },
            "bus_help_text": "The word 'Bus' is added later by us.",
            "recipient_full_name": "Recipient First Name + Recipient Last Name",
            "invoice_notify_email_cc": "Also send invoice by email to (in cc)",
            "order_notify_email_cc": "Send all order notifications by email to (in cc)",
            "employee_type": "Employee Type",
            "fixed_po_number": "Fixed PO number",
            "phone-number": "Phone number",
            "options_selected": "No option selected | {count} option selected | {count} options selected",
            "nothing_found": "Nothing found",
            "show_occasion_on_invoice": "Show order occasion on invoice",
            "use_new_address_or_search": "Select, or search for, a saved address",
            "password_explainer": "Password must be more than 8 characters long and should at least have: 1 uppercase letter, 1 lower case letter, 1 numeric value and 1 special character.",
            "occasions": "Occasions",
            "backup_approval_manager": "Backup for order approvals",
            "logo_standard": "Logo",
            "logo_standard_help_text": "Only logos in image format are allowed. E.g. .jpg, .jpeg, .png",
            "logo_vector": "Logo (vector)",
            "logo_vector_help_text": "Only logo's in vector format are allowed. E.g. .eps, .svg",
            "upload_logo": "Upload logo",
            "update_logo": "Update logo",
            "name": "Name"
        },
        "gifts": {
            "no_gifts_found": "No gifts were found matching your criteria.",
            "number_of_gifts_found": "Showing {showing} of {total} gifts",
            "not_shippable_error": "The shipping configuration is incomplete for this gift. Our team has been informed about this error, please try again later or contact customer support for details.",
            "show_gift_images": "Show product photos",
            "show_option_images": "Show option photos",
            "orders_limited_to_vendor": "You can only order from one gift partner per order",
            "orders_limited_to_vendor_description": "You can only place an order with gifts from one gift partner at a time. To order gifts from a different gift partner, please finish placing your current order or empty your cart.",
            "different_vendor": "Other gift partner",
            "all_gifts_shown": "All results are shown",
            "personalize_voucher_title": "Personalize voucher",
            "personalize_greeting_card_title": "Personalize card",
            "personalize_free_card_title": "Personalize card",
            "personalize_voucher_subtitle": "Personalize the content of your voucher",
            "personalize_greeting_card_subtitle": "This gift is a card. You can personalize the content of this card using the fields below.",
            "personalize_free_card_subtitle": "A card can be added to the gift. Personalize the content of this card using the fields below.",
            "min_quantity": "Minimum quantity",
            "max_quantity": "Maximum quantity",
            "language_description": "Determines the language of the automatically added text",
            "from_description": "Printed below your personal message",
            "to_description": "Printed above your personal message on voucher/card.",
            "pm_description": "No need to add the name of the receiver or the name of the sender if you’ve already added those to the 'From' and/or 'To' fields. Please note, there is a maximum of 300 characters (includes spaces, 'enter' counts as 40 characters). Emojis are not supported.",
            "personalize_gift_title": "Design Gift",
            "personalize_gift_subtitle": "This gift can be personalized via our editor",
            "personalize_gift_description": "You can design one gift at a time. If you want to design multiple gifts, you will need to go through the design process for each item separately and add them to your cart individually before checking out.",
            "start_personalization": "Start designing this gift",
            "edit_personalization": "Edit Design",
            "personalize": "Personalize",
            "excluding_vat": "(excl. VAT)",
            "add-to-favorites": "Add to favourites",
            "successfully-added-to-favorites": "Gift has been added to favourites for '{occasion}'. It will be shown at the top for this occasion.",
            "successfully-removed-from-favorites": "Gift was removed from favourites for '{occasion}'.",
            "favorite-gift-for-occasion": "Favourite gift for this occasion",
            "remove-from-favorites": "Remove from favourites",
            "looking_for_sth_custom": "Looking for something custom?",
            "customization_cta": "Contact us",
            "looking_for_sth_else_description": "Haven't found what you were looking for? Looking for a branded voucher or gift? Want to put together a box unique to your brand? Contact us, and we’ll make it happen!",
            "looking_for_sth_else_title": "Looking for something specific or custom?",
            "gift_without_customization_confirmation_description": "This gift can be customized, and you have not added a customization yet. Are you sure you wish to continue without the customization?",
            "gift_without_customization_confirmation_title": "No customization set",
            "show_gifts_from_all_vendors": "Show gifts from all vendors",
            "show_gifts_from_vendor": "Show gifts from the same vendor",
            "pm_description_for_card": "No need to add the name of the receiver or the name of the sender if you’ve already added those to the 'From' and/or 'To' fields. Please note, there is a maximum of 600 characters (includes spaces, 'enter' counts as 40 characters). Emojis are not supported.",
            "warnings": {
                "has_direct_future_delivery_non_voucher_gift_in_cart": {
                    "title": "Finish your order before adding new gifts",
                    "description": "You’ve added a gift to your cart where a delivery date is set per recipient. This cannot be combined with other gifts. Please place the order for the gift in your cart first.",
                    "button": "View cart"
                }
            },
            "pm_description_for_card_digitize_enabled": "This message will be shown on the greeting card. lease note, there is a maximum of 600 characters (includes spaces, 'enter' counts as 40 characters). Emojis are not supported."
        },
        "orders": {
            "excel_user_list_title": "Edit list of recipients",
            "employee_list_title": "Select and personalize employees",
            "add_employees_button": "Select employees",
            "empty_search_result": "No employees were found with this search term",
            "employee_list_info_message": "Select all the employees or teams you wish to give a gift. You can add employees one by one, per team or all at once. Afterwards you can personalize your gift per employee or for several employees at once.",
            "add_all_employees": "Select all employees",
            "search_and_select_info": "Search and select employees or teams",
            "add_employee_manually": "Add a recipient manually",
            "excel_upload_error_message": "An error occured when uploading and reading the provided excel file.",
            "excel_upload_success_message": "File uploaded successfully!",
            "view_imported_users": "View the list of imported recipients",
            "reading_excel": "Reading uploaded excel ...",
            "selected_employees": "{count} employee selected|{count} employees selected",
            "imported_employees": "{count} recipients imported|{count} recipients imported",
            "select_employees": "Select employees from employee management",
            "edit_employee_list": "Edit the list of selected employees",
            "errors_in_order_form": "There are errors in the data you provided. Please review the highlighted rows and fix them.",
            "search": "Search (on first or last name)",
            "remove_excel_confirmation_message": "This action will remove the uploaded excel file and any imported users. Do you want to continue?",
            "excel_data_loss_message": "This action will remove the uploaded excel file and require the upload of another excel file. Do you want to continue?",
            "employee_list_errors": "{0} There are {count} errors in the employee list you submitted.|{1} There is an error in the employee list you submitted.|There are {count} errors in the employee list you submitted.",
            "select_option_first": "Please, select an option first.",
            "add_video_for_personalization": "Would you like to add a video message?",
            "video_url": "URL of the video message",
            "drop_file_or_click": "Drop file or click to upload",
            "format": "File format",
            "video_invalid_type": "You can't upload files of this type.",
            "video_file_too_big": "File is too big ({filesize}MB). Max filesize: {maxFilesize}MB.",
            "video_response_error": "Server responded with {statusCode} code.",
            "video_invalid_url": "Please enter a valid URL",
            "upload_canceled": "The upload has been cancelled.",
            "cancel_upload_confirmation": "Are you sure?",
            "video_required": "URL of the video message or video message file is required",
            "video": "Video",
            "delete_video_info": "To upload a new video, you must first delete the current video.",
            "video_upload_in_progress": "Video upload is in progress, please wait...",
            "price_free": "Free",
            "order_parameters": "Order filters",
            "order_item_message_parameters": "Kadonation gift card filters",
            "show_all_filters": "More filters",
            "no_results_for_filter": "No orders match the given criteria",
            "no_results": "No orders found",
            "filters": "Filters",
            "remove_all_filters": "Remove all filters",
            "select_kudos_employees": "Edit the recipients",
            "edit_kudos_list": "Edit the recipients",
            "upload_video": "Add video",
            "thank_you_video": "Video message",
            "upload_video_button": "Add video",
            "no_video_uploaded": "No video uploaded",
            "no_to_name": "No \"To\" name added",
            "gift_vendor_internal_product_id": "Vendor product ID",
            "option_vendor_internal_product_id": "Vendor option ID",
            "order_handled_by": "Order placed by",
            "order_being_shipped": "Your order is being shipped",
            "take_look_at_all_orders": "Here you can take a look at all the items in your orders.",
            "track_shipment": "Track Shipment",
            "invoiced_to": "Invoiced To",
            "total_handling_fee": "Total extras and handling",
            "approve_orders": "Approve",
            "reject_orders": "Reject",
            "urgent": "Urgent",
            "message_details": "Message details",
            "deselect_all_recepients": "Deselect all recipients",
            "select_all_recepients": "Select all recipients",
            "recipients_selected": "{count} recipients selected",
            "mass_update_explainer": "Updating these fields will immediately update the corresponding column for all selected recipients.",
            "all_recipients_selected": "All recipients selected",
            "scroll_horizontally_to_show_more_fields": "Scroll horizontally to show more fields.",
            "these_are_required_fields": "The following fields are required:",
            "select_recipients_explanation": "Select Employees you’ve added to Select.",
            "email_explainer_for_direct_delivery_orders": "We require an email address per recipient to send the track and trace notifications to. If you're not sure about the recipient's email address or you do not want the recipient to receive a track-and-trace email, you can fill in your own email address.",
            "confirm-vendor-order-title": "Order confirmation needed",
            "confirm-vendor-order-description": "Please confirm this order. Confirmation means we assume you have seen its details and are processing it. Below you can find the information for this order as well as the button to confirm it.",
            "vendor-email-sent": "Vendor Email Sent",
            "vendor-order-please-confirm-order": "Please confirm this order",
            "vendor-order-confirmed-title": "Order confirmed",
            "vendor-order-confirmed-description": "This Order is now marked as 'confirmed'.",
            "vendor-email-confirmed-at": "Order confirmed on:",
            "show_preview": "Show preview",
            "vendor-order-reserved-title": "Order reservation confirmed",
            "reserve-vendor-order-title": "Confirmation of order reservation needed",
            "reserve-vendor-order-description": "Please confirm the reservation for this order. This means that you have the order items in stock or available in time for the delivery at the expected delivery date mentioned in the mail. The personalisation information to process this order will be send later. Below, you can find the button to confirm this reservation.",
            "vendor-order-please-reserve-order": "Please confirm reservation for this order",
            "vendor-order-reserved-description": "This order is now marked as 'reserved'",
            "vendor-email-reserved-at": "Order reserved on:",
            "order_gift": "Order gift",
            "loading_preview": "Loading preview, this may take a few seconds...",
            "failed_to_load_preview": "Failed to load preview, please try again later",
            "preview_card": "Preview card",
            "preview_voucher": "Preview voucher",
            "credits_used": "Credits Used",
            "excel_upload_error_message_v2": "There are errors in your Excel",
            "approval-explainer-title": "Order is awaiting approval by Kadonation",
            "approval-explainer-body": "Kadonation has daily and monthly spending limits to prevent fraud. You have hit these limits, causing orders to require approval by Kadonation. We do our best to approve these within a few hours after they are placed. Contact us if the order is urgent, or if you have any questions about these spending limits.",
            "created_via_automation": "Automated gift",
            "payment_method": "Payment method",
            "direct_payment_failed_title": "Payment failed",
            "direct_payment_failed_description": "Your payment has failed. Please try again using the button below.",
            "direct_payment_retry_button": "Retry payment",
            "direct_payment_pending_title": "Payment pending",
            "direct_payment_pending_description": "We have not yet received confirmation of your payment. If you have already paid, we should receive confirmation soon. If you have not yet paid, please try again.",
            "direct_payment_canceled_title": "Payment expired",
            "direct_payment_canceled_description": "The payment has expired. Please place your order again.",
            "after_receiving_invoice": "Bank transfer (after invoice)",
            "approval_flow_explainer": {
                "title": "Keep control over purchases",
                "description_1": "With the order approval system, your approval manager is able to approve or reject the orders placed by specific users.",
                "description_2": "To activate this feature, add an approval manager onto a user. Go to ‘Users’, edit the user that should have their orders approved, and set an approval manager.",
                "button": "Show users"
            },
            "contributor_selector": {
                "title": "Add message contributors",
                "explainer": "Select all the employees or teams you wish to add as a contributor. You can add employees one by one, per team or all at once. You can also manually invite contributors by filling out their email address. Click on 'Add a contributor manually' to do this."
            },
            "unblock_vouchers_page": {
                "unblock_vouchers": {
                    "title": "Unblock your vouchers",
                    "content": "To make sure your vouchers will only be used by the intended recipient, we have blocked them from being used before we shipped them to you. \n<br/><br/>\nUse the button below to make the vouchers available for use.\n<br/><br/>\nMake sure you have checked that all vouchers are in your possession! You can close this page and come back later if needed.",
                    "button": "UNBLOCK VOUCHERS"
                },
                "order_id": "Order ID",
                "number_of_vouchers": "Physical Vouchers",
                "vouchers_unblocked": {
                    "title": "Vouchers unblocked",
                    "content": "The vouchers related to this order have been unblocked and are now available for use. You can safely give them to the intended recipient, or let them know they may use the voucher to spend at more than 90 partners.",
                    "success_notification": "The vouchers are now available for use."
                }
            },
            "vendor_tracking": {
                "confirm_tracking": {
                    "title": "Confirm track & trace",
                    "explainer": "Please add the track & trace links for this order. If there is only one track and trace link, put it into the text field. If there are multiple, upload a file containing all of the links, or copy paste the links into the big text box."
                },
                "order_id": "Order ID",
                "email_sent_on": "Vendor Email Sent:",
                "form": {
                    "links_input_label": "Paste track and trace links",
                    "links_input_placeholder": "Paste your link(s) here",
                    "upload_links_label": "Or upload file with track & trace links",
                    "submit_button": "Save links",
                    "accepted_file_formats": "Allowed formats: csv, xls/xlsx, pdf"
                },
                "confirmed_on": "Order confirmed by vendor on:",
                "tracking_confirmed": {
                    "title": "Tracking confirmed",
                    "explainer": "Tracking confirmed",
                    "success_message": "Tracking confirmed"
                }
            },
            "confirm_tracking": {
                "head_title": "Track & trace"
            }
        },
        "tenant-creation": {
            "account-step-label": "Account",
            "account-step-title": "Create your account",
            "account-step-description": "Please use your work email address to create your account.",
            "company-email": "Work email address",
            "account-step-next-button": "Continue",
            "company-step-label": "Company",
            "company-step-title": "Which company do you work for?",
            "company-step-description": "Please fill in your company’s name, as well as the country it's in.",
            "company-step-country-help-text": "<a href=\"{mailTo}\">Contact us</a> if your country is not in the dropdown.",
            "company-step-next-button": "Continue",
            "profile-step-label": "Profile",
            "profile-step-title": "Complete your profile",
            "profile-step-description": "These profile details are required to complete your account. They help clarify to others who ordered what.",
            "profile-step-next-button": "Continue",
            "verify-step-title": "Please verify your info",
            "verify-step-description": "Please verify that the information below is correct. Click the 'Create account ' button at the bottom to create your account.",
            "verify-step-personal-info": "Personal info",
            "verify-step-company-info": "Company info",
            "edit-info": "Edit",
            "verify-step-next-button": "Create Select account",
            "verification-email-step-title": "Your account has been created!",
            "verification-email-step-description": "Check your inbox for the next step",
            "verification-email-step-body": "We’ve sent an email to {email} containing a link to set your password. Please check your email. Note that it may take a minute to arrive.",
            "account-step-company-has-account": "It seems this company already has an account.",
            "account-step-you-have-an-account": "It seems like you already have an account.",
            "account-step-company-has-account-body": "Colleagues in your company have already created an account with Kadonation Select. If you wish to gain access to this account, or believe there is a mistake, please contact us.",
            "account-step-you-have-an-account-body": "We’ve found an account related to this email address. Contact us if you’ve lost access to your account and don’t know what to do.",
            "side-info-title": "Create a culture of giving.",
            "side-info-description": "One intuitive platform that makes giving simple, accessible and personal. With Kadonation, you'll take leaps towards stronger relationships with every gift.",
            "side-info-bullet-point-1": "Gifts that impress, with heartfelt personal messages",
            "side-info-bullet-point-2": "Hyper-efficient workflows that work for you",
            "side-info-bullet-point-3": "Easy budget management and convenient control mechanisms",
            "set-password-step-title": "Set up your password",
            "set-password-step-description": "Almost done creating your account. Just create a password and we’ll start setting up your new Select account.",
            "set-password-step-next-button": "Save password",
            "set-password-step-link-expired-title": "This link has expired.",
            "set-password-step-link-expired-description": "You waited too long to set your password. If you want to set your password again, please request a new link.",
            "set-password-step-request-new-link": "Request New Link",
            "error-title": "Something went wrong...",
            "error-description": "An error occurred when we were creating your account. Please contact us so we can help you create you account and give you access to Kadonation Select.",
            "setup_status_1": "Setting up your access to 1000+ gifts",
            "setup_status_2": "Setting up gifting occasions",
            "setup_status_3": "Setting up personalisation and customization tools",
            "setup_status_4": "Setting up invoice and order management",
            "setup_status_5": "Securing your Select environment",
            "setup_status_6": "Finalizing your Select environment",
            "page-title": "Create Account",
            "profile-step-department-placeholder": "Department you work in, eg: HR",
            "new-link-sent": "A new link has been sent to your email address.",
            "account_being_created": "Creating your Select environment",
            "may_take_time": "This may take up to a minute."
        },
        "settings": {
            "invoice_configuration": {
                "grouped_monthly_invoice": "Grouped monthly invoice",
                "separate_invoice": "Separate invoice for order"
            },
            "no_delivery_address_set": "No delivery address set yet",
            "delivery_address_set": "{count} delivery address set|{count} delivery addresses set",
            "add_more_delivery_address": "Add more",
            "add_first_delivery_address": "Add one now",
            "entity_delivery_addresses_description": "Delivery addresses will be shown during checkout to all ordering users. Use them to allow users to quickly choose an address, instead of having to fill it out.",
            "change_default_entity_not_allowed": "Unsetting the default company is not allowed!"
        },
        "reports": {
            "chart_empty": {
                "title": "Place orders to start seeing your data",
                "button": "Browse gifts"
            },
            "filters": {
                "title": "Filters",
                "for_whole_company": "Whole company",
                "entities_selected": "{count} entity selected | {count} entities selected"
            },
            "export_button": "Export detailed list",
            "thank_yous": {
                "thanked_on": "Thanked on",
                "message": "Message",
                "title": "Thank yous from {entity}"
            },
            "tab_gifting": "Gifting",
            "tab_recipients": "Recipients",
            "tab_thank_yous": "Thank yous",
            "data_from_until": "Data from {from} until {until}",
            "subscription_blocked": {
                "gifting": {
                    "title": "Upgrade your plan see your data",
                    "description": "Reporting is only available for customers on the professional plan or above.\u2028On this page you can see statistics and graphs of how your company is ordering gifts and how money is being spent."
                },
                "recipients": {
                    "title": "Upgrade your plan see your recipients",
                    "description": "Reporting is only available for customers on the professional plan or above.\u2028You can use this list to get a clear overview of which person received which gift and for which occasion."
                },
                "thank_yous": {
                    "title": "Upgrade your plan to see your thank yous",
                    "description": "Reporting is only available to customers on the professional plan or above. Any order placed after 31/10/2024 will include the option for the receiver to send a thank you message to the gift giver. Start placing orders and see how appreciated your receivers feel."
                }
            },
            "gifting": {
                "title": "Gifting stats for {entity}",
                "chart": {
                    "most_active": "{count} most active",
                    "departments": "Departments",
                    "most_popular": "{count} most popular",
                    "occasions": "Occasions",
                    "gifts": {
                        "order_count": "{count} orders"
                    },
                    "total_spent": "Total spent",
                    "total_orders_placed": "Total orders placed"
                }
            },
            "recipients": {
                "title": "Recipients from {entity}"
            },
            "total_gifts_sent": "Total gifts sent",
            "total_vouchers_sent": "Total vouchers sent",
            "voucher_usage": "Voucher usage",
            "recipients_empty_banner": {
                "title": "Start gifting to see your recipients",
                "description": "You haven’t placed any orders yet, place one now to see your recipients listed here.\u2028You can use this list to get a clear overview of which person received which gift and for which occasion.",
                "button": "Browse gifts"
            },
            "total_thank_yous": "Total thank yous",
            "greeting_card_thank_yous": "Greeting card thank yous",
            "voucher_thank_yous": "Gift voucher thank yous",
            "thank_yous_empty_banner": {
                "title": "Start gifting to see your thank yous",
                "description": "You haven’t received any thank you messages so far. Any order placed after 31/10/2024 will include the option for the receiver to send a thank you message to the gift giver. Start placing orders and see how appreciated your receivers feel.",
                "button": "Browse gifts"
            },
            "whole_company": "the whole company",
            "multiple_entities": "selected entities"
        },
        "messages": {
            "order_confirmed": "Your order has been confirmed",
            "download_digital_vouchers_here": "Download your digital vouchers here",
            "order_confirmed_subtext": "Your digital vouchers are available here, your boxes will be delivered soon",
            "invoice_notify_explanation": "An email will be send to this email address when a new invoice has been created.",
            "check_verification_link": "Before you move on, please check your mail for a verification link.",
            "verification_link": "A fresh verification link has been sent to your email address.",
            "email_verify": "Verify Your Email Address",
            "not_received_verification_mail": "If you did not receive the email, then:",
            "resend_verification_email": "click here to request another",
            "forgot_password": "Forgot your password?",
            "send_password_link": "Send Password Reset Link",
            "order_for_group_step_1": "Download the template",
            "order_for_group_step_2": "Fill in the template with recipients data and upload the file here.",
            "order_for_group_step_1_subtext": "Download the excel file below and fill in the requested fields.",
            "order_for_group_step_2_subtext": "Once you have completed the excel file, you can upload it below and then add the order to your shopping cart.",
            "file_uploaded": "You have uploaded a file",
            "click_to_upload": "Drag or <span class=\"underline\">click here</span> to upload a file",
            "x_files_uploaded": "You have uploaded {count} files",
            "sent_password_link": "The password reset link is sent to your email if your email exists in our system. If you don’t receive an email, contact Kadonation customer support after checking your spam folders. Keep in mind that if you're logging in via an external service (such as Google, Microsoft, ...), we cannot reset your password.",
            "invoice_address_saved": "Modifications to company entity have been saved",
            "cannot_delete_default_entity": "Not possible to remove the default entity",
            "company_entity_deleted": "Company entity successfully deleted",
            "delivery_address_saved": "Delivery address have been saved",
            "delivery_address_deleted": "Delivery address deleted successfully",
            "settings_not_updated": "Settings could not be updated",
            "settings_added": "Settings successfully updated",
            "department_added": "Department has been added",
            "department_not_added": "Department could not be added",
            "item_placed_in_cart": "Item has been placed in your cart.",
            "occasions_updated": "Occasions have been updated",
            "item_removed_from_cart": "Item has been removed from your cart",
            "user_created": "User successfully created.",
            "user_updated": "User has been updated",
            "cart_empty": "Your cart is empty",
            "mail_subcopy": "Stay up to date on Kadonation by following our [linkedin](https://www.linkedin.com/company/groupgifting/).",
            "mail_greetings": "Kind regards,  \nThe Kadonation Team",
            "reports_text": "Reports provide you with useful information at a glance about how Kadonation Select is used in your company. It gives you more insight into what the most popular occasions are, the most chosen gifts, the average amount per gift... You can choose to get an overview per user, per department or of the entire company.\nAvailable autumn 2021.",
            "company_entity_created": "New company entity created",
            "creditnote_creation_failed": "Credit note creation failed",
            "not_a_valid_invoice": "Not a valid invoice",
            "you_must_confirm_the_warning": "Please confirm the warning message before proceeding to checkout",
            "po_number_has_been_saved": "PO Number has been saved",
            "po_number_has_been_saved_and_invoice_finalized": "PO Number has been saved and invoice finalized",
            "confirm": "Are you sure?",
            "employee_created": "Employee added",
            "employee_updated": "Employee updated",
            "employee_deleted": "Employee deleted",
            "employees_imported": "Employees imported",
            "invalid_time_format": "The provided time format is invalid, please format time as such hh{MM}",
            "invalid_time_in_past": "The provided time is in the past, please provide a time in the future",
            "invalid_date_format": "The provided date format is invalid, please format dates as such: dd/mm/yyyy",
            "invalid_date_in_past": "The provided date is in the past, please provide a date and time at least 1 hour in the future",
            "digital_delivery_treshold_cart_error": "Your cart contains digital vouchers that are send to recipients directly. Please checkout those items separately (from non-digital items) so that we can ensure on time delivery.",
            "employees_deleted": "Employees deleted",
            "invalid_must_ahead_hour": "Date and time must be at least more than one hour in the future",
            "campaign_created": "Campaign successfully created.",
            "campaign_inactive": "Campaign is inactive.",
            "kudos_user_not_found": "Kudos User not found",
            "kudos_campaign_not_found": "Kudos Campaign not found",
            "kudos_user_is_not_on_the_list_of_recipients": "Kudos User is not on the list of recipients",
            "there_are_orders_pending_approval": "Warning: there are orders pending approval",
            "order_is_pending_approval": "Order is pending approval",
            "order_has_been_cancelled": "Order has been cancelled",
            "invalid_datetime_format": "The provided date format is invalid, please format dates as such: dd/mm/yyyy hh{mm}",
            "department_restored": "A department with this name existed before and is re-activated",
            "orders_has_been_rejected": "Order(s) have been rejected",
            "orders_has_been_approved": "Order(s) have been approved",
            "successfully_updated": "Updated successfully!",
            "successfully_deleted": "Deleted!",
            "error_on_request": "Something wrong with your request. Please try again.",
            "copied": "Copied!",
            "api_key_removed": "API key removed",
            "api_key_added": "API key added",
            "order_vouchers_successfully_deleted": "Vouchers under this order has been successfully deleted!",
            "order_vouchers_failed_delete": "Error on deletion. Please try again later.",
            "order_vouchers_some_claimed": "Request failed! Some of the vouchers may have claimed.",
            "verify_when_importing_info": "When verifying, the order gets communicated to the client and an invoice gets generated",
            "direct_delivery_incomplete_or_invalid": "Direct delivery detected by valid fields: {validFields}.\nSome values are missing or invalid to continue: {invalidFields}",
            "sendgrid_failed_explanation": {
                "unknown": "At this moment, we can’t show the correct status of the delivery. This may be because the deadline has not yet been reached or because the status has not yet been transmitted by our email service provider (this may take several hours).",
                "deferred": "The recipient's email server has rejected the email, contact your IT department to ask why it was rejected. If the IT department has solved the problem, you can resend the shipment here.",
                "blocks": "The recipient's email server has rejected the email, contact your IT department to ask why it was rejected. If the IT department has solved the problem, you can resend the shipment here.",
                "dropped": "The email could not be delivered because with previous emails we received a response from your email service provider that it could not be delivered. Please contact our customer service department when the problem is resolved so we can retry to send the mail.",
                "bounces": "The recipient's email server has rejected the email, contact your IT department to ask why it was rejected. If the IT department has solved the problem, you can resend the shipment here."
            },
            "invalid_vat": "Invalid VAT number",
            "vat_validation_error": "Unable to validate VAT at this time.",
            "cannot_change_vat_explanation": "It is not possible to change a VAT number of an existing company entity. Please create a new company entity with the new VAT number.",
            "campaign_updated": "Campaign successfully updated.",
            "something_went_wrong": "Something went wrong, please try again.",
            "video_updated": "Updated successfully!",
            "cannot_add_multiple_vendor_in_cart": "You cannot add gifts from multiple vendors into the cart.",
            "no_direct_delivery_but_with_fields": "Direct delivery is set to \"no\" but your excel file contains columns for direct delivery. Remove these columns or set \"Send voucher to recipient directly\" to \"yes\".",
            "cannot_edit_your_permissions": "It's not possible to change your own permissions. Please ask another administrator to change your permissions.",
            "failed_to_place_the_order": "Failed to place the order.",
            "the_cart_is_empty": "The cart is empty.",
            "cannot_add_different_delivery_methods_to_cart": "Adding different delivery methods to the same cart is not allowed.",
            "orders_have_been_rejected": "Your orders have been rejected successfully",
            "orders_have_been_approved": "Your orders have been approved successfully",
            "should_not_add_physical_gifts_to_kudos_order": "You can only order digital gifts for Kudos campaigns.",
            "no-gifts-found-for-the-occasion": "No gifts were found for this occasion.",
            "failed_updating": "Failed to update",
            "video_not_updated": "Video was not updated!",
            "department_updated": "Department has been updated",
            "department_deleted": "Department has been deleted",
            "department_not_updated": "Department could not be updated",
            "department_not_deleted": "Department could not be deleted",
            "department_not_restored": "A department with this name existed before but could not be re-activated",
            "campaign_deleted": "Campaign successfully deleted.",
            "video_deleted": "Video deleted successfully.",
            "video_not_deleted": "Video was not deleted!",
            "shipping_restricted_to_weekdays": "The gift {gift} can only be delivered on {days}.",
            "delivery_date_field_must_be_greater_than_or_equal_to_earliest_delivery_date": "Delivery date field must be later than or equal to {date}.",
            "delivery_date_field_is_invalid": "delivery_date should be in this format: dd/mm/yyyy (example: 01/01/2023)",
            "cannot_deliver_on_delivery_date_because_it_is_holiday": "Cannot deliver on delivery date set. {date} falls on a holiday.",
            "delivery_date_field_must_be_in_shipping_option_available_delivery_days_array": "Delivery date {date} is on a {day}. Delivery date must fall on {delivery_days}.",
            "welcome_email_sent": "Welcome email is sent to the user.",
            "user_is_not_active": "Action is not allowed since the user is not active.",
            "cannot_send_welcome_email_as_user_is_verified": "Cannot send the welcome email as the user is already verified.",
            "sent_password_link_by_admin": "An email is sent to the user with the details on resetting their password.",
            "new_links": "NEW LINKS -",
            "resend_vendor_order_mail": "Order mail resend",
            "vendor_mail_has_been_resent": "Order email has been sent again.",
            "vendor_mail_resend_explanation": "Click the button below to resend the order email. The mail will contain fresh links.",
            "vendor_mail_resend_button_title": "Resend Order Email",
            "to_field_is_required": "Addressed_to is required.",
            "recipient_first_name_field_is_required": "'Recipient first name' is required.",
            "recipient_last_name_field_is_required": "'Recipient last name' is required.",
            "no_delivery_in_the_future_for_non_vouchers_gift_but_with_fields": "This gift does not support setting the delivery date in the Excel. Please remove the column to continue ordering.",
            "street_field_is_required": "'Street' is required.",
            "street_number_field_is_required": "'Number' is required.",
            "zip_code_field_is_required": "'Zip Code' is required.",
            "city_field_is_required": "'City' is required.",
            "country_field_is_required": "'Country' is required.",
            "proforma_notify_explanation": "An email will be send to this email address when a new proforma invoice has been created.",
            "magic_link_created": "New link has been sent to your email.",
            "shipping_cannot_be_done_in_this_day": "Cannot deliver on delivery date set",
            "no_apideck_connection": "No employee synchronization connection was found. Please reconfigure synchronization.",
            "invoice_notify_cc_explanation": "Any email entered here will also receive invoices. Multiple email addresses are possible, add a comma in between each email address.",
            "order_notify_email_cc": "Any email entered here will receive all emails related to orders. Multiple email addresses are possible, add a comma in between each email address.",
            "generating-try-later": "Vouchers are currently being generated for this order. Please try again later after the generation is complete.",
            "cannot_add_when_direct_future_delivery_non_voucher_gift_in_cart": "You’ve added a gift to your cart where a delivery date is set per recipient. This cannot be combined with other gifts. Please place the order for the gift in your cart first.",
            "can_only_add_one_item_with_future_delivery_to_cart": "You can only add one gift to your cart when the gift has a delivery date set per recipient.",
            "fixed_po_number_explainer": "Automatically uses this PO number on all orders placed for this company entity. This does not include automated orders.",
            "fixed_cost_center_explainer": "Automatically uses this cost center on all orders placed for this company entity. This does not include automated orders.",
            "daily_order_threshold_reached": "Daily order limit reached",
            "monthly_order_threshold_reached": "Monthly order limit reached",
            "order_is_pending_kadonation_approval": "Order is awaiting approval by Kadonation. We’ll contact you soon.",
            "user_deleted": "User was deleted.",
            "mollie_repay_failed": "Your payment has failed. Please try again.",
            "logo_updated": "Logo updated successfully.",
            "subscription_cancelled": "Subscription canceled.",
            "subscription_reactivated": "Subscription reactivated.",
            "keep-account-text": "Confirm below to keep your account."
        },
        "pagination": {
            "previous": "Previous",
            "next": "Next",
            "first": "First",
            "last": "Last"
        },
        "keep-account": {
            "heading": {
                "keep-account": "Keep Your Account Active",
                "account-restored": "Account Restored"
            },
            "body": {
                "keep-account": "Confirm below to keep your account.",
                "account-restored": "Thank you for confirming your continued usage of Kadonation Select."
            },
            "button": "Keep My Account",
            "title": "Keep Account"
        },
        "user": {
            "profile": "Profile",
            "actions": "Actions",
            "fixed_cost_center_explanation": "When a value is given here, the cost center is automatically added to every order this user places.",
            "search": {
                "placeholder": "Search for a user",
                "no_results": "No users were found.",
                "loading": "Searching for users..."
            },
            "buttons": {
                "edit": "Edit"
            },
            "approval_manager_explanation": "This person will need to approve or reject every order placed by the user.",
            "backup_approval_manager_explanation": "This person will be able to approve any order that {name} would need to approve. This is useful when {name} goes on holiday."
        },
        "occasion": {
            "shop_now": "Show gifts",
            "your_occasions": "Gift occasions",
            "upsell_banner_title": "Surprise someone else with the perfect gift!",
            "other_occasions": "Other occasions",
            "occasions_previously_bought_for": "Occasions previously purchased for",
            "popular_gift_occasions": "Popular gift occasions",
            "view_all_occasions": "View all occasions",
            "birthday": "Birthday",
            "work-anniversary": "Work Anniversary",
            "webshop_link_button": "Get a Kadonation Gift Card",
            "upsell_banner_list_items": "Personalizable with a custom message^Redeemable at 100+ partners^Valid for 3 years^Perfect for every occasion"
        },
        "countries": {
            "BE": "Belgium",
            "NL": "Netherlands",
            "FR": "France",
            "DE": "Germany",
            "LU": "Luxembourg",
            "ES": "Spain",
            "IT": "Italy",
            "GB": "United Kingdom of Great Britain and Northern Ireland",
            "AT": "Austria",
            "BG": "Bulgaria",
            "CY": "Cyprus",
            "CZ": "Czech Republic",
            "DK": "Denmark",
            "EE": "Estonia",
            "FI": "Finland",
            "GR": "Greece",
            "HU": "Hungary",
            "HR": "Croatia",
            "IE": "Ireland",
            "LT": "Lithuania",
            "LV": "Latvia",
            "MT": "Malta",
            "PL": "Poland",
            "PT": "Portugal",
            "RO": "Romania",
            "SE": "Sweden",
            "SI": "Slovenia",
            "SK": "Slovakia",
            "CH": "Switzerland",
            "UA": "Ukraine",
            "MK": "Macedonia",
            "IL": "Israel",
            "NO": "Norway",
            "US": "United States"
        },
        "project-vendors": {
            "delivery_address_info_for_home_delivery": "This order needs to be shipped to the recipients\\' home addresses, you can find the home addresses in the \\'download excel with recipients data\\'.",
            "download_recipients_data": "Download data of recipients",
            "recipients_data": "Data of recipients",
            "shipping_limitation_notice": "Only showing countries where our supplier can ship to.",
            "see_download_recipients_data": "See: download data of recipients",
            "personalization_file": "Personalization file:",
            "download_personalization_file": "Download file",
            "personalization_id": "Colorlab Personalization ID"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is no longer valid, please go to the application and request a new password reset link. Or contact your HR administrator and request a password reset from them.",
            "user": "We can't find a user with that e-mail address.",
            "password_created_successfully": "Your password has been created.",
            "invalid_token_while_creating": "The link for creating a password is not valid anymore. Please contact your HR administrator to request a new email.",
            "password_already_created": "You’ve already set a password for your account. Use it here to log in, or use the ‘forgot password’ link to set a new password.",
            "user_not_active": "Your account is not active. Please contact your HR administrator and ask if they can activate your account."
        },
        "activity": {
            "created": "Created",
            "updated": "Updated"
        },
        "searchg": {
            "head_title": "Search for a gift",
            "search_title": "What are you looking for?",
            "algolia_gift_search_placeholder": "Search for... ‘chocolate’, ‘letterbox’, ‘balloon’, 'voucher', 'apéro', etc"
        },
        "cookies": {
            "title": "Cookies",
            "text": "Kadonation wants to collect anonymous statistical data to make our platform more user-friendly, but for that we ask your permission\n\nDo you want to participate in this?",
            "accept": "Accept",
            "decline": "Decline"
        }
    },
    "fr": {
        "colorlab": {
            "add-to-cart": "Fini la personnalisation",
            "save-configuration": "Fini la personnalisation"
        },
        "message": {
            "within_14_days": "Devrait être livré dans les 2 semaines"
        },
        "employees": {
            "external_employee_id_info": "Saisissez ici le numéro de référence unique de cet employé. Cela permettra de mettre automatiquement à jour les données de vos employés lorsque des changements seront effectués.",
            "excel_upload": "Importer d'Excel",
            "manual_upload": "Ajouter un employé",
            "export": "Exporter des employés",
            "delete_selected": "Supprimer la sélection",
            "source": "Source",
            "source_manual_input": "Saisie manuelle",
            "hris_sync": "Sync d'API",
            "source_excel_upload": "Téléchargement Excel",
            "source_hris_sync": "Synchronisation SIRH",
            "employee": "Employé",
            "intro_notes": "La rubrique \"Employés\" enregistre les coordonnées de vos employés, ce qui vous permet de passer des commandes avec des adresses préremplies, de mettre en place des campagnes Kudos et d'automatiser l'envoi de cadeaux à l'occasion de leur anniversaire ou de leur fête professionnelle.",
            "sync": "Synchronisation des employés",
            "delete_confirmation_title": "Supprimer l'employé ?",
            "delete_confirmation": "Voulez-vous vraiment supprimer cet employé?<br>Cette action est irréversible.",
            "delete_multiple_confirmation_title": "Supprimer des employés ?",
            "delete_multiple_confirmation": "Êtes-vous sûr de vouloir supprimer {count} employés ?<br>Cette action est irréversible.",
            "api_connection_title": "Connexion API des employés",
            "api_connection_description": "Connectez votre système RH, et nous synchroniserons automatiquement vos employés et leurs données chaque semaine.",
            "manual_sync": "Synchronisation manuelle",
            "auto_sync": "Synchronisation automatique",
            "manual_sync_started": "Synchronisation manuelle commencée.",
            "auto_sync_started": "Synchronisation automatique commencée.",
            "manual_sync_completed": "Synchronisation manuelle complète.",
            "auto_sync_completed": "Synchronisation automatique complète.",
            "load_error_refresh_page": "Un problème est survenu. Veuillez réessayer en rafraîchissant la page.",
            "connection_success": "{connector} a connecté.",
            "setup_connection": "Configuration connexion",
            "loading": "Laden...",
            "setting_up_connection": "Configurer connexion...",
            "connect_api_message": "Optimisez votre efficacité en reliant votre outil RH à Kadonation Select. Cela nous permet d'importer automatiquement les données de vos employés dans Kadonation Select afin que vous puissiez plus facilement passer des commandes pour vos employés.",
            "connect_api_title": "Synchronisez vos employés à partir de votre système RH dans Kadonation Select",
            "not_synced_yet": "Pas encore synchronisé.",
            "last_synced_at": "Dernière synchronisation",
            "syncing_employees": "Synchroniser employés",
            "in_progress": "En cours",
            "success": "Réussi",
            "synchronization": "Synchronisation",
            "type": "Type",
            "date": "Date",
            "status": "Statut",
            "sync_message": "{count} d'employés ajoutés ou mis à jour",
            "employee_sync": "Synchronisation des employés {syncDate}",
            "employee_sync_summary": "Les employés qui ont été ajoutés ou mis à jour pendant la synchronisation avec {serviceName} le {syncDate} à {syncTime}.",
            "api_connection": "connexion API",
            "missing_address_info": "L'employé n'a pas d'adresse définie, contactez un administrateur pour compléter l'adresse via la gestion des employés.",
            "failed": "Échoué",
            "partial_success": "Succès partiel",
            "address_info_hidden": "Seuls les utilisateurs ayant le autorisation de \"gérer les employés\" peuvent voir l'adresse de l'employé.",
            "manager": "Manager/chef d'équipe",
            "link_companies": "Couple d'entreprises",
            "link_team_leader_companies": "Please link your Teamleader companies to your Kadonation companies",
            "kadonation_company": "Kadonation Company",
            "team_leader_company": "Teamleader Company",
            "save_companies": "Enregistrer les entreprises",
            "rerun_employee_sync": "Resynchronisation",
            "employees_added_successfully": "Les employés ont été ajoutés avec succès",
            "employees_not_added": "Employés qui n'ont pas pu être ajoutés",
            "employee_id": "ID de l'employé",
            "reason_of_failure": "Raison de l'échec",
            "copy_employee_id": "Copie de l'ID de l'employé",
            "automatically_sync_your_employees": "Synchronisez automatiquement vos employés",
            "sync_process_error_title": "Il y a eu un problème de synchronisation des employés",
            "no_sync": "Ajout automatique d'employés",
            "employees_title": "Gestion des employés",
            "modify_connection": "Modifier la connexion",
            "synchronise_now": "Synchroniser maintenant",
            "synchronising": "Synchronisation...",
            "no_employees_set_title": "Aucun employé n'a été désigné",
            "no_employees_set_description": "La fonction \"Employés\" stocke les détails de vos employés pour un accès facile dans Select. L'ajout d'employés est facile, il suffit de se connecter à votre système RH ou de télécharger un fichier Excel.",
            "after_setting_employees": "Lorsque vous avez défini les employés, vous pouvez:",
            "sync_with_hr_tool": "Synchronisation automatique avec votre outil RH",
            "employees_possiblities_list_items": "Commandez des cadeaux avec des adresses et des détails déjà remplis |\nCréez des automatismes de cadeaux d'anniversaire et d'anniversaire de travail, ainsi que des campagnes Kudos pour ajouter des messages d'équipe pour le destinataire d'un cadeau |\nRecevoir des rappels d'anniversaires et d'anniversaires de travail (à venir)",
            "add_manually": "Ajouter manuellement",
            "what_are_employees": "Quelle est la fonction \"Employés\" ?",
            "company_entity": "Entité",
            "cant_edit_employee": "Cet employé ne peut pas être modifié.",
            "cant_edit_employee_description": "Cet employé a été ajouté via la synchronisation du SIRH. Effectuez vos modifications dans le logiciel SIRH et nous les appliquerons lors de la prochaine synchronisation.",
            "no_email_for_track_and_trace": "Cet employé n'a pas d'adresse électronique associée. Par conséquent, nous avons ajouté une adresse électronique par défaut pour les informations de suivi et de traçabilité.",
            "add_new_team": "Ajouter une nouvelle équipe",
            "select_from_saved_teams": "Choisir parmi les équipes existantes",
            "type_employee": "Employé",
            "type_contractor": "Indépendant",
            "type_temp": "Temporaire",
            "type_other": "Autre",
            "dispatched": "En attente",
            "sync_throttled": "Veuillez réessayer plus tard dans {time_left} heures",
            "sync_already_in_progress": "Une tâche de synchronisation active est déjà en cours. Veuillez attendre la fin du processus avant de réessayer.",
            "contributor_qr_code_scan_string": "Hi {name} someone left you a message! Hurry up and scan it here.",
            "contributor_qr_code_shortlink_string": "{url}",
            "contributor_qr_code_thankyou_string": "Dis merci via le code QR"
        },
        "cart": {
            "confirm-moq-warning": "Confirmez et allez à la caisse.",
            "cart-cleared": "Votre panier a été vidé.",
            "clear": "Vider panier",
            "shipping_fee_calculated_at_checkout": "Les frais d'expédition sont calculés au moment du paiement.",
            "added_to_cart": "Ajouté au panier",
            "empty": "Aucun article dans le panier",
            "handling": "Extras et frais",
            "remove_from_cart": "Remove from cart",
            "items_in_cart": "{count} article | {count} articles"
        },
        "checkout": {
            "shipping_details": "Shipping details",
            "billing_details": "Billing details",
            "delivery_info_btn": "Comment entrer la date de livraison?",
            "delivery_info_format": "Entrer la date de livraison au format suivant: <b>\"année-mois-jour\"</b>.",
            "delivery_info_format_example": "Exemple pour le 1er mai 2021:",
            "delivery_info_after_date": "La livraison ne peut pas être avant:",
            "delivery_info_no_weekend": "Pas de livraison le samedi et dimanche",
            "delivery_info_urgent_dates": "Livraison urgente disponible le",
            "delivery_info_no_delivery_dates": "Pas de livraison aux dates suivantes:",
            "items_total": "Total des articles",
            "delivery_details": "Détails de la livraison",
            "order_summary": "Récapitulatif de la commande",
            "please_add_address": "Veuillez ajouter une adresse",
            "total_including_vat": "total (TVA incluse)",
            "company_field_help_text": "Ne pas remplir lors de l'expédition à une adresse personnelle.",
            "grouped_invoice_description": "Une fois par mois, vous recevez une facture qui comprend toutes les commandes ajoutées à la facture mensuelle groupée.",
            "separate_invoice_description": "Une facture séparée sera créée pour cette commande. Il ne sera pas ajouté à la facture mensuelle groupée.",
            "back_to_cart": "Au panier",
            "back_to_billing": "Retour",
            "continue_to_shipping": "Continuer vers la Livraison",
            "place_order": "Passer la Commande",
            "breadcrumb_cart": "Panier",
            "breadcrumb_checkout": "Checkout",
            "breadcrumb_billing": "Facturation",
            "breadcrumb_shipping": "Expédition",
            "terms_agreement_validation_error": "Veuillez lire les termes de l'accord. Vous devez accepter les conditions pour pouvoir continuer.",
            "address_numbered": "Adresse #{number}",
            "home_address_notification": "Vous n'avez pas besoin de fournir des informations d'expédition pour les articles qui seront livrés à domicile.",
            "shipping_fee_calculated_at_next_step": "Calculé à l'étape suivante",
            "there_are_errors": "Il y a des problèmes avec les informations que vous avez remplies.",
            "delivery_for_future_notification": "La date de livraison prévue était déjà fixée par le destinataire.",
            "remaining-credits": "Crédits restants",
            "insufficient-credits-for-order": "Vous ne disposez pas de crédits suffisants pour couvrir le total de cette commande.",
            "bus_help_text_checkout": "Le mot \"Bus\" sera ajouté par nos soins ultérieurement (dans le champ \"bus\").",
            "calculating_shipping_fee": "Calculer...",
            "continue_to_billing": "Poursuivre la facturation",
            "back_to_shipping": "Retour à l'expédition",
            "billing_details_shown_on_invoice": "Ces informations figureront sur la facture.",
            "pay_immediately": "Payer immédiatement",
            "pay_immediately_description": "Payez votre commande maintenant par carte de crédit ou de débit.",
            "pay_via_credits": "Payer par Crédits",
            "pay_via_credit_description": "Payez votre commande par Crédit prépayé.",
            "pay_after_invoice": "Payer après la facture",
            "pay_after_invoice_description": "Payez votre commande dès réception de votre facture.",
            "payment_method": "Mode de paiement",
            "direct_payment_disabled_order_approval_required": "(Non disponible lorsque l'approbation de la commande est requise)"
        },
        "credits": {
            "pay-with-credits": "Payer avec des crédits",
            "what-are-credits": "Comment obtenir des crédits ?",
            "i-want-to-pay-with-credits": "Je veux payer avec des crédits",
            "current-balance": "Solde actuel",
            "credits": "Crédits",
            "need-more-credits": "Vous avez besoin de plus de crédits ?",
            "need-more-credits-explanation": "Pour recevoir plus de crédits, veuillez contacter votre gestionnaire de compte. Il ajoutera des crédits à votre solde et créera une facture à cet égard..",
            "index": {
                "credits-will-expire": "{credit} crédits expireront le {date}.",
                "description": "Les crédits sont une monnaie numérique qui peut être dépensée dans Kadonation Select. Vous pouvez les acheter à tout moment et ils vous permettent de mieux budgétiser vos dépenses. Contactez-nous pour plus d'informations sur l'utilité des crédits pour votre organisation. 1 crédit équivaut à 1 euro.",
                "buy-more-credits": "Acheter plus de crédits"
            },
            "expires-on": "Expire le {date}",
            "ordered-by": "Commandé par {name}",
            "no-credits-link-to-top-up": "Vous n'avez actuellement aucun crédit.",
            "credits-with-value": "{count} crédits",
            "remaining-via-invoice": "Montant restant à payer par facture",
            "coming-soon": "Qu'est-ce qu'un crédit ?",
            "coming-soon-explanation": "Les crédits sont une monnaie numérique dans Kadonation Select. Ils sont achetés au préalable, via une facture, et sont ensuite ajoutés à votre compte Select. Une fois que vous avez des crédits, vous pouvez passer des commandes en les utilisant.\n\nVisitez la page 'Crédits' dans Select pour demander des crédits, ou contactez le support. (Vous pouvez utiliser le bouton \"Support\" dans le coin inférieur droit).",
            "no-items-found": "Rien trouvé",
            "spend-history": "Historique des dépenses",
            "buy-history": "Historique des achats",
            "no_items_found": "Rien trouvé",
            "expired_on": "Date d'expiration {expired_on}",
            "use_credits_by_default": "Utilisez les crédits pour passer vos commandes",
            "no-credits": "Pas de crédits disponibles",
            "paying-with-credits": "Sera acheté avec des crédits",
            "expiring-soon": "{amount} des crédits expirant le {date}",
            "balance-title": "Soldes de toutes les {hi}érarchies",
            "add-more-credits": "Ajouter des crédits",
            "view-credit-history": "Consulter les antécédents de crédit",
            "view-transactions": "Voir l'historique",
            "history": {
                "title": "Antécédents de crédit de",
                "spent-from": "Dépenses de {from}",
                "added-to": "Ajouté à {to}",
                "deducted-from": "Déduit de {from}",
                "short-title": "Antécédents de crédit"
            },
            "plural": {
                "user": "Utilisateurs",
                "entity": "Entités de l'entreprise",
                "department": "Départements"
            },
            "view-credit-balances": "Voir les soldes",
            "search-for-hierarchy": "Recherche de {hierarchy}",
            "by": "par",
            "added": "Ajouté",
            "spent": "Dépensé",
            "deducted": "Déduit",
            "amount-of-credits": "Montant à ajouter",
            "hierarchy-to-add-credits-to": "Ajouter des crédits à",
            "request-credits": "Demande de crédits",
            "select-a-hierarchy": "Sélectionner une option",
            "credit-request-placed-successfully": "Les crédits ont été demandés avec succès. Nous les ajouterons bientôt.",
            "add-modal-subtext-non-link": "La demande de crédit sera vérifiée par Kadonation, après quoi vous recevrez une facture. Les crédits deviennent utilisables après le paiement de la facture. En achetant des crédits, vous acceptez nos",
            "add-modal-subtext-link": "conditions d'utilisation",
            "use-from": "Utiliser les crédits de",
            "partially-paid": "Partiellement payé avec des crédits, le reste via facture.",
            "refund": "Remboursement",
            "refunded_for": "Remboursé pour"
        },
        "errors": {
            "mobile": "Cette plateforme n'est pas prise en charge sur les appareils mobiles. Veuillez vous connecter à l'aide d'un ordinateur ou d'un portable.",
            "error-request": "Erreur sur votre demande.",
            "saml-auth-403": "Contactez l'administrateur de votre entreprise si vous pensez que vous devriez avoir accès à cette application.",
            "general-error": "Quelque chose a mal tourn&eacute;, essayez &agrave; nouveau plus tard.",
            "saml-auth-bad-configured": "The SAML authentication has been incorrectly configured. Please contact fabrice@kadonation.com to check the configuration.",
            "error_on_row": "Il y a une erreur sur la ligne {row}.",
            "no_result_found": "No result found",
            "row_has_errors": "Cette ligne contient des erreurs.",
            "there_are_errors_on": "Il y a des erreurs sur",
            "error_form": "Il y a des erreurs dans les champs que vous avez remplis.",
            "error_page_401_title": "Non Autorisé",
            "error_page_403_title": "Accès Refusé",
            "error_page_404_title": "Pas Trouvé",
            "error_page_419_title": "Page Expirée",
            "error_page_429_title": "Trop de Demandes",
            "error_page_500_title": "Oops! Quelque Chose S'est Mal Passé",
            "error_page_503_title": "Nous Sommes En Maintenance",
            "error_page_401_generic_description": "Vous devez vous connecter pour accéder à cette page.",
            "error_page_403_generic_description": "Vous n'êtes pas autorisé à accéder à cette page.",
            "error_page_404_generic_description": "La page que vous recherchez est introuvable.",
            "error_page_419_generic_description": "Veuillez retourner au formulaire que vous essayez de soumettre, rechargez la page et essayez de soumettre à nouveau.",
            "error_page_429_generic_description": "Nous sommes désolés, mais nous rencontrons actuellement un volume élevé de demandes et ne sommes pas en mesure de les traiter toutes en même temps. Veuillez réessayer plus tard et assurez-vous que vous ne faites pas trop de demandes trop rapidement. Merci pour votre patience.",
            "error_page_500_generic_description": "Désolé, quelque chose s'est mal passé de notre côté et nous avons du mal à répondre à votre demande pour le moment. Veuillez réessayer plus tard ou contacter notre équipe d'assistance pour obtenir de l'aide. Merci pour votre compréhension.",
            "error_page_503_generic_description": "Nous sommes désolés, mais le service auquel vous essayez d'accéder est actuellement indisponible en raison de problèmes de maintenance ou d'autres problèmes techniques. Notre équipe s'efforce de mettre les choses en place dès que possible, alors revenez plus tard. Merci pour votre patience et votre compréhension."
        },
        "permissions": {
            "select_all": "Sélectionner toutes les permissions",
            "admin_permission_explainer": "Possibilité de voir et de modifier les paramètres (y compris les clés API et SAML SSO). Possibilité de modifier les occasions et les cadeaux disponibles. Possibilité de créer et de modifier des automatismes (si les autorisations de commande sont également accordées).",
            "user_management_explainer": "Possibilité de voir, créer et modifier les utilisateurs.",
            "order_explainer": "Possibilité de passer des commandes pour le département et l'entité auxquels cet utilisateur appartient.",
            "order_for_all_explainer": "Capable de passer des commandes pour tous les départements et entités de l'entreprise.",
            "view_my_invoices_explainer": "Possibilité d'afficher les factures en fonction des commandes passées par cet utilisateur.",
            "view_all_invoices_explainer": "Possibilité de visualiser toutes les factures.",
            "reporting_explainer": "Possibilité de voir les rapports sur les dépenses.",
            "manage_all_orders_explainer": "Possibilité de voir et de modifier toutes les commandes et leurs détails (y compris les adresses).",
            "budget_constraint_management_explainer": "Capable d'éditer des contraintes budgétaires.",
            "manage_kudos_campaigns_explainer": "Possibilité de voir, créer et modifier les campagnes Kudo.",
            "manage_employees_explainer": "Possibilité de voir, créer et modifier les employés. Possibilité de voir les informations sur les employés dans Select. Lorsqu'il est désactivé, cet utilisateur ne peut pas voir les adresses ou les détails personnels de l'employé (mais il peut commander pour lui en utilisant son nom).",
            "approve_orders_explainer": "Capable d'approuver les commandes des utilisateurs liés. Liez les utilisateurs qui commandent à cet utilisateur approbateur en utilisant l'onglet \"Profil\" ci-dessus.",
            "manage_credits_explainer": "Visualisation des crédits achetés et dépensés",
            "order_for_entity_explainer": "Possibilité de passer des commandes pour tous les départements et l'entité à laquelle appartient l'utilisateur."
        },
        "budget": {
            "title": "Les budgets",
            "text": "Sur cette page, vous pouvez définir les seuils des montants des bons d'achat pour chaque occasion. Avec le seuil d'avertissement, ils peuvent encore commander, avec le seuil de blocage, ils ne le peuvent plus. Vous pouvez spécifier la notification de la politique de l'entreprise pour chaque occasion en remplissant les champs. Ces informations sont visualisées dans la page d'ajout au panier pour servir de directive.",
            "warning-threshold": "Seuil d'avertissement",
            "blocking-threshold": "Seuil de blocage",
            "notifications-title": "Modifier les notifications de politique d'entreprise",
            "default-threshold-message": "Le {attribute} ne peut excéder {max} €.",
            "proceed-warning-threshold": "Êtes-vous sûr?",
            "updated-threshold-amounts": "Mise à jour des seuils.",
            "updated-threshold-messages-occasion": "Messages de seuil mis à jour pour {occasion}",
            "warning-threshold-message": "Le montant est supérieur à € {max}. Êtes-vous sûr?",
            "default-warning-info-message": "Veuillez respecter la politique de l'entreprise supérieur à {max} €.",
            "default-blocking-info-message": "La politique de l'entreprise a fixé le montant par cadeau à un maximum de € {max}.",
            "validation-threshold-min": "Le {attribute} doit être d'au moins {min} €.",
            "validation-threshold-max": "Le {attribute} ne peut être supérieur à {max} €",
            "validation-messages-required_with": "Veuillez saisir toutes les langues.",
            "message_rows": "{message} Ligne(s) {rows}.",
            "save_budget": "Enregistrer le budget"
        },
        "shared-order-approval": {
            "step-1": {
                "title": "Visualisation et signer du (des) devis",
                "text": "Vous devez faire ce qui suit pour que nous puissions passer votre commande.",
                "download_title": "1. Télécharger les offres disponibles",
                "download_text": "Vous ne pouvez obtenir qu'une seule offre par vente.\nSi vous souhaitez utiliser plus d'une de ces offres, vous devrez faire des achats supplémentaires.",
                "upload_title": "2. Télécharger l'offre signée",
                "upload_text": "Après avoir téléchargé les devis, vous devez télécharger le devis signé ici.\nTant que vous ne sauvegardez pas le devis, vous pouvez toujours modifier le devis signé.",
                "select_quotation_label": "Veuillez sélectionner l'offre que vous souhaitez utiliser.",
                "upload_quotation_label": "Téléchargez votre offre signée ici",
                "submit": "Transmission d'une offre signée",
                "processing_your_quotation": "Nous traitons votre devis",
                "processing_your_quotation_explanation": "Nous vous informerons par e-mail dès que la commande sera prête à être approuvée par vous. En attendant, n'hésitez pas à jeter un œil aux devis disponibles et à celui que vous avez téléchargé.",
                "available_quotations": "Devis Disponibles",
                "available_quotations_explanation": "Ce sont les devis que nous vous avons proposés. Vous ne pouvez commander qu'un seul devis à la fois. Si vous souhaitez passer une commande pour un devis différent, n'hésitez pas à nous contacter.",
                "your_signed_quotation": "Votre Devis Signé",
                "your_signed_quotation_explanation": "Il s'agit du devis signé que vous avez téléchargé. C'est le devis que nous traitons actuellement.",
                "deadline-missed": "{deadline} la date limite est passée !",
                "deadline-missed-explanation": "Vous avez dépassé la date limite pour télécharger votre devis signé, nous ne pouvons pas garantir une livraison à temps !",
                "deadline-approaching": "{deadline} la date limite approche !",
                "deadline-approaching-explanation": "Veuillez télécharger votre devis signé avant cette date, sinon nous ne pouvons pas garantir une livraison à temps !",
                "whoops": "Oops ...",
                "no-quotations-to-sign": "Il n'y a pas de devis à signer.",
                "contact-support": "Contactez notre service d'assistance"
            },
            "step-2": {
                "customize_and_order": "Personnalisez et passez votre commande",
                "customize_and_order_description": "Vous pouvez y consulter tous les articles de votre commande et les personnaliser si vous le souhaitez.",
                "order_id": "Numéro de commande",
                "view_quotation": "Afficher le devis",
                "personalize_order": "Vous voulez personnaliser votre commande ?",
                "personalize_order_description": "Ajoutez des noms, des messages personnels ... aux articles.",
                "invoiced_to": "Adresse de la facture",
                "edit_po_cost_center": "Modifiez votre numéro de commande et/ou votre centre de coûts",
                "edit_po_title": "Ajoutez un numéro de commande et/ou un centre de coûts à votre commande.",
                "place_order": "Passer une commande pour € {cost}",
                "no_order_items_found": "Aucun article de commande trouvé.",
                "deadline-missed": "{deadline} la date limite est passée !",
                "deadline-missed-explanation": "Vous avez dépassé la date limite pour télécharger votre devis signé, nous ne pouvons pas garantir une livraison à temps !",
                "deadline-approaching": "{deadline} la date limite approche !",
                "deadline-approaching-explanation": "Veuillez télécharger votre devis signé avant cette date, sinon nous ne pouvons pas garantir une livraison à temps !",
                "add_delivery_address": "Ajouter l'adresse de livraison",
                "update_delivery_address": "Mettez à jour l'adresse de livraison",
                "update_delivery_address_explanation": "Mettez à jour l'adresse de livraison de la commande",
                "add_delivery_address_explanation": "Ajouter une adresse de livraison à votre commande",
                "delivery-address-updated": "L'adresse de livraison a été mise à jour avec succès.",
                "errors-in-the-form": "Il y a des erreurs dans le formulaire. Veuillez les corriger.",
                "failed-to-update-delivery-address": "Nous n'avons pas pu mettre à jour l'adresse de livraison de la commande pour le moment. Veuillez réessayer ou contacter le service client.",
                "can_not_place_order_error": "Impossible de passer la commande en ce moment car certains éléments de la commande contiennent des erreurs.",
                "order_has_errors": "La commande contient des erreurs.",
                "review_item": "Veuillez revoir l'article de la commande '{item}'.",
                "review_items": "Veuillez revoir les éléments de la commande."
            },
            "step-3": {
                "title": "Nous traitons votre commande.",
                "subtitle": "Nous travaillons dur pour traiter votre commande. Vous trouverez ci-dessous un aperçu de votre commande."
            },
            "back_to_order_overview": "Retour à la liste des commandes",
            "display_logo": "Ajouter un logo ?",
            "display_logo_description_one": "Si vous ajoutez un logo, il sera imprimé sur vos cadeaux.",
            "display_logo_description_two": "Si un logo a déjà été créé pour votre entreprise, vous le verrez ici.",
            "logo_will_be_included": "Logo ajouté.",
            "logo_will_not_be_included": "Logo n'est pas ajouté",
            "preview_voucher_and_email": "Envoyer un exemple d'e-mail",
            "preview_voucher_and_email_description": "Pour savoir comment les destinataires recevront leur chèque cadeau numérique Kadonation, envoyez un example d'e-mail.",
            "send_example": "Envoyez un exemple",
            "preview_landing_page": "Aperçu de la page de renvoi",
            "preview_landing_page_description": "Les bénéficiaires pourront activer leur chèque cadeau Kadonation via la page d'accueil.",
            "view_landing_page": "Aperçu de la page de renvoi",
            "recipient_list": "Ajoutez la liste des bénéficiaires.",
            "recipient_list_description": "Vous pouvez télécharger les bénéficiaires via un fichier excel.",
            "upload_through_file": "Chargement par fichier",
            "upload_description": "Téléchargez le fichier modèle excel et remplissez les champs demandés.",
            "list_of_recipients": "Aperçu des bénéficiaires",
            "modified_order_message": "Vous avez ajouté d'autres destinataires ou vous avez modifié le montant de sorte qu'il ne correspond plus au devis. Veuillez noter que le prix de l'ordre a été mis à jour et que le devis peut ne plus refléter l'ordre réel.",
            "sending": "Envoi d'un exemple ...",
            "something_went_wrong": "Oups. Quelque chose a mal tourné.",
            "preview_email_sent": "Un e-mail de prévisualisation a été envoyé.",
            "provide_valid_email": "Veuillez saisir une adresse électronique valide.",
            "minimum_one_recipient": "Ajouter au moins un bénéficiaire",
            "direct_to_recipient": "Envoyer directement au destinataire",
            "physical_direct_to_recipient_explanation": "Votre commande sera expédiée à l'adresse personnelle des destinataires. Vous ne pouvez donc pas fournir d'adresse de livraison générale.",
            "digital_direct_to_recipient_explanation": "Votre commande sera envoyée directement à l'adresse électronique des destinataires.",
            "view_examples": "Voir les exemples",
            "personalize": "Personnalisez",
            "view_data": "Voir les données",
            "preview_voucher": "Afficher les fichiers d'impression",
            "preview_voucher_description": "Visualisez les fichiers d'impression qui seront utilisés pour imprimer les chèques cadeaux.",
            "preview_the_vouchers": "Aperçu des chèques cadeaus en format PDF",
            "step": "Étape {step}",
            "no_delivery_information": "Aucune information de livraison fournie.",
            "delivery": "Livraison",
            "discount_has_been_applied": "Une remise de {discount}% a été appliquée",
            "step-4": {
                "title": "Votre commande a été expédiée.",
                "description": "Nous avons expédié votre commande et vous devriez la recevoir bientôt. Nous vous avons envoyé un email avec le lien de suivi."
            },
            "step-5": {
                "description": "Vous avez reçu votre facture par e-mail, si vous avez des questions à ce sujet, veuillez contacter le service clientèle. Nous espérons que tout est à votre satisfaction et nous nous réjouissons de vous revoir !",
                "title": "Votre facture pour votre commande.",
                "subtitle": "La facture de cette commande vous a été remise par e-mail."
            },
            "select-via-employees-explanation": "Sélectionnez tous les employés ou équipes auxquels vous souhaitez offrir un cadeau. Vous pouvez ajouter des employés un par un, par équipe ou tous en même temps. Ensuite, vous pouvez personnaliser le cadeau par employé ou pour plusieurs employés à la fois.",
            "show-note": "Afficher la note",
            "hide-note": "Cacher la note",
            "generating_for_the_recipient": "Nous générons le bon démo pour {to}",
            "excel_limitation_note": "Remarque: le message personnel est limité à 300 caractères. Chaque entrée compte pour 40 caractères. La langue et le pays doivent être choisis parmi les options disponibles dans le fichier modèle. Les émojis ne sont pas supportés.",
            "place_order_confirmation_description": "Vous êtes sur le point de passer votre commande pour € {cost}. Vous ne pourrez apporter aucune modification et nous commencerons à traiter votre commande immédiatement. Êtes-vous sûr de vouloir continuer à passer votre commande ?",
            "preview_greeting_card": "Aperçu des cartes de vœux en format PDF"
        },
        "invoice": {
            "AMOUNT-EXCL-VAT": "Montant (hors TVA)",
            "BANK": "Banque",
            "CLIENT-REFERENCE": "Référence du client",
            "CREDIT_NOTE": "Note de crédit",
            "CREDIT-NOTE-LINKS-TO-INVOICE": "Note de crédit concernant la facture {invoiceNumber}",
            "DATE": "Date",
            "DELIVERY-ADDRESS": "Adresse de livraison",
            "DESCRIPTION": "Description",
            "EXPIRATION-DATE": "Date d'expiration",
            "GIFTBOX": "Boîte(s) cadeau Kadonation",
            "GIFTBOX-SLEEVE": "Boîte(s) cadeau avec pochette",
            "GIFTCARD": "Carte(s) cadeau Kadonation",
            "GIFTCARD-ENVELOPPE": "Carte(s) cadeau Kadonation avec enveloppe",
            "handling_fee": "Frais de traitement",
            "INVOICE": "Facture",
            "INVOICE-ADDRESS": "Adresse de facturation",
            "KADONATION-GIFT-CARD": "Chèque cadeau Kadonation",
            "KVK-NUMBER": "Numéro de la Chambre de Commerce",
            "NET": "Net",
            "ORDER": "Commande",
            "ORDER-NUMBER": "Numéro de commande",
            "PAYMENT-REFERENCE": "Message",
            "PO": "Numéro de PO",
            "PO-NUMBER": "Numéro de PO",
            "QUANTITY": "Quantité",
            "SHIPPING-COSTS": "Frais d'envoi",
            "TERMSMESSAGE": "Nos conditions générales se trouvent à la page suivante",
            "TOTAL": "Total",
            "urgent_fee": "Frais d'urgence",
            "VAT": "TVA",
            "total_exvat": "Total (hors TVA)",
            "OCCASION": "Occasion",
            "GIFT": "Cadeau",
            "PRICE": "Prix",
            "DETAILED_REFERENCE": "Référence détaillée",
            "FROM": "De",
            "TO": "À",
            "MESSAGE": "Message",
            "invoice_detail": "Détail",
            "INVOICE_DETAIL": "Détail",
            "credit_note_regarding_invoice": "Concernant la facture",
            "CREDIT-NOTE": "Note de crédit",
            "shipping_fee": "Frais d'expédition",
            "PRO_FORMA": "Facture Proforma",
            "total_invat": "Total (incl. TVA)",
            "home_delivery_fee": "Frais de livraison à domicile",
            "INTRA-COMMUNITY-EXPLANATION": "livraison intracommunautaire, exonérée de TVA art 39bis 1° W. TVA",
            "INVOICE-DETAILS-REFERENCE": "Vous pouvez consulter les détails supplémentaires de votre facture via l'aperçu de la facture ou via ce lien: <a href=\"{invoiceDetails}\">{invoiceDetails}</a>.",
            "no_invoices_found": "Vous n'avez pas encore de factures.",
            "paid_via_credits": "Payé par des crédits",
            "to_be_paid_invat": "A payer (TVA incluse)",
            "credit_description": "Crédits",
            "PAID-BY-CREDITS": "Facture déjà (partiellement) payée avec des crédits.",
            "BALANCE-TO-BE-PAID": "Solde à payer",
            "order_list_for_proforma_auto_finalization": "commande|commandes",
            "validation_max_date_range_for_export": "Please select a date range that is at most 1 year.",
            "export-mail-title": "Vos factures exportées",
            "export-mail-text": "Vous avez demandé à exporter des factures de Kadonation. Vous les trouverez dans le fichier Excel ci-joint.",
            "export-mail-subject": "Vos factures exportées",
            "export": "Exporter des factures",
            "export_successful": "Les factures sont en cours d'exportation. Cela peut prendre un certain temps. Nous vous enverrons bientôt un courriel contenant l'exportation.",
            "select_date_range_first": "Veuillez d'abord utiliser le filtre de date.",
            "clear_filters": "Effacer les filtres",
            "max_date_range_warning": "Seules les factures des 365 derniers jours peuvent être affichées.",
            "last_month": "30 derniers jours",
            "last_3_months": "90 derniers jours",
            "last_6_months": "180 derniers jours",
            "last_12_months": "365 derniers jours",
            "invoice_date_range": "Filtrer les factures",
            "credit_for_receiver_description": "Crédits pour {receiver}",
            "INVOICE-ALREADY-PAID": "Facture déjà payée",
            "INVOICE-TERMS-AND-CONDITIONS": "Conditions générales - la présente facture est régie par les conditions générales B2B et en font donc partie intégrante. En cas de contradiction ou d'incohérence entre cette facture et les conditions générales B2B, les dispositions des conditions générales B2B prévaudront, sauf si cette facture contient expressément des dispositions spécifiques, auquel cas ces dispositions de la facture prévaudront. Les conditions générales B2B sont consultables sur le site web de Kadonation via le lien hypertexte suivant : <a target='_blank' href='https://kadonationselect.com/terms-and-conditions'>les conditions générales</a>.\n\nConfidentialité - Le traitement des données personnelles dans le cadre de l'exécution du présent Accord est effectué et régi exclusivement conformément à la Politique de traitement des données de Kadonation, consultable sur le site web de Kadonation via le lien hypertexte suivant : <a target='_blank' href='https://kadonationselect.com/data-processing-agreement'>la politique informatique</a>. Le Client reconnaît expressément qu'en utilisant la Plateforme, en commandant les Produits et/ou en concluant un Accord avec Kadonation, il/elle a lu et accepté l'intégralité de la Politique de traitement des données.",
            "default-subscription-invoice-section-name": "abonnement"
        },
        "general": {
            "my_dashboard": "Mon tableau de bord",
            "gift_occasions": "Occasions de cadeaux",
            "check_all_occasions": "Consulter toutes les occasions",
            "reports": "Rapports",
            "coming_soon": "Bientôt disponible",
            "create_user": "Nouvel utilisateur",
            "user_management": "Utilisateurs",
            "name": "Nom",
            "department": "Département",
            "email": "E-mail",
            "cancel": "Annuler",
            "add_person": "Ajouter un utilisateur",
            "english": "Anglais",
            "dutch": "Néerlandais",
            "french": "Français",
            "german": "Allemand",
            "permissions": "Autorisations",
            "assign": "Assigner",
            "assign_permission": "Assigner une permission",
            "reset_password": "Réinitialiser le mot de passe",
            "cart": "Panier",
            "go_to_checkout": "Suivant",
            "occasion": "Occasion",
            "product": "Produit",
            "quantity": "Quantité",
            "price": "Prix",
            "discount": "Réduction",
            "total_ex_vat": "Total (hors TVA)",
            "total_inc_vat": "Total (TVA incluse)",
            "handling_fee": "Extras et frais",
            "grand_total": "Montant total",
            "checkout": "Checkout",
            "delivery_address": "Adresse de livraison",
            "overview": "Aperçu",
            "download": "Télécharger",
            "back_to_dashboard": "Retour au tableau de bord",
            "items": "Nombre d'articles",
            "urgency_fee": "Frais d'urgence",
            "item": "Article",
            "company_settings": "Paramètres de l'entreprise",
            "company_departments": "Départements de l'entreprise",
            "all_departments": "Tous les départements",
            "company_entities": "Entités de l'entreprise",
            "add_department": "Ajouter un département",
            "close": "Fermer",
            "add_entity": "Ajouter une entité",
            "vat_number": "Numéro de TVA",
            "address": "Adresse",
            "add_company_entity": "Ajouter une entité",
            "edit_delivery_address": "Modifier l'adresse",
            "back": "Retour",
            "edit_company_entity": "Entité d'édition",
            "invoice_address": "Adresse de facturation",
            "default": "Défaut",
            "add_delivery_address": "Ajouter une adresse",
            "delivery_addresses": "Adresses de livraison",
            "orders_overview": "Aperçu des commandes",
            "company_entity": "Entité de l'entreprise",
            "invoices_type": "Type de facture",
            "expected_delivery": "Date prévue de livraison",
            "messages": "Messages",
            "message": "Message",
            "order_item": "Poste de commande",
            "from": "De",
            "to": "à",
            "locale": "Langue",
            "gift": "Cadeau",
            "delivery_date": "Date de livraison prévue",
            "subtotal": "Sous-total",
            "cost": "Coût",
            "total": "Total",
            "order_details": "Les détails de la commande",
            "vat": "TVA",
            "order_items": "Articles commandés",
            "select_occasion": "Sélectionnez une occasion",
            "edit": "éditer",
            "edit_your_occasions": "Modifier les occasions",
            "update": "Mise à jour",
            "invoices_overview": "Aperçu des factures",
            "invoices_date": "Date de la facture",
            "total_amount": "Montant total",
            "select_gift_for_occasion": "Sélectionnez un cadeau pour l'occasion: {occasion}",
            "select_gift": "Sélectionnez un cadeau",
            "enter_the_details": "Entrez les détails",
            "settings": "Paramètres",
            "logout": "Se déconnecter",
            "home": "Page d'accueil",
            "orders": "Commandes",
            "invoices": "Factures",
            "login": "Se connecter",
            "select_options": "Sélectionnez une option",
            "select_price": "Sélectionner le prix",
            "personal_message": "Message personnel",
            "for_individuals": "Pour des individus",
            "for_group": "Pour un groupe",
            "order": "Commande",
            "download_file": "Télécharger le fichier",
            "choose_saved_address": "Choisissez une adresse sauvegardée",
            "choose_new_address": "Ou utilisez une nouvelle adresse",
            "be": "La Belgique",
            "nl": "Pays-Bas",
            "urgent_delivery": "Livraison urgente",
            "cart_empty": "Votre panier est vide, veuillez ajouter un produit",
            "generating_download": "Le téléchargement est en train d'être généré...",
            "invoice_type": "Type de facture",
            "separate_invoice": "Séparée",
            "grouped_monthly_invoice": "Groupée (mensuelle)",
            "make_first_order": "Passez votre première commande",
            "empty_orders_text": "Il n'y a pas encore de commandes.",
            "add_to_cart": "Ajouter au panier",
            "empty_invoices_text": "Il n'y a pas encore de factures.",
            "add_address": "Ajouter une adresse",
            "change_address": "Changer l'adresse",
            "no_address_selected": "Aucune adresse sélectionnée",
            "edit_occasions_info": "Contrôlez quelles occasions sont affichées sur votre plateforme. En activant une occasion, elle sera visible, vous permettant de voir et d'acheter des cadeaux pour celle-ci. Lorsqu'une occasion est désactivée, elle sera masquée.",
            "view_order": "Consulter la commande",
            "view_invoice": "Consulter la facture",
            "view_invoices_overview": "Consulter l'aperçu des factures",
            "invoices_id": "ID",
            "occasions": "Occasions de cadeaux",
            "order_id": "ID",
            "user": "Utilisateur",
            "status": "Etat de la commande",
            "active": "Actif",
            "inactive": "Inactif",
            "activate": "Activer",
            "deactivate": "Désactiver",
            "total_value": "Valeur",
            "exc": {
                "_VAT": "Excl. TVA"
            },
            "save": "Sauvegarder",
            "total_handling_fee": "Total extras et frais",
            "VAT": "TVA",
            "set_password": "définir un mot de passe",
            "order_confirmation_CTA": "aperçu de la commande",
            "invoice_mail_CTA": "consulter l’aperçu des factures",
            "language": "Langue du destinataire",
            "create_password": "Créez un mot de passe",
            "save_and_login": "Enregistrer et se connecter",
            "urgent_fee": "Frais d'urgence",
            "terms_and_framework_agreement": "Les termes de la convention sont définis dans un accord-cadre.",
            "read_and_accept": "J'ai lu et j'accepte les",
            "terms_and_agreement": "Conditions de la convention",
            "for_this_order": "Pour cette commande",
            "department_name": "Nom du département",
            "order_terms": "les conditions de commande",
            "select_amount": "Montant",
            "enter_amount": "Saisissez un montant",
            "order_terms_and_agreement": "J'ai lu et j'accepte les termes de l'accord relatif à cette commande.",
            "for": "pour",
            "link": "Cliquez ici pour consulter les termes de la convention.",
            "value": "Valeur",
            "privacy_policy": "Accepter la politique d'utilisation",
            "use_policy": "Politique d'utilisation",
            "data_policy": "Politique de données",
            "read_and_accept_use_policy": "J'ai lu et j'accepte la politique d'utilisation",
            "read_and_accept_data_policy": "J'ai lu et j'accepte la politique de données",
            "invoice": "Facture d'achat",
            "creditnote": "Note de crédit",
            "invoice_section": "Section",
            "invoice_sections": "Sections",
            "invoice_item": "Article",
            "invoice_items": "Articles",
            "description": "Description",
            "save_and_submit": "Enregistrer et envoyer",
            "save_as_draft": "Enregistrer comme brouillon",
            "change_status": "Changer de statut",
            "status_changed": "Statut changé!",
            "status_not_changed": "Statut non modifié pour les factures suivantes (vous ne pouvez approuver que les factures provisoires, en attente ou pro forma):",
            "linked_invoice": "Facture liée",
            "create_creditnote": "Créer une note de crédit",
            "credit_mail_CTA": "consulter l’aperçu des factures",
            "via_form": "Via formulaire",
            "via_excel": "Via excel",
            "detailed_reference": "Référence détaillée",
            "invoice_or_credit_note": "Type",
            "total_shipping_fee": "Frais d’envoi",
            "current_logo": "Logo",
            "contact_support": "Veuillez contacter notre support pour remplacer votre logo",
            "no_logo": "Aucun logo n'a encore été programmé",
            "shipping_fee": "Frais d'expédition",
            "and_department": "et du département",
            "kayako_faq": "Questions Fréquentes",
            "new_invoice": "Nouvelle facture",
            "update_invoice": "Modifier la facture",
            "budgets": "Budget",
            "finalize": "Finaliser",
            "generate_label": "Générer une étiquette",
            "delivery_email": "E-mail",
            "delivery_direct": "Envoyez le cadeau directement au bénéficiaire",
            "proforma": "Pro Forma",
            "po_number": "Numéro de PO",
            "proforma_id": "ID Pro Forma",
            "group": "Groupe",
            "team": "Équipe",
            "import": "Importer",
            "delivery_direct_tag": "Envoié directement au bénéficiaire.",
            "create": "créer",
            "delivery_direct_quantity-limit": "Veuillez utiliser \"via Excel\" ou \"en dehors des employés\" si vous souhaitez envoyer plus d'un cadeau directement aux bénéficiaires.",
            "truncate_employees": "Supprimer tous les employés",
            "clear": "Clair",
            "senders": "Envoyeur",
            "receivers": "Récepteurs",
            "empty_orders_for_approval_text": "Il n'y a pas encore de commandes à approuver.",
            "orders_for_approval": "Approbation des commandes",
            "view": "Voir",
            "orders_for_approval_overview": "Approbation des commandes",
            "orders-for-approval": "Approbation des commandes",
            "approve": "Approuver",
            "reject": "Rejeter",
            "go": "Enregistrer",
            "approval_manager": "Responsable de l'approbation",
            "cancel_order": "Annuler la commande",
            "last_updated": "Dernière mise à jour",
            "impersonating_welcome": "Impersonation active",
            "impersonating_description": "Vous vous faites maintenant passer pour un autre utilisateur. N'oubliez pas que les actions que vous effectuez le seront au nom de cette personne.",
            "order_creator": "Commande créé par",
            "impersonating_stop": "Stop Impersonation",
            "cost_center": "Centre de coûts",
            "reason": "Commentaire",
            "copy": "Copier",
            "lu": "Luxembourg",
            "api_keys": "clés API",
            "add_key": "ajouter une clé API",
            "next": "Suivant",
            "process_order": "Traiter la commande",
            "verify_order": "Vérifier la commande",
            "delivery_time": "Délai de livraison",
            "inbound": "Inbound",
            "outbound": "Outbound",
            "reseller": "Reseller",
            "tenders": "Tenders",
            "part": "Partie",
            "download_link_disclaimer": "Les liens de téléchargement ne sont valables que pendant une semaine.",
            "option": "Option",
            "via_employee_list": "En dehors des employés",
            "edit_employee": "Modifier l'employé",
            "gift_expires_on": "Expire le",
            "default_confirm_message": "Êtes-vous sûr de vouloir effectuer cette action ?",
            "recipient_type": "Ajouter des bénéficiaires",
            "add_all_teams": "Ajouter toutes les équipes",
            "personalize": "Personnaliser",
            "edit_occasion_by_drag_and_drop": "Vous pouvez modifier l'ordre des éléments en les faisant glisser.",
            "home_delivery_fee": "Frais de livraison à domicile",
            "home_delivery_address": "Adresse du domicile",
            "total_home_delivery_fee": "Coût total de la livraison à domicile",
            "no_shipping_address_when_home_delivery_is_enabled": "Votre commande sera expédiée à l'adresse personnelle des destinataires, il n'est donc pas possible de fournir une adresse de livraison générale.",
            "home_delivery_tag": "Livraison à domicile",
            "no_shipping_address_when_order_is_digital_only": "Votre commande contient uniquement les cartes cadeaux numériques, vous ne pouvez donc pas fournir d'adresse de livraison générale.",
            "view_all": "Voir tout",
            "no_home_delivery_for_card_must_choose_card_envelope": "Si vous souhaitez envoyer vos chèques cadeaux avec une carte à des domiciles, veuillez choisir l'option carte avec enveloppe",
            "selected_count": "+{count} sélectionnées",
            "apply": "Appliquer",
            "save_and_send": "Enregistrer et envoyer",
            "close_panel": "Fermer le panneau",
            "add_all_employees": "Ajouter tout les employés",
            "sendcloud_parcel_id": "Sendcloud Parcel ID",
            "tracking_number": "Numéro de suivi",
            "tracking_url": "URL de suivi",
            "are-you-sure": "Es-tu sûr?",
            "via_kudos": "Via Kudos",
            "kudos_campaign": "Kudos",
            "kudos_campaign_explanation": "Pour définir le montant du bon et d'autres détails concernant les destinataires de Kudos, utilisez le bouton \"Modifier les destinataires\" ci-dessous.",
            "kudos_tag": "Kudos",
            "sign-quotation": "Dessiner un devis",
            "place-order": "Confirmer la commande",
            "processing-order": "Commande en cours de traitement",
            "shipping-order": "Commande expédiée",
            "approve-invoice": "Consulter la facture",
            "contact_us": "Nous contacter",
            "having_trouble": "Vous avez des problèmes ?",
            "another_vendor_in_cart": "Veuillez d'abord compléter votre commande de cadeaux de {name} avant de commander des cadeaux d'un autre vendeur.",
            "open_cart": "Voir le panier d'achat",
            "vendor_about": "A propos de",
            "vendor_discover": "Découvrez {name}",
            "send_message": "Envoyez message",
            "help": "Aide",
            "order_info": "Info de commande",
            "price_vat": "Prix TVA",
            "total_items": "Articles au total",
            "total_vat": "TVA totale",
            "handling_fee_vat": "Frais de traitement TVA",
            "invoice_details": "Détails de la facture",
            "digital_vouchers": "Chèques-cadeaux numériques",
            "excel": "Excel",
            "download_excel": "Télécharger le fichier excel avec les chèques cadeaux",
            "track_and_trace": "Track & trace",
            "select_all": "Sélectionnez tout",
            "select_all_matching": "Sélectionnez toutes les correspondances",
            "vendor_greeting_cards": "Cartes de vœux avec personnalisation",
            "short_address": "Adresse (abrégée)",
            "option_price": "Prix:",
            "confirm_deletion": "Êtes-vous sûr de vouloir supprimer {item} ?",
            "gift_occasions_for": "{occasion}",
            "amount-of-choice": "Montant de votre choix",
            "load_more_gifts": "Chargez plus de cadeaux",
            "tbd": "Calculé à l'étape suivante",
            "select_address_to_calculate_shipping": "Veuillez sélectionner une adresse pour calculer les frais d'expédition.",
            "delete": "Effacer",
            "default_confirm_title": "Es-tu sûr?",
            "checkout-tbd": "Calculé après la définition de l'adresse de livraison",
            "please_wait": "Veuillez patienter",
            "breadcrumb": "Miette de pain",
            "another_directly_to_recipient_in_cart": "Vous ne pouvez pas modifier cette option car vous avez d'autres cadeaux dans votre panier.",
            "view_gifts": "Voir les cadeaux",
            "continue_shopping": "Poursuivre les achats",
            "view_cart": "Voir le panier",
            "please_enter_shipping_address": "Veuillez d'abord sélectionner ou saisir une adresse.",
            "discounted_handling_fee": "Réduction sur extras et frais",
            "continue": "Continuer",
            "landing-page": {
                "discover-kadonation": "Découvrez Kadonation",
                "sign-in-to-kadonation": "Connectez-vous à Kadonation Select"
            },
            "LOAD-ORDER-ITEMS": "Charger des articles plus anciens",
            "credits_used": "Crédits utilisés",
            "vendor_order_items_summary": "Aperçu des articles commandés",
            "vendor_kadonation_vouchers": "Chèque cadeaux de Kadonation",
            "vendor_merged_cards_front_back_kadonation_vouchers": "Fichiers d'impression pour les bons de kadonation",
            "vendor_merged_cards_front_kadonation_vouchers": "Fichiers d'impression pour les bons de kadonation pré-imprimés",
            "open": "Ouvrir",
            "of": "ou",
            "note": "Note",
            "vendor_merged_cards_greeting_cards": "Fichiers d'impression pour les cartes de vœux de kadonation pré-imprimés",
            "vendor_merged_digital_cards_greeting_cards": "Fichiers d'impression pour les cartes de vœux de kadonation",
            "saving": "Enregistrer...",
            "delete_delivery_address": "Supprimer l'adresse",
            "add": "Ajouter",
            "read_more": "Lire la suite",
            "read_less": "Lire moins",
            "address_from_vat_message": "Lorsqu'un numéro de TVA correct est indiqué, nous récupérons automatiquement l'adresse et la remplissons pour vous.",
            "edit_personalization": "Modifier la personnalisation",
            "created": "Créé",
            "mass_update_message_description": "Remplissez le message que vous voulez ajouter à vos destinataires sélectionnés et cliquez sur \"mise à jour\" pour l'enregistrer.",
            "page": "page",
            "download_personalization_file": "Télécharger le fichier",
            "show_details": "Afficher les détails",
            "hide_details": "Cacher les détails",
            "personalization_file": "Fichier de personnalisation du cadeau",
            "cannot_reset_because_saml_enabled": "Les options de réinitialisation du mot de passe ne sont pas disponibles car vous vous authentifiez via un service externe (tel que Google, Microsoft, etc.)",
            "no-invoices": "Aucune facture trouvée.",
            "top_info_bar_text": "false",
            "and": "et",
            "monday": "Lundi",
            "tuesday": "Mardi",
            "wednesday": "Mercredi",
            "thursday": "Jeudi",
            "friday": "Vendredi",
            "order_can_not_be_shipped_no_delivery_day": "Aucune date de livraison disponible n'a été trouvée, veuillez contacter notre équipe de service à la clientèle afin qu'elle puisse résoudre ce problème.",
            "generating_download_personalization_file": "Génération du fichier...",
            "resend_welcome_email": "Renvoyer l'e-mail de Bienvenue",
            "actions_unavailable_because_tenancy_disabled_for_tenant": "Les actions ne sont pas disponibles car le locataire n'est pas un client KDN Select.",
            "resend_welcome_email_confirmation": "Voulez-vous vraiment envoyer l'e-mail de bienvenue à l'utilisateur ?",
            "reset_password_confirmation": "Voulez-vous vraiment envoyer un e-mail de réinitialisation du mot de passe à l'utilisateur ?",
            "date_set_on_recipient": "La date est fixée aux destinataires",
            "address_to": "Adressé à",
            "order_can_not_be_shipped_no_countries": "Aucun pays n'est disponible pour l'expédition de vos cadeaux dans le panier. Veuillez vérifier chaque cadeau pour voir s'il est expédié dans le pays de votre choix.",
            "expected_delivery_date": "Date prévue de livraison",
            "saturday": "Samedi",
            "sunday": "Dimanche",
            "delivery_date_parse_failed": "Erreur dans l'analyse de la date de livraison!",
            "quote": "Citation",
            "vendor_greeting_cards_custom_link": "Cartes de vœux personnalisées avec personnalisation",
            "copied": "Copié",
            "full_name": "Nom",
            "full-name": "Nom",
            "delete-user": "Supprimer l'utilisateur",
            "subscription": "Abonnement",
            "no_vendor_greeting_cards": "PAS DE CARTES DE VOEUX",
            "or": "Ou",
            "keep-account-h2": "Conservez Votre Compte Actif",
            "keep-account": "Conserver Mon Compte",
            "you": "Vous"
        },
        "calendar": {
            "previous_month": "Mois précédent",
            "today": "Aujourd'hui",
            "next_month": "Mois prochain",
            "upcoming_events": "Événements à venir",
            "events": {
                "place_order": "Trouver un cadeau",
                "nothing_planned": "Rien de prévu",
                "no_employees_title": "N'oubliez jamais un jour spécial",
                "no_employees_description": "Ajoutez des employés à Select et consultez leurs anniversaires de naissance et de travail dans cette liste.",
                "add_employees_button": "Ajouter des employés"
            }
        },
        "automation": {
            "automations": "Cadeaux automagiques",
            "no_automation_created_yet": "Aucun cadeau automatique n'a encore été créé, sélectionnez un modèle pour mettre en place un cadeau automatique.",
            "automation_name": "Cadeau automagique",
            "expires_on": "Expire le",
            "never": "jamais",
            "delete_confirmation_title": "Supprimer le cadeau automatique",
            "delete_confirmation_message": "Êtes-vous sûr de vouloir supprimer cette automatisation ? Cette action ne peut pas être annulée.",
            "employee_birthday_condition_text": "Souhaitez un joyeux anniversaire à tous les membres de votre personnel et n’oubliez personne!",
            "employee_work_anniversary_condition_text": "Ne laissez jamais passer un autre anniversaire professionnel, vous décidez de fêter chaque anniversaire professionnel ou certains anniversaires professionnels fixes.",
            "description_text": "C’est encore plus simple d’offrir des cadeaux ! Grâce au service automatique de cadeaux de Kadonation Select, vous exploitez chaque occasion de montrer votre reconnaissance. En y associant votre fichier du personnel, vous célébrez l’anniversaire (de travail) de vos collaborateurs sans oublier qui que ce soit en ce jour spécial.",
            "birthday_template_title": "Cadeaux d'anniversaire automagiques",
            "work_anniversary_template_title": "Cadeaux d'anniversaire de travail automatiques",
            "birthday_template_cta": "Démarrage de l'installation",
            "work_anniversary_template_cta": "Démarrage de l'installation",
            "no_admin_found": "(Aucun utilisateur admin trouvé !)",
            "non_admin_modal_title": "Vous voulez mettre en place une automatisation?",
            "non_admin_modal_description": "Seuls les utilisateurs \"Admin\" peuvent créer et modifier des automations. Vous trouverez ci-dessous la liste des \"utilisateur Admin\" de votre entreprise. Contactez cette personne avec votre demande de création d'une automatisation et elle s'en chargera.",
            "automations_features_title": "Outre la fonction d'automatisation, vous pourrez aussi:",
            "no_employees_set_description": "Afin d'utiliser la fonction d'automatisation, vous devez d'abord ajouter des employés. La fonction \"Employés\" stocke les détails de vos employés pour un accès facile dans Kadonation Select. L'ajout d'employés est facile, il suffit de se connecter à votre système RH ou de télécharger un fichier Excel.",
            "automations_feature_items": "Commandez des cadeaux avec des adresses et des détails déjà remplis |\nCréer des campagnes Kudos pour ajouter des messages d'équipe pour le destinataire d'un cadeau |\nRecevoir des rappels d'anniversaires et d'anniversaires de travail (à venir)",
            "no_automations_set_title": "Aucune automatisation n'a encore été créée",
            "no_automations_set_description": "Utilisez l’un de nos modèles existants.",
            "what_are_automations": "Que sont les automatismes ?",
            "employees_required": "Veuillez sélectionner au moins un employé pour passer à l'étape suivante",
            "blocked_modal_for_payment": {
                "title": "Automatiser l'envoi de cadeaux",
                "description": "L'envoi de cadeaux via des automatismes est actuellement bloqué pour votre compte. Cette fonction sera débloquée lorsque nous aurons vérifié la situation financière de votre entreprise. Contactez-nous pour accélérer le processus."
            }
        },
        "export": {
            "from": "De",
            "to": "À",
            "message": "Message",
            "language": "Langue du destinataire",
            "detailed_reference": "Référence détaillée",
            "amount": "Montant",
            "order_template": "Modèle de commande",
            "email": "Email",
            "delivery_date": "Date de livraison",
            "first_name": "Prénom",
            "last_name": "Nom de famille",
            "locale": "Langue",
            "team": "Équipe",
            "employees_template": "employés_import",
            "delivery_time": "Heure de livraison",
            "kudos_user_uuid": "ID de l'utilisateur Kudos",
            "kudos_campaign": "Campagne kudos",
            "kudos_campaign_employees": "Employés de la campagne kudos",
            "date_of_birth": "Date de naissance",
            "company_entity": "Entité de l'entreprise",
            "department": "Département",
            "date_of_employment": "Date d'embauche",
            "street": "Rue",
            "street_number": "Numéro",
            "bus_2": "Boîte",
            "zip_code": "Code postal",
            "city_2": "Ville",
            "country": "Pays",
            "row": "Ligne {row}",
            "external_employee_id": "ID d'employé externe",
            "bus": "Boîte",
            "city": "Ville",
            "select_all_matching": "Sélectionner tout ce qui correspond",
            "select_all": "Sélectionner tout",
            "manager_id": "Manager/chef d'équipe adresse email",
            "addressed_to": "Adressé à",
            "recipient_first_name": "Prénom du destinataire",
            "recipient_last_name": "Nom de famille du destinataire",
            "explainer": {
                "red-columns-are-mandatory": "- Les colonnes rouges sont obligatoires.",
                "dropdown-options-only": "- Pour les colonnes déroulantes, seules les options de la liste déroulante sont autorisées.",
                "column": "Colonne",
                "instructions": "Instructions",
                "amount": "Valeur du chèque. Les décimales ne sont pas possibles.",
                "message": "Message personnel à votre destinataire. Imprimé sur la carte. Maximum 600 caractères. Chaque \"entrée\" compte pour 40 caractères. Les émojis ne sont pas supportés.",
                "language-beneficiary": "Détermine la langue du texte que Kadonation ajoute sur le bon/la carte. Sélectionnez une option dans le menu déroulant.",
                "email-delivery": "Adresse électronique du destinataire. Nous enverrons le chèque à cette adresse.",
                "email-track-and-trace": "Adresse électronique du destinataire. Nous enverrons les notifications de suivi à cette adresse.",
                "recipient-name": "Imprimé sur l'étiquette d'expédition. Maximum 30 caractères au total.",
                "street": "N'a pas de limite de caractères.",
                "street_number": "Limite de 8 caractères.",
                "bus": "Max 4 caractères. Le mot \"Bus\" est automatiquement ajouté à la suite.",
                "city": "Limite de 30 caractères.",
                "country": "Sélectionnez dans le menu déroulant. Si un pays est absent, cela signifie que nous ne pouvons pas expédier le cadeau dans ce pays.",
                "delivery_date": "Date à laquelle nous devons livrer le cadeau. Cette date doit être au format : jour/mois/année (par exemple : 25/03/2022).",
                "delivery_time": "Heure à laquelle nous devons délivrer le chèque. Cette heure doit être au format : heures{minutes} (par exemple : 09{30}).",
                "delivery_date_voucher": "Date à laquelle nous devons livrer le chèque. Cette date doit être au format : jour/mois/année (par exemple : 25/03/2022).",
                "detailed_reference": "Utilisé pour les détails administratifs. Cela dépend de la politique de votre entreprise en matière de dons.",
                "from": "Indique au destinataire la provenance du cadeau. Imprimé sur le chèque. Maximum 60 caractères.",
                "addressed_to": "Nom du bénéficiaire. Imprimé sur le chèque/la carte. Maximum 60 caractères.",
                "message-voucher": "Message personnel à votre destinataire. Imprimé sur le chèque. Maximum 300 caractères. Chaque \"entrée\" compte pour 40 caractères. Les émojis ne sont pas supportés."
            },
            "recipient-name": "Nom du bénéficiaire",
            "alias": {
                "order": {
                    "addressed_to": "À, A",
                    "language": "Langue du destinataire, langue",
                    "bus": "Boîte",
                    "city": "Ville"
                }
            }
        },
        "statuses": {
            "order": {
                "received": "Reçu",
                "generated": "Généré",
                "shipped": "Expédié",
                "cancelled": "Annulé",
                "generating": "Générer",
                "generation-failed": "Génération échouée",
                "pending-approval": "En attente d'approbation",
                "rejected": "Rejeté",
                "draft": "Brouillon",
                "invoiced": "Facturé",
                "quotation-sent": "Devis envoyé",
                "quotation-signed": "Devis signée",
                "quotation-verified": "Devis vérifié",
                "ready-to-review": "Prêt pour la révision",
                "scheduled-for-fulfillment": "Prévu pour l'exécution",
                "in-fulfillment": "Dans l'exécution",
                "sending-to-fulfillment": "De l'envoi à l'exécution",
                "in-cart": "En panier",
                "pending-sales-approval": "En attente de l'approbation de la Kadonation",
                "pending-payment": "Paiement en attente"
            },
            "invoice": {
                "draft": "Projet",
                "proforma": "Proforma",
                "pending": "En attente",
                "final": "Final",
                "rejected": "Rejeté",
                "cancelled": "Annulé"
            },
            "order-quotation": {
                "awaiting-vendor-approval": "En attente d'approbation",
                "vendor-accepted-request": "Demande acceptée par vendeur",
                "vendor-rejected-request": "Demande rejetée par vendeur"
            },
            "order-item": {
                "generated": "Géneré"
            },
            "order-item-message": {
                "shipped": "Envoyé"
            },
            "quotation-section": {
                "awaiting-vendor-approval": "En attente d'approbation",
                "vendor-accepted-request": "Accepté",
                "vendor-rejected-request": "Rejeté"
            },
            "quotation": {
                "draft": "Brouillon",
                "quotation-sent": "Envoyé au client",
                "quotation-signed": "Signé",
                "quotation-verified": "Approuvé",
                "quotation-rejected": "Rejeté",
                "awaiting-vendor-approval": "En attente d'approbation des vendeurs",
                "vendor-rejected-request": "Refus partiel du vendeur",
                "all-vendors-accepted": "Approbation complète du vendeur",
                "canceled": "devis annulé"
            }
        },
        "mails": {
            "vendor_order_mail_part_of_the_order": "(part of Order #{originalOrderId})",
            "vendor_order_mail_part_of_the_order_section": "<strong>Order Information</strong>\n</br>\n<ul style=\"list-style-type: none; padding-right: 0; padding-left: 0;\">\n<li>Order ID: {orderId}</li>\n<li>Main Order ID: {originalOrderId}</li>\n</ul>\n</br>"
        },
        "gifts_v2": {
            "placeholder": "Placeholder",
            "excluding_vat": "(hors TVA)",
            "amount_of_choice": "Quantité de choix",
            "available_until": "Disponible jusqu'à",
            "order_now_to_receive_by": "Commandez maintenant pour recevoir par",
            "physical_delivery_to_someone": "Livraison physique à votre bureau ou à vos destinataires",
            "more_about_shipping": "Plus d'informations sur la livraison",
            "made_locally": "Fabriqué localement",
            "sustainable": "Durable",
            "price_free": "Gratuit",
            "order_before_date_delivered_on_date": "Commande avant {orderDate}, livrée {deliveryDate}",
            "free_download_or_email_recipients": "Téléchargement instantané et gratuit, ou envoi par courriel aux destinataires",
            "option": "Option",
            "voucher_type": "Type de chèque",
            "request_quote": "Demande de devis",
            "quantity_min_error_message": "La quantité ne peut être inférieure à {quantity}",
            "quantity_max_error_message": "La quantité ne peut être supérieure à {quantity}",
            "budget_default_blocking_message": "Le prix unitaire ne peut être supérieur à {limit} pour cette occasion.",
            "back_to_top": "Haut de page",
            "ordering_for_campaign": "Vous passez commande pour la campagne Kudos {campaign}",
            "choose_recipients_and_personalize": "Choisir les destinataires et personnaliser",
            "add_to_favorite": "Ajouter à la sélection préférée",
            "remove_from_favorite": "Ajouter/supprimer un cadeau de votre sélection préférée",
            "gift_is_customizable": "Ce cadeau est personnalisable",
            "edit_colorlab_personalization": "Modifier la personnalisation",
            "start_colorlab_personalization": "Personnaliser le cadeau",
            "gift_without_customization_confirmation_title": "Pas de personnalisation ajoutée",
            "gift_without_customization_confirmation_description": "Vous n'avez pas personnalisé ce cadeau, êtes-vous sûr de vouloir continuer sans personnalisation ?",
            "add_customization": "Ajouter la personnalisation",
            "services": {
                "your_message_on_card": "Ajoutez votre message et nous l'imprimerons sur la carte",
                "customize_card": "Personnalisez la carte en fonction de votre marque (<a href=\"{mailTo}\" class=\"underline\">contactez-nous</a>) !",
                "less_response_time": "Contactez-nous pour obtenir une aide rapide pour passer votre commande",
                "free_personalization_for_voucher": "Votre logo et votre message ajoutés gratuitement au chèque-cadeau",
                "voucher_can_be_used_at": "Le chèque cadeau peut être utilisé chez plus de <a href=\"https://kadonation.com/be-fr/partenaires\" class=\"underline\" target=\"_blank\">80 partenaires</a>",
                "customize_voucher": "Personnalisez la chèque cadeau à votre image (<a href=\"{mailTo}\" class=\"underline\">contactez-nous</a>) !",
                "free_card_with_gift": "Une carte peur ëtre ajoutée à chaque cadeau, avec votre message",
                "ship_to_office_or_directly": "Envoi au bureau ou directement au destinataire"
            },
            "description": "Description",
            "contents": "Contenu",
            "product_specification": "Spécifications du cadeau",
            "shipping_info": "Informations sur l'expédition",
            "ordering_info": "Informations sur les commandes",
            "jump_to": "Aller à :",
            "sustainability": "Durabilité",
            "article_number": "Numéro d'article",
            "country_of_origin": "Pays d'origine",
            "shelf_life": "Durée de conservation",
            "sizing": "Taille",
            "length": "Longueur",
            "width": "Largeur",
            "height": "Hauteur",
            "weight": "Poids",
            "allergens": "Allergènes",
            "gift_is_shipped_from": "Ce cadeau est expédié à partir de",
            "delivery_time": "Délai de livraison",
            "delivery_cost": "Frais de livraison",
            "minimum_maximum_order_quantities": "Quantités minimales/maximales de commande",
            "minimum_order_quantity": "Minimum",
            "price_matrix_info": "Remises sur volume",
            "maximum_order_quantity": "Maximum",
            "selected_option": "Option sélectionnée",
            "change_option": "Modifier l'option",
            "customization": "Personnalisation",
            "change_customization": "Modifier la personnalisation",
            "customized": "Personnalisé",
            "not_customized": "Pas encore personnalisé",
            "costs": "Prix",
            "voucher_value": "Valeur du chèque",
            "price_per_item": "Prix / piece",
            "handling_fee": "Extras et frais",
            "view_gift_details": "Voir les détails du cadeau",
            "go_back_to_gift": "Retourner au cadeau",
            "add_to_cart": "Ajouter au panier",
            "add_recipients": "Destinataires et personnalisation",
            "add_recipients_description": "La commande peut être livrée à une adresse ou directement aux destinataires.",
            "enter_address_first": "Veuillez d'abord saisir une adresse",
            "delivery_time_title": "{range} pieces",
            "delivery_time_condition": "{days} jour ouvrable (si commandé avant {cutoff})|{days} jours ouvrables (si commandé avant {cutoff})",
            "price_will_be_charged_for_each_gift_by_vendor": "Les frais de livraison seront facturés pour chaque cadeau ajouté, car les cadeaux sont emballés et expédiés séparément.",
            "request_quote_for_unavailable_gift": "Ce cadeau n'est actuellement pas disponible à la commande via Select. Si vous souhaitez commander ce cadeau, vous pouvez demander un devis.",
            "go_to_cart_finish_order": "Voir le panier",
            "multi_vendor_warning_title": "Vous ne pouvez pas combiner des cadeaux de différents vendeurs dans une même commande.",
            "multi_vendor_warning_description": "<p>Vous avez déjà un cadeau dans votre panier provenant d'un autre vendeur que le vendeur de ce cadeau. One order can contain only gifts from one specific vendor. Poursuivez en sélectionnant l'une des options ci-dessous :</p>\n<ul style=\"margin-top: 12px\">\n<li>Aller au panier et terminer la commande en cours</li>\n<li>Vider le panier et ajouter ce cadeau</li>\n<li>Fermer ceci et ne rien faire</li>\n</ul>",
            "close_modal_do_nothing": "Fermer cet avertissement et ne rien faire",
            "clear_cart_and_add_gift": "Vider le panier et ajouter ce cadeau",
            "set_voucher_value_to_add_gift": "Veuillez indiquer une valeur de chèque cadeau pour continuer.",
            "additional_delivery_time_for_country": "(ajouter {days} jours ouvrable au délai de livraison)",
            "can_be_combined_with_voucher": "Ce cadeau peut être combiné avec un chèque-cadeau,",
            "can_be_combined_with_voucher_contact_us": "contactez-nous pour commander",
            "need_something_custom_get_quote": "Commande importante ? Besoin de quelque chose de personnalisé ? Demandez un devis ici",
            "which_occasion_to_place_order": "Pour quelle occasion ce cadeau est-il offert ?",
            "select_occasion": "Choisir l'occasion",
            "country_delivery_condition": "{cost} (ajouter {days} jours ouvrable au délai de livraison)",
            "cost_text": "(En fonction de la quantité achetée)",
            "bus_help_text": "Le mot \"Bus\" sera ajouté par nos soins ultérieurement.",
            "gifts_found": "Aucun cadeau trouvé | 1 cadeau trouvé | {count} cadeaux trouvés",
            "no_gifts_found": "Aucun cadeau trouvé",
            "no_gifts_found_description": "Aucun cadeau n'a été trouvé. Veuillez supprimer les filtres pour continuer à naviguer.",
            "favorited_gifts_for_occasion": "Cadeaux préférés pour cette occasion",
            "favorited_gifts_for_occasion_description": "Découvrez les cadeaux favoris, soigneusement sélectionnés par votre entreprise pour rendre cette occasion encore plus spéciale.",
            "view_all_gifts": "Voir tous les cadeaux",
            "no_favorited_gifts_found": "Aucun cadeau trouvé",
            "no_favorited_gifts_found_description": "Aucun cadeau préféré n'a été trouvé pour cette occasion. Veuillez contacter votre administrateur pour les configurer afin de pouvoir continuer à commander.",
            "all_gifts_for_occasion": "Tous les cadeaux pour cette occasion",
            "packaging_is_customizable": "L'emballage de ce cadeau peut être personnalisé",
            "product_is_customizable": "Le design de ce cadeau peut être personnalisé",
            "start_colorlab_personalization_packaging": "Personnaliser l'emballage",
            "start_colorlab_personalization_product": "Personnaliser le cadeau",
            "modals": {
                "direct_future_delivery_item_in_cart": {
                    "title": "Impossible d'ajouter un cadeau au panier",
                    "buttons": {
                        "close": "Fermer cet avertissement",
                        "go_to_cart": "Voir le panier"
                    },
                    "description": "Vous avez ajouté un cadeau à votre panier et vous avez fixé une date de livraison par destinataire. Ce cadeau n'est pas cumulable avec d'autres cadeaux. Veuillez d'abord passer la commande du cadeau dans votre panier."
                },
                "cannot_add_future_delivery_gift": {
                    "title": "Impossible d'ajouter un cadeau au panier",
                    "description": "Vous essayez d'ajouter à votre panier un cadeau dont la date de livraison est fixée par destinataire. Ce cadeau ne peut pas être combiné avec d'autres cadeaux. Veuillez d'abord passer la commande du cadeau dans votre panier.",
                    "buttons": {
                        "close": "Fermer cet avertissement",
                        "go_to_cart": "Voir le panier"
                    }
                },
                "set_occasion": {
                    "title": "Sélectionner une occasion",
                    "explainer": "Faites-nous savoir pour quelle occasion vous achetez ce cadeau.",
                    "buttons": {
                        "save": "Enregistrer et ajouter au panier"
                    }
                },
                "personalization_not_set": {
                    "title": "Personnalisation des Paramètres Recommandée",
                    "buttons": {
                        "add_to_cart": "Ajouter au panier"
                    },
                    "no_personalization_message": {
                        "explainer": "Pour offrir la meilleure expérience à vos destinataires, nous vous recommandons de définir un message personnel."
                    },
                    "no_contributors_added": {
                        "explainer": "Vous avez activé l'option de \"numérisation\", mais aucun contributeur n'a été ajouté."
                    }
                }
            },
            "regional_logic_title": "Livraison dans ces régions",
            "gift_can_only_be_shipped_to_this_regions": "Vérifiez que la région où vous souhaitez envoyer votre cadeau est incluse dans cette liste, car ce cadeau peut ne pas être expédié dans toutes les régions d'un pays donné.",
            "all_regions": "Toutes les régions",
            "more_about_shipping_per_region": "Livraison uniquement dans certaines régions",
            "add-vat-number-and-invoice-address-modal": {
                "vat-number-help-text": "<a href=\"{mailTo}\">Contactez-nous</a> si vous n'avez pas de numéro de TVA."
            },
            "add_vat_number_and_invoice_address": {
                "title": "Définir le numéro de TVA et l'adresse",
                "description": "Pour passer des commandes sur Select, vous devez d'abord ajouter votre numéro de TVA et votre adresse de facturation. Ces informations sont nécessaires pour pouvoir créer votre facture."
            },
            "preferred_gifts": {
                "add_modal_title": "Ajouter aux cadeaux préférés",
                "add_modal_description": "Les cadeaux préférés s'affichent en haut de la page lorsque vous parcourez les cadeaux pour une occasion. Vous pouvez définir chaque cadeau comme préféré pour plusieurs occasions. Seules les occasions que vous avez activées apparaissent dans la liste ci-dessous."
            },
            "preferred-gifts-updated": "Mise à jour des cadeaux préférés.",
            "packaging_and_product_are_customizable": "L'emballage et le cadeau peuvent être personnalisés.",
            "start_colorlab_personalization_packaging_and_product": "Personnalisez l'emballage et le cadeau",
            "voucher": {
                "delivery_radio": {
                    "direct_to_recipient": {
                        "label": "Envoyez le(s) bon(s) directement au(x) destinataire(s)",
                        "explainer": "Vous définirez les adresses e-mail pour chaque destinataire ici."
                    },
                    "office": {
                        "label": "Envoyez le(s) bon(s) à ma boîte de réception",
                        "explainer": "Nous l'enverrons à l'adresse e-mail de votre compte."
                    }
                },
                "digitize_message_toggle": {
                    "title": "Invitez des personnes à ajouter des messages personnels.",
                    "explainer": "Lors de l'échange du bon, le destinataire verra une page où tous les messages personels seront affichés."
                }
            },
            "physical": {
                "delivery_radio": {
                    "direct_to_recipient": {
                        "label": "Envoyez le(s) cadeau(x) directement au(x) destinataire(s).",
                        "explainer": "Vous définirez ici les adresses pour chaque destinataire."
                    },
                    "office": {
                        "label": "Envoyez le(s) cadeau(x) à un seul endroit (généralement le bureau).",
                        "explainer": "Vous définirez l'adresse lors du paiement."
                    }
                },
                "digitize_message_toggle": {
                    "title": "Invitez d'autre(s) collègue(s) à ajouter un message personnel",
                    "explainer": "Les messages seront disponibles derrière un QR-code sur la carte de vœux."
                }
            },
            "greeting_card_title": "Carte de vœux",
            "greeting_card_description": "Avec Kadonation, vous créez des moments impactants ! Nous croyons qu'un message personnel est tout aussi important que le cadeau. Partagez votre message via une belle carte de vœux et invitez également d'autres personnes à écrire un message.",
            "greeting_card_toggle_title": "Ajoutez une carte de vœux avec votre message personnel (+ {price})",
            "greeting_card_toggle_description": "Vous pouvez ajouter votre message personnel pour la carte ci-dessous.",
            "personalizaiton_disabled_title": "Personnalisation désactivée",
            "personalizaiton_disabled_description": "La personnalisation par destinataire n'est possible que lorsqu'une carte de vœux est ajoutée.",
            "delivery_direct_subtext": "Où ce cadeau doit-il être livré?",
            "delivery": "Livraison",
            "gift_quantity_and_value": "Quantité et montant",
            "gift_details": "Détails du cadeau",
            "pricing": "Prix",
            "unit_price": "Prix unitaire",
            "volume_discount": "Remise sur quantité",
            "gift_quantity": "Quantité",
            "recipients_selected": "Aucun destinataire sélectionné | 1 destinataire sélectionné | {count}\ndestinataires sélectionnés",
            "recipients_uploaded_via_excel": "Aucun destinataire téléchargé via excel | 1 destinataire téléchargé via excel | {count} destinataires téléchargés via excel",
            "delivery_type": {
                "directly_to_recipient": "Directement au destinataire",
                "inbox": "Boîte de courriels",
                "single_address": "Adresse unique",
                "title": "Livraison",
                "explainer": "Où ce cadeau doit-il être livré ?"
            },
            "occasion_not_set": "Occasion non définie",
            "select_employees_as_recipients": {
                "title": "Sélectionnez des employés comme destinataires",
                "explanation": "Sélectionnez les employés que vous avez ajoutés à Select. Vous pouvez également l'utiliser pour ajouter manuellement plusieurs destinataires."
            },
            "save_recipients_before_adding_to_cart": "Veuillez d'abord enregistrer les destinataires pour ajouter le cadeau au panier",
            "pricing_empty_no_recipient_selected": "Aucun destinataire(s) sélectionné(s) pour l'instant",
            "recipient_country_missing": "Pays manquant",
            "shipping_fee_calculated_at_checkout": "Calculé lors du paiement",
            "recipient_details": "Détails du destinataire",
            "recipient_address": "Adresse du destinataire",
            "upload_excel_file": "Télécharger le fichier excel",
            "invoice_info": "Informations sur la facture",
            "contributors_added": "1 contributeur ajouté | {count} contributeurs ajoutés",
            "message_contributors": {
                "title": "Messages personnels",
                "explainer": "Ajoutez ici les personnes qui peuvent écrire un message personnalisé. Grâce à un code QR sur la carte jointe, le destinataire pourra lire tous les messages personnels."
            },
            "add_contributors_button": "Ajoutez des personnes",
            "edit_contributors_button": "Modifiez les invités",
            "add_voucher_personal_message_disclaimer": {
                "title": "Invitez des contributeurs",
                "explainer": "Lors de l'échange du chèque-cadeau, le destinataire verra une page où votre message et les autres messages personnels seront affichés."
            },
            "digitize_enabled": {
                "personalize_voucher_subtitle": "Personnalisez le contenu de votre chèque cadeau.",
                "excel_disclaimer": {
                    "title": "Assurez-vous de ne pas avoir invité de destinataires de cadeaux à écrire des messages personnels, car ils recevront également un e-mail pour écrire un message pour eux-mêmes.",
                    "explainer": "Attention aux messages personnels"
                },
                "personalize_greeting_card_subtitle": "Personnalisez la carte de vœux avec un message. Vous pouvez également ajouter votre propre message personnel en vous invitant pour les 'Messages personnels'. Ces messages sont accessibles via un code QR imprimé sur la carte de vœux."
            },
            "add_contributor_manually": "Ajoutez un contributeur manuellement",
            "add_myself_as_contributor": "M'inviter pour un message",
            "message_contributor_explainer_modal": {
                "title": "Comment fonctionnent les 'Messages personnels' ?",
                "explainer": "Avec les 'Messages personnels', vous pouvez inviter d'autres personnes à écrire un message personnel au destinataire du cadeau."
            },
            "save_contributors": "Enregistrer",
            "personal_message_input_length_limit_error": "Votre message dépasse le nombre de caractères autorisés. Veuillez le raccourcir pour en assurer la lisibilité.",
            "vendor_cannot_print_qr_title": "Sur la carte de vœux accompagnant ce cadeau, aucun code QR ne peut être imprimé"
        },
        "api": {
            "key": "Key",
            "secret": "Secret",
            "secret_warning": "Veuillez conserver ce secret dans un endroit sûr, car c'est le seul moment où vous pouvez le voir.",
            "unused_token": "Le jeton n'a jamais été utilisé jusqu'à présent.",
            "token_last_used": "Dernière utilisation chez: {at}",
            "token_expires_at": "Les jetons n'expirent pas automatiquement. Nous vous conseillons d'effectuer une rotation fréquente de vos jetons. Vous pouvez créer ou révoquer des jetons à tout moment.",
            "new_token_placeholder": "Nouveau nom de jeton d'API",
            "too_many_tokens_warning": "Il semble que vous ayez plus d'un jeton d'API pour le moment. Ceci n'est pas recommandé pour des raisons de sécurité. Supprimez les jetons que vous n'utilisez pas."
        },
        "labels": {
            "beta": "Beta",
            "beta-description": "Ce produit est encore en version bêta. Y a-t-il des choses que nous pouvons améliorer ou rencontrez-vous des problèmes pour passer votre commande ? Veuillez nous contacter à l'adresse contact@kadonation.com ou appeler le +32 9 396 32 00."
        },
        "kudos": {
            "input-groups": "Contribution des groupes",
            "create-input-group": "Créer un groupe de contribution",
            "activate": "Activer",
            "deactivate": "Désactiver",
            "click_desired_locale_url": "Copier l'url",
            "order_campaign": "Commander des cadeaux",
            "senders_required": "Veuillez choisir qui peut écrire Kudos à l'étape 2",
            "receivers_required": "Veuillez sélectionner qui reçoit les messages Kudos à l'étape 3",
            "order_step_1": "Étape 1: téléchargez vos employés",
            "order_step_2": "Étape 2 : commande de cadeaux",
            "order_step_1_description": "Téléchargez le fichier excel ci-dessous et remplissez les champs demandés.",
            "order_step_2_description": "Une fois le fichier excel complété, vous pouvez le télécharger dans l'onglet \"via excel\" lors de la commande.",
            "order_already_placed": "Attention: une commande a déjà été passée",
            "order_already_placed_description": "Une commande a déjà été placée pour cette campagne.",
            "place_order": "Passer une commande",
            "edit_campaign": "Modifier la campagne",
            "edit_order": "Ordre de modification",
            "give_feedback": "Donner kudos",
            "search_employee": "Cherche employé",
            "search_colleague": "Pour",
            "select_teams": "Sélectionner les équipes",
            "no_employees_selected": "Aucun employé sélectionné",
            "description_only_two_translation": "Attention : la description de la campagne n'a que deux traductions",
            "name_only_two_translation": "Attention: le nom de la campagne n'a que deux traductions",
            "prefix_only_two_translation": "Attention: le préfixe n'a que deux traductions",
            "email_not_found": "E-mail non trouvé",
            "submit": "Envoyer",
            "campaign_text": "Les Kudos sont des messages positifs partagés par les collègues. Nous affichons ces messages lorsque le destinataire échange le chèque cadeau. Les Kudos font de la réception d'un chèque cadeau une expérience plus personnelle et créent un moment de cohésion d'équipe.",
            "campaign_title": "Campagnes de Kudos",
            "description_only_one_translation": "Attention : description de la campagne n'a qu'une seule traduction",
            "email_found": "Veuillez vérifier votre boîte de réception",
            "invite_text": "Pas besoin de s'inscrire ! Entrez votre adresse électronique dans la case ci-dessous et cliquez sur \"Envoyer\". Vous recevrez alors un lien pour écrire des félicitations à vos collègues pour cette campagne.",
            "prefix_only_one_translation": "Attention : le préfixe e n'a qu'une seule traduction",
            "welcome": "Bienvenue à Kadonation Kudos",
            "name_only_one_translation": "Attention : le nom de la campagne n'a qu'une seule traduction",
            "selected_teams": "Equipes sélectionnées",
            "selected_input_receivers": "Qui reçoit",
            "selected_input_givers": "Qui écrit",
            "use_givers_as_receivers": "Les destinataires sont le même groupe que celui qui écrit Kudos",
            "create_campaign": "Créer une nouvelle campagne",
            "campaign_details": "Info campagne",
            "input_givers": "Qui écrit des messages de kudos?",
            "input_receivers": "Qui est/sont le(s) destinataire(s) de la campagne Kudos ?",
            "campaign_form_description": "Configurez une campagne Kudos en trois étapes simples. À l'étape 1, définissez le nom et la description de la campagne dans au moins une langue. Vous pouvez également spécifier un préfixe tel que \"Merci pour...\" ou \"Merci pour ce souvenir...\" qui sera placé avant chaque message Kudos.",
            "name": "Nom de la campagne",
            "description": "Description",
            "prefix": "Préfixe de Kudo, par ex. \"Merci pour...\"",
            "campaign_form_receivers_description": "Étape 3 : Sélectionner les équipes et/ou les employés individuels pour lesquels des Kudos sont écrits",
            "campaign_form_senders_description": "Etape 2: Sélectionner des équipes et/ou des employés individuels qui peuvent écrire des Kudos",
            "campaigns": "Campagnes",
            "create_employee": "Créer un employé",
            "delete_employee": "Supprimer des employés",
            "employee": "Employé",
            "employees": "Employés",
            "employees_text": "Les employés peuvent être utilisés dans Kudos. Ils n'ont pas accès à Kadonation Select.",
            "empty_employees_text": "Vous n'avez pas encore ajouté d'employés",
            "empty_kudos_text": "Les employés peuvent être utilisés dans Kudos. Ils n'ont pas accès à Kadonation Select.",
            "input_received": "# reçu",
            "input_given": "# écrit",
            "get_url": "Obtenir l'url",
            "no_employees_added": "Aucun employé ajouté",
            "kudos": "Kudos",
            "add_employee": "Ajouter un employé",
            "import_employees": "Importer des employés",
            "you_will_receive_an_email_if_you_have_access_to_this_campaign": "Vous recevrez un e-mail si vous avez accès à cette campagne",
            "add_colleague": "Ecrire un message Kudos",
            "input": {
                "added_colleagues": "Votre messages de Kudos déjà écrit",
                "pending": "Vos campagnes d'ouverture de Kudos",
                "deadline": "Date limite pour l'attribution des Kudos",
                "character-limit": "van de 300 karakters",
                "none-written": "Vous n'avez pas encore écrit de messages de félicitations !",
                "no-one-left": "Vous ne devez pas écrire un message de félicitations pour quelqu'un d'autre !",
                "all_input_given_title": "Super !",
                "all_input_given_text": "Tu as écrit un message de Kudos pour tout le monde ! Si tu le souhaites, tu peux modifier tes messages tant que le délai n'est pas dépassé."
            },
            "employee_message": "Message",
            "senders": "Expéditeurs",
            "receivers": "Destinataires",
            "email_template": "Cher employé\n\nBienvenue à Kadonation Kudos. \nUne nouvelle campagne a été lancée à laquelle vous pouvez participer !\nEnvoyez vos félicitations à vos collègues via le lien ci-dessous.",
            "employees_title": "Employés",
            "email_title": "Texte de l'e-mail",
            "go_campaign_title": "Retour à la vue d'ensemble de la campagne",
            "campaign_links_title": "Liens de la campagne",
            "employees_description": "Copier le texte du modèle de l'e-mail et modifier le si nécessaire",
            "email_description": "Sélectionnez les e-mails des employés en masse",
            "campaign_links_description": "Copier les liens de la campagne pour d'autres canaux, par exemple slack",
            "all_employees": "Tous les employés",
            "en_employees": "Employés anglais",
            "whats_next": "Quelle est la prochaine étape ?",
            "campaign_overview": "Aperçu de la campagne",
            "fr_employees": "Employés Français",
            "nl_employees": "Employés néerlandais",
            "success": "Succès",
            "or": "ou",
            "emailing": "Envoyer un e-mail",
            "campaign_create_success": "Vous avez créé la campagne avec succès",
            "go_to_emailing": "Aller à la page d'envoi de l'e-mail",
            "empty_employees_message": "Vous ne pouvez pas créer une campagne sans employés. Commencez à ajouter ou à importer vos employés.",
            "sign_in_kudos_inactive_message": "Cette campagne n'est pas active !\nLa campagne est peut-être terminée ou doit encore être activée.\nVeuillez consulter le créateur de la campagne si vous avez des questions à ce sujet.",
            "receiver": "Destinataire",
            "sender": "Expéditeur",
            "email": {
                "magic_link_expired": "Le lien est expiré.",
                "email_start": "<div id=\"email-start\">\n      <p>Bonjour <strong>[Prénom] [Nom]</strong></p>\n  </div>",
                "email_end": "<div id=\"email-end\" class=\"my-4\">\n    <p>Cordialement,</p>\n    <p><strong>[Entité de l'entreprise]</strong></p>\n  </div>",
                "email_body": "<div class=\"py-2 opacity-50\" contenteditable=\"false\">\n    <div class=\"flex items-center space-x-2\">\n      <div class=\"w-full h-px border\"></div>\n      <div class=\"text-sm min-w-max uppercase font-bold\">Edit Above the Line</div>\n      <div class=\"w-full h-px border\"></div>\n    </div>\n    <br />\n    <!-- start content -->\n    <div>\n      <p>Vous êtes prêt à distribuer des Kadonation Kudos.</p>\n\t<p>Cliquez sur le lien personnel ci-dessous pour contribuer à la campagne</p><p>Bonne chance !</p>\n\t<div class=\"my-8 flex justify-center\">\n\t\t<span class=\"uppercase text-xs font-semibold text-white bg-pink-300 py-3 px-6 rounded\">\n\t         Donner Kudos\n        \t\t</span>\n\t</div>\n\t<p><strong>Important: pour garantir que d'autres personnes ne puissent pas accèder à votre compte, il est important que vous ne retransmettez pas cet e-mail</strong></p>      </div>\n    <!-- end content -->\n    <div class=\"flex items-center space-x-2\">\n      <div class=\"w-full h-px border\"></div>\n      <div class=\"text-sm min-w-max uppercase font-bold\">Edit Below the Line</div>\n      <div class=\"w-full h-px border\"></div>\n    </div>\n  </div>",
                "locale_emails": "Quand vous sélectionnez cette option, vous pouvez envoyer un message électronique différent pour chaque langue de vos employés.",
                "locale_emails_label": "Utilisez emails différents par langue",
                "general_language": "Langue",
                "subject": "Sujet",
                "subject-value": "Invitation pour {campaignName}",
                "resend_kudo_invite_to_new_people": "Envoyez les invitations aux kudos uniquement aux invités qui n'ont pas encore reçu d'invitation.",
                "send_to_new_people_label": "Renvoyer uniquement aux nouvelles personnes"
            },
            "ordering_message": "You are placing an order for the campaign \"{campaignName}\". Not ready yet?",
            "exit_button": "Arrêt de kudos",
            "stopped_ordering": "Vous ne commandez plus pour la campagne Kudos",
            "download_explanation": "You can now fill in the excel further and use it to order any gift/option. Upon redeem, the messages will be shown to the end user",
            "employee_search_placeholder": "Recherche par nom ou par email",
            "search": "Choisissez un collègue",
            "campaign_email_description": "Étape 4 : Envoyez un email avec le lien vers les Kudos à vos employés. Vous pouvez sauter cette étape si vous le souhaitez. Les mots suivants [Prénom], [Nom], [Nom de la campagne], [Entité de la société] seront remplis automatiquement avec les données correctes par employé.",
            "expired": {
                "btn": "Nieuwe link aanvragen",
                "title": "Oups!",
                "text": "Votre lien pour donner des kudos a expiré, veuillez demander un nouveau lien !"
            },
            "delete_title": "Are you sure you want to delete this campaign?",
            "delete_description": "Doing so means you'll lose all kudos messages. This action can't be undone!",
            "delete_cta": "Delete campaign",
            "activation_description": "Vous pouvez activer et désactiver votre campagne. Lorsque votre campagne de kudos est désactivée, aucun kudo ne peut être donné.",
            "send_campaign": "Envoyer des invitations",
            "tooltip": {
                "deadline": "Lorsque cette date est passée, vos employés ne peuvent plus écrire de messages",
                "prefix": "Utilisez-le pour pousser vos employés dans la bonne direction avec leur message.",
                "campaign_name": "Donnez un nom à cette campagne",
                "description": "Ici, vous pouvez écrire des informations supplémentaires qui seront montrées aux personnes susceptibles d'écrire un kudo, utilisez ceci pour informer les invités de l'opportunité de kudos."
            },
            "not_send_yet": "Cette campagne n'a pas encore été envoyée aux expéditeurs.",
            "kudos_given": "Vous avez déjà écrit des messages de Kudos pour :",
            "hide_message": "Cacher le message",
            "message_added": "Votre message a été enregistré.",
            "message_edited": "Votre message a été modifié",
            "kudos_to_give": "Vous devez encore écrire des messages de Kudos pour :",
            "deadline_has_passed_sending_out_is_disabled": "La date limite pour la campagne de kudo a expiré. Par conséquent, vous ne pouvez pas envoyer la campagne de kudo, veuillez modifier la date limite si vous souhaitez envoyer la campagne.",
            "campaign_status_updated": "La campagne a été mise à jour avec succès",
            "campaign-expired": {
                "title": "Cette campagne de Kudo a expiré.",
                "text": "Veuillez contacter l'organisateur si vous pensez qu'il s'agit d'une erreur. Ils peuvent modifier la date limite et envoyer une nouvelle invitation."
            },
            "cart_not_empty": "Vous avez des cadeaux dans votre panier. Veuillez compléter votre commande en cours avant de commander pour la campagne Kudos.",
            "some_employees_without_email_are_omitted": "Certains employés n'ont pas d'adresse électronique et ne peuvent pas recevoir votre invitation à partager un message personnel. Veuillez vérifier votre liste d'employés et vous assurer que tous les employés ont une adresse e-mail.",
            "what_are_kudos": "Que sont les Kudos?",
            "no_kudos_set_title": "Aucune campagne de Kudos n'a encore été créée",
            "no_kudos_set_description": "Lancez une campagne Kudos, invitez vos collègues et envoyez-vous des messages positifs.",
            "kudos_feature_items": "Commandez des cadeaux avec des adresses et des détails déjà remplis |\nCréer des automatismes de cadeaux d'anniversaire et d'anniversaire de travail |\nRecevoir des rappels d'anniversaires et d'anniversaires de travail (bientôt)",
            "no_employees_set_description": "Pour pouvoir utiliser la fonction Kudos, vous devez d'abord ajouter des employés. La fonction \"Employés\" stocke les détails de vos employés pour un accès facile dans Kadonation Select. L'ajout d'employés est facile, il suffit de se connecter à votre système RH ou de télécharger un fichier Excel.",
            "kudos_features_title": "En plus de la fonction Kudos, vous pourrez également:",
            "create_one_now": "Créez-en un maintenant",
            "kudo_deactivated_sending_out_is_disabled": "Cette campagne n'est pas active. Veuillez activer cette campagne pour envoyer des invitations.",
            "kudos_will_expire_on": "Date limite de remise des Kudos:",
            "edit_kudos_message": "Bewerk kudos bericht",
            "search_recipient": "Recherche d'un destinataire",
            "exit_confirmation_title": "Vous avez des cadeaux dans votre panier pour cette campagne Kudos. Ils seront supprimés de votre panier si vous continuez.",
            "exit_confirmation_message": "Le panier sera vidé",
            "deadline_description": "Lorsque la date limite est atteinte, il n'est plus possible d'introduire des messages Kudos. Cette date limite correspond généralement à la date de remise du cadeau.",
            "feature_removed_modal": {
                "title": "Cette fonctionnalité a été déplacée",
                "description": "Lors de la commande d’un cadeau, vous pouvez désormais inviter d’autres personnes à ajouter des messages personnels. Cela s’applique aussi bien aux bons numériques qu’aux cadeaux physiques. Le destinataire pourra lire ces messages personnels à la réception du cadeau."
            },
            "contributions": {
                "page_title": "Messages",
                "all_messages_delivered": {
                    "title": "Tous vos messages ont été livrés",
                    "explainer": "Nous serions ravis de vous revoir lorsque vous recevrez une nouvelle invitation pour ajouter un message à un cadeau."
                },
                "link_expired": {
                    "title": "Le lien que vous avez utilisé a expiré",
                    "explainer": "Veuillez demander un autre lien en cliquant sur le bouton ci-dessous.",
                    "request_new_link_button": "Demander un nouveau lien"
                },
                "influencing_text_to_add_message": "Un message sincère est tout aussi précieux que le cadeau lui-même et en fait un souvenir inoubliable.",
                "add_message_title": "Écrire un message",
                "written_messages_title": "Messages écrits",
                "written_messages_explainer": "Ceux-ci disparaissent lorsque le destinataire a reçu le cadeau.",
                "go_back_button": "Retour",
                "write_message_for": "Écrivez un message pour",
                "message_input_label": "Message",
                "message_input_placeholder": "Écrire un message",
                "from_input_label": "De",
                "save_message_button": "Enregistrer le message",
                "edit_message_button": "Modifier le message",
                "message_updated_notification": "Le message a été enregistré avec succès.",
                "view_message_button": "Voir",
                "write_message_button": "Écrire",
                "write_message_for_before_date_text": "par",
                "received_messages": {
                    "tap_instructions": {
                        "title": "Appuyez sur l'écran pour parcourir vos messages",
                        "previous": "Message précédent",
                        "next": "Message suivant",
                        "use_bottom_icons": "Ou utilisez ces boutons"
                    },
                    "skip_to_end_button": "Aller à la fin",
                    "page_title": "Félicitations !",
                    "say_thank_you_button": "Remercier",
                    "landing": {
                        "you_have_messages": {
                            "title": "Félicitations !",
                            "explainer": "Vous avez reçu quelques messages qui vous sont spécialement destinés."
                        },
                        "say_thank_you": {
                            "title": "Disez merci à",
                            "explainer": "Exprimez votre gratitude au donateur du cadeau avec un message."
                        },
                        "view_messages": "Voir les messages",
                        "say_thank_you_button": "Remercier"
                    }
                },
                "thank_you_modal": {
                    "thank_you_sent_title": "Votre merci est envoyé",
                    "title": "Dire merci",
                    "thank_you_sent_explainer": "L'expéditeur du cadeau recevra ce message par e-mail. Passez une excellente journée !",
                    "explainer": "La personne qui a passé la commande recevra un e-mail avec votre message de remerciement.",
                    "message_input_label": "Votre message",
                    "close": "Fermer",
                    "say_thanks_button": "Envoyer le message"
                },
                "thank_you_page": {
                    "title": "Dis merci à",
                    "explainer": "Faites-leur savoir que vous appréciez le cadeau en disant merci de la manière qui vous convient.\n\nCertains collègues peuvent encore ajouter un message, car la date limite pour contribuer n’a pas encore été atteinte. Revenez plus tard aujourd’hui ou demain !"
                },
                "rewatch_messages_button": "Revoir",
                "clear_all_contributors_button": "Vider la liste"
            }
        },
        "dashboard": {
            "news": "Nouvelles",
            "spend_report": "Rapport sur les dépenses",
            "no_news": "Pas de nouvelles",
            "no_spend_data": "Aucune commande n'a encore été passée, il n'y a donc pas de données pour un rapport.",
            "trees_planted": "Arbres Plantés",
            "trees_planted_great_job": "Bon travail à vous et à votre équipe !<br/>C'est suffisant pour compenser",
            "learn_more": "En savoir plus",
            "no_trees_planted_message": "Commencez à passer des commandes et voyez l'impact que cela a sur l'environnement grâce à notre programme WeForest.",
            "select_occasion_before_redirect": "Veuillez sélectionner une occasion avant que nous vous redirigions vers le cadeau choisi.",
            "dashboard": "Tableau de bord",
            "change_language": "Changer le langue"
        },
        "quotation": {
            "quotation": "Devis",
            "date": "Date du devis",
            "expiration-date": "Date d'expiration",
            "description": "Description",
            "amount-excl-vat": "Montant (hors TVA)",
            "quantity": "Quantité",
            "vat": "TVA",
            "total-excl-vat": "Total (hors TVA)",
            "net": "Net",
            "total": "Total",
            "created-at": "Créé à",
            "name": "Nom",
            "signature": "Signature",
            "delivery-date": "Date de livraison",
            "invoice-address": "Adresse de facturation",
            "our-terms-can-be-found-on-the-following-page": "L'exécution de cette offre sera régie exclusivement par <a target='_blank' href='https://kadonationselect.com/terms-and-conditions'>les conditions générales</a> et <a target='_blank' href='https://kadonationselect.com/data-processing-agreement'>la politique informatique</a> de Kadonation, telles que vous les trouverez ci-joint. En acceptant/signant cette offre (ou en confirmant cette offre par le biais, par exemple, d'un bon de commande qui s'y réfère), le client déclare avoir pris connaissance des documents susmentionnées et accepte les dispositions qui y sont contenues.",
            "for-agreement": "Par accord",
            "wanted-delivery-date": "Date de livraison souhaitée",
            "created-in-text": "Établi à Gand le {createdAt} en autant d'exemplaires que de parties",
            "ordered-quantity-may-differ": "Le nombre final de chèques-cadeaux commandés peut différer de 10 % du nombre indiqué dans cette offre.",
            "provide_valid_email": "Veuillez saisir une adresse électronique valide.",
            "minimum_one_recipient": "Ajouter au moins un bénéficiaire",
            "missing-delivery-info-title": "Aucune adresse de livraison n'a été ajoutée à la commande",
            "missing-delivery-info-description": "Pour que les frais d'expédition soient calculés correctement, une adresse de livraison doit être ajoutée à la commande. Vous pouvez le faire en vous rendant sur la page de la commande et en cliquant sur l'action \"Add delivery address to order\".",
            "requested-by": "Demandé par",
            "missing-expected-delivery-at-info-text": "This order does not have an expected delivery date set. Beware that this is needed to calculate the expiry date of vouchers!",
            "missing-expected-delivery-at-info-title": "No expected delivery date is set",
            "shipping-price-page": {
                "explanation": "Les frais de port indiqués ci-dessus sont à titre indicatif et peuvent varier. La page suivante contient nos tarifs d'expédition dans un tableau, vous permettant de calculer les coûts par livraison à une adresse de bureau ou à domicile.",
                "heading": "Tableau des tarifs d'expédition",
                "table": {
                    "country": "Pays",
                    "min_gifts": "Min Gifts",
                    "max_gifts": "Max Gifts",
                    "price": "Prix (hors TVA)"
                }
            },
            "delivery_to": "(livraison à : {deliveryType})",
            "office_delivery": "bureau",
            "home_delivery": "destinataire",
            "will-be-delivered-to": "À livrer en",
            "delivery-address": "Adresse de livraison"
        },
        "filter": {
            "price_range": "Prix",
            "min": "Min",
            "max": "Max",
            "category": "Catégorie",
            "countries": "Livraison à",
            "view_more": "Voir {count} plus",
            "view_less": "Voir moins",
            "sort": {
                "popular": "Populaire",
                "new": "Nouveau",
                "recommended": "Recommandé",
                "price_asc": "Prix bas-haut",
                "price_desc": "Prix haut-bas"
            },
            "no_filter_found": "Aucun filtre trouvé",
            "find_filter": "Recherche",
            "customizable": "Personnalisable",
            "next_day_delivery": "Livraison le lendemain",
            "sustainable": "Durable",
            "made_locally": "Fabriqué localement",
            "clear_filters": "Effacer les filtres",
            "boolan_filters": "Type",
            "loading_gifts": "Chargement des cadeaux",
            "price": "Prix",
            "all_price": "Tous les prix",
            "delivery_country": "Livraison à",
            "sort_label": "Trier",
            "delivery_region": "Livraison dans une région spécifique"
        },
        "modals": {
            "departments": {
                "delete_error": {
                    "has_users": {
                        "title": "Impossible de supprimer un département",
                        "message": "Ce département ne peut pas être supprimé, car des utilisateurs y sont associés. Veuillez affecter ces utilisateurs à un autre département avant de le supprimer."
                    },
                    "has_credits": {
                        "title": "Impossible de supprimer un département",
                        "message": "Ce département ne peut pas être supprimé, car il dispose de crédits inutilisés."
                    }
                }
            },
            "users": {
                "deactivate_error": {
                    "has_applicants": {
                        "title": "Impossible de désactiver l'utilisateur",
                        "message": "Cet utilisateur ne peut pas être supprimé, car il est défini comme responsable de l'approbation pour d'autres utilisateurs. Veuillez lui attribuer un autre gestionnaire d'approbation avant de le désactiver."
                    }
                }
            },
            "general": {
                "list": {
                    "has_more": "...et {number} plus"
                }
            },
            "add_to_cart": {
                "no_address": {
                    "title": "Pas d'adresse définie",
                    "message": "Aucune adresse n'a été définie pour l'entité de l'entreprise. Veuillez d'abord l'ajouter, car elle est nécessaire pour passer des commandes.",
                    "buttons": {
                        "confirm": "Définir l'adresse",
                        "cancel": "Fermer"
                    }
                }
            },
            "invoices": {
                "insufficient_credits_to_finalize_proforma": {
                    "title": "Crédits insuffisants",
                    "message": "Les crédits sont insuffisants pour couvrir le coût de certaines commandes. Si vous continuez à finaliser la proforma, le montant restant sera facturé.",
                    "buttons": {
                        "cancel": "Annuler",
                        "confirm": "Finaliser"
                    }
                }
            },
            "delete_department_modal_title": "Supprimer le département ?",
            "delete_address_modal_title": "Supprimer l'adresse ?",
            "clear-cart-modal-title": "Effacer le panier d'achat ?",
            "clear-cart-modal-message": "Êtes-vous sûr de vouloir retirer tous les cadeaux de votre panier ?",
            "confirm_delete_user": {
                "title": "Supprimer cet utilisateur ?",
                "message": "Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
            },
            "upload_logo_title": "Charger le logo",
            "upload_logo_body": "Votre logo sera imprimé sur les chèques-cadeaux et les cartes de vœux."
        },
        "validation": {
            "accepted": "{attribute} doit être accepté.",
            "active_url": "{attribute} n'est pas une URL valide.",
            "after": "{attribute} doit être une date après {date}.",
            "after_or_equal": "{attribute} doit être une date postérieure ou égale à {date}.",
            "alpha": "{attribute} ne peut contenir que des lettres.",
            "alpha_dash": "{attribute} ne peut contenir que des lettres, des chiffres, des tirets et des tirets en bas.",
            "alpha_num": "{attribute} ne peut contenir que des lettres et des chiffres.",
            "array": "{attribute} doit être un tableau.",
            "before": "{attribute} doit être une date antérieure à {date}.",
            "before_or_equal": "{attribute} doit être une date antérieure ou égale à {date}",
            "between": {
                "array": "{attribute} doit comprendre entre {min} et {max} articles.",
                "file": "{attribute} doit faire entre {min} et {max} kilo-octets.",
                "numeric": "{attribute} doit être entre {min} et {max}.",
                "string": "{attribute} doit contenir entre {min} et {max} caractères."
            },
            "boolean": "Le champ '{attribute}' doit être vrai ou faux.",
            "confirmed": "La confirmation {attribute} ne correspond pas.",
            "custom": {
                "attribute-name": {
                    "rule-name": "Message personnel"
                },
                "message": {
                    "max": "Le {attribute} ne peut pas être supérieur à {max} caractères.",
                    "required_with": "Le champ '{attribute}' est obligatoire"
                },
                "from": {
                    "required_with": "Le champ '{attribute}' est obligatoire"
                },
                "to": {
                    "required_with": "Le champ '{attribute}' est obligatoire."
                },
                "option": {
                    "required_without": "Le champ '{attribute}' est nécessaire lorsque {values} n'est pas présent.",
                    "required": "Veuillez sélectionner une option"
                },
                "price": {
                    "required": "Le champ '{attribute}' est obligatoire.",
                    "numeric": "Le champ '{attribute}' doit être numérique.",
                    "required_without": "Veuillez sélectionner ou saisir un montant."
                },
                "excel": {
                    "required_with": "Veuillez télécharger le fichier Excel",
                    "row_error": "Il y a eu une erreur à la ligne {row}. {message}",
                    "message_row": "{message}. Ligne(s) {rows}.",
                    "date_format": "Le format de date fourni n'est pas valide ({attribute}), veuillez formater les dates comme telles : jj/mm/aaaa",
                    "date_in_future": "La date fournie pour {attribute} ne peut pas être dans le futur",
                    "company_entity_not_found": "Entité de l'entreprise introuvable ({attribute})",
                    "department_not_found": "département introuvable ({attribute})",
                    "country_not_found_with_code": "Pays introuvable avec code {attribute}",
                    "external_employee_id": "ID d'employé externe en double !",
                    "attribute_missing": "L'e {attribute} est manquant!",
                    "manager_not_found": "Chef d'équipe/manager n'a pas pu être trouvé."
                },
                "personal_message": {
                    "max": "Les messages personnels ne doivent pas dépasser {max} caractères. Votre message est trop long de {excess} characters. N'oubliez pas qu'un 'enter' compte pour {newline_size} caractères.",
                    "emoji": "Votre message personnel ne doit pas contenir d'emojis ou de caractères formant des emojis. Ils ne peuvent pas être imprimés."
                }
            },
            "date_equals": "{attribute} doit être une date égale à {date}.",
            "date_format": "{attribute} ne correspond pas au format {format}.",
            "different": "Les champs {attribute} et {other} doivent être différents.",
            "digits": "{attribute} doit faire {digits} chiffres.",
            "digits_between": "{attribute} doit faire entre {min} et {max} chiffres.",
            "dimensions": "{attribute} a des dimensions d'image non valables.",
            "distinct": "Le champ '{attribute}' a une valeur double.",
            "email": "{attribute} doit être une adresse mail valable.",
            "ends_with": "{attribute} doit se terminer par l'une des mentions suivantes {values}.",
            "exists": "Le {attribute} séléctionné n'est pas valable.",
            "file": "{attribute} doit être un fichier.",
            "filled": "Le champ '{attribute}' doit avoir une valeur.",
            "gt": {
                "array": "{attribute} doit avoir plus que {value} articles.",
                "file": "{attribute} doit être supérieur à {value} kilo-octets.",
                "numeric": "{attribute} doit être supérieur à {value}.",
                "string": "{attribute} doit être supérieur à {value} caractères."
            },
            "gte": {
                "array": "{attribute} doit avoir {value} articles ou plus.",
                "file": "{attribute} doit être supérieur ou égal à {value} kilo-octets.",
                "numeric": "{attribute} doit être supérieur ou égal à {value}.",
                "string": "{attribute} doit être supérieur ou égal à {value} caractères."
            },
            "image": "{attribute} doit être une image.",
            "in": "Le {attribute} séléctionné n'est pas valable.",
            "in_array": "Le champ '{attribute}' n'existe pas dans {other}.",
            "integer": "{attribute} doit être un nombre entier.",
            "ip": "{attribute} doit être une adresse IP valide.",
            "ipv4": "{attribute} doit être une adresse IPv4 valide.",
            "ipv6": "{attribute} doit être une adresse IPv6 valide.",
            "json": "{attribute} doit être une chaîne JSON valide.",
            "lt": {
                "array": "{attribute} doit avoir moins de {value} articles.",
                "file": "{attribute} doit être inférieur à {value} kilo-octets.",
                "numeric": "doit être inférieur à {value}.",
                "string": "doit être inférieur à {value} caractères."
            },
            "lte": {
                "array": "{attribute} ne doit pas avoir plus de {value} articles.",
                "file": "{attribute} doit être inférieur ou égal à {value} kilo-octets.",
                "numeric": "doit être inférieur ou égal à {value}.",
                "string": "doit être inférieur ou égal à {value} caractères."
            },
            "max": {
                "array": "{attribute} ne peut pas avoir plus de {max} articles.",
                "file": "{attribute} ne peut pas être supérieur à {max} kilo-octets.",
                "numeric": "{attribute} ne peut pas être supérieur à {max}.",
                "string": "{attribute} ne peut pas être supérieur à {max} caractères."
            },
            "mimes": "{attribute} doit être un fichier de type: {values}.",
            "mimetypes": "{attribute} doit être un fichier de type: {values}.",
            "min": {
                "array": "{attribute} doit avoir au moins {min} articles.",
                "file": "{attribute} doit faire au moins {min} kilo-octets.",
                "numeric": "{attribute} doit être au moins {min}.",
                "string": "{attribute} doit avoir au moins {min} caractères."
            },
            "not_in": "le {attribute} sélectionné n'est pas valable.",
            "not_regex": "Le format {attribute} n'est pas valable.",
            "numeric": "{attribute} doit être un numéro.",
            "password": {
                "mixed": "Le {attribute} contenir au moins une lettre majuscule et une lettre minuscule.",
                "letters": "Le {attribute} doit contenir au moins une lettre.",
                "symbols": "Le {attribute} contenir au moins un symbole. Par exemple : @, &, /, etc",
                "numbers": "Le {attribute} contenir au moins un chiffre.",
                "uncompromised": "Le {attribute} donné est apparu dans une fuite de données et n'est pas sûr à utiliser. Veuillez choisir un autre {attribute}."
            },
            "present": "Le champ '{attribute}' doit être présent.",
            "regex": "Le format du {attribute} n'est pas valable. Le mot de passe doit comporter plus de 8 caractères et doit avoir au moins: 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et 1 caractère spécial.",
            "required": "Le champ '{attribute}' est obligatoire.",
            "required_if": "Le champ '{attribute}' est obligatoire lorsque {other} est {value}.",
            "required_unless": "Le champ '{attribute}' est obligatoire, sauf si {other} est en {values}.",
            "required_with": "Le champ '{attribute}' est obligatoire lorsque {values} est présent.",
            "required_with_all": "Le champ '{attribute}' est obligatoire lorsque {values} sont présents.",
            "required_without": "Le champ '{attribute}' est obligatoire lorsque {values} n'est pas présent.",
            "required_without_all": "Le champ '{attribute}' est obligatoire lorsqu'aucune des {values} n'est présente.",
            "same": "{attribute} et {other} doivent correspondre.",
            "size": {
                "array": "{attribute} doit contenir {size} articles.",
                "file": "{attribute} doit contenir {size} kilo-octets.",
                "numeric": "{attribute} doit faire {size}.",
                "string": "{attribute} doit faire {size} caractères."
            },
            "starts_with": "{attribute} doit commencer par l'un des éléments suivants: {values}.",
            "string": "{attribute} doit être une série.",
            "timezone": "{attribute} doit être une fuseau horaire valable.",
            "unique": "{attribute} est déjà pris.",
            "uploaded": "Le téléchargement de {attribute} a échoué.",
            "url": "Le format de {attribute} n'est pas valable.",
            "uuid": "{attribute} doit être un UUID valable.",
            "date": "{attribute} n'est pas une date valide.",
            "tenant_subdomain_in_use": "Tenant subdomain is in use",
            "attributes": {
                "first_name": "prénom",
                "option": "choisissez une option",
                "price": "montant",
                "is_group": "fichier de groupe",
                "department_id": "département",
                "last_name": "nom de famille",
                "message": "message",
                "from": "Le champ 'de'",
                "to": "Le champ 'à'",
                "password": "mot de passe",
                "excel_file": "fichier excel",
                "name": "Nom"
            },
            "message": "Message",
            "custom_dimension": "Image is invalid, Check the file for correct type and dimensions (Less than 150x30px or less than 5{1} ratio).",
            "duplicate_email_addresses": "Adresses e-mail en double dans le fichier ({address})",
            "excel": {
                "amount_required_with_from_to_message": "Le montant est obligatoire"
            },
            "name": {
                "en": "Nom",
                "fr": "Nom",
                "nl": "Nom"
            },
            "file_must_be_excel": "The file must be a valid excelfile",
            "must_have_only_one_option": "Ce cadeau n'accepte qu'une seule option.",
            "no_duplicate_data_is_allowed": "Aucun doublon dans le montant ou le nombre d'années n'est autorisé.",
            "employee_already_in_team": "L'employé est déjà membre de l'équipe précédemment choisie.",
            "year_count_should_be_divisible_by_point_five": "L'{attribute} doit être un nombre entier ou divisible par 0,5.",
            "employee_date_field_must_be_set": "La donnée employee_date_field n'est pas définie pour cette occasion.",
            "invalid_tenant_subdomain": "Sous-domaine invalide. Évitez d'utiliser des espaces, des points et d'autres caractères spéciaux.",
            "invalid_zipcode": "Code postal non valide, veuillez en saisir un valide.",
            "shipping_country_not_supported": "{vendor} ne prend actuellement pas en charge l'expédition à {value}. Veuillez choisir une autre adresse.",
            "default_entity_unset": "Impossible d'annuler la société par défaut. Pour changer, il faut en définir une autre par défaut, et celle-ci sera décochée automatiquement!",
            "unknown_shipping_country": "Nous ne prenons pas en charge l'expédition vers le pays de ce récepteur. ({country})",
            "recipient_name_character_limit_exceeded": "Le nom du destinataire (prénom + nom) peut comporter un maximum de 30 caractères.",
            "tenant_vat_number_does_not_exist": "The VAT Number was checked and does not exist. Please check with the Tenant for their correct VAT Number. You may proceed creating this Tenant by indicating they are not ‘VAT Liable’ (meaning we will charge them VAT always)",
            "phone": "Le champ {attribute} doit être un nombre valide.",
            "company_email": "Veuillez utiliser votre adresse électronique professionnelle pour créer un compte. Les domaines de messagerie tels que gmail, outlook et yahoo ne sont pas autorisés.",
            "unique_email_domain": "Il semble que cette entreprise dispose déjà d'un compte.",
            "gift_cannot_be_send_to_that_zipcode": "Le cadeau ne peut pas être envoyé à cette région ({zipCode}).",
            "gift_with_name_cannot_be_send_to_that_zipcode": "'{giftName}' ne peut pas être envoyé dans cette région ({zipCode}).",
            "credits_payer_required": "Sélectionnez l'origine des crédits.",
            "expiration_period": "The max expiration period is 3 years."
        },
        "auth": {
            "failed": "Les données de connexion ne correspondent pas. Veuillez réessayer.",
            "throttle": "Vous avez essayé de vous connecter trop souvent. Veuillez réessayer dans {seconds} secondes.",
            "2fa_verification": "Vérification 2FA",
            "2fa_continue": "Continuez",
            "2fa_setup": "Configurer la vérification 2FA",
            "2fa_scan_or_code": "Scannez le code-barres ci-dessous ou utilisez le code",
            "2fa_must_setup": "Vous devez configurer votre application Google Authenticator avant de continuer. Sinon, vous ne pourrez pas vous connecter.",
            "2fa_otp": "Le mot de passe à usage unique",
            "2fa_wrong_otp": "Le mot de passe à usage unique tapé était faux.",
            "2fa_cannot_be_empty": "Le mot de passe à usage unique ne peut pas être vide.",
            "2fa_unknown": "Une erreur inconnue s'est produite. Veuillez réessayer."
        },
        "anniversaries": {
            "step-1-title": "Détails {typeOfService}",
            "step-1-description": "Créez un {typeOfService} en définissant un nom et en déterminant le montant que les employés recevront.",
            "step-2-title": "Sélectionnez employés",
            "step-2-description": "Sélectionnez les employés ou les équipes qui doivent recevoir ce cadeau.\nVous pouvez également déterminer si les nouveaux employés sont automatiquement ajoutés à ce {typeOfService}.",
            "step-3-title": "Détails de commande",
            "step-3-description": "Vous pouvez contrôler entièrement la manière dont ces commandes sont passées et les données qui doivent figurer sur la facture.",
            "step-4-title": "Détails de la personnalisation",
            "step-4-description": "Personnalisez le cadeau pour l'employé, déterminez le message sur le chèque cadeau, l'e-mail que l'employé recevra et à quelle heure.",
            "different-amount-for-work": "Pour les anniversaires de travail, vous pouvez fixer des montants différents selon les années de service.",
            "year": "Année",
            "add-another-option": "Ajouter une autre option",
            "enrollment-title": "Permet l’inscription automatique",
            "enrollment-description": "Permet l'inscription automatique des employés de la liste lorsque tous les employés sont sélectionnés ou des équipes sélectionnées.",
            "no-results": "Aucun employé n'a été trouvé avec ce terme de recherche",
            "team": "Équipe | Équipes",
            "selected": "Sélectionnés",
            "select-all-employees": "Sélectionnés tout les employés",
            "selected-teams": "Équipes sélectionnées",
            "selected-employees": "Émployés sélectionnés",
            "search": "Chercher employées",
            "template": "Modèle",
            "birthday-service": "service de Joyeux Anniversaire",
            "work-anniversary-service": "service d'anniversaire de travail",
            "service-name-placeholder": "Par ex. {typeOfService} pour tous les employés",
            "select-employees-or-enable-auto-enrollment": "Vous devez sélectionner les employés/équipes ou activer l'inscription automatique.",
            "having-trouble": "Avez-vous un souci ?",
            "contact-us": "Contactez-nous",
            "automate-service": "Automatiser le {typeOfService}",
            "lets-automate": "Automatisons les cadeaux",
            "automate-gifts": "Automatiser les cadeaux",
            "created-successfully": "{typeOfService} a été créé avec succès.",
            "birthday-automated-service-description": "Votre service d'anniversaire est actif, vous pouvez le modifier ou le supprimer à tout moment.",
            "work-anniversary-automated-service-description": "Votre service d'anniversaire de travail est actif, vous pouvez le modifier ou le supprimer à tout moment.",
            "select-all-teams": "Sélectionner toutes les équipes",
            "template-preview": "Aperçu du modèle",
            "expiration-date-description": "Fixez une date d'expiration pour ce service automatique. Après cette date d'expiration, nous ne passerons plus de commandes pour ce service.",
            "deleted-successfully": "{typeOfService} a été supprimé avec succès.",
            "are-you-sure": "Etes-vous sûr ?",
            "all-employees": "Tout les employés",
            "updated-successfully": "{typeOfService} mis à jour avec succès.",
            "lets-automate-work-anniversary": "Souhaitez un joyeux anniversaire de travail à tous vos employés !\nSi, en tant qu'entreprise, vous mettez ce moment de fête à l’honneur, vous aurez un avantage certain. Veillez à n’oublier personne ? Notre service d'anniversaire de travail s'en charge !\n\nVous fixez un montant, vous nous transmettez une base de données du personnel et Kadonation Select enverra automatiquement votre cadeau d'anniversaire personnalisé à votre employé, le jour de son anniversaire de travail.",
            "lets-automate-birthday": "Souhaitez un joyeux anniversaire à tous vos employés !\nSi, en tant qu'entreprise, vous mettez ce moment de fête à l’honneur, vous aurez un avantage certain. Veillez à n’oublier personne ? Notre service gratuit \"Happy Birthday\" s'en charge !\n\nVous fixez un montant, vous nous transmettez une base de données du personnel et Kadonation Select enverra automatiquement votre cadeau d'anniversaire personnalisé à votre employé, le jour de son anniversaire.",
            "is-loading": "Chargement des employés...",
            "finish": "Enregistrer",
            "error_message_here": "Error message here"
        },
        "subscription": {
            "upgrade_modal": {
                "general": {
                    "enterprise": {
                        "title": "Passer à la version Enterprise",
                        "body": "Faites passer vos cadeaux d'entreprise à la vitesse supérieure avec le plan Entreprise de Kadonation Select, conçu pour les opérations à grande échelle et les entreprises qui exigent ce qu'il y a de mieux. Ce plan offre le summum de la personnalisation et du contrôle, garantissant que chaque cadeau reflète l'excellence de votre marque."
                    },
                    "professional": {
                        "title": "Passer à la version Professional",
                        "body": "Exploitez tout le potentiel de votre stratégie de cadeaux d'entreprise avec le plan Professional de Kadonation Select. Conçu pour les entreprises qui valorisent des relations exceptionnelles avec leurs employés et leurs clients, ce plan améliore l'expérience de vos cadeaux en offrant des outils et des services avancés."
                    }
                },
                "enterprise": {
                    "subscription_content": {
                        "title": "Passez à la version Enterprise pour débloquer ces fonctionnalités :",
                        "list": "Ajouter des occasions personnalisées ^\nSynchronisation quotidienne des employés ^\nPlateforme en marque blanche ^\nSingle Sign-On ^\nDedicated Success Manager ^\nPriorisation des fonctionnalités ^\nSupport prioritaire ^\nEt plus encore..."
                    }
                },
                "professional": {
                    "subscription_content": {
                        "title": "Passez à la version Professional pour débloquer ces fonctionnalités :",
                        "list": "Paramètres de facturation avancés ^\nGestion des cadeaux, des occasions et du budget ^\nSynchronisation automatisée des employés ^\nAutomatisation des cadeaux ^\nCampagnes de félicitations ^\nFlux d'approbation des commandes ^\nPermissions par utilisateur ^\nEt plus encore..."
                    }
                }
            },
            "view_plans": "Voir tous les plans",
            "upgrade_button": "Mettre à niveau",
            "disclosure": {
                "title": "Passez à la version Professional pour débloquer cette fonctionnalité"
            },
            "cancel_subscription_button": "Annuler l'abonnement",
            "canceled_disclosure": {
                "title": "Votre abonnement prendra fin le {date}",
                "body": "Vous avez annulé votre abonnement. Vous pouvez utiliser les fonctionnalités de l'abonnement jusqu'à la fin de celui-ci. Ensuite, les données relatives à ces fonctionnalités seront supprimées. Vous reviendrez automatiquement à la formule \"Basic\".",
                "button": "Réactiver l'abonnement"
            },
            "subscription_details": "Détails de l'abonnement",
            "subscription_plan": "Abonnement actuel",
            "trial": "version d'essai",
            "subscription_billing_cycle": "Fréquence",
            "subscription_billed_company_entity": "Entité de l'entreprise facturée",
            "subscription_starts_at": "Début de l'abonnement",
            "subscription_canceled_at": "Abonnement annulé le",
            "subscription_ends_at": "Fin de l'abonnement",
            "next_invoice_at": "Facture suivante",
            "cancel_modal": {
                "title": "Annuler l'abonnement",
                "cancel_subscription_button": "Oui, annuler l'abonnement",
                "keep_subscription_button": "Non, maintenir l'abonnement",
                "intro_text": "Votre abonnement sera immédiatement annulé et vous ne serez plus facturé.",
                "lose_access_on": "Vous perdrez l'accès aux fonctionnalités de votre abonnement le {date}.",
                "data_removal_warning": {
                    "title": "Toutes les données seront supprimées",
                    "body": "Nous supprimerons TOUTES LES DONNÉES relatives à vos fonctions d'abonnement le {date}. Cela inclut :",
                    "list": "Tous les automatismes et campagnes Kudos ^\nCadeaux préférés ^\nParamètres de budget et d'occasions ^\nAutorisations des utilisateurs et paramètres d'approbation des commandes ^\nParamètres avancés des factures"
                },
                "employee_sync_deactivation_warning": "Si vous avez une synchronisation automatique des employés, celle-ci sera désactivée. Les employés ajoutés seront conservés.",
                "sure_to_cancel_title": "Êtes-vous sûr de vouloir annuler votre abonnement ?"
            },
            "upgrade_disclosure": {
                "basic": {
                    "title": "Vous êtes actuellement sur le plan \"Basic\"",
                    "body": "Utilisez tout le potentiel de votre stratégie de cadeaux d'entreprise avec le plan professionnel de Kadonation Select.",
                    "button": "En savoir plus"
                },
                "professional": {
                    "title": "Vous êtes actuellement sur le plan \"Professionnel\".",
                    "body": "Faites passer vos cadeaux d'entreprise à la vitesse supérieure avec le plan Entreprise de Kadonation Select, conçu pour les grandes entreprises qui exigent ce qu'il y a de mieux.",
                    "button": "En savoir plus"
                },
                "trial": {
                    "title": "Vous êtes actuellement sur l'essai \"Professional\".",
                    "body": "Libérez tout le potentiel de votre stratégie de cadeaux d'entreprise avec le plan professionnel de Kadonation Select. Vous reviendrez automatiquement à la formule \"Basic\" à la fin de la période d'essai.",
                    "button": "En savoir plus"
                }
            },
            "canceled": "annulé"
        },
        "support": {
            "account_manager": "Responsable de compte",
            "chat": "Chattez avec nous",
            "FAQ": "Questions fréquemment posées",
            "chat-btn": "Lancer le chat",
            "no-articles": "Aucun article n'a été trouvé. Besoin d'aide ? Nous sommes disponibles par chat, email et téléphone.",
            "title": "Aide",
            "message_sent_successfully": "Votre message a été envoyé à notre équipe avec succès.",
            "support_message_received": "Nouvelle demande de support",
            "support_message_description": "&nbsp;",
            "view_order": "Voir la commande",
            "footer_thank_you": "&nbsp;",
            "call": "Appelez-nous",
            "call-be": "<b>Aide BE:</b> <a href=\"tel:093963200\" class=\"hover{text}-pink-300 transition ease-in-out duration-300 pt-1\">+32 (0) 9 396 32 00</a>",
            "call-nl": "<b>Aide NL:</b> <a href=\"tel:0858884262\" class=\"hover{text}-pink-300 transition ease-in-out duration-300 pt-1\">+31 (0) 85 888 49 33</a>"
        },
        "categories": {
            "categories": "Catégories de cadeaux",
            "gift_categories_for": "{category}"
        },
        "forms": {
            "first_name": "Prénom",
            "last_name": "Nom",
            "email": "Adresse e-mail",
            "password": "Mot de passe",
            "password_confirmation": "Confirmation du mot de passe",
            "department": "Département",
            "company_entity": "Entité de l'entreprise",
            "locale": "Langue",
            "save": "Enregistrer",
            "entity": "Entité",
            "po_number": "Numéro de PO",
            "seperate_invoice": "Facture séparée",
            "monthly_invoice": "Ajouter à ma facture mensuelle",
            "confirm": "Confirmez",
            "company_logo": "Logo de votre entreprise",
            "company_name": "Nom de l'entreprise",
            "company_vat_number": "Numéro de TVA de l'entreprise",
            "invoice_notify_email": "E-mail pour la facture",
            "invoice_configuration": "Type de facture par défaut",
            "add": "Ajouter",
            "department_name": "Nom du département",
            "company_entity_name": "Nom de l'entité de l'entreprise",
            "VAT_number": "Numéro TVA",
            "set_default": "Définir par défaut",
            "yes": "Oui",
            "no": "Non",
            "address": "Adresse",
            "location_title": "Titre du lieu",
            "to_name": "Adressé à",
            "street": "Rue",
            "number": "Numéro",
            "bus": "Boîte",
            "zipcode": "Code postal",
            "city": "Ville",
            "country": "Pays",
            "entity_name": "Nom de l'entité",
            "choose_option": "Veuillez choisir une option",
            "remember_me": "Souvenez-vous de moi",
            "confirm_password": "Confirmez votre mot de passe",
            "reset_password": "Réinitialiser le mot de passe",
            "choose_country": "Choisissez un pays",
            "language": "Langue",
            "is_active": "Actif",
            "permissions": "Autorisations",
            "invoice": "Type de facture",
            "to_name_explanation": "Vous pouvez librement changer ceci pour les adresses enregistrées",
            "address_title_explanation": "On utilise ceci pour sauvegarder l'adresse",
            "choose_language": "Choisissez langue",
            "general": "Informations générales",
            "file_cannot_be_processed": "Le fichier est incomplet, il ne peut donc pas être traité.",
            "detailed_invoice": "Facture détaillée",
            "invoice_per_department": "Facture mensuelle par département",
            "cost_center": "Centre de coûts",
            "proforma_first_for_separate_invoice": "D'abord proforma en cas de facture séparée",
            "finalize_proforma_invoice": "Finaliser Facture Pro Forma",
            "group_name": "Nom de groupe",
            "proforma_first_for_monthly_invoice": "D'abord proforma en cas de facture mensuelle",
            "default_proforma_first_for_separate_invoice": "Standard d'abord proforma en cas de facture mensuelle",
            "set_po_for_all_orders": "Fixer le PO pour toutes les commandes de cette facture",
            "finalize": "Finaliser la facture",
            "orders": "Commandes",
            "proforma_first": "Facture pro forma",
            "is_inactive": "Inactif",
            "processing": "Traitement en cours",
            "set_approval_manager": "Les commandes doivent être approuvées par",
            "your_email": "Adresse électronique",
            "submit": "Soumettre",
            "policy_updated_both": "La politique d'utilisation et la politique des données ont été ajustées. Veuillez accepter à nouveau.",
            "updated": "Ajustée",
            "data_policy_updated": "La politique en matière de données a été mise à jour. Veuillez l'accepter à nouveau.",
            "use_policy_updated": "La politique d'utilisation acceptable a été mise à jour. Veuillez l'accepter à nouveau.",
            "no_employees_added": "Aucun employé ajouté",
            "are_you_sure": "Êtes-vous sûr?",
            "please_select_items_to_update": "Veuillez sélectionner les éléments à mettre à jour",
            "reason_approve": "Motif",
            "reject": "Rejeter",
            "approve": "Approuver",
            "reason_reject": "Motif",
            "update_delivery_title": "Mettre à jour la livraison",
            "update_delivery_title_delivered": "Mettre à jour la livraison",
            "update_delivery_description": "Mise à jour des données de livraison",
            "delete": "Effacer",
            "update_delivery": "Mettre à jour la livraison",
            "select_all_permissions": "Cochez / décochez toutes les autorisations",
            "date_of_birth": "Date de naissance",
            "date_of_employment": "Date d'embauche",
            "update_delivery_description_delivered": "Ce bon a déjà été envoyé. Mettez à jour la date et / ou l\\'adresse e-mail à renvoyer.",
            "unknown": "Inconnue",
            "prepared": "préparé",
            "delivered": "Livré",
            "saml_sso_url": "SSO URL",
            "saml_entity_id": "ID d'entité SSO",
            "saml_sso_certificate": "Certificat (X.509)",
            "saml": "Activer SAML SSO",
            "file_upload": {
                "btn": "Télécharger",
                "btn-remove": "Supprimer",
                "drop": "Ou déposez un fichier ici"
            },
            "from": "De",
            "to": "Adressé à",
            "amount": "Montant",
            "message": "Message",
            "delivery_date": "Date de livraison",
            "delivery_time": "Délai de livraison",
            "detailed_reference": "Réference détail",
            "enter_message_here": "Entrez votre message ici",
            "show": "Tonen",
            "hide": "Cacher",
            "errors": "Errors",
            "deferred": "Deferred",
            "dropped": "Dropped",
            "bounce": "Bounces",
            "blocks": "Bloqué",
            "include_department_name_on_invoices": "Inclure le nom du département sur la facture",
            "enter_street_here": "Entrez la rue ici",
            "enter_street_number_here": "Entrez le numéro de la rue ici",
            "enter_bus_here": "Entrez le numéro de bus ici",
            "enter_zipcode_here": "Entrez le code postal ici",
            "enter_city_here": "Entrez la ville ici",
            "enter_country_here": "Sélectionnez le pays",
            "location_to_name": "Nom destinataire",
            "default_proforma_first_for_monthly_invoice": "Standard d'abord proforma en cas de facture séparée",
            "vat_liable": "Assujetti à la TVA",
            "address_from_vat_message": "Nous essayons de remplir votre adresse sur la base de votre numéro de TVA.",
            "edit": "Éditer",
            "cancel": "Annuler",
            "occasion": "Occasion",
            "ordered_by": "Commandé par",
            "optional": "Optionnel",
            "date_from": "Date du",
            "date_to": "Date à",
            "order-id": "Numéro de commande",
            "full_name": "Nom complet",
            "external_employee_id": "Numéro d'employé",
            "employees_override": "Écraser tous les employés avec de nouvelles données",
            "employees_override_info": "Cela mettra à jour les informations des employés existants avec les données du fichier Excel. ATTENTION: cela supprimera également tous les employés qui ne figurent pas dans le fichier Excel.",
            "company": "Entreprise",
            "saved_addresses": "Adresses sauvegardées",
            "use_new_address": "Utiliser la nouvelle adresse",
            "search_employee": "Recherche d'un employé",
            "select_occasion": "Select Occasion",
            "reset_password_sent": "Le lien de réinitialisation de votre mot de passe a été envoyé à votre email!",
            "back_to_login": "Retourner à la page de connexion",
            "reset_password_resend": "Renvoyer l'e-mail de réinitialisation du mot de passe",
            "subdomain": "Sous-domaine",
            "processed": "Processé",
            "add_more_options": "Ajouter plus d'options",
            "remove_option": "Supprimer l'option",
            "edit_message": "Modifier le message",
            "apideck_company_id": "ID de l'entreprise (dans votre logiciel RH)",
            "open_in_editor": "Ouvrir dans l'éditeur",
            "force_invoice_configuration": "Verrouiller le type de facture par défaut pour toutes les commandes",
            "fixed_cost_center": "Centre de coût fixe",
            "recipient_email_address": "Email du destinataire",
            "why_email_track_and_trace": "Les notifications de suivi seront envoyées à cette email.",
            "po_number_required": "Numéro de commande requis",
            "recipient_first_name": "Prénom du bénéficiaire",
            "recipient_last_name": "Nom de famille du bénéficiaire",
            "enter_recipient_first_name": "Indiquer le prénom du destinataire",
            "enter_recipient_last_name": "Indiquer le nom de famille du destinataire",
            "street_number": "Numéro de rue",
            "expected_delivery_date": "Date de livraison prévue",
            "require_proforma_invoice": "Facture proforma requise",
            "cost_center_required": "Centre de coûts requis",
            "proforma_notify_email": "E-mail pour la facture proforma",
            "datepicker": {
                "select": "Sélectionner",
                "cancel": "Annuler"
            },
            "bus_help_text": "The word 'Bus' is added later by us.",
            "recipient_full_name": "Prénom du bénéficaire + nom de famille du bénéficaire",
            "invoice_notify_email_cc": "Envoyer également la facture à (en cc)",
            "order_notify_email_cc": "Envoyer toutes les notifications de commande à (en cc)",
            "employee_type": "Type d'employé",
            "fixed_po_number": "Numéro de commande fixe",
            "phone-number": "Numéro de téléphone",
            "options_selected": "Aucune option sélectionnée | {count} option sélectionnée | {count} options sélectionnée",
            "nothing_found": "Rien n'a été trouvé",
            "show_occasion_on_invoice": "Afficher l'occasion de la commande sur la facture",
            "use_new_address_or_search": "Sélectionner ou rechercher une adresse enregistrée",
            "password_explainer": "Le mot de passe doit comporter plus de 8 caractères et au moins : 1 lettre majuscule, 1 lettre minuscule, 1 valeur numérique et 1 caractère spécial.",
            "occasions": "Occasions",
            "backup_approval_manager": "Sauvegarde pour l'approbation des commandes",
            "logo_standard": "Logo",
            "logo_standard_help_text": "Seuls les logos au format image sont autorisés. Par exemple. .jpg, .jpeg, .png",
            "logo_vector": "Logo (vectoriel)",
            "logo_vector_help_text": "Seuls les logos en format vectoriel sont autorisés. Par exemple, .eps, .svg",
            "upload_logo": "Charger le logo",
            "update_logo": "Actualiser le logo",
            "name": "Nom"
        },
        "gifts": {
            "no_gifts_found": "Aucun cadeau correspondant à vos critères n'a été trouvé.",
            "number_of_gifts_found": "Affichage de {showing} cadeaux sur {total}",
            "not_shippable_error": "La configuration d'expédition est incomplète pour ce cadeau. Notre équipe a été informée de cette erreur, veuillez réessayer plus tard ou contacter le support client pour plus de détails.",
            "show_gift_images": "Montrer les photos du produit",
            "show_option_images": "Montrer les photos des option",
            "orders_limited_to_vendor": "Vous ne pouvez commander qu'auprès d'un seul partenaire cadeau par commande",
            "orders_limited_to_vendor_description": "Vous ne pouvez passer une commande de cadeaux qu'auprès d'un seul partenaire à la fois. Pour commander des cadeaux d'un autre partenaire-cadeau, veuillez terminer votre commande en cours ou vider votre panier.",
            "different_vendor": "Autres partenaire",
            "all_gifts_shown": "Tous les résultats sont affichés",
            "personalize_voucher_title": "Personnalisez chèque cadeau",
            "personalize_greeting_card_title": "Personnaliser la carte",
            "personalize_free_card_title": "Personnaliser la carte",
            "personalize_voucher_subtitle": "Personnalisez le contenu de votre chèque cadeau.",
            "personalize_greeting_card_subtitle": "Ce cadeau est une carte. Vous pouvez personnaliser le contenu de cette carte en utilisant les champs ci-dessous.",
            "personalize_free_card_subtitle": "Une carte personnalisée peut être ajoutée à ce cadeau . Personnalisez le contenu de cette carte en utilisant les champs ci-dessous.",
            "min_quantity": "Quantité minimale",
            "max_quantity": "Quantité maximale",
            "language_description": "Détermine la langue du texte ajouté automatiquement",
            "from_description": "Imprimé sous votre message personnel",
            "to_description": "Imprimé au-dessus de votre message personnel.",
            "pm_description": "Il n'est pas nécessaire d'ajouter le nom du destinataire ou le nom de l'expéditeur si vous les avez déjà ajoutés dans les champs 'De' et/ou 'À'. Veuillez noter que le nombre de caractères est limité à 300 (espaces compris, la touche \"entrée\" comptant pour 40 caractères). Les émojis ne sont pas supportés.",
            "personalize_gift_title": "Personnaliser le cadeau",
            "personalize_gift_subtitle": "Ce cadeau peut être personnalisé via notre editor",
            "personalize_gift_description": "Vous pouvez personnaliser un seul cadeau à la fois. Si vous souhaitez personnaliser plusieurs cadeaux, vous devrez suivre le processus de personnalisation pour chaque article séparément et les ajouter à votre panier individuellement avant de passer à la caisse.",
            "start_personalization": "Commencez à personnaliser ce cadeau",
            "edit_personalization": "Personnaliser le design",
            "personalize": "Personnaliser",
            "excluding_vat": "(excl. TVA)",
            "add-to-favorites": "Ajouter aux favoris",
            "successfully-added-to-favorites": "Le cadeau a été ajouté aux favoris pour '{occasion}'. Cela se verra d'en haut à cette occasion.",
            "successfully-removed-from-favorites": "Le cadeau a été retiré des favoris à '{occasion}'.",
            "favorite-gift-for-occasion": "Cadeau favori pour cette occasion",
            "remove-from-favorites": "Retirer des favoris",
            "looking_for_sth_custom": "Vous cherchez quelque chose sur mesure ?",
            "customization_cta": "Contactez-nous",
            "looking_for_sth_else_description": "Vous ne trouvez pas ce que vous cherchez ? Vous cherchez un bon d'achat ou un cadeau dans votre style maison ? Vous souhaitez créer un paquet cadeau unique pour votre marque ? Contactez-nous, et nous en occuperons !",
            "looking_for_sth_else_title": "Vous cherchez quelque chose de spécifique ou de personnalisé ?",
            "gift_without_customization_confirmation_description": "Ce cadeau peut être personnalisé, et vous n'avez pas encore ajouté de personnalisation. Êtes-vous sûr de vouloir continuer sans personnalisation ?",
            "gift_without_customization_confirmation_title": "Cadeau non personnalisé",
            "show_gifts_from_all_vendors": "Montrer les cadeaux de tous les fournisseurs",
            "show_gifts_from_vendor": "Présenter des cadeaux provenant du même vend",
            "pm_description_for_card": "Il n'est pas nécessaire d'ajouter le nom du destinataire ou le nom de l'expéditeur si vous les avez déjà ajoutés dans les champs 'De' et/ou 'À'. Veuillez noter que le nombre de caractères est limité à 600 (espaces compris, la touche \"entrée\" comptant pour 40 caractères). Les émojis ne sont pas supportés.",
            "warnings": {
                "has_direct_future_delivery_non_voucher_gift_in_cart": {
                    "title": "Terminez votre commande avant d'ajouter de nouveaux cadeaux",
                    "description": "Vous avez ajouté à votre panier un cadeau pour lequel une date de livraison est fixée par destinataire. Ce cadeau ne peut pas être combiné avec d'autres cadeaux. Veuillez d'abord passer la commande du cadeau dans votre panier.",
                    "button": "Voir le panier"
                }
            },
            "pm_description_for_card_digitize_enabled": "Ce code QR vous mène à une page où les messages personnels seront affichés. Veuillez noter que le nombre de caractères est limité à 600 (espaces compris, la touche \"entrée\" comptant pour 40 caractères). Les émojis ne sont pas supportés."
        },
        "orders": {
            "excel_user_list_title": "Modifier la liste des destinataires",
            "employee_list_title": "Sélectionner et personnaliser les employés",
            "add_employees_button": "Sélectionner les employés",
            "empty_search_result": "Aucun employé n'a été trouvé avec ce terme de recherche",
            "employee_list_info_message": "Sélectionnez tous les employés ou équipes auxquels vous souhaitez offrir un cadeau. Vous pouvez ajouter des employés un par un, par équipe ou tous en même temps. Ensuite, vous pouvez personnaliser le cadeau par employé ou pour plusieurs employés à la fois.",
            "add_all_employees": "Sélectionner tout les employés",
            "search_and_select_info": "Rechercher et sélectionner des employés ou des équipes",
            "add_employee_manually": "Ajouter un destinataire manuellement",
            "excel_upload_error_message": "Une erreur s'est produite lors du téléchargement et de la lecture du fichier Excel fourni.",
            "excel_upload_success_message": "Fichier téléchargé avec succès !",
            "view_imported_users": "Consulter la liste des destinataires importés",
            "reading_excel": "Lecture de fichier Excel téléchargé ...",
            "selected_employees": "{count} employé sélectionné|{count} employés sélectionnés",
            "imported_employees": "{count} destinataire importé|{count} destinataires importés",
            "select_employees": "Sélectionner employés du gestion des employés",
            "edit_employee_list": "Modifier la liste des employés sélectionnés",
            "errors_in_order_form": "Il y a des erreurs dans les données que vous avez fournies. Veuillez revoir les lignes en surbrillance et les corriger.",
            "search": "Rechercher (par prénom ou nom de famille)",
            "remove_excel_confirmation_message": "Cette action supprimera le fichier Excel téléchargé et tous les utilisateurs importés. Voulez-vous continuer ?",
            "excel_data_loss_message": "Cette action supprimera le fichier excel téléchargé et nécessitera le téléchargement d'un autre fichier excel. Voulez-vous continuer ?",
            "employee_list_errors": "{0} Il y a {count} erreurs dans la liste d'employés que vous avez soumise.|{1} Il y a une erreur dans la liste d'employés que vous avez soumise.|{1} Il y a {count} erreurs dans la liste d'employés que vous avez soumise.",
            "select_option_first": "Veuillez d'abord choisir une option",
            "add_video_for_personalization": "Vous souhaitez ajouter un message vidéo ?",
            "video_url": "URL du message vidéo",
            "drop_file_or_click": "Déposer un fichier ou cliquer pour le télécharger",
            "format": "Format de fichier",
            "video_invalid_type": "Vous ne pouvez pas télécharger des fichiers de ce type.",
            "video_file_too_big": "Le fichier est trop gros ({filesize}MB). Taille maximale des fichiers : {maxFilesize}MB.",
            "video_response_error": "Le serveur a répondu avec le code {statusCode}.",
            "video_invalid_url": "Veuillez entrer une URL valide",
            "upload_canceled": "Le téléchargement a été annulé.",
            "cancel_upload_confirmation": "Vous êtes sûr ?",
            "video_required": "L'URL du message vidéo ou du fichier du message vidéo est requise.",
            "video": "Vidéo",
            "delete_video_info": "Pour télécharger une nouvelle vidéo, vous devez d'abord supprimer la vidéo actuelle.",
            "video_upload_in_progress": "Le téléchargement de la vidéo est en cours, veuillez patienter...",
            "price_free": "Gratuit",
            "order_parameters": "Filtres pour des commandes",
            "order_item_message_parameters": "Filtres des chèques-cadeaux Kadonation",
            "show_all_filters": "Autres filtres",
            "no_results_for_filter": "Aucune commande n'a pu être trouvée avec les filtres sélectionnés",
            "no_results": "Aucun ordre n'a pu être trouvé",
            "filters": "Filtres",
            "remove_all_filters": "Supprimer tous les filtres",
            "select_kudos_employees": "Modifier les bénéficiaires",
            "edit_kudos_list": "Modifier les bénéficiaires",
            "upload_video": "Ajouter une vidéo",
            "thank_you_video": "Un message vidéo",
            "upload_video_button": "Ajouter une vidéo",
            "no_video_uploaded": "Aucune vidéo téléchargée",
            "no_to_name": "Aucun nom ajouté",
            "gift_vendor_internal_product_id": "Vendor product ID",
            "option_vendor_internal_product_id": "Vendor option ID",
            "order_handled_by": "Commande passée par",
            "order_being_shipped": "Votre commande est en cours d'expédition",
            "take_look_at_all_orders": "Ici, vous pouvez voir tous les articles de vos commandes.",
            "track_shipment": "Suivi de l'expédition",
            "invoiced_to": "Facturé à",
            "total_handling_fee": "Total extras et frais",
            "approve_orders": "Approuver",
            "reject_orders": "Rejeter",
            "urgent": "Urgent",
            "message_details": "Détails du message",
            "deselect_all_recepients": "Désélectionner tous les destinataires",
            "select_all_recepients": "Sélectionner tous les destinataires",
            "recipients_selected": "{count} bénéficiaires sélectionnés",
            "mass_update_explainer": "La mise à jour de ces champs entraîne la mise à jour immédiate de la colonne correspondante pour tous les destinataires sélectionnés.",
            "all_recipients_selected": "Tous les bénéficiaires sélectionnés",
            "scroll_horizontally_to_show_more_fields": "Faites défiler horizontalement pour afficher plus de champs.",
            "these_are_required_fields": "Les champs suivants sont obligatoires:",
            "select_recipients_explanation": "Sélectionnez les employés que vous avez ajoutés à Kadonation Select.",
            "email_explainer_for_direct_delivery_orders": "Nous avons besoin d'une email par destinataire pour envoyer les notifications de suivi et de localisation. Si vous ne voulez pas que le destinataire reçoive une email, vous pouvez indiquer votre propre email.",
            "confirm-vendor-order-title": "Confirmation nécessaire",
            "confirm-vendor-order-description": "Veuillez confirmer cette commande. La confirmation signifie que nous supposons que vous en avez vu les détails et que vous êtes en train de la traiter. Vous trouverez ci-dessous les informations concernant cette commande ainsi que le bouton pour la confirmer.",
            "vendor-email-sent": "E-mail au fournisseur envoyée",
            "vendor-order-please-confirm-order": "Veuillez confirmer cette commande",
            "vendor-order-confirmed-title": "Commande confirmée",
            "vendor-order-confirmed-description": "Cette commande est maintenant marquée comme 'confirmée'",
            "vendor-email-confirmed-at": "Commande confirmée à :",
            "show_preview": "Toon voorbeeld",
            "vendor-order-reserved-title": "Réservation de commande confirmée",
            "reserve-vendor-order-title": "Confirmation de réservation nécessaire",
            "reserve-vendor-order-description": "Confirmez la réservation pour cette commande. Cela signifie que vous avez les articles commandés en stock ou qu'ils seront disponibles à temps pour la livraison à la date prévue mentionnée dans l'e-mail. Les fichiers de personnalisation seront envoyés plus tard. Vous trouverez ci-dessous le bouton pour confirmer cette réservation.",
            "vendor-order-please-reserve-order": "Confirmez ci-dessous la réservation de cette commande",
            "vendor-order-reserved-description": "Cette commande est maintenant marquée comme 'réservée'",
            "vendor-email-reserved-at": "Commande réservée à :",
            "order_gift": "Achat cadeaux",
            "loading_preview": "Chargement de la prévisualisation, cela peut prendre quelques secondes...",
            "failed_to_load_preview": "Échec du chargement de la prévisualisation, veuillez réessayer plus tard",
            "preview_card": "Prévisualiser carte",
            "preview_voucher": "Prévisualiser chèque-cadeau",
            "credits_used": "Crédits Utilisés",
            "excel_upload_error_message_v2": "There are errors in your Excel",
            "approval-explainer-title": "La commande est en attente d'approbation par la Kadonation.",
            "approval-explainer-body": "Kadonation a fixé des limites de dépenses quotidiennes et mensuelles afin d'éviter les fraudes. Vous avez atteint ces limites, ce qui fait que les commandes doivent être approuvées par Kadonation. Nous faisons de notre mieux pour approuver ces commandes quelques heures après qu'elles aient été passées. Contactez-nous si la commande est urgente ou si vous avez des questions sur ces limites de dépenses.",
            "created_via_automation": "Cadeau automatisé",
            "payment_method": "Mode de paiement",
            "direct_payment_failed_title": "Échec du paiement",
            "direct_payment_failed_description": "Votre paiement a échoué. Veuillez réessayer en utilisant le bouton ci-dessous.",
            "direct_payment_retry_button": "Nouvelle tentative de paiement",
            "direct_payment_pending_title": "Paiement en attente",
            "direct_payment_pending_description": "Nous n'avons pas encore reçu la confirmation de votre paiement. Si vous avez déjà payé, nous devrions recevoir la confirmation prochainement. Si vous n'avez pas encore payé, veuillez réessayer.",
            "direct_payment_canceled_title": "Paiement expiré",
            "direct_payment_canceled_description": "Le paiement a expiré. Veuillez repasser votre commande.",
            "after_receiving_invoice": "Virement bancaire (après facturation)",
            "approval_flow_explainer": {
                "title": "Garder le contrôle sur les achats",
                "description_1": "Grâce au système d'approbation des commandes, votre gestionnaire d'approbation est en mesure d'approuver ou de rejeter les commandes passées par des utilisateurs spécifiques.",
                "description_2": "Pour activer cette fonctionnalité, ajoutez un gestionnaire d'approbation à un utilisateur. Allez dans \"Utilisateurs\", modifiez l'utilisateur dont les commandes doivent être approuvées et définissez un gestionnaire d'approbation.",
                "button": "Afficher les utilisateurs"
            },
            "contributor_selector": {
                "title": "Inviter des contributeurs",
                "explainer": "Sélectionnez tous les employés ou équipes que vous souhaitez ajouter en tant que contributeur. Vous pouvez ajouter les employés un par un, par équipe ou tous à la fois. Vous pouvez également inviter des contributeurs manuellement en renseignant leur adresse e-mail. Cliquez sur 'Ajouter un contributeur manuellement' pour le faire."
            },
            "unblock_vouchers_page": {
                "unblock_vouchers": {
                    "title": "Débloquez vos bons",
                    "content": "Pour garantir que vos bons ne seront utilisés que par le destinataire prévu, nous les avons bloqués avant de vous les expédier.\n<br/><br/>\nUtilisez le bouton ci-dessous pour rendre les bons disponibles à l'utilisation.\n<br/><br/>\nAssurez-vous que tous les bons sont en votre possession ! Vous pouvez fermer cette page et revenir plus tard si nécessaire.",
                    "button": "DÉBLOQUER LES BONS"
                },
                "order_id": "Order ID",
                "number_of_vouchers": "Bons physiques",
                "vouchers_unblocked": {
                    "title": "Bons débloqués",
                    "content": "Les bons associés à cette commande ont été débloqués et sont maintenant disponibles pour utilisation. Vous pouvez les remettre en toute sécurité au destinataire prévu ou l'informer qu'il peut utiliser le bon auprès de plus de 90 partenaires.",
                    "success_notification": "Les bons sont maintenant disponibles pour utilisation."
                }
            },
            "vendor_tracking": {
                "confirm_tracking": {
                    "title": "Valider le suivi et traçage",
                    "explainer": "Veuillez ajouter les liens de suivi pour cette commande. S'il n'y a qu'un seul lien de suivi, insérez-le dans le boîte de texte. S'il y en a plusieurs, téléchargez un fichier contenant tous les liens ou copiez-collez les liens dans le boîte de texte."
                },
                "order_id": "Order ID",
                "email_sent_on": "E-mail fournisseur envoyé le",
                "form": {
                    "links_input_label": "Collez ici les liens de suivi et traçage",
                    "links_input_placeholder": "Collez votre/vos lien(s) ici",
                    "upload_links_label": "Ou téléchargez un fichier contenant les liens de suivi",
                    "submit_button": "Enregistrer les liens",
                    "accepted_file_formats": "formats autorisés: csv, xls/xlsx, pdf"
                },
                "confirmed_on": "Commande confirmée par le fournisseur le :",
                "tracking_confirmed": {
                    "title": "Traçage confirmé",
                    "explainer": "Traçage confirmé",
                    "success_message": "Traçage confirmé"
                }
            },
            "confirm_tracking": {
                "head_title": "Suivi et traçage"
            }
        },
        "tenant-creation": {
            "account-step-label": "Compte",
            "account-step-title": "Créez votre compte",
            "account-step-description": "Veuillez utiliser votre adresse électronique professionnelle pour créer votre compte.",
            "company-email": "Adresse électronique professionnelle",
            "account-step-next-button": "Continuer",
            "company-step-label": "Entreprise",
            "company-step-title": "Pour quelle entreprise travaillez-vous ?",
            "company-step-description": "Veuillez indiquer le nom de votre entreprise, ainsi que le pays dans lequel elle réside.",
            "company-step-country-help-text": "<a href=\"{mailTo}\">Contactez-nous</a> si votre pays ne figure pas dans la liste déroulante.",
            "company-step-next-button": "Continuer",
            "profile-step-label": "Profil",
            "profile-step-title": "Complétez votre profil",
            "profile-step-description": "Ces données de profil sont nécessaires pour compléter votre compte. Elles permettent de préciser aux autres qui a commandé quoi.",
            "profile-step-next-button": "Continuer",
            "verify-step-title": "Veuillez vérifier vos informations",
            "verify-step-description": "Veuillez vérifier que les informations ci-dessous sont correctes. Cliquez sur le bouton \"Créer un compte\" en bas de page pour créer votre compte.",
            "verify-step-personal-info": "Informations personnelles",
            "verify-step-company-info": "Informations sur l'entreprise",
            "edit-info": "Editer",
            "verify-step-next-button": "Créer un compte Select",
            "verification-email-step-title": "Votre compte a été créé !",
            "verification-email-step-description": "Consultez votre boîte de réception pour connaître la prochaine étape",
            "verification-email-step-body": "Nous avons envoyé un courriel à {email} contenant un lien pour définir votre mot de passe. Veuillez vérifier votre courrier électronique. Notez qu'il peut prendre une minute pour arriver.",
            "account-step-company-has-account": "Il semble que cette entreprise dispose déjà d'un compte.",
            "account-step-you-have-an-account": "Il semble que vous ayez déjà un compte.",
            "account-step-company-has-account-body": "Des collègues de votre entreprise ont déjà créé un compte chez Kadonation Select. Si vous souhaitez accéder à ce compte, ou si vous pensez qu'il y a une erreur, veuillez nous contacter.",
            "account-step-you-have-an-account-body": "Nous avons trouvé un compte lié à cette adresse électronique. Contactez-nous si vous avez perdu l'accès à votre compte et que vous ne savez pas quoi faire.",
            "side-info-title": "Offrir n’a jamais été aussi agréable.",
            "side-info-description": "Une plateforme intuitive qui rend le don simple, accessible et personnel. Avec Kadonation, vous ferez des bonds en avant vers des relations plus fortes à chaque don.",
            "side-info-bullet-point-1": "Des cadeaux qui impressionnent, avec des messages personnels sincères",
            "side-info-bullet-point-2": "Des flux de travail hyper-efficaces qui fonctionnent pour vous",
            "side-info-bullet-point-3": "Une gestion budgétaire aisée et des mécanismes de contrôle pratiques",
            "set-password-step-title": "Créez votre mot de passe",
            "set-password-step-description": "La création de votre compte est presque terminée. Créez un mot de passe et nous commencerons à configurer votre nouveau compte Select.",
            "set-password-step-next-button": "Sauvegarder le mot de passe",
            "set-password-step-link-expired-title": "Ce lien a expiré.",
            "set-password-step-link-expired-description": "Vous avez attendu trop longtemps pour définir votre mot de passe. Si vous souhaitez définir à nouveau votre mot de passe, veuillez demander un nouveau lien.",
            "set-password-step-request-new-link": "Demander un nouveau lien",
            "error-title": "Quelque chose a mal tourné...",
            "error-description": "Une erreur s'est produite lors de la création de votre compte. Veuillez nous contacter afin que nous puissions vous aider à créer votre compte et vous donner accès à Kadonation Select.",
            "setup_status_1": "Configurer votre accès à plus de 1000 cadeaux",
            "setup_status_2": "Organiser des occasions de faire des cadeaux",
            "setup_status_3": "Mise en place d'outils de personnalisation et d'adaptation",
            "setup_status_4": "Mise en place de la gestion des factures et des commandes",
            "setup_status_5": "Sécuriser votre environnement Select",
            "setup_status_6": "Finalisation de l'environnement Select",
            "page-title": "Créer un compte",
            "profile-step-department-placeholder": "Département dans lequel vous travaillez, par exemple : RH",
            "new-link-sent": "Un nouveau lien a été envoyé à votre adresse électronique.",
            "account_being_created": "Nous créons votre environnement Select",
            "may_take_time": "Cela peut prendre jusqu'à une minute."
        },
        "settings": {
            "invoice_configuration": {
                "grouped_monthly_invoice": "Facture mensuelle groupée",
                "separate_invoice": "Facture séparée par commande"
            },
            "no_delivery_address_set": "Aucune adresse de livraison n'a encore été définie",
            "delivery_address_set": "{count} adresse de livraison définie|{count} adresses de livraison définies",
            "add_more_delivery_address": "Ajouter plus",
            "add_first_delivery_address": "Ajoutez-en un maintenant",
            "entity_delivery_addresses_description": "Les adresses de livraison seront affichées lors du passage à la caisse pour tous les utilisateurs qui passent commande. Utilisez-les pour permettre aux utilisateurs de choisir rapidement une adresse, au lieu de devoir la remplir.",
            "change_default_entity_not_allowed": "La désactivation de l'entreprise par défaut n'est pas autorisée!"
        },
        "messages": {
            "order_confirmed": "Votre commande a été confirmée",
            "download_digital_vouchers_here": "Téléchargez vos chèques électroniques ici",
            "order_confirmed_subtext": "Vos chèques électroniques sont disponibles ici, vos boîtes cadeaux seront bientôt livrées",
            "invoice_notify_explanation": "Un email sera envoyé à cette adresse mail lorsqu'une nouvelle facture aura été créée.",
            "check_verification_link": "Avant de poursuivre, veuillez consulter votre e-mail et cliquer sur le lien de vérification.",
            "verification_link": "Un nouveau lien de vérification a été envoyé à votre adresse mail.",
            "email_verify": "Vérifiez votre adresse mail",
            "not_received_verification_mail": "Si vous n'avez pas reçu le email, alors:",
            "resend_verification_email": "Cliquez ici pour en demander un autre",
            "forgot_password": "Mot de passe oublié?",
            "send_password_link": "Envoyer le lien de réinitialisation du mot de passe",
            "order_for_group_step_1": "Télécharger le modèle",
            "order_for_group_step_2": "Remplissez le modèle avec les données des employés et téléchargez le fichier ici",
            "order_for_group_step_1_subtext": "Téléchargez le fichier excel ci-dessous et remplissez les champs demandés.",
            "order_for_group_step_2_subtext": "Une fois que vous avez rempli le fichier excel, vous pouvez le télécharger ci-dessous et ensuite ajouter la commande à votre panier.",
            "file_uploaded": "Vous avez téléchargé un fichier",
            "click_to_upload": "Faites glisser ou <span class=\"underline\">cliquez ici</span> pour télécharger un fichier",
            "x_files_uploaded": "Vous avez téléchargé {count} fichiers",
            "sent_password_link": "Le lien de réinitialisation du mot de passe est envoyé à votre email si votre email existe dans notre système. Si vous ne recevez pas d'e-mail, contactez le service clientèle de Kadonation après avoir vérifié vos dossiers de spam. N'oubliez pas que si vous vous connectez via un service externe (tel que Google, Microsoft, ...), nous ne pouvons pas réinitialiser votre mot de passe.",
            "invoice_address_saved": "Les modifications apportées à l'entité de l'entreprise ont été sauvegardées",
            "cannot_delete_default_entity": "Impossible de supprimer l'entité par défaut",
            "company_entity_deleted": "L'entité de l'entreprise a été supprimée avec succès",
            "delivery_address_saved": "L'adresse de livraison a été enregistrée",
            "delivery_address_deleted": "L'adresse de livraison a été supprimée avec succès",
            "settings_not_updated": "Les paramètres n'ont pas pu être mis à jour",
            "settings_added": "Les paramètres ont été mis à jour avec succès",
            "department_added": "Le département a été ajouté",
            "department_not_added": "Le département n'a pas pu être ajouté",
            "item_placed_in_cart": "L'article a été ajouté à votre panier.",
            "occasions_updated": "Les occasions ont été mises à jour",
            "item_removed_from_cart": "L'article a été retiré de votre panier",
            "user_created": "Utilisateur créé avec succès.",
            "user_updated": "L'utilisateur a été mis à jour",
            "cart_empty": "Votre panier est vide",
            "mail_subcopy": "Restez à jour à propos de Kadonation en suivant notre [linkedin](https://www.linkedin.com/company/groupgifting/).",
            "mail_greetings": "Bien à vous, l'équipe Kadonation",
            "reports_text": "Les rapports vous fournissent des informations utiles en un coup d'œil sur la manière dont Kadonation Select est utilisé dans votre entreprise. Ils vous donnent un meilleur aperçu des occasions les plus populaires, des cadeaux les plus choisis, du montant moyen par cadeau... Vous pouvez choisir d'obtenir un aperçu par utilisateur, par département ou de l'ensemble de l'entreprise.\nDisponible à l'automne 2021.",
            "company_entity_created": "Nouvelle entité créée",
            "creditnote_creation_failed": "La création de la note de crédit a échoué",
            "not_a_valid_invoice": "Pas une facture valide",
            "you_must_confirm_the_warning": "Veuillez confirmer le message d'avertissement avant de passer à la caisse",
            "po_number_has_been_saved": "Le numéro du PO a été enregistré",
            "po_number_has_been_saved_and_invoice_finalized": "Le numéro du PO a été enregistré et la facture a été finalisée",
            "confirm": "êtes-vous sûr?",
            "employee_created": "Employé ajouté",
            "employee_updated": "Employé mis à jour",
            "employee_deleted": "Employé supprimé",
            "employees_imported": "Employés importés",
            "invalid_time_format": "Le format de l'heure fourni n'est pas valide, veuillez formater l'heure comme telle hh{MM}",
            "invalid_time_in_past": "Le temps fourni est dans le passé, veuillez fournir un temps dans le futur",
            "invalid_date_format": "Le format de date fourni n'est pas valide, veuillez formater les dates comme telles : jj/mm/aaaa",
            "invalid_date_in_past": "La date fournie est dans le passé, veuillez fournir une date au moins plus d'une heure dans le future",
            "digital_delivery_treshold_cart_error": "Votre panier contient des cheques electroniques qui sont envoyés directement aux destinataires. Veuillez les commander séparément afin que nous puissions garantir une livraison dans les délais.",
            "employees_deleted": "Employés supprimés",
            "invalid_must_ahead_hour": "La date et l'heure doivent être au moins plus d'une heure dans le future",
            "campaign_created": "Campagne créée avec succès.",
            "campaign_inactive": "La campagne est inactive.",
            "kudos_user_not_found": "Utilisateur introuvable",
            "kudos_campaign_not_found": "Campagne introuvable",
            "kudos_user_is_not_on_the_list_of_recipients": "Utilisateur introuvable dans la liste des bénéficiaires",
            "there_are_orders_pending_approval": "Attention : certaines ordonnances sont en attente d'approbation",
            "order_is_pending_approval": "La commande est en attente d'approbation",
            "order_has_been_cancelled": "La commande a été annulée",
            "invalid_datetime_format": "Le format de date fourni n'est pas valide, veuillez formater les dates comme telles : jj/mm/aaaa hh{mm}",
            "department_restored": "Un département portant ce nom existait auparavant et est réactivé",
            "orders_has_been_rejected": "Les commandes ont été rejetées",
            "orders_has_been_approved": "Les commandes ont été approuvées",
            "successfully_updated": "Enregistrement mis à jour avec succès !",
            "successfully_deleted": "Enregistrement supprimé avec succès !",
            "error_on_request": "Un problème avec votre demande. Veuillez réessayer.",
            "copied": "Copié",
            "api_key_removed": "clé api supprimée",
            "api_key_added": "clé API ajoutée",
            "order_vouchers_successfully_deleted": "Les bons de cette commande ont été supprimés avec succès !",
            "order_vouchers_failed_delete": "Erreur lors de la suppression. Veuillez réessayer plus tard.",
            "order_vouchers_some_claimed": "La demande a échoué ! Certains des bons peuvent avoir été réclamés.",
            "verify_when_importing_info": "Lors de la vérification, la commande est communiquée au client et une facture est générée",
            "direct_delivery_incomplete_or_invalid": "Livraison directe détectée par des champs valides: {validFields}.\nCertaines valeurs sont manquantes ou invalides pour continuer: {invalidFields}",
            "sendgrid_failed_explanation": {
                "unknown": "Actuellement, nous ne sommes pas en mesure de vous communiquer le statut réel de votre commande. Soit parce que la date d'échéance n'est pas encore atteinte, soit parce que notre fournisseur de services de messagerie ne nous a pas encore fourni de statut d'envoi (cela peut prendre quelques heures).",
                "deferred": "Le serveur de messagerie du destinataire a rejeté l'e-mail, veuillez contacter votre service informatique pour demander pourquoi il a été rejeté. Si l’équipe informatique a résolu le problème, vous pouvez à nouveau envoyer le courrier à cette adresse.",
                "blocks": "Le serveur de messagerie du destinataire a rejeté l'e-mail, veuillez contacter votre service informatique pour demander pourquoi il a été rejeté. Si l’équipe informatique a résolu le problème, vous pouvez à nouveau envoyer le courrier à cette adresse.",
                "dropped": "L'e-mail n'a pas pu être remis car, lors de précédents e-mails, nous avons reçu une réponse automatique de votre fournisseur de services de messagerie indiquant qu'il ne pouvait pas être remis. Veuillez contacter notre service clientèle si le problème est résolu afin que nous puissions réessayer l'envoi.",
                "bounces": "Le serveur de messagerie du destinataire a rejeté l'e-mail, veuillez contacter votre service informatique pour demander pourquoi il a été rejeté. Si l’équipe informatique a résolu le problème, vous pouvez à nouveau envoyer le courrier à cette adresse."
            },
            "invalid_vat": "Numéro de TVA non valide",
            "vat_validation_error": "Impossible de valider la TVA pour le moment.",
            "cannot_change_vat_explanation": "Il n'est pas possible de changer le numéro de TVA d'une entité commerciale existante. Veuillez créer une nouvelle entité commerciale avec le nouveau numéro de TVA.",
            "campaign_updated": "Campagne modifié avec succès.",
            "something_went_wrong": "Quelque chose s'est mal passé, veuillez réessayer.",
            "video_updated": "Enregistrement mis à jour avec succès !",
            "cannot_add_multiple_vendor_in_cart": "Vous ne pouvez pas ajouter des cadeaux de plusieurs vendeurs dans le panier.",
            "no_direct_delivery_but_with_fields": "La livraison directe est désactivée mais l'excel contient des colonnes pour cela. Supprimez-les ou activez l'option \"Envoyez directement au bénéficiaire\".",
            "cannot_edit_your_permissions": "Il n'est pas possible de modifier vos propres autorisations. Veuillez demander à un autre administrateur de modifier vos autorisations.",
            "failed_to_place_the_order": "Impossible de passer la commande.",
            "the_cart_is_empty": "Le panier est vide.",
            "cannot_add_different_delivery_methods_to_cart": "L'ajout de différents modes de livraison au même panier n'est pas autorisé.",
            "orders_have_been_rejected": "Vos commandes ont été rejetées avec succès",
            "orders_have_been_approved": "Vos commandes ont été approuvées avec succès",
            "should_not_add_physical_gifts_to_kudos_order": "Vous ne pouvez commander des cadeaux numériques que pour les campagnes Kudos.",
            "no-gifts-found-for-the-occasion": "Aucun cadeau n'a été trouvé pour cette occasion.",
            "failed_updating": "Échec de la mise à jour",
            "video_not_updated": "La vidéo n'a pas été mise à jour!",
            "department_updated": "Le département a été mis à jour",
            "department_deleted": "Le département a été supprimé",
            "department_not_updated": "Le département n'a pas pu être mis à jour",
            "department_not_deleted": "Le département n'a pas pu être ajouté",
            "department_not_restored": "Un service portant ce nom a déjà existé mais n'a pas pu être réactivé.",
            "campaign_deleted": "Campagne supprimée avec succès.",
            "video_deleted": "Vidéo supprimée avec succès.",
            "video_not_deleted": "La vidéo n'a pas été supprimée!",
            "shipping_restricted_to_weekdays": "Le cadeau {gift} ne peut être livré que les jours {days}.",
            "delivery_date_field_must_be_greater_than_or_equal_to_earliest_delivery_date": "Le champ de la date de livraison doit être postérieur ou égal à la date {date}.",
            "delivery_date_field_is_invalid": "date_de_livraison doit être au format suivant : jj/mm/aaaa (exemple : 01/01/2023)",
            "cannot_deliver_on_delivery_date_because_it_is_holiday": "Aucune livraison ne peut être effectuée le {date}. Il s'agit d'un jour férié.",
            "delivery_date_field_must_be_in_shipping_option_available_delivery_days_array": "Jour de livraison {date} est un {day}. Le jour de livraison doit être l'un des suivants: {delivery_days}.",
            "welcome_email_sent": "Un e-mail de bienvenue est envoyé à l'utilisateur.",
            "user_is_not_active": "L'action n'est pas autorisée car l'utilisateur n'est pas actif.",
            "cannot_send_welcome_email_as_user_is_verified": "Impossible d'envoyer l'e-mail de bienvenue car l'utilisateur est déjà vérifié.",
            "sent_password_link_by_admin": "Un e-mail est envoyé à l'utilisateur avec les détails sur la réinitialisation de son mot de passe.",
            "new_links": "NOUVEAU LIENS -",
            "resend_vendor_order_mail": "Envoi de l'e-mail de commande",
            "vendor_mail_has_been_resent": "L'email de commande a été renvoyé.",
            "vendor_mail_resend_explanation": "Cliquez sur le bouton ci-dessous pour renvoyer l'e-mail de commande. Celui-ci contient de nouveaux liens vers des fichiers.",
            "vendor_mail_resend_button_title": "Renvoi d'e-mail",
            "to_field_is_required": "'Adressé_à' est obligatoire.",
            "recipient_first_name_field_is_required": "'Prénom du destinataire' est obligatoire.",
            "recipient_last_name_field_is_required": "'Nom de famille du destinataire' est obligatoire.",
            "no_delivery_in_the_future_for_non_vouchers_gift_but_with_fields": "Ce cadeau ne permet pas de définir la date de livraison dans Excel. Veuillez supprimer la colonne pour continuer à commander.",
            "street_field_is_required": "'Rue' est obligatoire.",
            "street_number_field_is_required": "'Numéro' est obligatoire.",
            "zip_code_field_is_required": "'Code postal' est obligatoire.",
            "city_field_is_required": "\"Ville\" est obligatoire.",
            "country_field_is_required": "\"Pays\" est obligatoire.",
            "proforma_notify_explanation": "Un email sera envoyé à cette adresse mail lorsqu'une nouvelle facture proforma aura été créée.",
            "magic_link_created": "Un nouveau lien a été envoyé à votre e-mail.",
            "shipping_cannot_be_done_in_this_day": "Cannot deliver on delivery date set",
            "no_apideck_connection": "Aucune connexion de synchronisation des employés n'a été trouvée. Veuillez reconfigurer la synchronisation.",
            "invoice_notify_cc_explanation": "Toute adresse électronique saisie ici recevra également les factures. Plusieurs adresses électroniques sont possibles, ajoutez une virgule entre chaque adresse électronique.",
            "order_notify_email_cc": "L'adresse électronique saisie ici recevra tous les courriels relatifs aux commandes. Plusieurs adresses électroniques sont possibles, ajoutez une virgule entre chaque adresse électronique.",
            "generating-try-later": "Des bons sont actuellement générés pour cette commande. Veuillez réessayer plus tard, une fois la génération terminée.",
            "cannot_add_when_direct_future_delivery_non_voucher_gift_in_cart": "Vous avez ajouté à votre panier un cadeau pour lequel une date de livraison est fixée par destinataire. Ce cadeau ne peut pas être combiné avec d'autres cadeaux. Veuillez d'abord passer la commande du cadeau dans votre panier.",
            "can_only_add_one_item_with_future_delivery_to_cart": "Vous ne pouvez ajouter qu'un seul cadeau à votre panier lorsque le cadeau a une date de livraison fixée par destinataire.",
            "fixed_po_number_explainer": "Utilise automatiquement ce numéro de commande pour toutes les commandes passées pour cette entité de l'entreprise. Cela n'inclut pas les commandes automatisées.",
            "fixed_cost_center_explainer": "Utilise automatiquement ce centre de coûts pour toutes les commandes passées pour cette entité de l'entreprise. Cela n'inclut pas les commandes automatisées.",
            "monthly_order_threshold_reached": "Limite mensuelle de commande atteinte",
            "order_is_pending_kadonation_approval": "La commande est en attente d’approbation par Kadonation. Nous vous contacterons bientôt.",
            "user_deleted": "L'utilisateur a été supprimé.",
            "mollie_repay_failed": "Votre paiement a échoué. Veuillez réessayer.",
            "logo_updated": "Le logo a été actualisé avec succès.",
            "keep-account-text": "Confirmez ci-dessous pour conserver votre compte."
        },
        "pagination": {
            "next": "Suivant",
            "previous": "Précédent",
            "first": "Premier",
            "last": "Dernier"
        },
        "user": {
            "profile": "Profil",
            "actions": "Actions",
            "fixed_cost_center_explanation": "Lorsqu'une valeur est indiquée ici, le centre de coûts sera automatiquement ajouté à toute commande passée par cet utilisateur.",
            "search": {
                "placeholder": "Recherche d'un utilisateur",
                "no_results": "Aucun utilisateur n'a été trouvé.",
                "loading": "Recherche d'utilisateurs..."
            },
            "buttons": {
                "edit": "Editer"
            },
            "approval_manager_explanation": "Cette personne devra approuver ou rejeter chaque commande passée par l'utilisateur.",
            "backup_approval_manager_explanation": "Cette personne sera en mesure d'approuver toute commande que {name} devrait approuver. Cette fonction est utile lorsque {name} part en vacances."
        },
        "occasion": {
            "shop_now": "Afficher les cadeaux",
            "your_occasions": "Occasions de cadeaux",
            "upsell_banner_title": "Avez-vous pensé à cette occasion ?",
            "other_occasions": "Autres occasions",
            "occasions_previously_bought_for": "Occasions pour lesquelles des achats ont déjà été effectués",
            "popular_gift_occasions": "Occasions de cadeaux populaires",
            "view_all_occasions": "Voir toutes les occasions",
            "webshop_link_button": "Commandez le Chèque Cadeau Kadonation",
            "upsell_banner_list_items": "Personnaliser avec un message pour le destinataire^Remboursable dans plus de 100 partenaires^Votre chèque cadeau est valable 3 ans^Idéal pour chaque occasion"
        },
        "countries": {
            "BE": "Belgique",
            "AT": "Autriche",
            "BG": "Bulgarie",
            "CY": "Chypre",
            "CZ": "République tchèque",
            "DE": "Allemagne",
            "DK": "Danemark",
            "EE": "Estonie",
            "ES": "Espagne",
            "FI": "Finlande",
            "FR": "France",
            "GB": "Royaume-Uni",
            "GR": "Grèce",
            "HR": "Croatie",
            "HU": "Hongrie",
            "IE": "Irlande",
            "IT": "Italie",
            "LT": "Lituanie",
            "LU": "Luxembourg",
            "LV": "Lettonie",
            "MT": "Malte",
            "NL": "Pays-Bas",
            "PL": "Pologne",
            "PT": "Portugal",
            "RO": "Roumanie",
            "SE": "Suède",
            "SI": "Slovénie",
            "SK": "Slovaquie",
            "CH": "Suisse",
            "UA": "Ukraine",
            "MK": "Macedonia",
            "IL": "Israel",
            "NO": "Norvège",
            "US": "l'états unit"
        },
        "project-vendors": {
            "delivery_address_info_for_home_delivery": "Cette commande doit être expédiée à l\\'adresse du domicile des destinataires. Vous trouverez les adresses dans le fichier Excel à 'télécharger les données des destinataires\\'.",
            "download_recipients_data": "Télécharger les données des destinataires",
            "recipients_data": "Données des destinataires",
            "shipping_limitation_notice": "Afficher uniquement les pays dans lesquels peut expédier ses produits.",
            "see_download_recipients_data": "Voir: Télécharger les données des destinataires",
            "personalization_file": "Le fichier de personnalisation:",
            "download_personalization_file": "Télécharger le fichier",
            "personalization_id": "Colorlab Personalization ID"
        },
        "passwords": {
            "reset": "Votre mot de passe a été réinitialisé !",
            "sent": "Nous vous avons envoyé un email avec le lien pour réinitialiser votre mot de passe !",
            "throttled": "Veuillez attendre avant de réessayer.",
            "token": "Ce token de réinitialisation du mot de passe n'est pas plus valable. Veuillez vous rendre sur l'application et demander un nouveau lien de réinitialisation du mot de passe.",
            "user": "Nous ne trouvons pas d'utilisateur avec cette adresse mail.",
            "password_created_successfully": "Votre mot de passe a été créé.",
            "invalid_token_while_creating": "Le lien pour créer un mot de passe n'est plus valide. Veuillez contacter votre administrateur RH pour demander un nouvel e-mail.",
            "password_already_created": "Vous avez déjà défini un mot de passe pour votre compte. Utilisez-le ici pour vous connecter ou utilisez le lien \"Mot de passe oublié\" pour définir un nouveau mot de passe.",
            "user_not_active": "Votre compte n'est pas actif. Veuillez contacter votre administrateur RH et lui demander s'il peut activer votre compte."
        },
        "activity": {
            "created": "Créé",
            "updated": "Actualisé"
        },
        "searchg": {
            "head_title": "Recherche d'un cadeau",
            "search_title": "Que recherchez-vous ?",
            "algolia_gift_search_placeholder": "Recherchez 'chocolat', 'boîte aux lettres', 'ballon', 'bon', 'apéro', ..."
        },
        "cookies": {
            "title": "Cookies",
            "text": "Kadonation souhaite collecter des données statistiques anonymes afin de rendre notre plateforme plus conviviale. Pour cela, nous avons besoin de votre permission. Êtes-vous d'accord ?",
            "accept": "Accepter",
            "decline": "Rejeter"
        }
    },
    "nl": {
        "colorlab": {
            "add-to-cart": "Klaar met ontwerpen",
            "save-configuration": "Klaar met ontwerpen"
        },
        "message": {
            "within_14_days": "Verwachting om binnen 2 weken geleverd te worden."
        },
        "employees": {
            "external_employee_id_info": "Geef hier het unieke referentienummer van deze werknemer in. Hiermee zullen de gegevens van je werknemer automatisch geüpdatet worden wanneer wijzigingen aangebracht worden.",
            "excel_upload": "Importeren via Excel",
            "manual_upload": "Werknemer toevoegen",
            "export": "Werknemers exporteren",
            "delete_selected": "Selectie verwijderen",
            "source": "Bron",
            "source_manual_input": "Handmatige invoer",
            "hris_sync": "API synchronisatie",
            "source_excel_upload": "Excel-upload",
            "source_hris_sync": "HRIS-synchronisatie",
            "employee": "Werknemer",
            "intro_notes": "'Werknemers' slaat de gegevens van uw werknemers op. Dit laat u toe te bestellen met adressen reeds ingevuld, het creëeren van Kudos campagnes en automatisch versturen van cadeaus op hun (werk)verjaardagen.",
            "sync": "Automatische synchronisatie",
            "delete_confirmation_title": "Werknemer verwijderen?",
            "delete_confirmation": "Weet u zeker dat u deze medewerker wilt verwijderen?<br>Deze actie kan niet ongedaan worden gemaakt.",
            "delete_multiple_confirmation_title": "Medewerkers verwijderen?",
            "delete_multiple_confirmation": "Weet u zeker dat u {count} medewerkers wilt verwijderen?<br>Deze actie kan niet ongedaan worden gemaakt.",
            "api_connection_title": "Werknemers API verbinding",
            "api_connection_description": "Verbind uw HR systeem, en we synchroniseren elke week automatisch uw werknemers and hun gegevens.",
            "manual_sync": "Manueel synchroniseren",
            "auto_sync": "Automatisch synchroniseren",
            "manual_sync_started": "Manueel synchroniseren gestart.",
            "auto_sync_started": "Automatisch synchroniseren gestart.",
            "manual_sync_completed": "Manueel synchroniseren voltooid.",
            "auto_sync_completed": "Automatisch synchroniseren voltooid.",
            "load_error_refresh_page": "Er is iets fout gegaan. Probeer het opnieuw door de pagina te vernieuwen.",
            "connection_success": "{connector} is geconnecteerd.",
            "setup_connection": "Instellen koppeling",
            "loading": "Charger...",
            "setting_up_connection": "Instellen van koppeling...",
            "connect_api_message": "Maximaliseer uw efficiëntie door uw HR-tool te koppelen aan Kadonation Select. Dan kunnen wij uw personeelsgegevens automatisch importeren in Kadonation Select, zodat u eenvoudiger bestellingen kunt plaatsen voor uw medewerkers.",
            "connect_api_title": "Synchroniseer uw werknemers vanuit uw HR-systeem in Kadonation Select",
            "not_synced_yet": "Nog niet gesynchroniseerd.",
            "last_synced_at": "Laatste synchronisatie",
            "syncing_employees": "Medewerkers synchroniseren",
            "in_progress": "Bezig",
            "success": "Geslaagd",
            "synchronization": "Synchronisatie",
            "type": "Type",
            "date": "Datum",
            "status": "Status",
            "sync_message": "{count} werknemers toegevoegd of bijgewerkt",
            "employee_sync": "Werknemer Sync {syncDate}",
            "employee_sync_summary": "Werknemers die toegevoegd of bijgewerkt werden tijdens de synchronisatie met {serviceName} op {syncDate} om {syncTime}.",
            "api_connection": "API-connectie",
            "missing_address_info": "De werknemer heeft geen adres ingesteld, contacteer een administrator om het adres aan te vullen via werknemersbeheer.",
            "failed": "Mislukt",
            "partial_success": "Gedeeltelijk gelukt",
            "address_info_hidden": "Alleen gebruikers met het recht “beheer werknemers“ kunnen het adres van de werknemer zien.",
            "manager": "Manager/teamleider",
            "link_companies": "Koppel bedrijven",
            "link_team_leader_companies": "Please link your Teamleader companies to your Kadonation companies",
            "kadonation_company": "Kadonation Company",
            "team_leader_company": "Teamleader Company",
            "save_companies": "Bedrijven opslaan",
            "rerun_employee_sync": "Opnieuw synchroniseren",
            "employees_added_successfully": "Werknemers succesvol toegevoegd",
            "employees_not_added": "Werknemers die niet toegevoegd konden worden",
            "employee_id": "Werknemer ID",
            "reason_of_failure": "Fout reden",
            "copy_employee_id": "Kopieer Werknemer ID",
            "automatically_sync_your_employees": "Synchroniseer uw medewerkers automatisch",
            "sync_process_error_title": "Er was een probleem met het synchroniseren van de werknemers",
            "no_sync": "Werknemers automatisch toevoegen",
            "employees_title": "Werknemersbeheer",
            "modify_connection": "Verbinding wijzigen",
            "synchronise_now": "Nu synchroniseren",
            "synchronising": "Synchroniseren...",
            "no_employees_set_title": "Geen werknemers toegevoegd",
            "no_employees_set_description": "De 'Werknemer' feature slaat de details van je werknemers op zodat je hier makkelijk toegang tot hebt in Select. Werknemers toevoegen is eenvoudig, je hoeft enkel je HR systeem te koppelen, of een Excel te uploaden.",
            "after_setting_employees": "Wanneer je Werknemers toegevoegd hebt, kan je:",
            "sync_with_hr_tool": "Automatisch synchroniseren met je HR systeem",
            "employees_possiblities_list_items": "Bestel geschenken met adressen en details reeds ingevuld |\nMaak (werk)verjaardag cadeau automatisaties aan, alsook Kudos campagnes waarmee de ontvanger berichtjes krijgt van zijn team |\nOntvang herinneringen aan (werk)verjaardagen (in ontwikkeling)",
            "add_manually": "Handmatig toevoegen",
            "what_are_employees": "Wat is de 'Werknemers' feature?",
            "company_entity": "Entiteit",
            "cant_edit_employee": "Deze werknemer kan niet aangepast worden.",
            "cant_edit_employee_description": "Deze werknemer is toegevoegd via de HRIS synchronisatie. Maak je wijzigingen in de HRIS software en we zullen deze overbrengen bij de volgende synchronisatie.",
            "no_email_for_track_and_trace": "Deze werknemer heeft geen email adres. Daarom hebben we ons email adres toegevoegd voor de track & trace informatie.",
            "add_new_team": "Nieuw team toevoegen",
            "select_from_saved_teams": "Kies uit bestaande teams",
            "type_employee": "Werknemer",
            "type_contractor": "Freelancer",
            "type_temp": "Tijdelijk",
            "type_other": "Ander",
            "dispatched": "Wachtend",
            "sync_throttled": "Probeer het later over {time_left} hours opnieuw",
            "sync_already_in_progress": "Er is al een actieve synchronisatietaak bezig. Wacht tot het klaar is voordat u het opnieuw probeert.",
            "contributor_qr_code_scan_string": "Hi {name} someone left you a message! Hurry up and scan it here.",
            "contributor_qr_code_shortlink_string": "{url}",
            "contributor_qr_code_thankyou_string": "Zeg dankjewel via de QR-code"
        },
        "cart": {
            "confirm-moq-warning": "Bevestig en ga naar de kassa",
            "cart-cleared": "Je winkelwagen werd leeggemaakt.",
            "clear": "Winkelwagen leegmaken",
            "shipping_fee_calculated_at_checkout": "Verzendkosten worden berekend bij het afrekenen",
            "added_to_cart": "Toegevoegd aan winkelwagen",
            "empty": "Geen artikelen in winkelwagen",
            "handling": "Extra's en verwerking",
            "remove_from_cart": "Remove from cart",
            "items_in_cart": "{count} artikel | {count} artikels"
        },
        "checkout": {
            "shipping_details": "Verzendingsdetails",
            "billing_details": "Betalingsinfo",
            "delivery_info_btn": "Hoe moet ik de leverdatum invullen?",
            "delivery_info_format": "Gelieve de leverdatum in te vullen in het volgende formaat: <b>\"jaar-maand-dag\"</b>.",
            "delivery_info_format_example": "Voorbeeld voor 1 mei 2021:",
            "delivery_info_after_date": "Levering niet mogelijk op data voor:",
            "delivery_info_no_weekend": "Geen levering op zaterdag en zondag",
            "delivery_info_urgent_dates": "Dringende levering mogelijk op volgende data:",
            "delivery_info_no_delivery_dates": "Geen levering mogelijk op volgende data:",
            "items_total": "Artikelen totaal",
            "delivery_details": "Leveringsgegevens",
            "order_summary": "Overzicht van de bestelling",
            "please_add_address": "Voeg een adres toe",
            "total_including_vat": "totaal (incl. btw)",
            "company_field_help_text": "Leeg laten bij verzending naar een persoonlijk adres.",
            "grouped_invoice_description": "Eén keer per maand ontvangt u een factuur met alle bestellingen die zijn toegevoegd aan de gegroepeerde maandelijkse factuur.",
            "separate_invoice_description": "Voor deze bestelling wordt een aparte factuur aangemaakt. Het wordt niet toegevoegd aan de gegroepeerde maandelijkse factuur.",
            "back_to_cart": "Winkelwagen",
            "back_to_billing": "Vorige",
            "continue_to_shipping": "Ga verder naar Verzenden",
            "place_order": "Plaats Bestelling",
            "breadcrumb_cart": "Winkelwagen",
            "breadcrumb_checkout": "Bestellen",
            "breadcrumb_billing": "Facturering",
            "breadcrumb_shipping": "Verzenden",
            "terms_agreement_validation_error": "Lees de voorwaarden van de overeenkomst. U moet akkoord gaan met de voorwaarden om verder te kunnen gaan.",
            "address_numbered": "Adres #{number}",
            "home_address_notification": "U hoeft geen verzendgegevens op te geven voor de artikelen die op huisadressen worden bezorgd.",
            "shipping_fee_calculated_at_next_step": "Berekend bij volgende stap",
            "there_are_errors": "Er zijn problemen met de informatie die je hebt ingevuld.",
            "delivery_for_future_notification": "Verwachte leveringsdatum is reeds gezet per ontvanger.",
            "remaining-credits": "Resterende Credits",
            "insufficient-credits-for-order": "Je hebt niet genoeg Credits om deze bestelling te plaatsen.",
            "bus_help_text_checkout": "Het woord \"Bus\" wordt later door ons toegevoegd (aan het 'bus' veld).",
            "calculating_shipping_fee": "Berekenen...",
            "continue_to_billing": "Verder naar facturatie",
            "back_to_shipping": "Terug naar verzending",
            "billing_details_shown_on_invoice": "Deze gegevens zullen op de factuur worden gezet.",
            "pay_immediately": "Meteen betalen",
            "pay_immediately_description": "Betaal je bestelling meteen via kredietkaart of debetkaart.",
            "pay_via_credits": "Betalen met Credits",
            "pay_via_credit_description": "Betaal je bestelling nu met voorafbetaalde Credits.",
            "pay_after_invoice": "Betalen na factuur",
            "pay_after_invoice_description": "Betaal je bestelling na ontvangst van de factuur.",
            "payment_method": "Betaalmethode",
            "direct_payment_disabled_order_approval_required": "(Niet beschikbaar wanneer goedkeuring vereist is)"
        },
        "credits": {
            "pay-with-credits": "Betalen via credits",
            "what-are-credits": "Hoe kom ik aan credits?",
            "i-want-to-pay-with-credits": "Ik wil met credits betalen",
            "current-balance": "Huidig saldo",
            "credits": "Credits",
            "need-more-credits": "Heb je meer credits nodig?",
            "need-more-credits-explanation": "Om meer credits te ontvangen kunt u contact opnemen met uw account manager. Zij zullen de credits aan uw saldo toevoegen en er een factuur voor aanmaken.",
            "index": {
                "credits-will-expire": "{credits} credits vervallen op {date}.",
                "description": "Credits zijn een digitale munteenheid die je kan uitgegeven in Kadonation Select. Je kan deze aankopen op eender welk moment en laten je toe om je uitgaven te budgetteren. Contacteer ons voor meer informatie over hoe Credits handig kunnen zijn in je organisatie. 1 Credit is gelijk aan 1 euro.",
                "buy-more-credits": "Meer credits kopen"
            },
            "expires-on": "Verloopt op {date}",
            "ordered-by": "Besteld door {name}",
            "no-credits-link-to-top-up": "Je hebt momenteel geen krediet.",
            "credits-with-value": "{count} credits",
            "remaining-via-invoice": "Resterend bedrag te betalen via factuur",
            "coming-soon": "Wat zijn credits?",
            "coming-soon-explanation": "Credits zijn een digitale munteenheid in Kadonation Select. Ze worden vooraf gekocht, via factuur, en vervolgens toegevoegd aan je Select-account. Zodra je Credits hebt, kun je er bestellingen mee plaatsen.\n\nGa naar de 'Credits' pagina in Select om Credits aan te vragen, of neem contact op met support. (Je kunt de knop 'Support' rechtsonder gebruiken)",
            "no-items-found": "Niets gevonden",
            "spend-history": "Credits gebruikt",
            "buy-history": "Credits gekocht",
            "no_items_found": "Niets gevonden",
            "expired_on": "Verlopen op {expired_on}",
            "use_credits_by_default": "Gebruik standaard credits bij het plaatsen van bestellingen",
            "no-credits": "Geen credits beschikbaar",
            "paying-with-credits": "Wordt betaald met Credits",
            "expiring-soon": "{amount} credits die verlopen op {date}",
            "balance-title": "Balans van alle {hierarchy}",
            "add-more-credits": "Credits toevoegen",
            "view-credit-history": "Toon credit geschiedenis",
            "view-transactions": "Toon geschiedenis",
            "history": {
                "title": "Credit geschiedenis van",
                "spent-from": "Uitgegeven van {from}",
                "added-to": "Toegevoegd aan {to}",
                "deducted-from": "Afgetrokken van {from}",
                "short-title": "Credit Geschiedenis"
            },
            "plural": {
                "user": "Gebruikers",
                "entity": "Bedrijfsentiteiten",
                "department": "Departementen"
            },
            "view-credit-balances": "Balansen bekijken",
            "search-for-hierarchy": "Zoek naar {hierarchy}",
            "by": "Door",
            "added": "Toegevoegd",
            "spent": "Uitgegeven",
            "deducted": "Afgetrokken",
            "amount-of-credits": "Bedrag toe te voegen",
            "hierarchy-to-add-credits-to": "Voeg credits toe aan",
            "request-credits": "Credits aanvragen",
            "select-a-hierarchy": "Selecteer een optie",
            "credit-request-placed-successfully": "Credits zijn succesvol aangevraagd. Deze worden binnenkort toegevoegd.",
            "add-modal-subtext-non-link": "Credit aanvragen worden eerst goedgekeurd door Kadonation, waarna je een factuur zal ontvangen. Credits worden bruikbaar na betaling van de factuur. Door credits aan te kopen ga je akkoord met onze",
            "add-modal-subtext-link": "servicevoorwaarden",
            "use-from": "Gebruik credits van",
            "partially-paid": "Gedeeltelijk met credits betaald, rest via factuur",
            "refund": "Terugbetaling",
            "refunded_for": "Terugbetaald voor"
        },
        "errors": {
            "mobile": "Dit platform wordt niet ondersteund op mobiele apparaten. Gelieve je aan te melden via een computer of laptop.",
            "error-request": "Fout op uw verzoek.",
            "saml-auth-403": "Neem contact op met uw bedrijfsbeheerder als u denkt dat u toegang moet hebben tot deze applicatie.",
            "general-error": "Er ging iets mis, probeer later opnieuw.",
            "saml-auth-bad-configured": "The SAML authentication has been incorrectly configured. Please contact fabrice@kadonation.com to check the configuration.",
            "error_on_row": "Er is een fout op rij {row}.",
            "no_result_found": "No result found",
            "row_has_errors": "Deze rij bevat fouten.",
            "there_are_errors_on": "Er zijn fouten op",
            "error_form": "Er zijn fouten in de velden dat je hebt ingevuld.",
            "error_page_401_title": "Ongeautoriseerd",
            "error_page_403_title": "Toegang Geweigerd",
            "error_page_404_title": "Niet Gevonden",
            "error_page_419_title": "Pagina Verlopen",
            "error_page_429_title": "Te Veel Verzoeken",
            "error_page_500_title": "Oeps. Er is iets fout gegaan.",
            "error_page_503_title": "We Zijn In Onderhoud",
            "error_page_401_generic_description": "U moet inloggen om toegang te krijgen tot deze pagina.",
            "error_page_403_generic_description": "U heeft geen toegang tot deze pagina.",
            "error_page_404_generic_description": "De pagina die u zoekt, kan niet worden gevonden.",
            "error_page_419_generic_description": "Ga terug naar het formulier dat u probeert in te dienen, laad de pagina opnieuw en probeer opnieuw in te dienen.",
            "error_page_429_generic_description": "Het spijt ons, maar we hebben momenteel te maken met een groot aantal verzoeken en kunnen ze niet allemaal tegelijk verwerken. Probeer het later opnieuw en zorg ervoor dat u niet te snel te veel verzoeken indient. Bedankt voor je geduld.",
            "error_page_500_generic_description": "Sorry, er is iets misgegaan aan onze kant en we kunnen momenteel niet aan uw verzoek voldoen. Probeer het later opnieuw of neem contact op met ons ondersteuningsteam voor hulp. Bedankt voor uw begrip.",
            "error_page_503_generic_description": "Het spijt ons, maar de service waartoe u toegang probeert te krijgen, is momenteel niet beschikbaar vanwege onderhoud of andere technische problemen. Ons team werkt eraan om alles zo snel mogelijk weer op gang te krijgen, dus kom later nog eens terug. Bedankt voor uw geduld en begrip."
        },
        "permissions": {
            "select_all": "Selecteer alle machtigingen",
            "admin_permission_explainer": "Instellingen kunnen bekijken en bewerken (inclusief API-sleutels en SAML SSO). In staat om beschikbare gelegenheden en geschenken te bewerken. In staat om automatiseringen aan te maken en te bewerken (als er ook bestelrechten zijn toegekend).",
            "user_management_explainer": "Kan gebruikers zien, aanmaken en bewerken.",
            "order_explainer": "In staat om bestellingen te plaatsen voor het departement en entiteit waartoe deze gebruiker behoort.",
            "order_for_all_explainer": "In staat om bestellingen te plaatsen voor alle departementen en entiteiten van het bedrijf.",
            "view_my_invoices_explainer": "In staat om facturen te bekijken op basis van bestellingen die deze gebruiker heeft geplaatst.",
            "view_all_invoices_explainer": "Alle facturen kunnen bekijken.",
            "reporting_explainer": "In staat om rapporten over de uitgaven te zien.",
            "manage_all_orders_explainer": "Alle bestellingen en bestelgegevens (inclusief adressen) kunnen bekijken en bewerken.",
            "budget_constraint_management_explainer": "In staat om budgettaire beperkingen te bewerken.",
            "manage_kudos_campaigns_explainer": "Kudos-campagnes kunnen bekijken, aanmaken en bewerken.",
            "manage_employees_explainer": "In staat om werknemers te zien, te creëren en te bewerken. In staat om werknemersinformatie te zien in Select. Indien uitgeschakeld, kan deze gebruiker geen adressen of persoonlijke gegevens van de Werknemer zien (maar kan wel bestellen voor hen via naam van Werknemer).",
            "approve_orders_explainer": "Kan bestellingen voor gekoppelde gebruikers goedkeuren. Koppel bestellende gebruikers aan deze goedkeurende gebruiker via het tabblad 'Profiel' hierboven.",
            "manage_credits_explainer": "Bekijken van aangekochte en uitgegeven credits",
            "order_for_entity_explainer": "In staat om bestellingen te plaatsen voor all departementen en de entiteit waartoe deze gebruiker behoort."
        },
        "budget": {
            "title": "Budgetten",
            "text": "Op deze pagina kunt u voor elke gelegenheid de drempels voor het bedrag instellen. Met de waarschuwingsdrempel kunnen ze nog steeds bestellen, met de blokkerende drempel niet. U kunt het cadeaubeleid voor de gelegenheid specificeren door de velden in te vullen. Dit wordt gevisualiseerd bij het toevoegen aan de winkelwagen om als richtlijn te dienen.",
            "warning-threshold": "Waarschuwingsdrempel",
            "blocking-threshold": "Blokkerende drempel",
            "notifications-title": "Bewerk bedrijfsbeleid melding",
            "default-threshold-message": "Het {attribute} mag niet hoger zijn dan € {max}.",
            "proceed-warning-threshold": "Weet je het zeker?",
            "updated-threshold-amounts": "Bijgewerkte drempelbedragen.",
            "updated-threshold-messages-occasion": "Bijgewerkte drempelberichten voor {occasion}",
            "warning-threshold-message": "Bedrag is hoger dan € {max}. Weet u het zeker?",
            "default-warning-info-message": "Consulteer het bedrijfsbeleid vanaf € {max} per geschenk.",
            "default-blocking-info-message": "Bedrijfbeleid heeft het bedrag per geschenk op maximum € {max} gezet.",
            "validation-threshold-min": "De {attribute} moet minimaal € {min} zijn.",
            "validation-threshold-max": "De {attribute} mag niet groter zijn dan € {max}",
            "validation-messages-required_with": "Voer alle talen in.",
            "message_rows": "{message} Rij(en) {rows}.",
            "save_budget": "Budget opslaan"
        },
        "shared-order-approval": {
            "step-1": {
                "title": "Offerte(s) bekijken en tekenen",
                "text": "Je moet het volgende nog doen zodat we jouw bestelling kunnen plaatsen.",
                "download_title": "1. Download de beschikbare offertes",
                "download_text": "Je kan maar één offerte per bestelling ondertekenen.\nAls je meer dan één van deze offertes wil gebruiken zullen we meerdere bestellingen moeten maken.",
                "upload_title": "2. Ondertekende offerte opladen",
                "upload_text": "Na het downloaden van de offertes dien je de ondertekende offerte hier op te laden. Zolang je de offerte niet opslaat kan je de ondertekende offerte nog veranderen.",
                "select_quotation_label": "Gelieve te selecteren welke offerte je wil gebruiken.",
                "upload_quotation_label": "Laad jouw geondertekende offerte hier op",
                "submit": "Getekende offerte doorsturen",
                "processing_your_quotation": "We verwerken uw offerte",
                "processing_your_quotation_explanation": "We laten je weten via een email, van zodra de bestelling klaar staat om goedgekeurd te worden. Kijk in de tussentijd gerust eens naar de offertes die beschikbaar waren en welke je hebt geüpload.",
                "available_quotations": "Beschikbare Offertes",
                "available_quotations_explanation": "Dit waren de offertes die we u hebben aangeboden. U kunt slechts voor één offerte tegelijk een bestelling plaatsen. Indien u een bestelling wilt plaatsen voor een andere offerte neem dan gerust contact met ons op.",
                "your_signed_quotation": "Uw Getekende Offerte",
                "your_signed_quotation_explanation": "Dit is de ondertekende offerte die u heeft geüpload. Dit is de offerte die we momenteel aan het verwerken zijn.",
                "deadline-missed": "{deadline} deadline verstreken!",
                "deadline-missed-explanation": "De deadline voor het uploaden van de ondertekende offerte is verstreken, wij kunnen geen tijdige levering garanderen!",
                "deadline-approaching": "{deadline} deadline nadert!",
                "deadline-approaching-explanation": "Upload uw ondertekende offerte vóór deze datum, anders kunnen wij een tijdige levering niet garanderen!",
                "whoops": "Oeps...",
                "no-quotations-to-sign": "Er zijn geen offertes beschikbaar om te ondertekenen.",
                "contact-support": "Contacteer onze helpdesk"
            },
            "step-2": {
                "customize_and_order": "Je bestelling personaliseren en plaatsen",
                "customize_and_order_description": "Hier kan je alle artikelen van je bestelling bekijken en indien gewenst ook aanpassen.",
                "order_id": "Bestelnummer",
                "view_quotation": "Bekijk offerte",
                "personalize_order": "Wil je je bestelling personaliseren?",
                "personalize_order_description": "Voeg namen, persoonlijke berichten ... toe aan de artikels.",
                "invoiced_to": "Factuuradres",
                "edit_po_cost_center": "Wijzig uw PO nummer en/of kostenplaats",
                "edit_po_title": "Voeg een PO nummer en/of kostenplaats toe aan uw bestelling.",
                "place_order": "Plaats bestelling van € {cost}",
                "no_order_items_found": "Geen bestelitems gevonden.",
                "deadline-missed": "{deadline} deadline verstreken!",
                "deadline-missed-explanation": "De deadline voor het uploaden van de ondertekende offerte is verstreken, wij kunnen geen tijdige levering garanderen!",
                "deadline-approaching": "{deadline} deadline nadert!",
                "deadline-approaching-explanation": "Upload uw ondertekende offerte vóór deze datum, anders kunnen wij een tijdige levering niet garanderen!",
                "add_delivery_address": "Leveringsadres toevoegen",
                "update_delivery_address": "Leveringsadres bijwerken",
                "update_delivery_address_explanation": "Wijzig het leveringsadres voor de bestelling",
                "add_delivery_address_explanation": "Voeg een leveringsadres toe aan uw bestelling",
                "delivery-address-updated": "Het leveringsadres werd succesvol bijgewerkt.",
                "errors-in-the-form": "Het formulier bevat fouten, gelieve ze na te kijken.",
                "failed-to-update-delivery-address": "We konden het afleveradres voor de bestelling op dit moment niet bijwerken. Probeer het opnieuw of neem contact op met de klantendienst.",
                "can_not_place_order_error": "Bestelling kan niet geplaatst worden. Bepaalde artikelen bevatten fouten of missende informatie.",
                "order_has_errors": "De bestelling bevat fouten.",
                "review_item": "Gelieve dit artikel te controleren: '{item}'.",
                "review_items": "Gelieve de bestelde artikelen te controleren"
            },
            "step-3": {
                "title": "We zijn je bestelling aan het verwerken.",
                "subtitle": "We zijn hard aan het werk om je bestelling te verwerken. Hieronder vind je een overzicht van je bestelling."
            },
            "back_to_order_overview": "Terug naar besteloverzicht",
            "display_logo": "Logo toevoegen?",
            "display_logo_description_one": "Als je een logo toevoegt, dan wordt deze mee afgedrukt op jouw geschenken.",
            "display_logo_description_two": "Als er reeds een logo is ingesteld voor jouw bedrijf, dan zal je deze hier zien staan.",
            "logo_will_be_included": "Logo is toegevoegd.",
            "logo_will_not_be_included": "Logo is niet toegevoegd.",
            "preview_voucher_and_email": "Verstuur een voorbeeld e-mail",
            "preview_voucher_and_email_description": "Benieuwd hoe de ontvangers hun digitale Kadonation cadeaubon zullen ontvangen, verstuur dan een voorbeeld e-mail.",
            "send_example": "Verstuur een voorbeeld",
            "preview_landing_page": "Bekijk voorbeeld van landingspagina",
            "preview_landing_page_description": "De begunstigden zullen via de landingspagina hun Kadonation cadeaubon kunnen activeren.",
            "view_landing_page": "Bekijk voorbeeld van landingspagina",
            "recipient_list": "Voeg de lijst van begunstigden toe.",
            "recipient_list_description": "Je kan de begunstigden uploaden via een excelbestand.",
            "upload_through_file": "Opladen via bestand",
            "upload_description": "Download het excel-sjabloonbestand en vul de gevraagde velden in.",
            "list_of_recipients": "Overzicht van begunstigden",
            "modified_order_message": "Je hebt meer ontvangers toegevoegd of je hebt het bedrag aangepast waardoor het bedrag niet meer gelijk is aan de offerte. Houd er rekening mee dat de prijs van de bestelling is bijgewerkt en dat de offerte mogelijk niet meer overeenkomt met de werkelijke bestelling.",
            "sending": "Een voorbeeld sturen...",
            "something_went_wrong": "Oeps. Er is iets fout gegaan.",
            "preview_email_sent": "Een voorbeeld e-mail is verzonden.",
            "provide_valid_email": "Gelieve een geldig e-mailadres op te geven.",
            "minimum_one_recipient": "Voeg minstens één begunstigde toe.",
            "direct_to_recipient": "Rechtstreeks naar ontvanger verzenden",
            "physical_direct_to_recipient_explanation": "Je bestelling wordt naar het thuisadres van de ontvanger verzonden, u kunt dus geen algemeen verzendadres opgeven.",
            "digital_direct_to_recipient_explanation": "Jouw bestelling wordt rechtstreeks naar het e-mailadres van de ontvanger gestuurd.",
            "view_examples": "Voorbeeld Bekijken",
            "personalize": "Personaliseer",
            "view_data": "Bekijk gegevens",
            "preview_voucher": "Bekijk printbestanden",
            "preview_voucher_description": "Bekijk welke printbestanden gebruikt zullen worden om de cadeaubonnen af te drukken. Gebruik dit om te bekijken hoe je cadeau er zal uitzien.",
            "preview_the_vouchers": "Bekijk een voorbeeld van de cadeaubon in PDF-formaat",
            "step": "Stap {step}",
            "no_delivery_information": "Geen leveringsinformatie verstrekt.",
            "delivery": "Levering",
            "discount_has_been_applied": "Er is {discount}% korting toegepast",
            "step-4": {
                "title": "Jouw bestelling is verzonden.",
                "description": "Wij hebben je bestelling verzonden en je zou jouw bestelling spoedig moeten ontvangen. We hebben je een e-mail gestuurd met de track and trace link."
            },
            "step-5": {
                "description": "Je hebt via e-mail jouw factuur ontvangen, mocht je hier nog vragen over hebben contacteer dan de klantendienst. Wij hopen dat alles naar wens is en zien je graag terug!",
                "title": "Jouw factuur voor uw bestelling.",
                "subtitle": "De factuur van deze bestelling is per mail aan jou bezorgd."
            },
            "select-via-employees-explanation": "Selecteer hier alle medewerkers of teams die je een cadeau wenst te geven. Je kan medewerkers één voor één toevoegen, per team of allemaal tegelijkertijd. Nadien kan je het geschenk personaliseren per medewerker of voor meerdere ineens.",
            "show-note": "Toon notitie",
            "hide-note": "Verberg notitie",
            "generating_for_the_recipient": "We genereren de demo-voucher voor  {to}",
            "excel_limitation_note": "Let op: Het persoonlijke bericht is gelimiteerd tot 300 karakters. Elke enter telt voor 40 karakters. Taal en land moeten gekozen worden van de beschikbare opties in het voorbeeld bestand. Emojis zijn niet ondersteund.",
            "place_order_confirmation_description": "Dit zal je bestelling van € {cost} bevestigen. Je zal geen wijzigingen meer kunnen maken en we zullen meteen starten met het verwerken van je bestelling. Ben je zeker dat wenst door te gaan met het plaatsen van je bestelling?",
            "preview_greeting_card": "Bekijk een voorbeeld van de wenskaart in PDF-formaat"
        },
        "invoice": {
            "KADONATION-GIFT-CARD": "Kadonation cadeaubon(nen)",
            "GIFTCARD": "Kadonation kadokaartje(s)",
            "GIFTCARD-ENVELOPPE": "Kadonation kadokaartje(s) in enveloppe",
            "GIFTBOX": "Kadonation kadodoosje(s)",
            "GIFTBOX-SLEEVE": "Kadonation kadodoosje(s) met sleeve",
            "VAT": "BTW",
            "INVOICE-ADDRESS": "Facturatieadres",
            "DELIVERY-ADDRESS": "Leveringsadres",
            "KVK-NUMBER": "KVK nummer",
            "CREDIT_NOTE": "Kredietnota",
            "INVOICE": "Factuur",
            "CREDIT-NOTE-LINKS-TO-INVOICE": "Kredietnota mbt. factuur {invoiceNumber}",
            "DATE": "Datum",
            "EXPIRATION-DATE": "Vervaldatum",
            "CLIENT-REFERENCE": "Klantenreferentie",
            "ORDER-NUMBER": "Bestelnummer",
            "PO-NUMBER": "PO nummer",
            "DESCRIPTION": "Beschrijving",
            "AMOUNT-EXCL-VAT": "Bedrag (excl. BTW)",
            "QUANTITY": "Aantal",
            "TOTAL": "Totaal",
            "NET": "Netto",
            "SHIPPING-COSTS": "Verzendkosten",
            "PAYMENT-REFERENCE": "Mededeling",
            "BANK": "Bank",
            "TERMSMESSAGE": "Onze algemene voorwaarden vind je op de volgende pagina",
            "ORDER": "bestelling",
            "PO": "PO nummer",
            "handling_fee": "Verwerkingskosten",
            "urgent_fee": "Urgentiekost",
            "total_exvat": "Totaal (excl. BTW)",
            "OCCASION": "Gelegenheid",
            "GIFT": "Geschenk",
            "PRICE": "Prijs",
            "DETAILED_REFERENCE": "Detail Referentie",
            "FROM": "Van",
            "TO": "Aan",
            "MESSAGE": "Boodschap",
            "invoice_detail": "Detail",
            "INVOICE_DETAIL": "Detail",
            "credit_note_regarding_invoice": "Betreffende factuur",
            "CREDIT-NOTE": "Kredietnota",
            "shipping_fee": "Verzendingskost",
            "PRO_FORMA": "Profroma Factuur",
            "total_invat": "Total (incl. BTW)",
            "home_delivery_fee": "Thuisleveringskost",
            "INTRA-COMMUNITY-EXPLANATION": "intracommunautaire levering, vrijgesteld van btw art 39bis 1° W. btw",
            "INVOICE-DETAILS-REFERENCE": "Je kan extra details van je factuur bekijken via het factuuroverzicht of via deze link <a href=\"{invoiceDetails}\">{invoiceDetails}</a>.",
            "no_invoices_found": "Je hebt nog geen facturen.",
            "paid_via_credits": "Betaald via credits",
            "to_be_paid_invat": "Te betalen (incl. BTW)",
            "credit_description": "Credits",
            "auto_approve_tenant_default": "Default van Tenant",
            "auto_approve_yes": "Ja",
            "auto_approve_no": "Nee",
            "PAID-BY-CREDITS": "Factuur reeds (deels) voldaan met credits.",
            "BALANCE-TO-BE-PAID": "Te betalen saldo",
            "order_list_for_proforma_auto_finalization": "bestellen|bestellen",
            "validation_max_date_range_for_export": "Please select a date range that is at most 1 year.",
            "export-mail-title": "Je geëxporteerde facturen",
            "export-mail-text": "Je hebt een export aangevraagd van de facturen van Kadonation. Je vindt deze in de Excel in bijlage.",
            "export-mail-subject": "Je geëxporteerde facturen",
            "export": "Facturen Exporteren",
            "export_successful": "Facturen worden geëxporteerd. Dit kan even duren. We sturen je een email met de export in.",
            "select_date_range_first": "Gelieve eerst de datum filter te gebruiken.",
            "clear_filters": "Reset filter",
            "max_date_range_warning": "Enkel facturen van de afgelopen 365 dagen kunnen getoond worden.",
            "last_month": "Laatste 30 dagen",
            "last_3_months": "Laatste 90 dagen",
            "last_6_months": "Laatste 180 dagen",
            "last_12_months": "Laatste 365 dagen",
            "invoice_date_range": "Filter facturen",
            "credit_for_receiver_description": "Credits voor {receiver}",
            "INVOICE-ALREADY-PAID": "Factuur reeds betaald",
            "INVOICE-TERMS-AND-CONDITIONS": "Algemene voorwaarden – voorliggende factuur wordt beheerst door de algemene voorwaarden B2B en maken bijgevolg integraal deel uit van deze factuur. In geval van enige tegenstrijdigheid of inconsistentie tussen deze factuur en de algemene voorwaarden B2B zullen de bepalingen van de algemene voorwaarden B2B prevaleren, tenzij deze factuur uitdrukkelijk specifieke bepalingen bevat, in welk geval dergelijke bepalingen van de factuur zullen prevaleren. De algemene voorwaarden B2B zijn consulteerbaar op de website van Kadonation via de volgende hyperlink: <a target='_blank' href='https://kadonationselect.com/terms-and-conditions'>Algemene Voorwaarden</a>.”\n\n“Privacy – De verwerking van persoonsgegevens in het kader van de uitvoering van deze Overeenkomst worden uitsluitend uitgevoerd en beheerst in overeenstemming met het Gegevensverwerkingsbeleid van Kadonation, zoals consulteerbaar op de website van Kadonation via de volgende hyperlink: <a target='_blank' href='https://kadonationselect.com/data-processing-agreement'>Data Processing Policy</a>. De Klant erkent uitdrukkelijk dat hij/zij door het gebruik van het Platform, het bestellen van de Producten en/of het aangaan van een Overeenkomst met Kadonation, het Gegevensverwerkingsbeleid in zijn geheel heeft gelezen en geaccepteerd.",
            "default-subscription-invoice-section-name": "abonnement"
        },
        "general": {
            "my_dashboard": "Mijn dashboard",
            "gift_occasions": "Geschenkgelegenheden",
            "check_all_occasions": "Bekijk alle gelegenheden",
            "reports": "Rapporten",
            "coming_soon": "Binnenkort beschikbaar",
            "create_user": "Nieuwe gebruiker",
            "user_management": "Gebruikers",
            "name": "Naam",
            "department": "Departement",
            "email": "E-mail",
            "cancel": "Annuleer",
            "add_person": "Voeg een gebruiker toe",
            "english": "Engels",
            "dutch": "Nederlands",
            "french": "Frans",
            "german": "Duits",
            "permissions": "Rechten",
            "assign": "Toewijzen",
            "assign_permission": "Verleen toestemming",
            "reset_password": "Wachtwoord resetten",
            "cart": "Winkelmandje",
            "go_to_checkout": "Volgende",
            "occasion": "Gelegenheid",
            "product": "Product",
            "quantity": "Hoeveelheid",
            "price": "Prijs",
            "discount": "Korting",
            "total_ex_vat": "Totaal (excl. BTW)",
            "total_inc_vat": "Totaal (incl. BTW)",
            "handling_fee": "Extra's en verwerking",
            "grand_total": "Eindtotaal",
            "checkout": "Bestellen",
            "delivery_address": "Leveringsadres",
            "overview": "Overzicht",
            "download": "Download",
            "back_to_dashboard": "Keer terug naar dashboard",
            "items": "Aantal artikels",
            "urgency_fee": "Urgentiekost",
            "item": "Artikel",
            "company_settings": "Bedrijfsinstellingen",
            "company_departments": "Bedrijfsdepartementen",
            "all_departments": "Alle departementen",
            "company_entities": "Bedrijfsentiteiten",
            "add_department": "Voeg een departement toe",
            "close": "Sluiten",
            "add_entity": "Voeg een entiteit toe",
            "vat_number": "BTW-nummer",
            "address": "Adres",
            "add_company_entity": "Entiteit toevoegen",
            "edit_delivery_address": "Adres bewerken",
            "back": "Terug",
            "edit_company_entity": "Bewerk entiteit",
            "invoice_address": "Facturatieadres",
            "default": "Standaard",
            "add_delivery_address": "Adres toevoegen",
            "delivery_addresses": "Leveringsadressen",
            "orders_overview": "Overzicht van de bestellingen",
            "company_entity": "Bedrijfsentiteit",
            "invoices_type": "Factuurtype",
            "expected_delivery": "Gewenste leverdatum",
            "messages": "Boodschappen",
            "message": "Boodschap",
            "order_item": "Bestelartikel",
            "from": "Van",
            "to": "Aan",
            "locale": "Taal",
            "gift": "Geschenk",
            "delivery_date": "Gewenste leverdatum",
            "subtotal": "Subtotaal",
            "cost": "Kost",
            "total": "Totaal",
            "order_details": "Details van de bestelling",
            "vat": "BTW",
            "order_items": "Bestelde artikels",
            "select_occasion": "Kies een gelegenheid",
            "edit": "aanpassen",
            "edit_your_occasions": "Gelegenheden wijzigen",
            "update": "Update",
            "invoices_overview": "Overzicht van de facturen",
            "invoices_date": "Factuurdatum",
            "total_amount": "Totaalbedrag",
            "select_gift_for_occasion": "Kies een geschenk voor de gelegenheid: {occasion}",
            "select_gift": "Kies een geschenk",
            "enter_the_details": "Geef de details in",
            "settings": "Instellingen",
            "logout": "Afmelden",
            "home": "Homepagina",
            "orders": "Bestellingen",
            "invoices": "Facturen",
            "login": "Aanmelden",
            "select_options": "Kies een optie",
            "select_price": "Prijs selecteren",
            "personal_message": "Persoonlijk bericht",
            "for_individuals": "Voor afzonderlijke personen",
            "for_group": "Voor een groep",
            "order": "Bestelling",
            "download_file": "Bestand downloaden",
            "choose_saved_address": "Kies een opgeslagen adres",
            "choose_new_address": "Of gebruik een nieuw adres",
            "be": "België",
            "nl": "Nederland",
            "urgent_delivery": "Dringende levering",
            "cart_empty": "Jouw winkelmandje is leeg, gelieve een product toe te voegen.",
            "generating_download": "Download wordt gegenereerd...",
            "invoice_type": "Factuurtype",
            "separate_invoice": "Apart",
            "grouped_monthly_invoice": "Maandelijks gegroepeerd",
            "make_first_order": "Plaats uw eerste bestelling",
            "empty_orders_text": "U heeft nog geen bestellingen.",
            "add_to_cart": "Voeg toe aan mijn winkelmandje",
            "empty_invoices_text": "U heeft nog geen facturen.",
            "add_address": "Voeg een adres toe",
            "change_address": "Adres wijzigen",
            "no_address_selected": "Geen adres geselecteerd",
            "edit_occasions_info": "Beheer welke gelegenheden op je platform worden getoond. Door een gelegenheid in te schakelen, wordt deze zichtbaar, zodat je hiervoor geschenken kunt bekijken en kopen. Als een gelegenheid is uitgeschakeld, wordt deze verborgen.",
            "view_order": "Bekijk de bestelling",
            "view_invoice": "Bekijk de factuur",
            "view_invoices_overview": "Bekijk het factuuroverzicht",
            "invoices_id": "ID",
            "occasions": "Geschenkgelegenheden",
            "order_id": "ID",
            "user": "Gebruiker",
            "status": "Status",
            "active": "Actief",
            "inactive": "Inactief",
            "activate": "Activeren",
            "deactivate": "Deactiveren",
            "total_value": "Totale waarde",
            "exc": {
                "_VAT": "Excl. BTW"
            },
            "save": "Opslaan",
            "total_handling_fee": "Totaal extra's en verwerking",
            "VAT": "BTW",
            "set_password": "wachtwoord instellen",
            "order_confirmation_CTA": "overzicht van je bestelling",
            "invoice_mail_CTA": "factuuroverzicht bekijken",
            "language": "Ontvanger's taal",
            "create_password": "Maak een wachtwoord aan",
            "save_and_login": "Opslaan en inloggen",
            "urgent_fee": "Urgentiekost",
            "terms_and_framework_agreement": "De voorwaarden van de overeenkomst zijn vastgelegd in een kaderovereenkomst.",
            "read_and_accept": "Ik heb hetvolgende gelezen en geaccepteerd:",
            "terms_and_agreement": "de voorwaarden van de overeenkomst",
            "for_this_order": "Voor deze bestelling",
            "department_name": "Naam van het departement",
            "order_terms": "de bestelvoorwaarden",
            "select_amount": "Bedrag",
            "enter_amount": "Geef een bedrag in",
            "order_terms_and_agreement": "Ik heb de voorwaarden voor deze bestelling gelezen en accepteer ze.",
            "for": "voor",
            "link": "Klik hier om de voorwaarden van de overeenkomst te raadplegen.",
            "value": "Waarde",
            "privacy_policy": "Accepteer beleid",
            "use_policy": "Gebruiksbeleid",
            "data_policy": "Gegevensbeleid",
            "read_and_accept_use_policy": "Ik heb het gebruiksbeleid gelezen en ga ermee akkoord",
            "read_and_accept_data_policy": "Ik heb het gegevensbeleid gelezen en ga hiermee akkoord",
            "invoice": "Factuur",
            "creditnote": "Kredietnota",
            "invoice_section": "Sectie",
            "invoice_sections": "Secties",
            "invoice_item": "Item",
            "invoice_items": "Items",
            "description": "Omschrijving",
            "save_and_submit": "Opslaan en verzenden",
            "save_as_draft": "Opslaan als concept",
            "change_status": "Verander status",
            "status_changed": "Status gewijzigd!",
            "status_not_changed": "Status niet gewijzigd voor de volgende facturen (je kan enkel facturen met status 'ontwerp', 'in afwachting' of 'proforma' goedkeuren):",
            "linked_invoice": "Gekoppelde factuur",
            "create_creditnote": "Kredietnota maken",
            "credit_mail_CTA": "factuuroverzicht bekijken",
            "via_form": "Via formulier",
            "via_excel": "Via excel",
            "detailed_reference": "Detail referentie",
            "invoice_or_credit_note": "Type",
            "total_shipping_fee": "Totale verzendingskost",
            "current_logo": "Logo",
            "contact_support": "Gelieve onze support te contacteren om uw logo te vervangen",
            "no_logo": "Er is nog geen logo ingesteld",
            "shipping_fee": "Verzendingskost",
            "and_department": "en department",
            "kayako_faq": "Veelgestelde vragen",
            "new_invoice": "Nieuwe factuur",
            "update_invoice": "Factuur aanpassen",
            "budgets": "Budgetten",
            "finalize": "Finaliseer",
            "generate_label": "Genereer label",
            "delivery_email": "E-mailadres",
            "delivery_direct": "Stuur geschenk rechtstreeks naar ontvanger",
            "proforma": "Proforma",
            "po_number": "PO-nummer",
            "proforma_id": "Proforma ID",
            "group": "Groep",
            "team": "Team",
            "import": "Importeer",
            "delivery_direct_tag": "Rechtstreeks naar ontvanger",
            "create": "aanmaken",
            "delivery_direct_quantity-limit": "Gebruik \"via Excel\" of \"uit werknemers\" als u meer dan één geschenk direct naar de ontvanger wilt sturen.",
            "truncate_employees": "Verwijder alle medewerkers",
            "clear": "Leegmaken",
            "senders": "Afzender",
            "receivers": "Ontvangers",
            "empty_orders_for_approval_text": "U heeft nog geen bestellingen die moeten goedgekeurd worden.",
            "orders_for_approval": "Goedkeuring bestellingen",
            "view": "Bekijken",
            "orders_for_approval_overview": "Goedkeuring bestellingen",
            "orders-for-approval": "Goedkeuring bestellingen",
            "approve": "Goedkeuren",
            "reject": "Afkeuren",
            "go": "Opslaan",
            "approval_manager": "Verantwoordelijke goedkeuring",
            "cancel_order": "Annuleer bestelling",
            "last_updated": "Laatste aanpassing",
            "impersonating_welcome": "Impersonation actief",
            "impersonating_description": "Je doet je nu voor als een andere gebruiker. Houd er rekening mee dat de acties die u uitvoert in naam van deze persoon zijn.",
            "order_creator": "Bestelling geplaatst door",
            "impersonating_stop": "Stop Impersonation",
            "cost_center": "Kostenplaats",
            "reason": "Toelichting",
            "copy": "Kopiëren",
            "lu": "Luxemburg",
            "api_keys": "API-sleutels",
            "add_key": "api-sleutel toevoegen",
            "next": "Volgende",
            "process_order": "Bestelling verwerken",
            "verify_order": "Bevestig bestelling",
            "delivery_time": "Levertijd",
            "inbound": "Inbound",
            "outbound": "Outbound",
            "reseller": "Reseller",
            "tenders": "Tenders",
            "part": "Deel",
            "download_link_disclaimer": "Downloadlinks zijn 1 week geldig.",
            "option": "Optie",
            "via_employee_list": "Uit werknemers",
            "edit_employee": "Bewerk medewerker",
            "gift_expires_on": "Verloopt op",
            "default_confirm_message": "Weet u zeker dat u deze actie wilt uitvoeren?",
            "recipient_type": "Ontvangers toevoegen",
            "add_all_teams": "Alle teams toevoegen",
            "personalize": "Personaliseer",
            "edit_occasion_by_drag_and_drop": "Je kan de volgorde wijzigen door de items te verslepen",
            "home_delivery_fee": "Thuisleveringskost",
            "home_delivery_address": "Thuisadres",
            "total_home_delivery_fee": "Totale kost voor thuisadres levering",
            "no_shipping_address_when_home_delivery_is_enabled": "Je bestelling wordt verzonden naar de thuisadressen van de ontvangers, daardoor kan je geen algemeen verzendadres opgeven.",
            "home_delivery_tag": "Levering op thuisadressen",
            "no_shipping_address_when_order_is_digital_only": "Jouw bestelling bevat alleen digitale cadeaubonnen, daarom kunt u geen algemeen verzendadres opgeven.",
            "view_all": "Bekijk alles",
            "no_home_delivery_for_card_must_choose_card_envelope": "Wens je kadonation cadeaubonnen op een kaartje te versturen naar thuisadressen, kies dan voor de optie kaartje met enveloppe",
            "selected_count": "+{count} geselecteerd",
            "apply": "Toepassen",
            "save_and_send": "Opslaan en verzenden",
            "close_panel": "Sluit paneel",
            "add_all_employees": "Alle medewerkers toevoegen",
            "sendcloud_parcel_id": "Sendcloud Parcel ID",
            "tracking_number": "Track & trace nummer",
            "tracking_url": "Track & trace link",
            "are-you-sure": "Weet je zeker dat?",
            "via_kudos": "Via Kudos campagne",
            "kudos_campaign": "Kudos campagne",
            "kudos_campaign_explanation": "Om de prijs van de cadeaubon (en andere details) in te stellen voor de Kudos ontvangers, klik op de 'Bewerk Begunstigen' knop hieronder.",
            "kudos_tag": "Kudos",
            "sign-quotation": "Offerte tekenen",
            "place-order": "Bestelling bevestigen",
            "processing-order": "Bestelling in verwerking",
            "shipping-order": "Bestelling verzonden",
            "approve-invoice": "Factuur raadplegen",
            "contact_us": "Contacteer ons",
            "having_trouble": "Heb je problemen?",
            "another_vendor_in_cart": "Gelieve eerst uw bestelling te vervolledigen met geschenken van {name} vooraleer geschenken van een andere verkoper te bestellen.",
            "open_cart": "Bekijk winkelmandje",
            "vendor_about": "Over",
            "vendor_discover": "Ontdek {name}",
            "send_message": "Verstuur bericht",
            "help": "Help",
            "order_info": "Details van je bestelling",
            "price_vat": "Prijs BTW",
            "total_items": "Totaal artikelen",
            "total_vat": "Totaal BTW",
            "handling_fee_vat": "Administratiekosten BTW",
            "invoice_details": "Factuurgegevens",
            "digital_vouchers": "Digitale cadeaubonnen",
            "excel": "Excel",
            "download_excel": "Download excel-bestand met cadeaubonnen",
            "track_and_trace": "Track & trace",
            "select_all": "Selecteer alles",
            "select_all_matching": "Selecteer alle overeenkomende",
            "vendor_greeting_cards": "Wenskaarten met personalisatie",
            "short_address": "Adres (Verkort)",
            "option_price": "Prijs:",
            "confirm_deletion": "Ben je zeker dat je {item} wil verwijderen?",
            "gift_occasions_for": "{occasion}",
            "amount-of-choice": "Bedrag naar keuze",
            "load_more_gifts": "Meer geschenken laden",
            "tbd": "Berekend bij de volgende stap",
            "select_address_to_calculate_shipping": "Selecteer een adres om de verzendkosten te berekenen.",
            "delete": "Verwijderen",
            "default_confirm_title": "Weet je het zeker?",
            "checkout-tbd": "Berekend na het kiezen van het verzendingsadres",
            "please_wait": "Even geduld a.u.b.",
            "breadcrumb": "Broodkruimel",
            "another_directly_to_recipient_in_cart": "Je kunt deze optie niet wijzigen omdat je andere geschenken in je winkelwagen hebt.",
            "view_gifts": "Bekijk geschenken",
            "continue_shopping": "Ga verder met winkelen",
            "view_cart": "Bekijk winkelwagen",
            "please_enter_shipping_address": "Gelieve eerst een adres te selecteren of in te vullen.",
            "discounted_handling_fee": "Extra's en verwerking na korting",
            "continue": "Ga verder",
            "landing-page": {
                "discover-kadonation": "Ontdek Kadonation",
                "sign-in-to-kadonation": "Meld u aan bij Kadonation Select"
            },
            "LOAD-ORDER-ITEMS": "Oudere items laden",
            "credits_used": "Gebruikte credits",
            "vendor_order_items_summary": "Overzicht van bestelde artikelen",
            "vendor_kadonation_vouchers": "Kadonation cadeaubonnen",
            "vendor_merged_cards_front_back_kadonation_vouchers": "Printbestanden voor kadonation cadeaubonnen",
            "vendor_merged_cards_front_kadonation_vouchers": "Print bestanden voor voorgedrukte kadonation cadeaubonnen",
            "open": "Open",
            "of": "of",
            "note": "Opmerking",
            "vendor_merged_cards_greeting_cards": "Print bestanden voor voorgedrukte kadonation wenskaarten",
            "vendor_merged_digital_cards_greeting_cards": "Printbestanden voor kadonation wenskaarten",
            "saving": "Opslaan...",
            "delete_delivery_address": "Adres wissen",
            "add": "Toevoegen",
            "read_more": "Lees meer",
            "read_less": "Lees minder",
            "address_from_vat_message": "Wanneer u een correct BTW nummer ingeeft zullen wij automatisch het adres ophalen en invullen.",
            "edit_personalization": "Personalisatie Wijzigen",
            "created": "Gemaakt",
            "mass_update_message_description": "Vul het bericht in dat je wenst toe te voegen aan de geselecteerde ontvangers en klik op 'update' om het op te slaan.",
            "page": "pagina",
            "download_personalization_file": "Download bestand",
            "show_details": "Details tonen",
            "hide_details": "Details verbergen",
            "personalization_file": "Personalisatiebestand van het geschenk",
            "cannot_reset_because_saml_enabled": "Opties voor het opnieuw instellen van het wachtwoord zijn niet beschikbaar omdat u zich authenticeert via een externe service (zoals Google, Microsoft, enz.)",
            "no-invoices": "Geen facturen gevonden.",
            "top_info_bar_text": "false",
            "and": "en",
            "monday": "Maandag",
            "tuesday": "Dinsdag",
            "wednesday": "Woensdag",
            "thursday": "Donderdag",
            "friday": "Vrijdag",
            "order_can_not_be_shipped_no_delivery_day": "Er zijn geen beschikbare leverdata gevonden, contacteer onze klantendienst zodat zij dit kunnen oplossen.",
            "generating_download_personalization_file": "Bezig met genereren...",
            "resend_welcome_email": "Welkom Email Opnieuw Verzenden",
            "actions_unavailable_because_tenancy_disabled_for_tenant": "De acties zijn niet beschikbaar omdat de Tenant geen KDN Select Customer is.",
            "resend_welcome_email_confirmation": "Bent u zeker dat u de Welkom Email naar de gebruiker wilt sturen?",
            "reset_password_confirmation": "Weet u zeker dat u een e-mail voor het opnieuw instellen van het wachtwoord naar de gebruiker wilt sturen?",
            "date_set_on_recipient": "Datum staat bij ontvangers",
            "set_on_recipients": "Staat bij ontvangers",
            "address_to": "Geadresseerd aan",
            "order_can_not_be_shipped_no_countries": "Er is geen land waarnaar alle geschenken in je winkelwagen verzonden kunnen worden. Gelieve elk geschenk te controleren om te zien of het kan verzonden worden naar je gekozen land.",
            "expected_delivery_date": "Verwachte leveringsdatum",
            "multiple_delivery_set_per_recipient_in_excel": "meerdere, per ontvanger in Excel",
            "saturday": "Zaterdag",
            "sunday": "Zondag",
            "delivery_date_parse_failed": "Fout bij het parsen van leveringsdatum!",
            "quote": "Offerte",
            "vendor_merged_digital_cards_greeting_cards_custom": "Print bestanden voor custom wenskaartjes",
            "vendor_greeting_cards_custom_design": "CUSTOM DESIGN",
            "vendor_greeting_cards_custom_link": "Custom wenskaarten met personalisatie",
            "copied": "Gekopieerd",
            "full_name": "Naam",
            "full-name": "Naam",
            "delete-user": "Gebruiker verwijderen",
            "subscription": "Licentie",
            "no_vendor_greeting_cards": "GEEN WENSKAARTEN",
            "or": "Of",
            "keep-account-h2": "Houd Je Account Actief",
            "keep-account": "Behoud Mijn Account",
            "you": "Jijzelf"
        },
        "calendar": {
            "previous_month": "Vorige maand",
            "today": "Vandaag",
            "next_month": "Volgende maand",
            "upcoming_events": "Komende events",
            "events": {
                "place_order": "Geschenk zoeken",
                "nothing_planned": "Niets gepland",
                "no_employees_title": "Vergeet nooit meer een speciale dag",
                "no_employees_description": "Voeg werknemers toe aan Select and zie hun verjaardagen en jubilea hier",
                "add_employees_button": "Werknemers toevoegen"
            }
        },
        "automation": {
            "automations": "Automagische geschenken",
            "no_automation_created_yet": "Nog geen automatische geschenken aangemaakt, selecteer een template om een automatisch geschenk aan te maken.",
            "automation_name": "Automatisch geschenk",
            "expires_on": "Vervalt op",
            "never": "nooit",
            "delete_confirmation_title": "Verwijder automatisch geschenk",
            "delete_confirmation_message": "Weet u zeker dat je dit automatisch geschenk wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.",
            "employee_birthday_condition_text": "Wens elke werknemer een gelukkige verjaardag, zonder iemand te vergeten!",
            "employee_work_anniversary_condition_text": "Laat nooit nog een werk verjaardag voorbijpasseren, je bepaalt zelf of je elke werk verjaardag viert of bepaalde vaste werk verjaardagen.",
            "description_text": "Nóg eenvoudiger geschenken schenken! Dankzij de geautomatiseerde cadeauservice van Kadonation Select laat je geen enkel waarderingsmoment onbenut. Door je personeelsbestand te koppelen, vier je de (werk)verjaardag van je medewerkers zonder iemand te vergeten op deze speciale dag.",
            "birthday_template_title": "Automagische verjaardagsgeschenken",
            "work_anniversary_template_title": "Automagische werkverjaardagsgeschenken",
            "birthday_template_cta": "Start configuratie",
            "work_anniversary_template_cta": "Start configuratie",
            "no_admin_found": "(Geen admin gebruiker gevonden!)",
            "non_admin_modal_title": "Wil je een automatisering opzetten?",
            "non_admin_modal_description": "Alleen \"Admin\"-gebruikers kunnen automatiseringen aanmaken en bewerken. Hieronder vindt u de \"Admin-gebruiker\" voor uw bedrijf. Contacteer deze persoon met uw verzoek om een automatisering aan te maken en zij zullen ervoor zorgen.",
            "automations_features_title": "Naast de automatisaties feature, zal dit ook mogelijk zijn:",
            "no_employees_set_description": "Om van de automatisaties feature gebruik te kunnen maken moet je eerst werknemers toevoegen. De 'Werknemers' feature slaat de details van je werknemers op, zodat je eenvoudig toegang hebt hiertoe in Kadonation Select. Toevoegen van werknemers is eenvoudig, enkel je HR systeem verbinden, of een Excel bestand uploaden.",
            "automations_feature_items": "Bestel geschenken met adressen en details reeds ingevuld |\nMaak Kudos campagnes waarmee collega's berichtjes kunnen nalaten voor de ontvanger van een cadeau |\nOntvang herinneringen aan (werk)verjaardagen (in ontwikkeling)",
            "no_automations_set_title": "Nog geen automatisaties aangemaakt",
            "no_automations_set_description": "Gebruik één van onze sjablonen.",
            "what_are_automations": "Wat zijn Automatisaties?",
            "employees_required": "Gelieve ten minste één werknemer te selecteren om verder te gaan naar de volgende stap",
            "blocked_modal_for_payment": {
                "title": "Automatisch cadeaus versturen",
                "description": "Het automatisch versturen van geschenken is momenteel geblokkeerd voor je account. Deze functie wordt ontgrendeld wanneer de financiële status van je bedrijf door ons is geverifieerd. Neem contact met ons op om het proces te versnellen."
            }
        },
        "export": {
            "from": "Van",
            "to": "Aan",
            "message": "Bericht",
            "language": "Ontvanger's taal",
            "detailed_reference": "Detail referentie",
            "amount": "Bedrag",
            "order_template": "Bestelling template",
            "email": "Email",
            "delivery_date": "Bezorgdatum",
            "first_name": "Voornaam",
            "last_name": "Achternaam",
            "locale": "Taal",
            "team": "Team",
            "employees_template": "werknemers_import",
            "delivery_time": "Bezorgtijd",
            "kudos_user_uuid": "Kudos user ID",
            "kudos_campaign": "Kudos campagne",
            "kudos_campaign_employees": "Werknemers Kudos campagne",
            "date_of_birth": "Geboortedatum",
            "company_entity": "Bedrijfsentiteit",
            "department": "Departement",
            "date_of_employment": "Datum van indiensttreding",
            "street": "Straat",
            "street_number": "Nummer",
            "bus_2": "Bus",
            "zip_code": "Postcode",
            "city_2": "Stad",
            "country": "Land",
            "row": "Rij {row}",
            "external_employee_id": "Externe werknemer-ID",
            "bus": "Bus",
            "city": "Stad",
            "select_all_matching": "Selecteer alle overeenkomende",
            "select_all": "Alles selecteren",
            "manager_id": "Manager/teamleider email adres",
            "addressed_to": "Geaddresseerd aan",
            "recipient_first_name": "Ontvanger voornaam",
            "recipient_last_name": "Ontvanger achternaam",
            "explainer": {
                "red-columns-are-mandatory": "- Rode kolommen zijn verplicht.",
                "dropdown-options-only": "- In dropdown kolommen zijn enkel de opties in de dropdown toegestaan.",
                "column": "Kolom",
                "instructions": "Instructies",
                "amount": "Waarde van de bon. Decimalen zijn niet mogelijk.",
                "message": "Persoonlijk bericht voor je ontvanger. Afgedrukt op het kaartje. Maximaal 600 karakters. Elke 'enter' telt als 40 karakters. Emojis zijn niet ondersteund.",
                "language-beneficiary": "Bepaalt de taal van de tekst die Kadonation toevoegt op de bon/het kaartje. Selecteer optie uit dropdown.",
                "email-delivery": "Email adres van de ontvanger. We sturen de bon hier naartoe.",
                "email-track-and-trace": "Email adres van de ontvanger. We sturen de track & trace notificaties hier naar toe.",
                "recipient-name": "Afgedrukt op de shipping label. Maximaal 30 karakters in totaal.",
                "street": "Heeft geen karakter limiet.",
                "street_number": "Limiet van 8 karakters.",
                "bus": "Max 4 karakters. Het woord 'Bus' wordt automatisch toegevoegd.",
                "city": "Max 30 karakters.",
                "country": "Kies uit de dropdown. Indien een land niet zichtbaar is, dan kan het geschenk niet geleverd worden in het land.",
                "delivery_date": "Datum waarop het geschenk geleverd moet worden. In te vullen in dit formaat: dag/maand/jaar (bvb: 25/03/2022).",
                "delivery_time": "Tijdstip waarop de bon geleverd moet worden. In te vullen in dit formaat: uren{minuten} (bvb: 09{30}).",
                "delivery_date_voucher": "Datum waarop de bon geleverd moet worden. In te vullen in dit formaat: dag/maand/jaar (bvb: 25/03/2022).",
                "detailed_reference": "Te gebruiken voor administratieve details. Dit is afhankelijk van het geschenkbeleid van je bedrijf.",
                "from": "Toont de ontvanger van wie het geschenk komt. Afgedrukt op de bon/het kaartje. Maximum 60 karakters.",
                "addressed_to": "Naam van de ontvanger. Afgedrukt op de bon/het kaartje. Maximum 60 karakters.",
                "message-voucher": "Persoonlijk bericht voor je ontvanger. Afgedrukt op de bon. Maximaal 300 karakters. Elke 'enter' telt als 40 karakters. Emojis zijn niet ondersteund."
            },
            "recipient-name": "Ontvanger's naam",
            "alias": {
                "order": {
                    "addressed_to": "Aan, Geadresseerd aan, Geaddresseerd aan",
                    "language": "Ontvanger's taal, taal",
                    "bus": "Bus",
                    "city": "Stad"
                }
            }
        },
        "statuses": {
            "order": {
                "received": "Ontvangen",
                "generated": "Gegenereerd",
                "shipped": "Verzonden",
                "cancelled": "Geannuleerd",
                "generating": "Genereren",
                "generation-failed": "Genereren Gefaald",
                "pending-approval": "Wacht Op Goedkeuring",
                "rejected": "Afgekeurd",
                "draft": "Ontwerp",
                "invoiced": "Gefactureerd",
                "quotation-sent": "Offerte Verstuurd",
                "quotation-signed": "Offerte Getekend",
                "quotation-verified": "Offerte Geverifieerd",
                "ready-to-review": "Klaar Voor Beoordeling",
                "scheduled-for-fulfillment": "Gepland Voor Uitvoering",
                "in-fulfillment": "In Uitvoering",
                "sending-to-fulfillment": "Versturen Naar Uitvoering",
                "in-cart": "In Winkelmandje",
                "pending-sales-approval": "Wacht Op Goedkeuring Kadonation",
                "pending-payment": "Wacht Op Betaling"
            },
            "invoice": {
                "draft": "Ontwerp",
                "proforma": "Proforma",
                "pending": "In afwachting van",
                "final": "Laatste",
                "rejected": "Afgekeurd",
                "cancelled": "Geannuleerd"
            },
            "order-quotation": {
                "awaiting-vendor-approval": "Wacht op vendor goedkeuring",
                "vendor-accepted-request": "Aanvraag aanvaard door Vendor",
                "vendor-rejected-request": "Aanvraag geweigerd door Vendor"
            },
            "order-item": {
                "generated": "Gegenereerd"
            },
            "order-item-message": {
                "shipped": "Verzonden"
            },
            "quotation-section": {
                "awaiting-vendor-approval": "Wacht op goedkeuring",
                "vendor-accepted-request": "Geaccepteerd",
                "vendor-rejected-request": "Afgewezen"
            },
            "quotation": {
                "draft": "Ontwerp",
                "quotation-sent": "Verzonden naar klant",
                "quotation-signed": "Ondertekend",
                "quotation-verified": "Goedgekeurd",
                "quotation-rejected": "Afgekeurd",
                "awaiting-vendor-approval": "Wacht op goedkeuring van leveranciers",
                "vendor-rejected-request": "Gedeeltelijke afwijzing door leverancier",
                "all-vendors-accepted": "Volledige leveranciersgoedkeuring",
                "canceled": "offerte geannuleerd"
            }
        },
        "mails": {
            "vendor_order_mail_part_of_the_order": "(deel van bestelling #{originalOrderId})",
            "vendor_order_mail_part_of_the_order_section": "<strong>Order Information</strong>\n</br>\n<ul style=\"list-style-type: none; padding-right: 0; padding-left: 0;\">\n<li>Order ID: {orderId}</li>\n<li>Origineel Order ID: {originalOrderId}</li>\n</ul>\n</br>"
        },
        "gifts_v2": {
            "placeholder": "Placeholder",
            "excluding_vat": "(excl. BTW)",
            "amount_of_choice": "Bedrag naar keuze",
            "available_until": "Beschikbaar tot",
            "order_now_to_receive_by": "Bestel nu om te ontvangen op",
            "physical_delivery_to_someone": "Levering naar kantoor, of ontvangers",
            "more_about_shipping": "Meer informatie over verzending",
            "made_locally": "Lokaal gemaakt",
            "sustainable": "Duurzaam",
            "price_free": "Gratis",
            "order_before_date_delivered_on_date": "Bestel voor {orderDate}, geleverd op {deliveryDate}",
            "free_download_or_email_recipients": "Gratis instant download, or via email verstuurd naar ontvangers",
            "option": "Optie",
            "voucher_type": "Bon type",
            "request_quote": "Offerte aanvragen",
            "quantity_min_error_message": "Je kan niet minder dan {quantity} stuks bestellen",
            "quantity_max_error_message": "Je kan niet meer dan {quantity} stuks bestellen",
            "budget_default_blocking_message": "De eenheidsprijs mag niet groter zijn dan {limit} voor deze gelegenheid.",
            "back_to_top": "Terug naar boven",
            "ordering_for_campaign": "Je bent aan het bestellen voor Kudos campagne {campaign}",
            "choose_recipients_and_personalize": "Kies ontvangers en personaliseer",
            "add_to_favorite": "Toevoegen aan voorkeur geschenken",
            "remove_from_favorite": "Als voorkeursgeschenk toevoegen/verwijderen",
            "gift_is_customizable": "Het ontwerp van dit geschenk kan aangepast worden",
            "edit_colorlab_personalization": "Ontwerp Wijzigen",
            "start_colorlab_personalization": "Geschenk Ontwerpen",
            "gift_without_customization_confirmation_title": "Geschenk ontwerp niet aangepast",
            "gift_without_customization_confirmation_description": "Je hebt het ontwerp van dit geschenk niet gewijzigd, ben je zeker dat je wenst verder te gaan zonder een eigen ontwerp?",
            "add_customization": "Ontwerp toevoegen",
            "services": {
                "your_message_on_card": "Voeg je bericht toe en wij printen het op het kaartje",
                "customize_card": "Ontwerp het kaartje naar je huisstijl (<a href=\"{mailTo}\" class=\"underline\">contacteer ons</a>)!",
                "less_response_time": "Contacteer ons voor snelle hulp bij het plaatsen van je bestelling",
                "free_personalization_for_voucher": "Jouw logo en boodschap gratis toegevoegd aan de cadeaubon",
                "voucher_can_be_used_at": "Cadeaubon kan bij meer dan <a href=\"https://kadonation.com/be-nl/kadopartners\" class=\"underline\" target=\"_blank\">80 partners</a> gebruikt worden",
                "customize_voucher": "Ontwerp de cadeaubon naar je huisstijl (<a href=\"{mailTo}\" class=\"underline\">contacteer ons</a>)!",
                "free_card_with_gift": "Een kaartje kan toegevoegd worden aan het geschenk, met jouw boodschap",
                "ship_to_office_or_directly": "Verzend naar kantoor, or rechtstreeks naar ontvanger"
            },
            "description": "Omschrijving",
            "contents": "Inhoud",
            "product_specification": "Geschenk Specificaties",
            "shipping_info": "Leveringsinfo",
            "ordering_info": "Bestel Info",
            "jump_to": "Ga naar:",
            "sustainability": "Duurzaamheid",
            "article_number": "Artikel nummer",
            "country_of_origin": "Land van origine",
            "shelf_life": "Houdbaarheid",
            "sizing": "Afmetingen",
            "length": "Lengte",
            "width": "Breedte",
            "height": "Hoogte",
            "weight": "Gewicht",
            "allergens": "Allergenen",
            "gift_is_shipped_from": "Dit geschenk wordt verzonden vanuit",
            "delivery_time": "Leveringstijd",
            "delivery_cost": "Leveringskosten",
            "minimum_maximum_order_quantities": "Minimum/Maximum bestelhoeveelheden",
            "minimum_order_quantity": "Minimum",
            "price_matrix_info": "Volume korting",
            "maximum_order_quantity": "Maximum",
            "selected_option": "Geselecteerde optie",
            "change_option": "Optie wijzigen",
            "customization": "Ontwerp",
            "change_customization": "Ontwerp wijzigen",
            "customized": "Ontwerp toegevoegd",
            "not_customized": "Nog geen ontwerp",
            "costs": "Prijs",
            "voucher_value": "Bon waarde",
            "price_per_item": "Prijs / stuk",
            "handling_fee": "Extra's en verwerking",
            "view_gift_details": "Toon geschenk details",
            "go_back_to_gift": "Terug naar geschenk",
            "add_to_cart": "Toevoegen aan winkelmandje",
            "add_recipients": "Ontvangers en personalisatie",
            "add_recipients_description": "De bestelling kan geleverd worden op één adres, of rechtstreeks naar de ontvangers gestuurd worden.",
            "enter_address_first": "Gelieve eerst een adres in te geven",
            "delivery_time_title": "{range} stuks",
            "delivery_time_condition": "{days} werkdag (indien besteld voor {cutoff})|{days} werkdagen (indien besteld voor {cutoff})",
            "price_will_be_charged_for_each_gift_by_vendor": "Leveringskost zal aangerekend worden per geschenk, daar de geschenken apart verpakt en verzonden worden.",
            "request_quote_for_unavailable_gift": "Dit geschenk is momenteel niet beschikbaar om via Select besteld te worden. Je kan een offerte aanvragen indien je dit geschenk wenst te bestellen.",
            "go_to_cart_finish_order": "Naar winkelmandje",
            "multi_vendor_warning_title": "Je kan geen geschenken van verschillende leveranciers combineren in één bestelling.",
            "multi_vendor_warning_description": "<p>Je hebt reeds een geschenk in je winkelmandje van een andere leverancier dan de leverancier van dit geschenk. Een bestelling kan enkel geschenken bevatten van één specifieke leverancier. Kies één van de volgende opties om verder te gaan:</p>\n<ul style=\"margin-top: 12px\">\n<li>Ga naar winkelmandje en rond je huidige bestelling af</li>\n<li>Maak winkelmandje leeg en voeg dit geschenk toe</li>\n<li>Dit venster sluiten en niets doen</li>\n</ul>",
            "close_modal_do_nothing": "Sluiten en niets wijzigen",
            "clear_cart_and_add_gift": "Winkelmandje leegmaken en dit geschenk toevoegen",
            "set_voucher_value_to_add_gift": "Gelieve een waarde in te vullen om verder te gaan.",
            "additional_delivery_time_for_country": "(voeg {days} werkdagen toe aan de levertijd)",
            "can_be_combined_with_voucher": "Dit geschenk kan gecombineerd worden met een cadeaubon,",
            "can_be_combined_with_voucher_contact_us": "contacteer ons om te bestellen",
            "need_something_custom_get_quote": "Grote bestelling? Iets op maat nodig? Vraag hier een offerte aan",
            "which_occasion_to_place_order": "Voor welke gelegenheid is dit geschenk?",
            "select_occasion": "Selecteer gelegenheid",
            "country_delivery_condition": "{cost} (voeg {days} werkdagen toe aan de levertijd)",
            "cost_text": "(Afhankelijk van gekochte hoeveelheid)",
            "bus_help_text": "Het woord \"Bus\" wordt later door ons toegevoegd",
            "gifts_found": "Geen geschenken gevonden | 1 geschenk gevonden | {count} geschenken gevonden",
            "no_gifts_found": "Geen geschenken gevonden",
            "no_gifts_found_description": "Er werden geen geschenken gevonden. Gelieve filters te verwijderen om verder te zoeken.",
            "favorited_gifts_for_occasion": "Voorkeursgeschenken voor deze gelegenheid",
            "favorited_gifts_for_occasion_description": "Ontdek de favoriete cadeaus, zorgvuldig geselecteerd door jouw bedrijf om deze gelegenheid iets extra's te geven.",
            "view_all_gifts": "Toon alle geschenken",
            "no_favorited_gifts_found": "Geen geschenken gevonden",
            "no_favorited_gifts_found_description": "Geen voorkeursgeschenken gevonden voor deze gelegenheid. Gelieve je admin te contacteren om deze in te stellen, zodat je verder kan met je bestelling.",
            "all_gifts_for_occasion": "Alle geschenken voor deze gelegenheid",
            "packaging_is_customizable": "Het ontwerp van de verpakking kan aangepast worden",
            "product_is_customizable": "Het ontwerp van dit geschenk kan aangepast worden",
            "start_colorlab_personalization_packaging": "Verpakking ontwerpen",
            "start_colorlab_personalization_product": "Geschenk ontwerpen",
            "modals": {
                "direct_future_delivery_item_in_cart": {
                    "title": "Kan geschenk niet toevoegen aan winkelmandje",
                    "buttons": {
                        "close": "Sluit deze waarschuwing",
                        "go_to_cart": "Bekijk winkelmandje"
                    },
                    "description": "Je hebt een geschenk toegevoegd aan je winkelmandje waarbij je een bezorgdatum per ontvanger hebt ingesteld. Dit kan niet worden gecombineerd met andere geschenken. Plaats eerst de bestelling voor het geschenk in je winkelwagen."
                },
                "cannot_add_future_delivery_gift": {
                    "title": "Kan geschenk niet toevoegen aan winkelmandje",
                    "description": "Je probeert een geschenk aan je winkelmandje toe te voegen waarbij de leverdatum per ontvanger wordt ingesteld. Dit kan niet worden gecombineerd met andere geschenken. Plaats eerst de bestelling voor het geschenk in je winkelwagen.",
                    "buttons": {
                        "close": "Sluit deze waarschuwing",
                        "go_to_cart": "Bekijk winkelmandje"
                    }
                },
                "set_occasion": {
                    "title": "Selecteer een gelegenheid",
                    "explainer": "Laat ons weten voor welke gelegenheid je dit geschenk koopt.",
                    "buttons": {
                        "save": "Opslaan en aan winkelmandje toevoegen"
                    }
                },
                "personalization_not_set": {
                    "title": "Aanbevolen instellingen personalisatie",
                    "buttons": {
                        "add_to_cart": "Toevoegen aan winkelmandje"
                    },
                    "no_personalization_message": {
                        "explainer": "Om je ontvangers de beste ervaring te bieden, raden we aan een persoonlijk bericht in te stellen."
                    },
                    "no_contributors_added": {
                        "explainer": "Je hebt de de optie \"digitale bijdrages toevoegen\" ingeschakeld, maar er zijn geen bijdragers toegevoegd."
                    }
                }
            },
            "regional_logic_title": "Levering mogelijk in deze regio's",
            "gift_can_only_be_shipped_to_this_regions": "Controleer of de regio waar je het cadeau naartoe wilt sturen in deze lijst staat, want dit cadeau wordt mogelijk niet naar alle regio's van een bepaald land verzonden.",
            "all_regions": "Alle regio's",
            "more_about_shipping_per_region": "Levering enkel in specifieke regio's",
            "add-vat-number-and-invoice-address-modal": {
                "vat-number-help-text": "<a href=\"{mailTo}\">Contacteer ons</a> indien je geen BTW nummer hebt."
            },
            "add_vat_number_and_invoice_address": {
                "title": "BTW nummer en adres nodig",
                "description": "Om bestellingen te plaatsen op Select moet je eerst je BTW nummer en facturatie-adres ingeven. Deze zijn noodzakelijk om je factuur aan te kunnen maken."
            },
            "preferred_gifts": {
                "add_modal_title": "Toevoegen aan voorkeursgeschenken",
                "add_modal_description": "Voorkeursgeschenken worden bovenaan de pagina weergegeven wanneer je door de geschenken van een gelegenheid bladert. Je kunt elk geschenk instellen als voorkeursgeschenk voor meerdere gelegenheden. Alleen gelegenheden die u hebt geactiveerd, worden in de onderstaande lijst weergegeven."
            },
            "preferred-gifts-updated": "Voorkeursgeschenken bijgewerkt.",
            "packaging_and_product_are_customizable": "De verpakking en het geschenk zelf kunnen worden gepersonaliseerd.",
            "start_colorlab_personalization_packaging_and_product": "Personaliseer verpakking en geschenk",
            "voucher": {
                "delivery_radio": {
                    "direct_to_recipient": {
                        "label": "Stuur de voucher(s) rechtstreeks naar de ontvanger(s)",
                        "explainer": "Je stelt hier de e-mailadressen voor elke ontvanger in."
                    },
                    "office": {
                        "label": "Stuur de voucher(s) naar mijn e-mail inbox",
                        "explainer": "We sturen het naar het e-mailadres van je account."
                    }
                },
                "digitize_message_toggle": {
                    "title": "Nodig personen uit om persoonlijke boodschappen toe te voegen.",
                    "explainer": "Bij het inwisselen van de voucher zal de ontvanger een pagina zien waar alle persoonlijke boodschappen worden getoond."
                }
            },
            "physical": {
                "delivery_radio": {
                    "direct_to_recipient": {
                        "label": "Stuur het/de geschenk(en) direct naar de ontvanger(s).",
                        "explainer": "Je stelt hier de adressen voor elke ontvanger in."
                    },
                    "office": {
                        "label": "Stuur het/de geschenk(en) naar één locatie (meestal het kantoor).",
                        "explainer": "Je stelt het adres in tijdens het afrekenen."
                    }
                },
                "digitize_message_toggle": {
                    "title": "Nodig andere collega('s) uit om een persoonlijke boodschap te schrijven",
                    "explainer": "De berichten zullen beschikbaar zijn via een QR-code op de wenskaart."
                }
            },
            "greeting_card_title": "Wenskaart",
            "greeting_card_description": "Met Kadonation creëer je impactvolle momenten! We geloven dat een persoonlijke boodschap net zo belangrijk is als de attentie. Deel jouw bericht via een mooie wenskaart en nodig ook anderen uit een boodschap te schrijven.",
            "greeting_card_toggle_title": "Voeg een wenskaart met persoonlijke boodschap toe (+ {price})",
            "greeting_card_toggle_description": "Je kan je boodschap voor op het kaartje onderaan toevoegen.",
            "personalizaiton_disabled_title": "Personalisatie uitgeschakeld",
            "personalizaiton_disabled_description": "Personalisatie per ontvanger is alleen mogelijk wanneer een wenskaart is toegevoegd.",
            "delivery_direct_subtext": "Waar moet dit cadeau bezorgd worden?",
            "delivery": "Bezorging",
            "gift_quantity_and_value": "Aantal en bedrag",
            "gift_details": "Geschenkgegevens",
            "pricing": "Prijs",
            "unit_price": "Eenheidsprijs",
            "volume_discount": "Volumekorting",
            "gift_quantity": "Aantal",
            "recipients_selected": "Geen ontvanger geselecteerd | 1 ontvanger geselecteerd | : count\nontvangers geselecteerd",
            "recipients_uploaded_via_excel": "Geen ontvanger geüpload via excel | 1 ontvanger geüpload via excel | {count} ontvangers geüpload via excel",
            "delivery_type": {
                "directly_to_recipient": "Rechtstreeks naar ontvanger",
                "inbox": "E-mail inbox",
                "single_address": "Enkel adres",
                "title": "Levering",
                "explainer": "Waar moet dit geschenk bezorgd worden?"
            },
            "occasion_not_set": "Gelegenheid niet ingesteld",
            "select_employees_as_recipients": {
                "title": "Selecteer medewerkers als ontvangers",
                "explanation": "Selecteer medewerkers die u heeft toegevoegd aan Select. Je kan dit ook gebruiken om handmatig meerdere ontvangers toe te voegen."
            },
            "save_recipients_before_adding_to_cart": "Gelieve eerst de ontvangers te bevestigen om het geschenk aan het winkelmandje toe te voegen",
            "pricing_empty_no_recipient_selected": "Nog geen ontvanger(s) geselecteerd",
            "recipient_country_missing": "Land ontbreekt",
            "shipping_fee_calculated_at_checkout": "Berekend bij het afrekenen",
            "recipient_details": "Gegevens van de ontvanger",
            "recipient_address": "Adres van de ontvanger",
            "upload_excel_file": "Upload excel-bestand",
            "invoice_info": "Factuurinformatie",
            "contributors_added": "1 persoon toegevoegd | {count} personen toegevoegd",
            "message_contributors": {
                "title": "Persoonlijke boodschappen",
                "explainer": "Bepaal hier wie een persoonlijke boodschap kan schrijven. We plaatsen een QR code op de wenskaart. Door deze code te scannen krijgt de ontvanger alle persoonlijke berichten te zien."
            },
            "add_contributors_button": "Voeg personen toe",
            "edit_contributors_button": "Wijzig de uitgenodigden",
            "add_voucher_personal_message_disclaimer": {
                "title": "Nodig personen uit voor een persoonlijke boodschap",
                "explainer": "Bij het inwisselen van de cadeaubon zal de ontvanger een pagina zien waar jouw bericht en de andere persoonlijke boodschappen worden getoond."
            },
            "digitize_enabled": {
                "personalize_voucher_subtitle": "Personaliseer de inhoud van de cadeaubon.",
                "excel_disclaimer": {
                    "title": "Zorg ervoor dat je geen ontvangers van geschenken hebt uitgenodigd voor persoonlijke boodschappen te schrijven, aangezien zij dan ook gemaild worden om een persoonlijke boodschap voor zichzelf te schrijven.",
                    "explainer": "Opgelet bij persoonlijke boodschappen"
                },
                "personalize_greeting_card_subtitle": "Personaliseer de wenskaart met een bericht. Je kunt ook je eigen persoonlijke boodschap toevoegen door jezelf uit te nodigen voor 'Persoonlijke boodschappen'. Deze berichten zijn beschikbaar via een QR-code die op de wenskaart is gedrukt."
            },
            "add_contributor_manually": "Voeg persoon manueel toe",
            "add_myself_as_contributor": "Mezelf uitnodigen voor een boodschap",
            "message_contributor_explainer_modal": {
                "title": "Hoe werkt 'Persoonlijke boodschappen?'",
                "explainer": "Met 'Persoonlijke boodschappen' kun je andere mensen uitnodigen om een persoonlijke boodschap te schrijven aan de ontvanger van het cadeau."
            },
            "save_contributors": "Opslaan",
            "personal_message_input_length_limit_error": "Je bericht overschrijdt het aantal toegestane karakters. Maak deze korter om leesbaarheid te garanderen.",
            "vendor_cannot_print_qr_title": "Het wenskaartje bij dit geschenk heeft geen QR-code"
        },
        "days": {
            "sunday": "zondag",
            "monday": "maandag",
            "tuesday": "dinsdag",
            "wednesday": "woensdag",
            "thursday": "donderdag",
            "friday": "vrijdag",
            "saturday": "zaterdag"
        },
        "api": {
            "key": "Key",
            "secret": "Secret",
            "secret_warning": "Dit is de enige keer dat je jouw Secret kan bekijken. Gelieve het ergens veilig op te slaan.",
            "unused_token": "De token is tot nu toe nog nooit gebruikt.",
            "token_last_used": "Laatst gebruikt op: {at}",
            "token_expires_at": "Tokens verlopen niet automatisch. Wij adviseren je om jullie tokens regelmatig te roteren. Je kan op elk moment tokens aanmaken of intrekken.",
            "new_token_placeholder": "Nieuwe API token naam",
            "too_many_tokens_warning": "Het lijkt erop dat je meer dan één API token hebt op dit moment. Dit is om veiligheidsredenen niet aan te raden. Verwijder tokens die je niet gebruikt."
        },
        "labels": {
            "beta": "Beta",
            "beta-description": "Dit product zit momenteel nog in een beta fase. Zijn er zaken die we kunnen verbeteren of ondervind je problemen bij het plaatsen van je bestelling? Neem dan contact op met ons via contact@kadonation.com of op het nummer +32 9 396 32 00."
        },
        "kudos": {
            "input-groups": "Input groepen",
            "create-input-group": "Invoergroep creëren",
            "activate": "Activeren",
            "deactivate": "Deactiveren",
            "click_desired_locale_url": "Kopieer url naar klembord",
            "order_campaign": "Geschenken bestellen",
            "senders_required": "Selecteer wie Kudos mag schrijven in stap 2",
            "receivers_required": "Selecteer wie Kudos berichten ontvangt in stap 3",
            "order_step_1": "Stap 1: download uw werknemers",
            "order_step_2": "Stap 2: geschenken bestellen",
            "order_step_1_description": "Download onderstaand excelbestand en vul de gevraagde velden in.",
            "order_step_2_description": "Zodra u het excelbestand hebt ingevuld, kunt u het uploaden in het tabblad 'via excel' bij het plaatsen van een bestelling.",
            "order_already_placed": "Waarschuwing: er is al een bestelling geplaatst",
            "order_already_placed_description": "Er is al een bestelling geplaatst voor deze campagne.",
            "place_order": "Plaats bestelling",
            "edit_campaign": "Campagne aanpassen",
            "edit_order": "Editeren volgorde",
            "give_feedback": "Geef kudos",
            "search_employee": "Medewerker zoeken",
            "search_colleague": "Aan",
            "select_teams": "Selecteer teams",
            "no_employees_selected": "Geen medewerkers geselecteerd",
            "description_only_two_translation": "Waarschuwing: campagnebeschrijving heeft slechts twee vertalingen",
            "name_only_two_translation": "Waarschuwing: campagnenaam heeft slechts twee vertalingen",
            "prefix_only_two_translation": "Waarschuwing: prefix heeft slechts twee vertalingen",
            "email_not_found": "E-mail niet gevonden",
            "submit": "Indienen",
            "campaign_text": "Kudos zijn positieve berichten gedeeld door collega's. We tonen deze berichten wanneer de ontvanger de cadeaubon inwisselt. Kudos maakt het ontvangen van een cadeaubon een persoonlijkere ervaring, en creëert een team bonding moment.",
            "campaign_title": "Kudos campagnes",
            "description_only_one_translation": "Waarschuwing: campagne-omschrijving heeft slechts één vertaling",
            "email_found": "Controleer uw inbox",
            "invite_text": "Je hoeft je niet aan te melden! Voer uw e-mailadres in het vakje hieronder in en klik op verzenden. U ontvangt dan een link om Kudos te schrijven naar uw collega's voor deze campagne.",
            "prefix_only_one_translation": "Waarschuwing: prefix heeft slechts één vertaling",
            "welcome": "Welkom bij Kadonation Kudos",
            "name_only_one_translation": "Opgelet: de campagnenaam is slechts in 1 taal geconfigureerd.",
            "selected_teams": "Geselecteerde teams",
            "selected_input_receivers": "Wie ontvangt",
            "selected_input_givers": "Wie schrijft",
            "use_givers_as_receivers": "Voeg dezelfde werknemers toe die Kudos gaan schrijven",
            "create_campaign": "Nieuwe campagne aanmaken",
            "campaign_details": "Info campagne",
            "input_givers": "Wie mag Kudos-berichten schrijven?",
            "input_receivers": "Wie is/zijn de ontvanger(s) van de Kudos-campagne?",
            "campaign_form_description": "Een Kudos-campagne configureer je eenvoudig in drie stappen. \nStap 1: geef je campagne een naam en een beschrijving in ten minste één taal. Indien gewenst kan je een prefix toevoegen zoals \"Bedankt voor...\" die aan elke Kudos-boodschap voorafgaat.",
            "name": "Naam van de campagne",
            "description": "Beschrijving",
            "prefix": "Kudo prefix, bv. \"Bedankt voor...\"",
            "campaign_form_receivers_description": "Stap 3: Selecteer teams en/of individuele medewerkers voor wie de campagne bedoeld is.",
            "campaign_form_senders_description": "Stap 2: Selecteer teams en/of individuele medewerkers die Kudos-berichten mogen schrijven voor deze campagne.",
            "campaigns": "Campagnes",
            "create_employee": "Creëer medewerker",
            "delete_employee": "Medewerkers verwijderen",
            "employee": "Werknemer",
            "employees": "Werknemers",
            "employees_text": "Medewerkers kunnen in Kudos worden ingezet. Zij hebben geen toegang tot Kadonation Select zelf.",
            "empty_employees_text": "Je hebt nog geen werknemers toegevoegd",
            "empty_kudos_text": "Werknemers kunnen in Kudos worden ingezet. Zij hebben geen toegang tot Kadonation Select zelf.",
            "input_received": "# ontvangen",
            "input_given": "# geschreven",
            "get_url": "Link ophalen",
            "no_employees_added": "Geen medewerkers toegevoegd",
            "kudos": "Kudos",
            "add_employee": "Werknemer toevoegen",
            "import_employees": "Importeer werknemers",
            "you_will_receive_an_email_if_you_have_access_to_this_campaign": "U zult een e-mail ontvangen als u toegang heeft tot deze campagne",
            "add_colleague": "Schrijf een Kudos bericht",
            "input": {
                "added_colleagues": "Je gegeven Kudos",
                "pending": "Je open Kudos-campagnes",
                "deadline": "Deadline voor het geven van Kudos",
                "character-limit": "van de 300 karakters",
                "none-written": "Je hebt nog geen kudos berichtjes geschreven!",
                "no-one-left": "Je moet voor niemand anders nog een kudos berichtje schrijven!",
                "all_input_given_title": "Super!",
                "all_input_given_text": "Je hebt voor iedereen een Kudos-berichtje geschreven! Zolang de deadline niet verstreken is, kan je steeds je Kudos-berichten aanpassen indien je dit wenst."
            },
            "employee_message": "Boodschap",
            "senders": "Afzenders",
            "receivers": "Ontvangers",
            "email_template": "Beste werknemer\n\nWelkom bij Kadonation Kudos. \nEr is een nieuwe campagne opgestart waar jij onderdeel van uit maakt!\nStuur Kudos naar jou collega(’s) via onderstaande link.",
            "employees_title": "Werknemers",
            "email_title": "E-mail tekst",
            "go_campaign_title": "Ga terug naar campagneoverzicht",
            "campaign_links_title": "Campagne links",
            "employees_description": "Kopieer e-mail sjabloontekst en pas aan waar nodig",
            "email_description": "Selecteer de e-mails van de werknemers in bulk",
            "campaign_links_description": "Kopieer campagnelinks voor andere kanalen, bijv. slack",
            "all_employees": "Alle medewerkers",
            "en_employees": "Engelse werknemers",
            "whats_next": "Wat is de volgende stap?",
            "campaign_overview": "Campagne overzicht",
            "fr_employees": "Franse werknemers",
            "nl_employees": "Nederlandse werknemers",
            "success": "Succes",
            "or": "of",
            "emailing": "E-mailen",
            "campaign_create_success": "U heeft de campagne succesvol aangemaakt",
            "go_to_emailing": "Naar de e-mail pagina",
            "empty_employees_message": "U kunt geen campagne maken zonder werknemers. Begin met het toevoegen of importeren van uw werknemers.",
            "sign_in_kudos_inactive_message": "Deze campagne is niet actief! \nHet kan zijn dat de campagne is afgelopen of nog moet worden geactiveerd.\nNeem contact op met de maker van de campagne als u hier vragen over heeft.",
            "receiver": "Ontvanger",
            "sender": "Afzender",
            "email": {
                "magic_link_expired": "De link is verlopen.",
                "email_start": "<div id=\"email-start\">\n      <p>Hallo <strong>[Voornaam] [Achternaam]</strong></p>\n  </div>",
                "email_end": "<div id=\"email-end\" class=\"my-4\">\n    <p>Met vriendelijke groeten,</p>\n    <p><strong>[Bedrijfsentiteit]</strong ></p>\n  </div>",
                "email_body": "<div class=\"py-2 opacity-50\" contenteditable=\"false\">\n    <div class=\"flex items-center space-x-2\">\n      <div class=\"w-full h-px border\"></div>\n      <div class=\"text-sm min-w-max uppercase font-bold\">Aanpassen boven de lijn</div>\n      <div class=\"w-full h-px border\"></div>\n    </div>\n    <br />\n    <!-- start content -->\n    <div>\n      <p>Je bent klaar om Kadonation Kudos uit te delen.</p>\n\t<p>Klik op onderstaande persoonlijke link om bij te dragen aan de campagne</p><p>Veel succes!</p>\n\t<div class=\"my-8 flex justify-center\">\n\t\t<span class=\"uppercase text-xs font-semibold text-white bg-pink-300 py-3 px-6 rounded\">\n\t         Kudos geven\n        \t\t</span>\n\t</div>\n\t<p><strong>Belangrijk: om ervoor te zorgen dat anderen niet zomaar toegang krijgen, is het belangrijk dat je deze e-mail niet doorstuurt.</strong></p>     \n    </div>\n    <!-- end content -->\n    <div class=\"flex items-center space-x-2\">\n      <div class=\"w-full h-px border\"></div>\n      <div class=\"text-sm min-w-max uppercase font-bold\">Aanpassen onder de lijn</div>\n      <div class=\"w-full h-px border\"></div>\n    </div>\n  </div>",
                "locale_emails": "Wanneer je deze optie selecteert, kan je per taal een aparte e-mail opstellen om jouw medewerkers uit te nodigen voor de campagne.",
                "locale_emails_label": "Aparte e-mail per taal",
                "general_language": "Taal",
                "subject": "Onderwerp",
                "subject-value": "Uitnodiging voor {campaignName}",
                "resend_kudo_invite_to_new_people": "Verstuur de kudo uitnodigingen enkel naar genodigden die nog geen uitnodiging hebben ontvangen",
                "send_to_new_people_label": "Alleen opnieuw versturen naar nieuwe mensen"
            },
            "ordering_message": "Je plaatst een bestelling voor \"{campaignName}\". Nog niet klaar voor?",
            "exit_button": "Kudos bestelling afsluiten",
            "stopped_ordering": "Je bent niet meer aan het bestellen voor de Kudos campagne",
            "download_explanation": "You can now fill in the excel further and use it to order any gift/option. Upon redeem, the messages will be shown to the end user",
            "employee_search_placeholder": "Zoek via naam of e-mail",
            "search": "Kies een collega",
            "campaign_email_description": "Stap 4: Stuur een e-mail met de link van de Kudos-campagne naar de werknemers die een boodschap mogen achterlaten. Deze stap kan je overslaan als je dit wenst.\nDe velden [Voornaam], [Achternaam], [Campagnenaam] en [Bedrijfsentiteit] zullen automatisch worden ingevuld met de juiste gegevens.",
            "expired": {
                "btn": "Nieuwe link aanvragen",
                "title": "Oeps!",
                "text": "Jouw link om kudos te geven is vervallen, vraag een nieuwe link aan!"
            },
            "delete_title": "Ben je zeker dat je deze campagne wil verwijderen?",
            "delete_description": "Als je dit doet ben je alle Kudos berichtjes kwijt. Dit kan niet ongedaan gemaakt worden!",
            "delete_cta": "Campagne verwijderen",
            "activation_description": "Je kunt je campagne activeren en deactiveren. Wanneer je kudo campagne is gedeactiveerd, kunnen er geen kudos meer worden gegeven.",
            "send_campaign": "Uitnodigingen versturen",
            "tooltip": {
                "deadline": "Wanneer de deadline verstreken is, kunnen de medewerkers geen Kudos-berichten meer schrijven.",
                "prefix": "Gebruik dit om jouw werknemers al een stapje in de juiste richting te sturen met hun berichtje.",
                "campaign_name": "Geef deze campagne een naam",
                "description": "Hier kan je extra info toevoegen over de campagne. Gebruik dit om de collega's te informeren over de Kudo gelegenheid."
            },
            "not_send_yet": "Deze campagne is nog niet uitgestuurd naar de Kudos gevers",
            "kudos_given": "Je hebt al Kudos-berichten geschreven voor:",
            "hide_message": "Bericht verbergen",
            "message_added": "Jouw bericht is opgeslaan.",
            "message_edited": "Jouw bericht is bijgewerkt",
            "kudos_to_give": "Je moet nog Kudos schrijven voor:",
            "deadline_has_passed_sending_out_is_disabled": "De deadline voor de kudo campagne is verlopen. Daarom kan je de kudo campagne niet meer verzenden, pas de deadline aan als je de campagne wilt verzenden.",
            "campaign_status_updated": "Campagne succesvol bijgewerkt",
            "campaign-expired": {
                "title": "Deze Kudo campagne is verlopen.",
                "text": "Neem contact op met de organisator als je denkt dat dit een vergissing is. Zij kunnen de deadline wijzigen en een nieuwe uitnodiging sturen."
            },
            "cart_not_empty": "Je hebt geschenken in je winkelwagen. Voltooi uw lopende bestelling voordat u bestelt voor de Kudos-campagne.",
            "some_employees_without_email_are_omitted": "Er zijn werknemers zonder een email-adres and kunnen daarom uw uitnodiging voor het delen van een persoonlijk bericht niet ontvangen. Gelieve uw werknemers lijst te controleren en te zorgen dat alle werknemer een email-adres hebben.",
            "what_are_kudos": "Wat zijn Kudos?",
            "no_kudos_set_title": "Nog geen Kudos campagne gemaakt",
            "no_kudos_set_description": "Start een Kudos campagne, nodig je collega's uit en stuur elkaar positieve berichten.",
            "kudos_feature_items": "Bestel geschenken met adressen en details reeds ingevuld |\nMaak (werk)verjaardag cadeau automatisaties aan |\nOntvang herinneringen aan (werk)verjaardagen (in ontwikkeling)",
            "no_employees_set_description": "Om van de Kudos feature gebruik te kunnen maken moet je eerst werknemers toevoegen. De 'Werknemers' feature slaat de details van je werknemers op, zodat je eenvoudig toegang hebt hiertoe in Kadonation Select. Toevoegen van werknemers is eenvoudig, enkel je HR systeem verbinden, of een Excel bestand uploaden.",
            "kudos_features_title": "Naast de Kudos feature, zal dit ook mogelijk zijn:",
            "create_one_now": "Maak één aan",
            "kudo_deactivated_sending_out_is_disabled": "Deze campagne is niet actief. Gelieve deze campagne te activeren om uitnodigingen te versturen.",
            "kudos_will_expire_on": "Deadline voor het geven van Kudos:",
            "edit_kudos_message": "Kudos bericht bewerken",
            "search_recipient": "Zoek ontvanger",
            "exit_confirmation_title": "Je hebt geschenken in je winkelwagen voor een Kudos-campagne. Deze worden uit je winkelwagen verwijderd als je doorgaat.",
            "exit_confirmation_message": "Winkelwagen wordt leeggemaakt",
            "deadline_description": "Wanneer de deadline is bereikt, is het niet langer mogelijk om Kudos-berichten in te voeren. Deze deadline is over het algemeen de datum waarop het cadeau wordt bezorgd.",
            "feature_removed_modal": {
                "title": "Deze functionaliteit is verhuisd",
                "description": "Bij het bestellen van een geschenk kun je nu mensen uitnodigen om persoonlijke boodschappen toe te voegen. Dit geldt zowel voor digitale vouchers als fysieke geschenken. De ontvanger kan deze persoonlijke boodschappen lezen zodra ze het geschenk ontvangen."
            },
            "contributions": {
                "page_title": "Berichten",
                "all_messages_delivered": {
                    "title": "Al je berichten zijn bezorgd",
                    "explainer": "We zien je graag terug wanneer je een nieuwe uitnodiging ontvangt om een bericht toe te voegen aan een cadeau."
                },
                "link_expired": {
                    "title": "De link die je hebt gebruikt is verlopen",
                    "explainer": "Vraag een nieuwe link aan door op onderstaande knop te klikken.",
                    "request_new_link_button": "Nieuwe link aanvragen"
                },
                "influencing_text_to_add_message": "Een oprechte boodschap is net zo waardevol als het geschenk zelf en maakt er een onvergetelijke herinnering van.",
                "add_message_title": "Schrijf een boodschap",
                "written_messages_title": "Geschreven berichten",
                "written_messages_explainer": "Deze verdwijnen wanneer de ontvanger het geschenk heeft ontvangen.",
                "go_back_button": "Terug",
                "write_message_for": "Schrijf een bericht voor",
                "message_input_label": "Bericht",
                "message_input_placeholder": "Schrijf een bericht",
                "from_input_label": "Van",
                "save_message_button": "Bericht opslaan",
                "edit_message_button": "Wijzig bericht",
                "message_updated_notification": "Je bericht is succesvol opgeslagen.",
                "view_message_button": "Bekijken",
                "write_message_button": "Schrijven",
                "write_message_for_before_date_text": "voor",
                "received_messages": {
                    "tap_instructions": {
                        "title": "Tik op het scherm om door je berichten te gaan",
                        "previous": "Vorig bericht",
                        "next": "Volgend bericht",
                        "use_bottom_icons": "Of gebruik deze knoppen"
                    },
                    "skip_to_end_button": "Naar einde",
                    "page_title": "Proficiat!",
                    "say_thank_you_button": "Zeg dankjewel",
                    "landing": {
                        "you_have_messages": {
                            "title": "Proficiat!",
                            "explainer": "Je hebt een paar berichten ontvangen die speciaal voor jou bedoeld zijn."
                        },
                        "say_thank_you": {
                            "title": "Zeg bedankt aan",
                            "explainer": "Hier kan je met een boodschap jouw waardering uiten aan de gever van het geschenk."
                        },
                        "view_messages": "Bekijk berichten",
                        "say_thank_you_button": "Zeg dankjewel"
                    }
                },
                "thank_you_modal": {
                    "thank_you_sent_title": "Je dankbericht is verzonden",
                    "title": "Zeg bedankt",
                    "thank_you_sent_explainer": "De verzender van het geschenk zal deze boodschap via mail ontvangen. Fijne dag!",
                    "explainer": "De persoon die bestelde zal een e-mail ontvangen met jouw bedanktboodschap.",
                    "message_input_label": "Je boodschap",
                    "close": "Sluiten",
                    "say_thanks_button": "Boodschap verzenden"
                },
                "thank_you_page": {
                    "title": "Zeg dankjewel aan",
                    "explainer": "Laat hen weten dat je het geschenk waardeert door op jouw manier dankjewel te zeggen.\n\nSommige collega’s kunnen nog een bericht toevoegen, aangezien de deadline voor het bijdragen nog niet is bereikt. Kom later vandaag of morgen nog eens terug!"
                },
                "rewatch_messages_button": "Herbekijken",
                "clear_all_contributors_button": "Lijst leegmaken"
            }
        },
        "dashboard": {
            "news": "Nieuws",
            "spend_report": "Bestedingsrapport",
            "no_news": "Geen nieuws",
            "no_spend_data": "Er zijn nog geen bestellingen geplaatst, waardoor er geen data is voor een rapport.",
            "trees_planted": "Geplante bomen",
            "trees_planted_great_job": "Goed werk voor jou en je team!<br>Dat is genoeg ter compensatie van",
            "learn_more": "Meer weten",
            "no_trees_planted_message": "Begin met het plaatsen van bestellingen en zie hoe dit het milieu beïnvloedt via ons WeForest programma.",
            "select_occasion_before_redirect": "Selecteer een gelegenheid voordat we je doorverwijzen naar het geselecteerde cadeau.",
            "dashboard": "Dashboard",
            "change_language": "Taal veranderen"
        },
        "quotation": {
            "quotation": "Offerte",
            "date": "Offertedatum",
            "expiration-date": "Vervaldatum",
            "description": "Omschrijving",
            "amount-excl-vat": "Bedrag (excl. BTW)",
            "quantity": "Aantal",
            "vat": "BTW",
            "total-excl-vat": "Totaal (excl. BTW)",
            "net": "Netto",
            "total": "Totaal",
            "created-at": "Aangemaakt op",
            "name": "Naam",
            "signature": "Handtekening",
            "delivery-date": "Leveringsdatum",
            "invoice-address": "Facturatieadres",
            "our-terms-can-be-found-on-the-following-page": "De uitvoering van deze offerte wordt exclusief beheerst door de <a target='_blank' href='https://kadonationselect.com/terms-and-conditions'>Algemene Voorwaarden</a> en de <a target='_blank' href='https://kadonationselect.com/data-processing-agreement'>Data Processing Policy</a> van Kadonation, zoals bijgevoegd. Door goedkeuring/ondertekening van deze offerte (of bevestiging van deze offerte via bv. een aankooporder met verwijzing naar de offerte), verklaart de klant kennis te hebben genomen van voormelde documenten en akkoord te gaan met de bepalingen daarin opgenomen.",
            "for-agreement": "Voor akkoord",
            "wanted-delivery-date": "Gewenste leverdatum",
            "created-in-text": "Opgemaakt te Gent op {createdAt} in zoveel exemplaren als er partijen zijn",
            "ordered-quantity-may-differ": "Het definitief aantal bestelde cadeaubonnen mag 10% afwijken van de in deze offerte opgenomen aantallen.",
            "provide_valid_email": "Gelieve een geldig e-mailadres op te geven.",
            "minimum_one_recipient": "Voeg minstens één begunstigde toe.",
            "missing-delivery-info-title": "Geen leveringsadres toegevoegd aan de bestelling",
            "missing-delivery-info-description": "Om ervoor te zorgen dat de verzendkosten correct berekend kunnen worden moet er een verzendadres toegevoegd worden aan de bestelling. Je kan dit doen door naar de pagina van de bestelling te gaan en te klikken op de actie \"Add delivery address to order\".",
            "vendor_confirm_quote": {
                "description": "De offerte is klaar om gepresenteerd te worden aan onze klant. Gelieve te bevestigen of de items kunnen aangeboden worden aan de klant, of laat ons weten wat er moet veranderen met behulp van het 'Opmerkingen' veld. Indien er meerdere offertes zijn, gelieve te laten weten welke kunnen of niet kunnen.",
                "approve_or_reject_request": "Aanvraag aanvaarden/weigeren",
                "order_id": "Order ID",
                "email_sent_on": "Aanvraag email verstuurd op",
                "remarks": "Opmerkingen",
                "accept": "Aanvaarden",
                "reject": "Weigeren",
                "request_accepted": "Aanvraag aanvaard",
                "request_accepted_description": "Deze aanvraag is nu gemarkeerd als 'aanvaard'. We sturen de offerte door naar de klant.",
                "request_rejected": "Aanvraag geweigerd",
                "request_rejected_description": "Deze aanvraag is nu gemarkeerd als 'geweigerd'. We bekijken je opmerkingen, maken de gevraagde wijzigingen en nemen opnieuw contact op voor goedkeuring.",
                "quotation_info": "Offerte info",
                "response_on": "Aanvraag beantwoord op",
                "quote_id": "Offerte ID",
                "title": "Offerte Aanvraag"
            },
            "requested-by": "Aangevraagd door",
            "missing-expected-delivery-at-info-text": "This order does not have an expected delivery date set. Beware that this is needed to calculate the expiry date of vouchers!",
            "missing-expected-delivery-at-info-title": "No expected delivery date is set",
            "shipping-price-page": {
                "explanation": "De hierboven vermelde verzendkosten zijn indicatief en kunnen variëren. Op de volgende pagina staan onze verzendtarieven in een tabel, zodat u de kosten per levering aan kantoor/thuisadres kunt berekenen.",
                "heading": "Verzendprijstabel",
                "table": {
                    "country": "Land",
                    "min_gifts": "Min Gifts",
                    "max_gifts": "Max Gifts",
                    "price": "Prijs (excl. BTW)"
                }
            },
            "delivery_to": "(levering naar: {deliveryType})",
            "office_delivery": "kantoor",
            "home_delivery": "ontvanger",
            "will-be-delivered-to": "Te leveren in",
            "delivery-address": "Leveradres"
        },
        "filter": {
            "price_range": "Prijs",
            "min": "Min",
            "max": "Max",
            "category": "Categorie",
            "countries": "Levering naar",
            "view_more": "Toon {count} andere",
            "view_less": "Minder tonen",
            "sort": {
                "popular": "Populair",
                "new": "Nieuw",
                "recommended": "Aanbevolen",
                "price_asc": "Prijs laag-hoog",
                "price_desc": "Prijs hoog-laag"
            },
            "no_filter_found": "Geen filters gevonden",
            "find_filter": "Zoeken",
            "customizable": "Personaliseerbaar",
            "next_day_delivery": "Volgende dag geleverd",
            "sustainable": "Duurzaam",
            "made_locally": "Lokaal gemaakt",
            "clear_filters": "Filters leegmaken",
            "boolan_filters": "Type",
            "loading_gifts": "Geschenken laden",
            "price": "Prijs",
            "all_price": "Elke prijs",
            "delivery_country": "Levering naar",
            "sort_label": "Sorteren",
            "delivery_region": "Levering naar specifieke regio"
        },
        "modals": {
            "departments": {
                "delete_error": {
                    "has_users": {
                        "title": "Kan departement niet verwijderen",
                        "message": "Dit departement kan niet verwijderd worden, omdat er gebruikers aan gekoppeld zijn. Wijs deze gebruikers toe aan een ander departement voordat u het departement verwijdert."
                    },
                    "has_credits": {
                        "title": "Kan departement niet verwijderen",
                        "message": "Dit departement kan niet verwijderd worden, omdat er ongebruikte credits voor beschikbaar zijn."
                    }
                }
            },
            "users": {
                "deactivate_error": {
                    "has_applicants": {
                        "title": "Kan gebruiker niet deactiveren",
                        "message": "Deze gebruiker kan niet worden verwijderd omdat deze is ingesteld als verantwoordelijke voor goedkeuring voor andere gebruikers. Wijs hiervoor een andere verantwoordelijke aan voordat u deze deactiveert."
                    }
                }
            },
            "general": {
                "list": {
                    "has_more": "... en nog {number} andere"
                }
            },
            "add_to_cart": {
                "no_address": {
                    "title": "Geen adres ingesteld",
                    "message": "Er is nog geen adres ingesteld op de bedrijfsentiteit. Gelieve eerst een adres in te geven, aangezien dit vereist is om orders te kunnen plaatsen.",
                    "buttons": {
                        "confirm": "Adres instellen",
                        "cancel": "Sluiten"
                    }
                }
            },
            "invoices": {
                "insufficient_credits_to_finalize_proforma": {
                    "title": "Onvoldoende credits",
                    "message": "Er zijn onvoldoende credits om de kosten van sommige bestellingen te dekken. Als u doorgaat met het finaliseren van de proforma, wordt het resterende bedrag via een factuur in rekening gebracht.",
                    "buttons": {
                        "cancel": "Annuleren",
                        "confirm": "Finaliseren"
                    }
                }
            },
            "delete_department_modal_title": "Departement verwijderen?",
            "delete_address_modal_title": "Adres verwijderen?",
            "clear-cart-modal-title": "Winkelwagen leegmaken?",
            "clear-cart-modal-message": "Ben je zeker dat je alle geschenken uit je winkelwagen wilt verwijderen?",
            "confirm_delete_user": {
                "title": "Gebruiker verwijderen?",
                "message": "Ben je zeker dat je deze gebruiker wenst te verwijderen?"
            },
            "upload_logo_title": "Upload logo",
            "upload_logo_body": "Je logo zal geprint worden op geschenkbonnen en wenskaartjes."
        },
        "validation": {
            "custom": {
                "message": {
                    "max": "Het {attribute} mag niet groter zijn dan {max} karakters.",
                    "required_with": "Het veld '{attribute}' is verplicht."
                },
                "from": {
                    "required_with": "Het veld '{attribute}' is verplicht."
                },
                "to": {
                    "required_with": "Het veld '{attribute}' is verplicht."
                },
                "option": {
                    "required_without": "Het veld '{attribute}' is verplicht wanneer {values} niet aanwezig is.",
                    "required": "Gelieve een optie te selecteren"
                },
                "attribute-name": {
                    "rule-name": "Persoonlijk bericht"
                },
                "price": {
                    "required": "Het veld '{attribute}' is verplicht.",
                    "numeric": "Het veld '{attribute}' moet numeriek zijn.",
                    "required_without": "Gelieve een bedrag te kiezen of in te vullen"
                },
                "excel": {
                    "required_with": "Gelieve het excelbestand op te laden",
                    "row_error": "Er deed zich een fout voor op rij {row}. {message}",
                    "message_row": "{message}. Rij {rows}.",
                    "date_format": "Het datumformaat voor {attribute} is ongeldig, gelieve data zo te formatteren: dd/mm/jjjjj",
                    "date_in_future": "De opgegeven datum voor {attribute} mag niet in de toekomst liggen",
                    "company_entity_not_found": "Bedrijfsentiteit niet gevonden ({attribute})",
                    "department_not_found": "Departement niet gevonden ({attribute})",
                    "country_not_found_with_code": "Land niet gevonden met code {attribute}",
                    "external_employee_id": "Gedupliceerde externe werknemer-ID!",
                    "attribute_missing": "De {attribute} ontbreekt!",
                    "manager_not_found": "Teamleider/manager kon niet gevonden worden."
                },
                "personal_message": {
                    "max": "Persoonlijke berichten kunnen maximum {max} karakters zijn. Je bericht is {excess} karakters te lang. Houd in gedachte dat een 'enter' telt as {newline_size} karakters.",
                    "emoji": "Je persoonlijke bericht mag geen emoji's of tekens die emoji's vormen bevatten. Deze kunnen niet geprint worden."
                }
            },
            "accepted": "De {attribute} moet geaccepteerd worden.",
            "active_url": "De {attribute} is geen geldige URL.",
            "after": "De {attribute} moet een datum zijn na {date}.",
            "after_or_equal": "De {attribute} moet een datum zijn gelijk aan of na {date}.",
            "alpha": "De {attribute} mag enkel letters bevatten.",
            "alpha_dash": "De {attribute} mag enkel letters, cijfers, gewone en liggende streepjes bevatten.",
            "alpha_num": "De {attribute} mag enkel letters en cijfers bevatten.",
            "array": "De {attribute} moet een rij zijn.",
            "before": "De {attribute} moet een datum voor {date} zijn.",
            "before_or_equal": "De {attribute} moet een datum voor of gelijk aan {date} zijn.",
            "between": {
                "array": "De {attribute} moet tussen {min} en {max} artikels bevatten.",
                "file": "De {attribute} moet tussen {min} en {max} kilobytes zijn.",
                "numeric": "De {attribute} moet tussen {min} en {max} zijn.",
                "string": "De {attribute} moet tussen {min} en {max} karakters bevatten."
            },
            "boolean": "Het veld '{attribute}' moet juist of fout zijn.",
            "confirmed": "De {attribute} bevestiging komt niet overeen.",
            "date": "De {attribute} is geen geldige datum.",
            "date_equals": "De {attribute} moet een datum gelijk zijn aan {date}.",
            "date_format": "De {attribute} komt niet overeen met het formaat {format}.",
            "different": "De velden {attribute} en {other} moeten verschillend zijn.",
            "digits": "{attribute} moet {digits} cijfers zijn.",
            "digits_between": "{attribute} moet tussen {min} en {max} cijfers zijn.",
            "dimensions": "{attribute} heeft ongeldige fotoafmetingen.",
            "distinct": "Het veld '{attribute}' heeft een dubbele waarde.",
            "email": "{attribute} moet een geldig e-mailadres zijn.",
            "ends_with": "{attribute} moet eindigen met een van de volgende waarden: {values}.",
            "exists": "Het geselecteerde {attribute} is ongeldig.",
            "file": "{attribute} moet een bestand zijn.",
            "filled": "Het veld '{attribute}' moet een waarde hebben.",
            "gt": {
                "array": "{attribute} moet meer dan {value} artikels hebben.",
                "file": "{attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "{attribute} moet groter zijn dan {value}.",
                "string": "{attribute} moet groter zijn dan {value} karakters."
            },
            "gte": {
                "array": "{attribute} moet {value} of meer artikels hebben.",
                "file": "{attribute} moet groter of gelijk zijn aan {value} kilobytes.",
                "numeric": "{attribute} moet groter of gelijk zijn aan {value}.",
                "string": "{attribute} moet groter of gelijk zijn aan {value} karakters."
            },
            "image": "{attribute} moet een afbeelding zijn.",
            "in": "De gekozen {attribute} is ongeldig.",
            "in_array": "Het veld '{attribute}' bestaat niet in {other}.",
            "integer": "{attribute} moet een geheel getal zijn.",
            "ip": "{attribute} moet een geldig IP-adres zijn.",
            "ipv4": "{attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "{attribute} moet een geldig IPv6-adres zijn.",
            "json": "{attribute} moet een geldig JSON string zijn.",
            "lt": {
                "array": "{attribute} moet minder dan {value} artikels hebben.",
                "file": "{attribute} moet minder dan {value} kilobytes hebben.",
                "numeric": "{attribute} moet minder zijn dan {value}.",
                "string": "{attribute} moet minder dan {value} karakters hebben."
            },
            "lte": {
                "array": "{attribute} mag niet meer dan {value} artikels hebben.",
                "file": "{attribute} moet kleiner of gelijk zijn aan {value} kilobytes.",
                "numeric": "{attribute} moet kleiner of gelijk zijn aan {value} karakters.",
                "string": "{attribute} moet kleiner of gelijk zijn aan {value} karakters."
            },
            "max": {
                "array": "{attribute} mag niet meer dan {max} artikels bevatten.",
                "file": "{attribute} mag niet groter zijn dan {max} kilobytes.",
                "numeric": "{attribute} mag niet groter zijn dan {max}.",
                "string": "{attribute} mag niet groter zijn dan {max} karakters."
            },
            "mimes": "{attribute} moet een bestand zijn van het type {values}.",
            "mimetypes": "{attribute} moet een bestand zijn van het type {values}.",
            "min": {
                "array": "{attribute} moet minstens {min} artikels bevatten.",
                "file": "{attribute} moet minstens {min} kilobytes zijn.",
                "numeric": "{attribute} moet minstens {min} zijn.",
                "string": "{attribute} moet minstens {min} karakters bevatten."
            },
            "not_in": "De geselecteerde {attribute} is ongeldig.",
            "not_regex": "Het {attribute} formaat is ongeldig.",
            "numeric": "{attribute} moet een getal zijn.",
            "password": {
                "mixed": "Het {attribute} moet ten minste één hoofdletter en één kleine letter bevatten.",
                "letters": "Het {attribute} moet ten minste één letter bevatten.",
                "symbols": "Het {attribute} moet tenminste één speciaal karakter bevatten. Bijvoorbeeld: @, &, /, etc",
                "numbers": "Het {attribute} moet ten minste één nummer bevatten.",
                "uncompromised": "Het gegeven {attribute} komt voor in een data lek and is niet veilig om te gebruiken. Gelieve een ander {attribute} in te geven."
            },
            "present": "Het veld '{attribute}' moet aanwezig zijn.",
            "regex": "Het {attribute} formaat is ongeldig. Het wachtwoord moet minstens 8 karakters lang zijn en moet volgende zaken bevatten: 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal teken.",
            "required": "Het veld '{attribute}' is verplicht.",
            "required_if": "Het veld '{attribute}' is verplicht wanneer {other} {values} is.",
            "required_unless": "Het veld '{attribute}' is verplicht, behalve wanneer {other} in {values} is.",
            "required_with": "Het veld '{attribute}' is verplicht wanneer {values} aanwezig is.",
            "required_with_all": "Het veld '{attribute}' is verplicht wanneer {values} aanwezig zijn.",
            "required_without": "Het veld '{attribute}' is verplicht wanneer {values} niet aanwezig is.",
            "required_without_all": "Het veld '{attribute}' is verplicht wanneer geen van de {values} aanwezig zijn.",
            "same": "{attribute} en {other} moeten overeenkomen.",
            "size": {
                "array": "{attribute} moet {size} artikels bevatten.",
                "file": "{attribute} moet {size} kilobytes bevatten.",
                "numeric": "{attribute} moet {size} zijn.",
                "string": "{attribute} moet {size} karakters zijn."
            },
            "starts_with": "{attribute} moet beginnen met een van de volgende elementen: {values}.",
            "string": "{attribute} moet een reeks zijn.",
            "timezone": "{attribute} moet een geldige tijdzone zijn.",
            "unique": "{attribute} is al ingenomen.",
            "uploaded": "Het uploaden van {attribute} is mislukt.",
            "url": "Het formaat van {attribute} is ongeldig.",
            "uuid": "{attribute} moet een geldige UUID zijn.",
            "tenant_subdomain_in_use": "Tenant subdomain is in use",
            "attributes": {
                "first_name": "voornaam",
                "option": "kies een optie",
                "price": "bedrag",
                "is_group": "groepsbestand",
                "department_id": "departement",
                "last_name": "achternaam",
                "message": "bericht",
                "from": "Het veld 'van'",
                "to": "Het veld 'aan'",
                "password": "wachtwoord",
                "excel_file": "excelbestand",
                "name": "Naam"
            },
            "message": "Bericht",
            "custom_dimension": "Image is invalid, Check the file for correct type and dimensions (Less than 150x30px or less than 5{1} ratio).",
            "duplicate_email_addresses": "Dubbele e-mailadressen in het bestand ({address})",
            "excel": {
                "amount_required_with_from_to_message": "Het bedrag is verplicht"
            },
            "name": {
                "en": "Naam",
                "fr": "Naam",
                "nl": "Naam"
            },
            "file_must_be_excel": "The file must be a valid excelfile",
            "must_have_only_one_option": "Dit geschenk accepteert maar één optie.",
            "no_duplicate_data_is_allowed": "Dubbele gegevens in bedrag of jaartelling zijn niet toegestaan.",
            "employee_already_in_team": "Werknemer is reeds lid van het eerder gekozen team.",
            "year_count_should_be_divisible_by_point_five": "Het {attribute} moet een geheel getal zijn of deelbaar door 0,5.",
            "employee_date_field_must_be_set": "employee_date_field gegevens zijn niet ingesteld voor deze gelegenheid.",
            "invalid_tenant_subdomain": "Ongeldig subdomein. Vermijd het gebruik van spaties, punten en andere speciale tekens.",
            "invalid_zipcode": "Ongeldige postcode, gelieve een geldige postcode in te vullen.",
            "shipping_country_not_supported": "{vendor} ondersteunt momenteel geen verzending naar {value}. Kies een ander adres.",
            "default_entity_unset": "Kan standaard bedrijf niet uitzetten. Als u een ander bedrijf als standaard wilt instellen, wordt dit automatisch uitgevinkt!",
            "unknown_shipping_country": "Wij ondersteunen geen verzending naar het land van deze ontvanger. ({country})",
            "recipient_name_character_limit_exceeded": "De naam van de ontvanger (voornaam + achternaam) mag maximum 30 karakters lang zijn.",
            "tenant_vat_number_does_not_exist": "The VAT Number was checked and does not exist. Please check with the Tenant for their correct VAT Number. You may proceed creating this Tenant by indicating they are not ‘VAT Liable’ (meaning we will charge them VAT always)",
            "phone": "Het veld {attribute} moet een geldig getal zijn.",
            "company_email": "Gebruik je werkmail om een account aan te maken. E-maildomeinen zoals gmail, outlook en yahoo zijn niet toegestaan.",
            "unique_email_domain": "Het lijkt erop dat dit bedrijf reeds een account heeft.",
            "gift_cannot_be_send_to_that_zipcode": "Geschenk kan niet verstuurd worden naar deze regio ({zipCode}).",
            "gift_with_name_cannot_be_send_to_that_zipcode": "'{giftName}' kan niet gestuurd worden naar deze regio ({zipCode}).",
            "credits_payer_required": "Selecteer waarvan Credits moeten genomen worden.",
            "expiration_period": "The max expiration period is 3 years."
        },
        "auth": {
            "failed": "De inloggegevens komen niet overeen. Gelieve opnieuw te proberen.",
            "throttle": "Je hebt te vaak geprobeerd om in te loggen. Gelieve in {seconds} seconden opnieuw te proberen.",
            "2fa_verification": "2FA-verificatie",
            "2fa_continue": "Doorgaan",
            "2fa_setup": "2FA-verificatie instellen",
            "2fa_scan_or_code": "Scan onderstaande barcode of gebruik de code",
            "2fa_must_setup": "Je moet je Google Authenticator-app instellen voordat je verdergaat. Anders kan je je niet aanmelden.",
            "2fa_otp": "Eenmalig wachtwoord",
            "2fa_wrong_otp": "Het eenmalige wachtwoord is verkeerd getypt.",
            "2fa_cannot_be_empty": "Het eenmalige wachtwoord mag niet leeg zijn.",
            "2fa_unknown": "Er is een onbekende fout opgetreden. Gelieve opnieuw te proberen."
        },
        "anniversaries": {
            "step-1-title": "Gegevens {typeOfService}",
            "step-1-description": "Zet een {typeOfService} op door een naam te bepalen en te bepalen welk bedrag de medewerkers ontvangen.",
            "step-2-title": "Selecteer medewerkers",
            "step-2-description": "Selecteer welke medewerkers of welke teams dit geschenk mogen ontvangen.\nJe kan ook bepalen of nieuwe medewerkers automatisch worden toegevoegd aan deze {typeOfService}.",
            "step-3-title": "Bestellingsgegevens",
            "step-3-description": "Controleer volledig zelf hoe deze bestellingen geplaatst worden en welke gegevens op de factuur moeten komen.",
            "step-4-title": "Personalisatie gegevens",
            "step-4-description": "Personaliseer het geschenk voor de medewerker, bepaal de boodschap op de cadeaubon, welke e-mail zal de medewerker ontvangen en op welk uur.",
            "different-amount-for-work": "Voor jubileum kan je verschillende bedragen instellen voor verschillende jaren in dienst",
            "year": "Jaar",
            "add-another-option": "Voeg een nieuwe optie toe",
            "enrollment-title": "Automatische inschrijving",
            "enrollment-description": "Maakt automatische toevoeging mogelijk van werknemers uit de lijst wanneer alle werknemers zijn geselecteerd of uit de geselecteerde teams",
            "no-results": "Er zijn geen medewerkers gevonden met deze zoekterm",
            "team": "Team | Teams",
            "selected": "Geselecteerd",
            "select-all-employees": "Selecteer alle medewerkers",
            "selected-teams": "Geselecteerde teams",
            "selected-employees": "Geselecteerde medewerkers",
            "search": "Zoek medewerkers",
            "template": "Sjabloon",
            "birthday-service": "Happy Birthday service",
            "work-anniversary-service": "jubileumservice",
            "service-name-placeholder": "Bv. {typeOfService} voor alle medewerkers",
            "select-employees-or-enable-auto-enrollment": "U moet werknemers/teams selecteren of automatische inschrijving inschakelen",
            "having-trouble": "Heeft u een probleem?",
            "contact-us": "Contacteer ons",
            "automate-service": "Automatiseer {typeOfService}",
            "lets-automate": "Geschenken automatiseren",
            "automate-gifts": "Automatiseer geschenken",
            "created-successfully": "{typeOfService} is met succes aangemaakt.",
            "birthday-automated-service-description": "Jouw verjaardagsservice is actief, je kan deze op elk moment aanpassen of verwijderen.",
            "work-anniversary-automated-service-description": "Jouw jubileumservice is actief, je kan deze op elk moment aanpassen of verwijderen.",
            "select-all-teams": "Selecteer alle teams",
            "template-preview": "Sjabloon voorbeeld",
            "expiration-date-description": "Stel een vervaldatum in voor deze automatisch dienst. Na deze vervaldatum zullen we geen bestellingen meer plaatsen voor deze dienst.",
            "deleted-successfully": "{typeOfService} is met succes verwijderd.",
            "are-you-sure": "Ben je zeker?",
            "all-employees": "Alle medewerkers",
            "updated-successfully": "{typeOfService} is met succes aangepast.",
            "lets-automate-work-anniversary": "Wens élke medewerker een fijn jubileum! Laat je als bedrijf dit waardevolle moment niet ongemerkt voorbij gaan, dan heb je beslist een streepje voor. Niemand vergeten? Daar zorgt onze gratis automatische jubileum service voor!Maak een koppeling met je personeelsbestand, stel een bedrag in, en Kadonation Select bezorgt jouw medewerker automatisch op de dag van zijn jubilea jouw attentie mét persoonlijke boodschap!",
            "lets-automate-birthday": "Wens élke medewerker een fijne verjaardag! \nLaat je als bedrijf dit waardevolle moment niet ongemerkt voorbij gaan, dan heb je beslist een streepje voor. Niemand vergeten? Daar zorgt onze gratis automatische Happy Birthday Service voor!\n\nMaak een koppeling met je personeelsbestand, stel een bedrag in, en Kadonation Select bezorgt jouw jarige medewerker automatisch jouw attentie mét persoonlijke boodschap!",
            "is-loading": "Werknemers laden...",
            "finish": "Opslaan",
            "error_message_here": "Error message here"
        },
        "subscription": {
            "upgrade_modal": {
                "general": {
                    "enterprise": {
                        "title": "Upgrade naar Enterprise",
                        "body": "Breng uw attentiebeleid naar nieuwe hoogten met het Enterprise-plan van Kadonation Select, ontworpen voor grootschalige activiteiten en bedrijven die het beste eisen. Dit plan biedt het ultieme in maatwerk en controle, zodat elk geschenk de kwaliteit van uw merk weerspiegelt."
                    },
                    "professional": {
                        "title": "Upgrade naar Professional",
                        "body": "Benut het potentieel van uw attentiebeleid met het Professional plan van Kadonation Select. Dit plan is ontworpen voor bedrijven die waarde hechten aan uitzonderlijke relaties met medewerkers en klanten, en verbetert uw geschenkervaring door geavanceerde tools en diensten aan te bieden."
                    }
                },
                "enterprise": {
                    "subscription_content": {
                        "title": "Upgrade naar Enterprise om toegang te krijgen tot:",
                        "list": "Eigen gelegenheden toevoegen ^\nDagelijkse synchronisatie van werknemers ^\nWhite label platform ^\nSingle Sign-On ^\nDedicated Success Manager ^\nFeature prioritering ^\nPrioritair support ^\nEn nog meer..."
                    }
                },
                "professional": {
                    "subscription_content": {
                        "title": "Upgrade naar Professional om toegang te krijgen tot:",
                        "list": "Geavanceerde facturatie-instellingen ^\nBeheer geschenken, gelegenheden en budget ^\nGeautomatiseerde synchronisatie van medewerkers ^\nAutomatiseringen voor geschenken ^\nKudos campagnes ^\nGoedkeuringsflow van bestellingen ^\nPermissies per gebruiker ^\nEn nog meer..."
                    }
                }
            },
            "view_plans": "Toon alle opties",
            "upgrade_button": "Upgrade",
            "disclosure": {
                "title": "Upgrade naar Professional om deze feature te gebruiken"
            },
            "cancel_subscription_button": "Licentie opzeggen",
            "canceled_disclosure": {
                "title": "Je licentie zal eindigen op {date}",
                "body": "Je hebt je licentie opgezegd. Je kunt de functies van de licentie gebruiken totdat de licentie eindigt. Daarna worden de gegevens met betrekking tot deze licentie verwijderd. Je keert automatisch terug naar de 'Basic'-licentie.",
                "button": "Licentie heractiveren"
            },
            "subscription_details": "Licentie Details",
            "subscription_plan": "Huidige licentie",
            "trial": "proefversie",
            "subscription_billing_cycle": "Frequentie",
            "subscription_billed_company_entity": "Bedrijfsentiteit",
            "subscription_starts_at": "Licentie start op",
            "subscription_canceled_at": "Licentie opgezegd op",
            "subscription_ends_at": "Licentie eindigt op",
            "next_invoice_at": "Volgende factuur",
            "cancel_modal": {
                "title": "Licentie opzeggen",
                "cancel_subscription_button": "Ja, licentie opzeggen",
                "keep_subscription_button": "Neen, licentie behouden",
                "intro_text": "Je licentie wordt onmiddellijk geannuleerd en zal niet langer aangerekend worden.",
                "lose_access_on": "U verliest de toegang tot de functies van uw licentie op {date}.",
                "data_removal_warning": {
                    "title": "Alle gegevens worden verwijderd",
                    "body": "We verwijderen ALLE GEGEVENS met betrekking tot de features in je licentie op {date}. Dit betreft:",
                    "list": "Alle automatiseringen en Kudos-campagnes ^\nVoorkeur geschenken ^\nInstellingen voor budgetten en gelegenheden ^\nInstellingen voor gebruikersrechten en ordergoedkeuring ^\nGeavanceerde factuurinstellingen"
                },
                "employee_sync_deactivation_warning": "Als je een automatische synchronisatie van werknemers hebt, wordt deze gedeactiveerd. Uw reeds toegevoegde werknemers blijven behouden.",
                "sure_to_cancel_title": "Weet je zeker dat je je licentie wilt opzeggen?"
            },
            "upgrade_disclosure": {
                "basic": {
                    "title": "Je beschikt momenteel over een 'Basic' licentie",
                    "body": "Haal alles uit jouw attentiebeleid met het Professional plan van Kadonation Select.",
                    "button": "Meer weten"
                },
                "professional": {
                    "title": "Je beschikt momenteel over een 'Professional' licentie",
                    "body": "Til jouw attentiebeleid naar een nog hoger niveau met het Kadonation Select's Enterprise plan.",
                    "button": "Meer weten"
                },
                "trial": {
                    "title": "Je beschikt momenteel over een 'Professional' proefversie",
                    "body": "Til jouw attentiebeleid naar een nog hoger niveau met het Kadonation Select's Enterprise plan. Je keert automatisch terug naar de 'Basic' licentie nadat de proefversie is afgelopen.",
                    "button": "Meer weten"
                }
            },
            "canceled": "Opgezegd"
        },
        "support": {
            "account_manager": "Customer success manager",
            "chat": "Chat met ons",
            "FAQ": "Veelgestelde vragen",
            "chat-btn": "Chat openen",
            "no-articles": "Geen artikels gevonden. Contacteer ons via chat, email of telefoon als u hulp nodig heeft.",
            "title": "Hulp",
            "message_sent_successfully": "Uw bericht is succesvol naar ons team verzonden.",
            "support_message_received": "Nieuw ondersteuningsverzoek",
            "support_message_description": "&nbsp;",
            "view_order": "Bekijk bestelling",
            "footer_thank_you": "&nbsp;",
            "call": "Bel ons",
            "call-be": "<b>Support BE:</b> <a href=\"tel:093963200\" class=\"hover{text}-pink-300 transition ease-in-out duration-300 pt-1\">+32 (0) 9 396 32 00</a>",
            "call-nl": "<b>Support NL:</b> <a href=\"tel:0858884933\" class=\"hover{text}-pink-300 transition ease-in-out duration-300 pt-1\">+31 (0) 85 888 49 33</a>"
        },
        "categories": {
            "categories": "Geschenkcategorieën",
            "gift_categories_for": "{category}"
        },
        "forms": {
            "first_name": "Voornaam",
            "last_name": "Achternaam",
            "email": "E-mailadres",
            "password": "Wachtwoord",
            "password_confirmation": "Wachtwoord bevestiging",
            "department": "Departement",
            "company_entity": "Bedrijfsentiteit",
            "locale": "Taal",
            "save": "Opslaan",
            "entity": "Entiteit",
            "po_number": "PO-nummer",
            "seperate_invoice": "Aparte facture",
            "monthly_invoice": "Voeg toe aan mijn maandelijkse factuur",
            "confirm": "Bevestig",
            "company_logo": "Logo van je bedrijf",
            "company_name": "Bedrijfsnaam",
            "company_vat_number": "BTW-nummer van je bedrijf",
            "invoice_notify_email": "E-mailbevestiging van de factuur",
            "invoice_configuration": "Standaard factuur type",
            "add": "Voeg toe",
            "department_name": "Naam van het departement",
            "company_entity_name": "Naam van de bedrijfsentiteit",
            "VAT_number": "BTW-nummer",
            "set_default": "Als default instellen",
            "yes": "Ja",
            "no": "Nee",
            "address": "Adres",
            "location_title": "Titel van de locatie",
            "to_name": "Geadresseerde",
            "street": "Straat",
            "number": "Nummer",
            "bus": "Busnummer",
            "zipcode": "Postcode",
            "city": "Stad",
            "country": "Land",
            "entity_name": "Naam van de entiteit",
            "choose_option": "Gelieve een optie te kiezen",
            "remember_me": "Onthoud mij",
            "confirm_password": "Bevestig je wachtwoord",
            "reset_password": "Reset mijn wachtwoord",
            "choose_country": "Kies een land",
            "language": "Taal",
            "is_active": "Actief",
            "permissions": "Rechten",
            "invoice": "Factuurtype",
            "to_name_explanation": "Je kan dit vrij aanpassen voor opgeslagen adressen",
            "address_title_explanation": "We gebruiken dit om het adres op te slaan",
            "choose_language": "Kies taal",
            "general": "Algemene informatie",
            "file_cannot_be_processed": "Het bestand is onvolledig en kan dus niet verwerkt worden.",
            "detailed_invoice": "Detailfactuur",
            "invoice_per_department": "Maandelijkse factuur per departement",
            "cost_center": "Kostenplaats",
            "proforma_first_for_separate_invoice": "Eerst proforma bij aparte factuur",
            "finalize_proforma_invoice": "Finaliseer Proforma Factuur",
            "group_name": "Groepsnaam",
            "proforma_first_for_monthly_invoice": "Standaard eerst proforma bij maandelijkse factuur",
            "default_proforma_first_for_separate_invoice": "Standaard eerst proforma bij aparte factuur",
            "set_po_for_all_orders": "PO instellen voor alle orders van deze factuur",
            "finalize": "Finaliseer factuur",
            "orders": "Bestellingen",
            "proforma_first": "Proforma factuur?",
            "is_inactive": "Inactief",
            "processing": "Aan het verwerken",
            "set_approval_manager": "Bestellingen moeten goedgekeurd worden door",
            "your_email": "E-mailadres",
            "submit": "Opslaan",
            "policy_updated_both": "Het gebruikersbeleid en gegevensbeleid zijn aangepast. Gelieve opnieuw te accepteren.",
            "updated": "Aangepast",
            "data_policy_updated": "Het gegevensbeleid is aangepast. Gelieve deze opnieuw te accepteren.",
            "use_policy_updated": "Het gegevensbeleid is aangepast. Gelieve deze opnieuw te accepteren.",
            "no_employees_added": "Geen werknemers toegevoegd",
            "are_you_sure": "Ben je zeker?",
            "please_select_items_to_update": "Gelieve de elementen te selecteren die je wenst aan te passen",
            "reason_approve": "Reden",
            "reject": "Afkeuren",
            "approve": "Goedkeuren",
            "reason_reject": "Reden",
            "update_delivery_title": "Update de levering",
            "update_delivery_title_delivered": "Update de levering",
            "update_delivery_description": "Leveringsgegevens bijwerken",
            "delete": "Verwijder",
            "update_delivery": "Update de levering",
            "select_all_permissions": "Schakel alle rechten in / uit",
            "date_of_birth": "Geboortedatum",
            "date_of_employment": "Datum van indiensttreding",
            "update_delivery_description_delivered": "Deze bon is al verzonden. Werk de datum en / of het e-mailadres bij om opnieuw te verzenden.",
            "unknown": "Onbekend",
            "prepared": "Voorbereid",
            "delivered": "Afgeleverd",
            "saml_sso_url": "SSO URL",
            "saml_entity_id": "SSO-entiteits-ID",
            "saml_sso_certificate": "Certificaat (X.509)",
            "saml": "SAML SSO inschakelen",
            "file_upload": {
                "btn": "Opladen",
                "btn-remove": "verwijderen",
                "drop": "Of sleep hier het bestand"
            },
            "from": "Van",
            "to": "Geaddresseerd aan",
            "amount": "Bedrag",
            "message": "Bericht",
            "delivery_date": "Bezorgdatum",
            "delivery_time": "Levertijd",
            "detailed_reference": "Detail referentie",
            "enter_message_here": "Vul je bericht hier in",
            "show": "Tonen",
            "hide": "Verbergen",
            "errors": "Problemen",
            "deferred": "Deferred",
            "dropped": "Dropped",
            "bounce": "Bounces",
            "blocks": "Geblokkeerd",
            "include_department_name_on_invoices": "Vermeld de naam van het departement op de factuur",
            "enter_street_here": "Vul hier de straat in",
            "enter_street_number_here": "Vul hier de straatnummer in",
            "enter_bus_here": "Vul hier de bus nummer in",
            "enter_zipcode_here": "Vul hier de postcode in",
            "enter_city_here": "Vul hier de stad in",
            "enter_country_here": "Selecteer land",
            "location_to_name": "Naam ontvanger",
            "default_proforma_first_for_monthly_invoice": "Standaard eerst proforma bij maandelijkse factuur",
            "vat_liable": "BTW-plichtig",
            "address_from_vat_message": "We proberen je adresgegevens in te vullen op basis van je BTW nummer.",
            "edit": "Bewerk",
            "cancel": "Annuleren",
            "occasion": "Gelegenheid",
            "ordered_by": "Besteld door",
            "optional": "Optioneel",
            "date_from": "Datum vanaf",
            "date_to": "Datum tot",
            "order-id": "Bestelling ID",
            "full_name": "Volledige naam",
            "external_employee_id": "Werknemersnummer",
            "employees_override": "Overschrijf alle medewerkers met nieuwe gegevens",
            "employees_override_info": "Dit zal de informatie van bestaande werknemers bijwerken met de gegevens uit de Excel. OPGELET: dit zal ook alle werknemers verwijderen die niet voorkomen in de Excel.",
            "company": "Bedrijf",
            "saved_addresses": "Opgeslagen adressen",
            "use_new_address": "Nieuw adres gebruiken",
            "search_employee": "Werknemer zoeken",
            "select_occasion": "Select Occasion",
            "reset_password_sent": "Uw wachtwoord reset link is verzonden naar uw e-mail!",
            "back_to_login": "Ga terug naar login pagina",
            "reset_password_resend": "Opnieuw verzenden wachtwoord reset e-mail",
            "subdomain": "Subdomein",
            "processed": "Verwerkt",
            "add_more_options": "Voeg meer opties toe",
            "remove_option": "Verwijder optie",
            "edit_message": "Bewerk bericht",
            "apideck_company_id": "Bedrijf ID (in je HRIS software)",
            "open_in_editor": "Open in editor",
            "force_invoice_configuration": "Zet standaard factuur type vast voor alle bestellingen",
            "fixed_cost_center": "Vaste kostenplaats",
            "recipient_email_address": "Ontvanger Email Adres",
            "why_email_track_and_trace": "De track & trace notificaties zullen naar dit email adres verstuurd worden.",
            "po_number_required": "PO nummer verplicht",
            "recipient_first_name": "Ontvanger Voornaam",
            "recipient_last_name": "Ontvanger Achternaam",
            "enter_recipient_first_name": "Vul hier de ontvanger's voornaam in",
            "enter_recipient_last_name": "Vul hier de ontvanger's achternaam in",
            "street_number": "Straatnummer",
            "expected_delivery_date": "Gewenste leverdatum",
            "require_proforma_invoice": "Proforma factuur verplicht",
            "cost_center_required": "Kostenplaats verplicht",
            "proforma_notify_email": "E-mailbevestiging van de proforma factuur",
            "datepicker": {
                "select": "Selecteren",
                "cancel": "Annuleren"
            },
            "bus_help_text": "The word 'Bus' is added later by us.",
            "recipient_full_name": "Ontvanger voornaam + Ontvanger achternaam",
            "invoice_notify_email_cc": "Stuur factuur ook naar (in cc)",
            "order_notify_email_cc": "Stuur alle bestelling emails ook naar (in cc)",
            "employee_type": "Type werknemer",
            "fixed_po_number": "Vast PO nummer",
            "phone-number": "Telefoon nummer",
            "options_selected": "Geen optie geselecteerd | {count} optie geselecteerd | {count} opties geselecteerd",
            "nothing_found": "Niets gevonden",
            "show_occasion_on_invoice": "Toon gelegenheid waarvoor besteld op factuur",
            "use_new_address_or_search": "Selecteer, of zoek naar, een opgeslagen adres",
            "password_explainer": "Het wachtwoord moet meer dan 8 tekens lang zijn en ten minste bestaan uit: 1 hoofdletter, 1 kleine letter, 1 numerieke waarde en 1 speciaal teken.",
            "occasions": "Gelegenheden",
            "backup_approval_manager": "Backup voor goedkeuren van bestellingen",
            "logo_standard": "Logo",
            "logo_standard_help_text": "Enkel logos in afbeelding formaat (bvb .jpg .jpeg .png)",
            "logo_vector": "Logo (vector)",
            "logo_vector_help_text": "Enkel logo's in vector formaat (bvb .eps, .svg)",
            "upload_logo": "Upload logo",
            "update_logo": "Update logo",
            "name": "Naam"
        },
        "gifts": {
            "no_gifts_found": "Er zijn geen geschenken gevonden die voldoen aan uw criteria.",
            "number_of_gifts_found": "{showing} van {total} geschenken weergegeven",
            "not_shippable_error": "De verzendconfiguratie is onvolledig voor dit geschenk. Ons team is op de hoogte gesteld van deze fout. Probeer het later opnieuw of neem contact op met de klantenservice voor meer informatie.",
            "show_gift_images": "Toon product foto's",
            "show_option_images": "Toon optie foto's",
            "orders_limited_to_vendor": "Je kan per bestelling enkel bij één geschenken partner bestellen",
            "orders_limited_to_vendor_description": "Je kan slechts bij één geschenken partner tegelijk een bestelling plaatsen. Om cadeaus van een andere geschenken partner te bestellen, moet je eerst je huidige bestelling afronden of je winkelwagen leegmaken.",
            "different_vendor": "Andere geschenken partner",
            "all_gifts_shown": "Alle resultaten worden getoond",
            "personalize_voucher_title": "Personaliseer cadeaubon",
            "personalize_greeting_card_title": "Personaliseer kaart",
            "personalize_free_card_title": "Personaliseer kaart",
            "personalize_voucher_subtitle": "Personaliseer de inhoud van de cadeaubon.",
            "personalize_greeting_card_subtitle": "Dit cadeau is een kaartje. Je kan de inhoud van dit kaartje personaliseren met de velden hieronder.",
            "personalize_free_card_subtitle": "Aan dit geschenk kan een kaartje toegevoegd worden. Personaliseer de inhoud van dit kaartje met de velden hieronder.",
            "min_quantity": "Minimum hoeveelheid",
            "max_quantity": "Maximum hoeveelheid",
            "language_description": "Bepaalt de taal van de automatisch toegevoegde tekst",
            "from_description": "Gedrukt onder uw persoonlijke boodschap",
            "to_description": "Gedrukt boven uw persoonlijke boodschap op de cadeaubon/kaart.",
            "pm_description": "U hoeft de naam van de ontvanger of de naam van de afzender niet toe te voegen als u die al hebt toegevoegd aan de velden 'Van' en/of 'Aan'. Let op, maximum 300 karakters (inclusief spaties, een 'enter' telt als 40 karakters). Emojis zijn niet ondersteund.",
            "personalize_gift_title": "Cadeau Ontwerpen",
            "personalize_gift_subtitle": "Dit geschenk kan gepersonaliseerd worden via onze editor",
            "personalize_gift_description": "Je kan één geschenk per keer ontwerpen. Indien je meerdere geschenken wil ontwerpen, zal je per geschenk het ontwerp moeten doen en toevoegen aan je winkelmandje.",
            "start_personalization": "Start met ontwerpen",
            "edit_personalization": "Ontwerp Aanpassen",
            "personalize": "Personaliseer",
            "excluding_vat": "(excl. BTW)",
            "add-to-favorites": "Toevoegen aan favorieten",
            "successfully-added-to-favorites": "Cadeau werd toegevoegd aan de favorieten voor '{occasion}'. Het zal vanboven tonen in deze gelegenheid.",
            "successfully-removed-from-favorites": "Cadeau werd verwijderd van favorieten voor '{occasion}'.",
            "favorite-gift-for-occasion": "Favoriet geschenk voor deze gelegenheid",
            "remove-from-favorites": "Verwijder van favorieten",
            "looking_for_sth_custom": "Op zoek naar iets op maat?",
            "customization_cta": "Contacteer ons",
            "looking_for_sth_else_description": "Niet gevonden naar wat je op zoek bent? Op zoek naar een cadeaubon of geschenk in jouw huisstijl? Wens je een geschenkpakket samen te stellen, uniek aan jouw merk? Contacteer ons, en we zorgen ervoor!",
            "looking_for_sth_else_title": "Op zoek naar iets specifiek of op maat?",
            "gift_without_customization_confirmation_description": "Dit geschenk kan gepersonaliseerd worden, en hebt nog geen zo'n personalisatie gedaan. Ben je zeker dat je verder wenst te gaan zonder personalisatie?",
            "gift_without_customization_confirmation_title": "Geschenk niet gepersonaliseerd",
            "show_gifts_from_all_vendors": "Toon cadeaus van alle leveranciers",
            "show_gifts_from_vendor": "Toon geschenken van dezelfde leverancier",
            "pm_description_for_card": "U hoeft de naam van de ontvanger of de naam van de afzender niet toe te voegen als u die al hebt toegevoegd aan de velden 'Van' en/of 'Aan'. Let op, maximum 600 karakters (inclusief spaties, een 'enter' telt als 40 karakters). Emojis zijn niet ondersteund.",
            "warnings": {
                "has_direct_future_delivery_non_voucher_gift_in_cart": {
                    "title": "Maak je bestelling af voordat je nieuwe geschenken toevoegt",
                    "description": "Je hebt een geschenk aan je winkelwagen toegevoegd waarbij per ontvanger een bezorgdatum is ingesteld. Dit kan niet worden gecombineerd met andere geschenken. Plaats eerst de bestelling voor het geschenk in je winkelwagen.",
                    "button": "Bekijk winkelwagen"
                }
            },
            "pm_description_for_card_digitize_enabled": "Deze boodschap wordt mee op het kaartje getoond. Let op, maximum 600 karakters (inclusief spaties, een 'enter' telt als 40 karakters). Emojis zijn niet ondersteund."
        },
        "orders": {
            "excel_user_list_title": "Bewerk lijst met begunstigden",
            "employee_list_title": "Selecteer en personaliseer medewerkers",
            "add_employees_button": "Selecteer medewerkers",
            "empty_search_result": "Er zijn geen medewerkers gevonden met deze zoekterm",
            "employee_list_info_message": "Selecteer hier alle medewerkers of teams die je een geschenk wenst te geven. Je kan medewerkers één voor één toevoegen, per team of allemaal tegelijkertijd. Nadien kan je het geschenk personaliseren per medewerker of voor meerdere ineens.",
            "add_all_employees": "Selecteer alle medewerkers",
            "search_and_select_info": "Zoek en selecteer medewerkers of teams",
            "add_employee_manually": "Voeg een ontvanger manueel toe",
            "excel_upload_error_message": "Er is een fout opgetreden bij het uploaden en lezen van het opgegeven Excel-bestand.",
            "excel_upload_success_message": "Bestand succesvol opgeladen!",
            "view_imported_users": "Bekijk de lijst van geïmporteerde ontvangers",
            "reading_excel": "Opgeladen excel lezen ...",
            "selected_employees": "{count} medewerker geselecteerd|{count} medewerkers geselecteerd",
            "imported_employees": "{count} begunstigde geïmporteerd|{count} begunstigden geïmporteerd",
            "select_employees": "Selecteer medewerkers uit werknemersbeheer",
            "edit_employee_list": "Bewerk de lijst met geselecteerde medewerkers",
            "errors_in_order_form": "Er zitten fouten in de verstrekte gegevens. Controleer de gemarkeerde rijen en herstel ze.",
            "search": "Zoeken (op voor- of achternaam)",
            "remove_excel_confirmation_message": "Deze actie zal het geuploade excel bestand en alle geïmporteerde gebruikers verwijderen. Wilt u doorgaan?",
            "excel_data_loss_message": "Deze actie verwijdert het geüploade excelbestand en vereist het uploaden van een ander excelbestand. Wilt u doorgaan?",
            "employee_list_errors": "{0} Er zijn {count} fouten in de werknemerslijst die je hebt ingediend.|{1} Er is een fout in de lijst met werknemers die je hebt ingediend.|Er zijn {count} fouten in de lijst met werknemers die je hebt ingediend.",
            "select_option_first": "Gelieve eerst een optie te kiezen",
            "add_video_for_personalization": "Wil je een videoboodschap toevoegen?",
            "video_url": "URL van de videoboodschap",
            "drop_file_or_click": "Drop bestand of klik om te uploaden",
            "format": "Bestandstype",
            "video_invalid_type": "Je kunt geen bestanden van dit type uploaden.",
            "video_file_too_big": "Bestand is te groot ({filesize}MB). Max bestandsgrootte: {maxFilesize}MB.",
            "video_response_error": "Server antwoordde met {statusCode} code.",
            "video_invalid_url": "Gelieve een geldige URL op te geven",
            "upload_canceled": "Het opladen is geannuleerd.",
            "cancel_upload_confirmation": "Ben je zeker?",
            "video_required": "URL van de videoboodschap of bestand is vereist",
            "video": "Video",
            "delete_video_info": "Om een nieuwe video op te laden moet je eerst de huidige video verwijderen.",
            "video_upload_in_progress": "De video wordt opgeladen, gelieve te wachten...",
            "price_free": "Gratis",
            "order_parameters": "Filters voor bestellingen",
            "order_item_message_parameters": "Kadonation cadeaubon filters",
            "show_all_filters": "Meer filters",
            "no_results_for_filter": "Er konden geen bestellingen gevonden worden met de geselecteerde filters",
            "no_results": "Er konden geen bestellingen gevonden worden",
            "filters": "Filters",
            "remove_all_filters": "Verwijder alle filters",
            "select_kudos_employees": "Bewerk de begunstigden",
            "edit_kudos_list": "Bewerk de begunstigden",
            "upload_video": "Video toevoegen",
            "thank_you_video": "Videoboodschap",
            "upload_video_button": "Video toevoegen",
            "no_video_uploaded": "Geen video opgeladen",
            "no_to_name": "Geen naam toegevoegd",
            "gift_vendor_internal_product_id": "Vendor product ID",
            "option_vendor_internal_product_id": "Vendor option ID",
            "order_handled_by": "Bestelling geplaatst door",
            "order_being_shipped": "Je bestelling is verzonden",
            "take_look_at_all_orders": "Hier kan je alle artikelen van je bestelling bekijken",
            "track_shipment": "Traceer bestelling",
            "invoiced_to": "Gefactureerd aan",
            "total_handling_fee": "Totaal extra's en verwerking",
            "approve_orders": "Goedkeuren",
            "reject_orders": "Weiger",
            "urgent": "Dringend",
            "message_details": "Details van het bericht",
            "deselect_all_recepients": "Deselecteer alle ontvangers",
            "select_all_recepients": "Selecteer alle ontvangers",
            "recipients_selected": "{count} ontvangers geselecteerd",
            "mass_update_explainer": "Het aanpassen van deze velden zal meteen de overeenstemmende kolom updaten voor alle geselecteerde ontvangers.",
            "all_recipients_selected": "Alle ontvangers geselecteerd",
            "scroll_horizontally_to_show_more_fields": "Scroll horizontaal om meer velden te tonen.",
            "these_are_required_fields": "Deze velden zijn verplicht:",
            "select_recipients_explanation": "Selecteer werknemers die je hebt toegevoegd aan Kadonation Select.",
            "email_explainer_for_direct_delivery_orders": "We hebben een email adres per ontvanger nodig om de track & trace notificaties naar toe te sturen. Indien je onzeker bent over het email adres van de ontvanger of als je niet wilt dat de ontvanger een track & trace email ontvangt, vul dan je eigen email adres in.",
            "confirm-vendor-order-title": "Bevestiging nodig",
            "confirm-vendor-order-description": "Gelieve de bestelling te bevestigen. 'Bevestiging' betekent dat we er vanuit gaan dat je de details gezien hebt, en dat je deze aan het verwerken bent. Hieronder vind je de informatie over deze bestelling, alsook de knop om de bestelling te bevestigen.",
            "vendor-email-sent": "Vendor email verstuurd",
            "vendor-order-please-confirm-order": "Gelieve deze bestelling te bevestigen",
            "vendor-order-confirmed-title": "Bestelling bevestigd",
            "vendor-order-confirmed-description": "Deze bestelling is gemarkeerd als 'bevestigd'.",
            "vendor-email-confirmed-at": "Bestelling bevestigd op:",
            "show_preview": "Afficher l'aperçu",
            "vendor-order-reserved-title": "Bevestiging van reservatie in orde",
            "reserve-vendor-order-title": "Bevestiging van reservatie nodig",
            "reserve-vendor-order-description": "Bevestig de reservering voor deze bestelling. Dit betekent dat je de bestelde artikelen op voorraad hebt of op tijd beschikbaar hebt voor de levering op de verwachte leverdatum die in de e-mail wordt vermeld. De personalisatie bestanden zullen later bezorgd worden. Hieronder vindt u de knop om deze reservering te bevestigen.",
            "vendor-order-please-reserve-order": "Bevestig hieronder de reservering van deze bestelling",
            "vendor-order-reserved-description": "Deze bestelling heeft nu de status 'gereserveerd'",
            "vendor-email-reserved-at": "Bestelling gereserveerd om:",
            "order_gift": "Bestel geschenk",
            "loading_preview": "Preview laden, dit kan enkele seconden duren...",
            "failed_to_load_preview": "De preview kon niet geladen worden, gelieve het later opnieuw te proberen",
            "preview_card": "Preview kaart",
            "preview_voucher": "Preview cadeaubon",
            "credits_used": "Gebruikte Credits",
            "excel_upload_error_message_v2": "There are errors in your Excel",
            "approval-explainer-title": "Bestelling wacht op goedkeuring door Kadonation",
            "approval-explainer-body": "Kadonation heeft dagelijkse en maandelijkse limieten om fraude te voorkomen. Je hebt deze limieten bereikt, waardoor bestellingen moeten worden goedgekeurd door Kadonation. Wij doen ons best om deze binnen enkele uren na plaatsing goed te keuren. Neem contact met ons op als de bestelling dringend is, of als je vragen hebt over deze limieten.",
            "created_via_automation": "Automatisch geschenk",
            "payment_method": "Betaalmethode",
            "direct_payment_failed_title": "Betaling mislukt",
            "direct_payment_failed_description": "Je betaling is mislukt. Probeer het opnieuw met onderstaande knop.",
            "direct_payment_retry_button": "Opnieuw proberen betalen",
            "direct_payment_pending_title": "Wachtende op betaling",
            "direct_payment_pending_description": "We hebben nog geen bevestiging van je betaling ontvangen. Als je al hebt betaald, ontvangen we binnenkort een bevestiging. Als je nog niet hebt betaald, probeer het dan opnieuw.",
            "direct_payment_canceled_title": "Betaling verlopen",
            "direct_payment_canceled_description": "De betaling is verlopen. Gelieve uw bestelling opnieuw te plaatsen.",
            "after_receiving_invoice": "Bankoverschrijving (na factuur)",
            "approval_flow_explainer": {
                "title": "Hou controle over aankopen",
                "description_1": "Met het goedkeuringssysteem voor bestellingen kan je goedkeuringsmanager de bestellingen van specifieke gebruikers goedkeuren of afwijzen.",
                "description_2": "Om deze feature te activeren, voeg je een goedkeuringsmanager toe aan een gebruiker. Ga naar 'Gebruikers', bewerk de gebruiker van wie de bestellingen moeten worden goedgekeurd en stel een goedkeuringsmanager in.",
                "button": "Toon gebruikers"
            },
            "contributor_selector": {
                "title": "Nodig mensen uit voor een persoonlijke boodschap",
                "explainer": "Selecteer hier alle medewerkers of teams die je wil uitnodigen om een persoonlijke boodschap achter te laten. Je kan medewerkers 1 voor 1 uitnodigen, per team, of allen tegelijk. Je kan ook manueel personen toevoegen, door hun e-mailadres in te vullen. Klik hiervoor op 'Voeg persoon manueel toe'."
            },
            "unblock_vouchers_page": {
                "unblock_vouchers": {
                    "title": "Deblokkeer jouw vouchers",
                    "content": "Om ervoor te zorgen dat uw vouchers alleen door de beoogde ontvanger worden gebruikt, hebben we ze geblokkeerd totdat we ze naar u hebben verzonden.\n<br/><br/>\nGebruik de knop hieronder om de vouchers beschikbaar te maken voor gebruik.\n<br/><br/>\nControleer of alle vouchers in uw bezit zijn! U kunt deze pagina sluiten en later terugkeren indien nodig.",
                    "button": "VOUCHERS DEBLOKKEREN"
                },
                "order_id": "Order ID",
                "number_of_vouchers": "Fysieke vouchers",
                "vouchers_unblocked": {
                    "title": "Vouchers gedeblokkeerd",
                    "content": "De vouchers die bij deze bestelling horen, zijn gedeblokkeerd en nu beschikbaar voor gebruik. U kunt ze veilig aan de beoogde ontvanger geven of hen laten weten dat ze de voucher kunnen gebruiken bij meer dan 90 partners.",
                    "success_notification": "De vouchers zijn nu beschikbaar voor gebruik."
                }
            },
            "vendor_tracking": {
                "confirm_tracking": {
                    "title": "Track & trace bevestigen",
                    "explainer": "Voeg de track & trace-links toe voor deze bestelling. Als er slechts één track & trace-link is, plaatst u deze in het tekstvak Als er meerdere zijn, upload dan een bestand met alle links of kopieer en plak deze in het grote tekstvak."
                },
                "order_id": "Order ID",
                "email_sent_on": "Vendor email verzonden op:",
                "form": {
                    "links_input_label": "Plak hier de track & trace-links",
                    "links_input_placeholder": "Plak je link(s) hier",
                    "upload_links_label": "Of laad een bestand op met track & trace-links",
                    "submit_button": "Links opslaan",
                    "accepted_file_formats": "Toegestane extensies: csv, xls/xlsx, pdf"
                },
                "confirmed_on": "Order bevestigd door de vendor op:",
                "tracking_confirmed": {
                    "title": "Tracking bevestigd",
                    "explainer": "Tracking bevestigd",
                    "success_message": "Tracking bevestigd"
                }
            },
            "confirm_tracking": {
                "head_title": "Track & trace"
            }
        },
        "tenant-creation": {
            "account-step-label": "Account",
            "account-step-title": "Maak je account aan",
            "account-step-description": "Gebruik je werk e-mailadres om je account aan te maken.",
            "company-email": "Werk e-mailadres",
            "account-step-next-button": "Volgende",
            "company-step-label": "Bedrijf",
            "company-step-title": "Voor welk bedrijf werk je?",
            "company-step-description": "Vul de naam van je bedrijf in en het land waar je bedrijf gevestigd is.",
            "company-step-country-help-text": "<a href=\"{mailTo}\">Neem contact</a> met ons op als je land niet in de dropdown staat.",
            "company-step-next-button": "Volgende",
            "profile-step-label": "Profiel",
            "profile-step-title": "Vul je profiel aan",
            "profile-step-description": "Deze profielgegevens zijn nodig om je account te voltooien. Ze helpen anderen te verduidelijken wie wat heeft besteld.",
            "profile-step-next-button": "Volgende",
            "verify-step-title": "Controleer je gegevens",
            "verify-step-description": "Controleer of de onderstaande informatie correct is. Klik op de knop 'Account aanmaken' onderaan om uw account aan te maken.",
            "verify-step-personal-info": "Profiel gegevens",
            "verify-step-company-info": "Bedrijfsgegevens",
            "edit-info": "Bewerken",
            "verify-step-next-button": "Select account aanmaken",
            "verification-email-step-title": "Je account is aangemaakt!",
            "verification-email-step-description": "Controleer je inbox voor de volgende stap",
            "verification-email-step-body": "We hebben een e-mail gestuurd naar {email} met daarin een link om je wachtwoord in te stellen. Controleer uw e-mail. Het kan even duren voordat deze aankomt.",
            "account-step-company-has-account": "Het lijkt erop dat dit bedrijf al een omgeving heeft.",
            "account-step-you-have-an-account": "Het lijkt erop dat je reeds een account hebt.",
            "account-step-company-has-account-body": "Collega's in je bedrijf hebben reeds een omgeving aangemaakt bij Kadonation Select. Als je toegang wilt krijgen tot deze omgeving, of als je denkt dat er een fout is gemaakt, neem dan contact met ons op.",
            "account-step-you-have-an-account-body": "We hebben een account gevonden voor dit e-mailadres. Neem contact met ons op als je geen toegang meer hebt tot je account en niet weet wat je moet doen.",
            "side-info-title": "Pak uit met impact.",
            "side-info-description": "Eén intuïtief platform dat geven eenvoudig, toegankelijk en persoonlijk maakt. Dankzij Kadonation zet je met elk cadeau een stap naar sterkere relaties.",
            "side-info-bullet-point-1": "Attenties die spreken, met een boodschap die raakt",
            "side-info-bullet-point-2": "Razend efficiënte workflows die werken voor jou",
            "side-info-bullet-point-3": "Eenvoudig budgetbeheer en handige controlemechanismen",
            "set-password-step-title": "Wachtwoord instellen",
            "set-password-step-description": "Bijna klaar met het aanmaken van je account. Maak een wachtwoord aan en we beginnen met het instellen van je nieuwe Select-account.",
            "set-password-step-next-button": "Wachtwoord opslaan",
            "set-password-step-link-expired-title": "Deze link is verlopen.",
            "set-password-step-link-expired-description": "Je hebt te lang gewacht met het instellen van je wachtwoord. Als je je wachtwoord opnieuw wilt instellen, vraag dan een nieuwe link aan.",
            "set-password-step-request-new-link": "Nieuwe link aanvragen",
            "error-title": "Er ging iets mis...",
            "error-description": "Er is een fout opgetreden bij het aanmaken van je account. Neem contact met ons op zodat we je kunnen helpen bij het aanmaken van je account en je toegang kunnen geven tot Kadonation Select.",
            "setup_status_1": "Bezig met je toegang tot 1000+ geschenken op te zetten",
            "setup_status_2": "Bezig met geschenkgelegenheden op te zetten",
            "setup_status_3": "Bezig met personalizatie en ontwerp tools op te zetten",
            "setup_status_4": "Bezig met factuur en bestelling beheer",
            "setup_status_5": "Bezig met je Select omgeving te beveiligen",
            "setup_status_6": "Bezig met finaliseren van je Select omgeving",
            "page-title": "Account Maken",
            "profile-step-department-placeholder": "Departement dat je in werkt, bvb: HR",
            "new-link-sent": "Een nieuw link is gestuurd naar je email adres.",
            "account_being_created": "Je Select omgeving wordt aangemaakt",
            "may_take_time": "Dit kan ongeveer een minuut duren."
        },
        "settings": {
            "invoice_configuration": {
                "grouped_monthly_invoice": "Gegroepeerde maandelijkse factuur",
                "separate_invoice": "Aparte factuur per bestelling"
            },
            "no_delivery_address_set": "Nog geen leveringsadressen ingesteld",
            "delivery_address_set": "{count} leveringsadres ingesteld|{count} leveringsadressen ingesteld",
            "add_more_delivery_address": "Meer toevoegen",
            "add_first_delivery_address": "Voeg één toe",
            "entity_delivery_addresses_description": "Leveringsadressen worden tijdens het afrekenen getoond aan alle bestellende gebruikers. Gebruik ze om gebruikers snel een adres te laten kiezen, in plaats van het in te moeten vullen.",
            "change_default_entity_not_allowed": "Het is niet toegestaan het standaard bedrijf te verwijderen!"
        },
        "messages": {
            "order_confirmed": "Je bestelling is bevestigd",
            "download_digital_vouchers_here": "Download je digitale bonnen hier",
            "order_confirmed_subtext": "Je digitale bonnen zijn hier beschikbaar, de kadodoosjes zullen binnenkort bezorgd worden",
            "invoice_notify_explanation": "Er zal een e-mail naar dit adres verzonden worden wanneer een nieuwe factuur is aangemaakt.",
            "check_verification_link": "Voordat je verder gaat, gelieve in je mail te checken of je een verificatielink ontvangen hebt.",
            "verification_link": "Een nieuwe verificatielink is verzonden naar je e-mailadres",
            "email_verify": "Verifieer je e-mailadres",
            "not_received_verification_mail": "Indien je de mail niet ontvangen hebt, dan:",
            "resend_verification_email": "Klik hier om een nieuwe aan te vragen",
            "forgot_password": "Wachtwoord vergeten?",
            "send_password_link": "Verstuur een link om mijn wachtwoord te resetten",
            "order_for_group_step_1": "Download het sjabloon",
            "order_for_group_step_2": "Vul het sjabloon in met de gevraagde gegevens van je ontvangers en upload het bestand hier.",
            "order_for_group_step_1_subtext": "Download onderstaand excelbestand en vul de gevraagde velden in.",
            "order_for_group_step_2_subtext": "Wanneer je het excelbestand hebt ingevuld, kan je het hieronder uploaden en vervolgens de bestelling toevoegen aan je winkelmandje.",
            "file_uploaded": "Je hebt een bestand geüpload",
            "click_to_upload": "Versleep of <span class=\"underline\">klik hier</span> om een bestand te uploaden",
            "x_files_uploaded": "Je hebt {count} bestanden opgeladen",
            "sent_password_link": "De link om je wachtwoord te resetten wordt naar je e-mail gestuurd als je e-mail in ons systeem staat. Als je geen e-mail ontvangt, neem dan contact op met de klantenondersteuning van Kadonation nadat je je spam-mappen hebt gecontroleerd. Hou in gedachte indien u inlogt via een externe dienst (zoals Google, Microsoft, ...), kunnen wij uw wachtwoord niet resetten.",
            "invoice_address_saved": "Wijzigingen aan de bedrijfsentiteit zijn opgeslagen",
            "cannot_delete_default_entity": "Standaard entiteit kan niet verwijderd worden",
            "company_entity_deleted": "Bedrijfsentiteit met succes verwijderd",
            "delivery_address_saved": "Leveringsadres is opgeslagen",
            "delivery_address_deleted": "Leveringsadres met succes verwijderd",
            "settings_not_updated": "Instellingen konden niet worden bijgewerkt",
            "settings_added": "Instellingen succesvol bijgewerkt",
            "department_added": "Departement is toegevoegd",
            "department_not_added": "Departement kon niet worden toegevoegd",
            "item_placed_in_cart": "Artikel is in je winkelmandje geplaatst.",
            "occasions_updated": "Gelegenheden werden bijgewerkt",
            "item_removed_from_cart": "Artikel is verwijderd van je winkelmandje",
            "user_created": "Gebruiker succesvol aangemaakt",
            "user_updated": "Gebruiker succesvol bijgewerkt",
            "cart_empty": "Je winkelmandje is leeg",
            "mail_subcopy": "Blijf op de hoogte van Kadonation door onze [linkedin](https://www.linkedin.com/company/groupgifting/) te volgen.",
            "mail_greetings": "Met vriendelijke groeten, Het Kadonation Team",
            "reports_text": "Rapporten levert je handige informatie in één oogopslag over hoe Kadonation Select gebruikt wordt in jouw bedrijf. Het geeft je meer inzicht in wat de populairste gelegenheden zijn, de meest gekozen geschenken, het gemiddelde bedrag per cadeau… Je kan kiezen om een overzicht te krijgen per gebruiker, per departement of van het volledige bedrijf.\nBeschikbaar najaar 2021.",
            "company_entity_created": "Nieuwe bedrijfsentiteit toegevoegd",
            "creditnote_creation_failed": "Aanmaken van creditnota's is mislukt",
            "not_a_valid_invoice": "Geen geldige factuur",
            "you_must_confirm_the_warning": "Bevestig het waarschuwingsbericht voordat u naar de kassa gaat",
            "po_number_has_been_saved": "PO-nummer is opgeslagen",
            "po_number_has_been_saved_and_invoice_finalized": "PO-nummer is opgeslagen en de factuur is gefinaliseerd",
            "confirm": "Weet je het zeker?",
            "employee_created": "Werknemer toegevoegd",
            "employee_updated": "Werknemer bijgewerkt",
            "employee_deleted": "Werknemer verwijderd",
            "employees_imported": "Werknemers geïmporteerd",
            "invalid_time_format": "Het tijdsformaat is ongeldig, gelieve de tijd als volgt te formatteren hh{MM}",
            "invalid_time_in_past": "De tijd is in het verleden, gelieve een tijd op te geven in de toekomst",
            "invalid_date_format": "Het datumformaat is ongeldig, gelieve data zo te formatteren: dd/mm/jjjjj",
            "invalid_date_in_past": "De voorziene datum is in het verleden, gelieve een datum en tijd op te geven minimum 1 uur in de toekomst",
            "digital_delivery_treshold_cart_error": "Uw winkelmandje bevat digitale vouchers die rechtstreeks naar de ontvangers worden gestuurd. Gelieve deze artikelen apart af te rekenen (van niet-digitale artikelen), zodat we tijdige levering kunnen garanderen.",
            "employees_deleted": "Werknemers verwijderd",
            "invalid_must_ahead_hour": "Datum en tijd moeten minimaal meer dan één uur in de toekomst zijn",
            "campaign_created": "Campagne succesvol gemaakt.",
            "campaign_inactive": "Campagne is inactief.",
            "kudos_user_not_found": "Gebruiker niet gevonden",
            "kudos_campaign_not_found": "Campagne werd niet gevonden",
            "kudos_user_is_not_on_the_list_of_recipients": "Gebruiker is niet beschikbaar in de lijst van ontvangers",
            "there_are_orders_pending_approval": "Waarschuwing: er zijn bestellingen die op uw goedkeuring wachten",
            "order_is_pending_approval": "De bestelling wacht op goedkeuring",
            "order_has_been_cancelled": "De bestelling werd geannuleerd",
            "invalid_datetime_format": "Het datumformaat is ongeldig, gelieve data zo te formatteren: dd/mm/jjjjj uu{mm}",
            "department_restored": "Een departement met deze naam bestond reeds en is opnieuw geactiveerd",
            "orders_has_been_rejected": "Bestellingen zijn afgewezen",
            "orders_has_been_approved": "Bestellingen zijn goedgekeurd",
            "successfully_updated": "Succesvol bijgewerkt!",
            "successfully_deleted": "Verwijderd!",
            "error_on_request": "Er is iets mis met het verzoek. Probeer het opnieuw.",
            "copied": "Gekopieerd",
            "api_key_removed": "api-sleutel verwijderd",
            "api_key_added": "api-sleutel toegevoegd",
            "order_vouchers_successfully_deleted": "Vouchers onder deze order zijn succesvol verwijderd!",
            "order_vouchers_failed_delete": "Fout bij het verwijderen. Probeer het later nog eens.",
            "order_vouchers_some_claimed": "Aanvraag mislukt! Sommige van de vouchers kunnen reeds geclaimd zijn.",
            "verify_when_importing_info": "Bij verificatie wordt de bestelling naar de klant gecommuniceerd en wordt er een factuur gegenereerd",
            "direct_delivery_incomplete_or_invalid": "Directe levering gedetecteerd door geldige velden: {validFields}.\nSommige waarden ontbreken of zijn ongeldig om door te gaan: {invalidFields}",
            "sendgrid_failed_explanation": {
                "unknown": "Op dit moment kunnen we de correcte status van de levering niet tonen. Dit kan zijn omdat de deadline nog niet bereikt is of omdat de status nog niet is doorgegeven door onze e-mail service provider (dit kan enkele uren duren).",
                "deferred": "De e-mail server van de ontvanger heeft de e-mail geweigerd, contacteer jullie IT-afdeling om te vragen waarom deze is geweigerd. Als de IT-afdeling het heeft opgelost dan kan je hier de zending opnieuw versturen.",
                "blocks": "De e-mail server van de ontvanger heeft de e-mail geweigerd, contacteer jullie IT-afdeling om te vragen waarom deze is geweigerd. Als de IT-afdeling het heeft opgelost dan kan je hier de zending opnieuw versturen.",
                "dropped": "De e-mail kon niet geleverd worden omdat we bij voorgaande e-mails een automatisch antwoord kregen van jullie e-mail service provider dat deze niet geleverd kon worden. Contacteer onze klantendienst als het probleem is opgelost zodat we de zending opnieuw kunnen proberen.",
                "bounces": "De e-mail server van de ontvanger heeft de e-mail geweigerd, contacteer jullie IT-afdeling om te vragen waarom deze is geweigerd. Als de IT-afdeling het heeft opgelost dan kan je hier de zending opnieuw versturen."
            },
            "invalid_vat": "Ongeldig btw-nummer",
            "vat_validation_error": "Kan de BTW op dit moment niet valideren.",
            "cannot_change_vat_explanation": "Het is niet mogelijk om een btw-nummer te wijzigen van een bestaande bedrijfsentiteit. Gelieve een nieuwe bedrijfsentiteit aan te maken met het nieuwe btw-nummer.",
            "campaign_updated": "Campagne succesvol aangepast.",
            "something_went_wrong": "Er is iets misgegaan, probeer het nog eens.",
            "video_updated": "Succesvol bijgewerkt!",
            "cannot_add_multiple_vendor_in_cart": "Je kan geen geschenken van meerdere verkopers in de winkelwagen toevoegen.",
            "no_direct_delivery_but_with_fields": "Directe levering staat uit maar de excel bevat wel kolommen hiervoor. Verwijder deze of zet \"Rechtstreeks naar ontvanger\" aan.",
            "cannot_edit_your_permissions": "Het is niet mogelijk om je eigen permissies te wijzigen. Vraag een andere beheerder om je rechten te wijzigen.",
            "failed_to_place_the_order": "Kan de bestelling niet plaatsen.",
            "the_cart_is_empty": "De winkelwagen is leeg.",
            "cannot_add_different_delivery_methods_to_cart": "Het is niet toegestaan om verschillende bezorgmethoden aan dezelfde winkelwagen toe te voegen.",
            "orders_have_been_rejected": "Uw bestellingen zijn succesvol afgewezen",
            "orders_have_been_approved": "Uw bestellingen zijn succesvol goedgekeurd",
            "should_not_add_physical_gifts_to_kudos_order": "U kunt alleen digitale geschenken bestellen voor Kudos-campagnes.",
            "no-gifts-found-for-the-occasion": "Er zijn geen geschenken gevonden voor deze gelegenheid.",
            "failed_updating": "Mislukt om bij te werken",
            "video_not_updated": "Video werd niet bijgewerkt!",
            "department_updated": "Het department is bijgewerkt",
            "department_deleted": "Departement is verwijderd",
            "department_not_updated": "Departementen kunnen niet bijgewerkt worden.",
            "department_not_deleted": "Departement kon niet worden toegevoegd",
            "department_not_restored": "Een departement met deze naam bestond al eerder, maar kon niet opnieuw worden geactiveerd.",
            "campaign_deleted": "Campagne succesvol verwijderd.",
            "video_deleted": "Video succesvol verwijderd.",
            "video_not_deleted": "De video is niet verwijderd!",
            "shipping_restricted_to_weekdays": "Het geschenk {gift} kan enkel geleverd worden op {days}.",
            "delivery_date_field_must_be_greater_than_or_equal_to_earliest_delivery_date": "Bezorgdatum moet later of gelijk zijn dan {date}.",
            "delivery_date_field_is_invalid": "bezorgdatum moet in dit formaat zijn: dd/mm/jjjj (bvb: 01/01/2023)",
            "cannot_deliver_on_delivery_date_because_it_is_holiday": "Er kan niet geleverd worden op {date}. Dit is een feestdag.",
            "delivery_date_field_must_be_in_shipping_option_available_delivery_days_array": "Leveringsdag {date} is een {day}. De leveringsdag moet één van deze zijn: {delivery_days}.",
            "welcome_email_sent": "Welkom e-mail verzonden.",
            "user_is_not_active": "Actie is niet toegestaan omdat de gebruiker niet actief is.",
            "cannot_send_welcome_email_as_user_is_verified": "Kan de welkom e-mail niet verzenden omdat de gebruiker al geverifieerd is.",
            "sent_password_link_by_admin": "Er wordt een e-mail naar de gebruiker gestuurd met de details over het opnieuw instellen van het wachtwoord.",
            "new_links": "NIEUWE LINKS -",
            "resend_vendor_order_mail": "Order e-mail verzonden",
            "vendor_mail_has_been_resent": "Order email is opnieuw verzonden.",
            "vendor_mail_resend_explanation": "Klik op de knop hieronder om de order email opnieuw te versturen. Deze bevat nieuwe links naar bestanden.",
            "vendor_mail_resend_button_title": "Email Opnieuw Versturen",
            "to_field_is_required": "Geaddresseerd_aan is verplicht.",
            "recipient_first_name_field_is_required": "'Ontvanger voornaam' is verplicht.",
            "recipient_last_name_field_is_required": "'Ontvanger achternaam' is verplicht.",
            "no_delivery_in_the_future_for_non_vouchers_gift_but_with_fields": "Bij dit geschenk kan je de leveringsdatum niet in Excel zetten. Gelieve de kolom te verwijderen om te bestelling te kunnen plaatsen.",
            "street_field_is_required": "'Straat' is verplicht.",
            "street_number_field_is_required": "'Nummer' is verplicht.",
            "zip_code_field_is_required": "'Postcode' is verplicht.",
            "city_field_is_required": "'Stad' is verplicht.",
            "country_field_is_required": "'Land' is verplicht.",
            "proforma_notify_explanation": "Er zal een e-mail naar dit adres verzonden worden wanneer een nieuwe proforma factuur is aangemaakt.",
            "magic_link_created": "Nieuwe link is verstuurd naar je e-mail.",
            "shipping_cannot_be_done_in_this_day": "Cannot deliver on delivery date set",
            "no_apideck_connection": "Er is geen werknemerssynchronisatieverbinding gevonden. Configureer de synchronisatie opnieuw.",
            "invoice_notify_cc_explanation": "Elk ingegeven email adres hier zal ook de facturen ontvangen. Meerdere email adressen zijn mogelijk, scheid deze met een komma.",
            "order_notify_email_cc": "Elk ingegeven email adres hier zal alle bestelling emails ontvangen. Meerdere email adressen zijn mogelijk, scheid deze met een komma.",
            "generating-try-later": "Bonnen worden op dit moment gecreëerd. Gelieve later opnieuw te proberen.",
            "cannot_add_when_direct_future_delivery_non_voucher_gift_in_cart": "Je hebt een geschenk aan je winkelwagen toegevoegd waarbij per ontvanger een bezorgdatum is ingesteld. Dit kan niet worden gecombineerd met andere geschenken. Plaats eerst de bestelling voor het geschenk in je winkelwagen.",
            "can_only_add_one_item_with_future_delivery_to_cart": "Je kan maar één geschenk toevoegen aan je winkelwagen wanneer het geschenk een bezorgdatum per ontvanger heeft.",
            "fixed_po_number_explainer": "Gebruikt automatisch dit PO nummer op alle bestellingen geplaatst voor deze entiteit. Niet van toepassing op automatisaties.",
            "fixed_cost_center_explainer": "Gebruikt automatisch deze kostenplaats op alle bestellingen geplaatst voor deze entiteit. Niet van toepassing op automatisaties.",
            "daily_order_threshold_reached": "Dagelijkse bestelling limiet overschreden",
            "monthly_order_threshold_reached": "Maandelijkse bestelling limiet overschreden",
            "order_is_pending_kadonation_approval": "Bestelling wacht op goedkeuring door Kadonation. We nemen snel contact met u op.",
            "user_deleted": "Gebruiker werd verwijderd.",
            "mollie_repay_failed": "Je betaling is mislukt. Probeer het opnieuw.",
            "logo_updated": "Logo succesvol geupload.",
            "keep-account-text": "Bevestig hieronder om je account te behouden."
        },
        "pagination": {
            "next": "Volgende",
            "previous": "Vorige",
            "first": "Eerste",
            "last": "Laatste"
        },
        "user": {
            "profile": "Profiel",
            "actions": "Acties",
            "fixed_cost_center_explanation": "Wanneer hier een waarde gegeven is, zal de kostenplaats automatisch toegevoegd worden aan elke order dat deze gebruiker plaatst.",
            "search": {
                "placeholder": "Zoek naar een gebruiker",
                "no_results": "Geen gebruikers gevonden.",
                "loading": "Zoeken naar gebruikers..."
            },
            "buttons": {
                "edit": "Bewerken"
            },
            "approval_manager_explanation": "Deze persoon zal elke bestelling van de gebruiker moeten goedkeuren of afkeuren.",
            "backup_approval_manager_explanation": "Deze persoon kan elke bestelling goedkeuren die {name} zou moeten goedkeuren. Dit is handig wanneer {name} op vakantie gaat."
        },
        "occasion": {
            "shop_now": "Geschenken bekijken",
            "your_occasions": "Geschenkgelegenheden",
            "upsell_banner_title": "Verras iemand met het perfecte geschenk!",
            "other_occasions": "Andere gelegenheden",
            "occasions_previously_bought_for": "Geschenkgelegenheden waar eerder voor is gekocht",
            "popular_gift_occasions": "Populaire geschenkgelegenheden",
            "view_all_occasions": "Toon alle gelegenheden",
            "webshop_link_button": "Bestel de Kadonation Cadeaubon",
            "upsell_banner_list_items": "Personaliseer met boodschap voor de ontvanger^Gebruik bij meer dan 100 partners^Je cadeaubon is 3 jaar geldig^Ideaal voor elke gelegenheid"
        },
        "countries": {
            "BE": "België",
            "AT": "Oostenrijk",
            "BG": "Bulgarije",
            "CY": "Cyprus",
            "CZ": "Tsjechië",
            "DE": "Duitsland",
            "DK": "Denemarken",
            "EE": "Estland",
            "ES": "Spanje",
            "FI": "Finland",
            "FR": "Frankrijk",
            "GB": "Verenigd Koninkrijk",
            "GR": "Griekenland",
            "HR": "Kroatië",
            "HU": "Hongarije",
            "IE": "Ierland",
            "IT": "Italië",
            "LT": "Litouwen",
            "LU": "Luxemburg",
            "LV": "Letland",
            "MT": "Malta",
            "NL": "Nederland",
            "PL": "Polen",
            "PT": "Portugal",
            "RO": "Roemenië",
            "SE": "Zweden",
            "SI": "Slovenië",
            "SK": "Slowakije",
            "CH": "Zwitserland",
            "UA": "Ukraine",
            "MK": "Macedonia",
            "IL": "Israël",
            "NO": "Noorwegen",
            "US": "Verenigde Staten"
        },
        "project-vendors": {
            "delivery_address_info_for_home_delivery": "Deze bestelling moet rechtstreeks worden verzonden naar de adressen van de ontvangers, Je vindt de addresses van de ontvangers in de \\'download excel met gegevens van ontvangers\\'.",
            "download_recipients_data": "Download excel met gegevens van ontvangers (kan extra kolommen bevatten voor specifieke verzoeken)",
            "recipients_data": "Gegevens van ontvangers",
            "shipping_limitation_notice": "We tonen enkel landen waar onze leverancier naar kan verzenden.",
            "see_download_recipients_data": "Zie: download excel met gegevens van ontvangers",
            "personalization_file": "Personalizatie bestand:",
            "download_personalization_file": "Download bestand",
            "personalization_id": "Colorlab Personalisatie ID:"
        },
        "passwords": {
            "reset": "Jouw wachtwoord is gereset!",
            "sent": "We hebben je een link gestuurd om je wachtwoord te resetten.",
            "throttled": "Gelieve even te wachten alvorens het opnieuw te proberen.",
            "token": "Deze wachtwoord reset token is niet langer geldig, ga naar de applicatie en vraag een nieuwe wachtwoord reset link aan. Of contacteer je HR verantwoordelijke en vraag een wachtwoord reset aan hen.",
            "user": "We kunnen geen gebruiker vinden met dit e-mailadres.",
            "password_created_successfully": "Uw wachtwoord is aangemaakt.",
            "invalid_token_while_creating": "De link voor het aanmaken van een wachtwoord is niet meer geldig. Neem contact op met uw HR-beheerder om een nieuwe e-mail aan te vragen.",
            "password_already_created": "Je hebt al een wachtwoord ingesteld voor je account. Gebruik het hier om in te loggen, of gebruik de link 'wachtwoord vergeten' om een nieuw wachtwoord in te stellen.",
            "user_not_active": "Uw account is niet actief. Neem contact op met uw HR-beheerder en vraag of zij uw account kunnen activeren."
        },
        "activity": {
            "created": "Gemaakt",
            "updated": "Bijgewerkt"
        },
        "searchg": {
            "head_title": "Geschenk zoeken",
            "search_title": "Naar wat ben je op zoek?",
            "algolia_gift_search_placeholder": "Zoeken naar... 'chocolade', 'brievenbus', 'ballon', 'cadeaubon', 'apéro', etc"
        },
        "cookies": {
            "title": "Cookies",
            "text": "Kadonation wil anoniem statistische data verzamelen om ons platform gebruiksvriendelijker te maken. Hiervoor hebben we jouw toestemming nodig. Ga je hiermee akkoord?",
            "accept": "Aanvaarden",
            "decline": "Weigeren"
        }
    }
}
